<div class="omg-form">
  <ion-item class="omg-form-checkbox" lines="none">
    <ion-checkbox label-placement="end" justify="start" [ngModel]="nextMeeting?.show" (ngModelChange)="onShowChange($event)">
      {{ "sendProtocol.sendNewAppointment" | translate }}
    </ion-checkbox>
  </ion-item>

  <ng-container *ngIf="nextMeeting?.show">
    <app-next-meeting-form
      [nextMeeting]="nextMeeting"
      (nextMeetingChange)="handleNextMeetingChange($event)"
    ></app-next-meeting-form>
    <app-individual-next-meetings-groups
      [participants]="participants"
      (afterViewInit)="handleMeetingsGroupsInit()"
      [nextMeeting]="nextMeeting"
      [meetings]="individualNextMeetings"
      (nextMeetingChange)="nextMeetingChange.emit($event)"
      (individualNextMeetingsChange)="individualNextMeetingsChange.emit($event)"
    ></app-individual-next-meetings-groups>
  </ng-container>
</div>
