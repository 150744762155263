import {Injectable, OnDestroy} from '@angular/core';
import posthog from 'posthog-js';
import {ClientStatusEnum, LicenseType, User} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {UserDataService} from '../data/user-data.service';
import {Subscription} from 'rxjs';
import {observableToPromise} from 'src/app/utils/async-utils';
import {map} from 'rxjs/operators';
import {ProfileDataService} from '../data/profile-data.service';
import {AddressDataService} from '../data/address-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {ThemeService} from '../ui/theme.service';
import {environment} from 'src/environments/environment';
import {ClientService} from '../client/client.service';
import {Platform} from '@ionic/angular';
import {StorageService} from '../storage.service';
import {StorageKeyEnum} from 'src/app/shared/constants';
import {FeatureFlagKey} from 'src/app/model/feature-flags';
import {Device} from '@capacitor/device';
import {LanguageService} from '../i18n/language.service';
import {DeviceService} from '../ui/device.service';

const STORAGE_KEY = StorageKeyEnum.POSTHOG_FEATURE_OPTED_IN;

@Injectable({
  providedIn: 'root',
})
export class PosthogService implements OnDestroy {
  private userSubscription: Subscription | undefined;
  private routerSubscription: Subscription | undefined;
  private user: User | undefined;
  public posthogInitialized = false;

  constructor(
    private userDataService: UserDataService,
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private companyDataService: CompanyDataService,
    private themeService: ThemeService,
    private clientService: ClientService,
    private platform: Platform,
    private storage: StorageService,
    private languageService: LanguageService,
    private deviceService: DeviceService
  ) {}

  public initPosthog() {
    this.unsubscribeAll();
    posthog.init(environment.posthogToken, {
      api_host: environment.posthogUrl,
      disable_session_recording: true,
      autocapture: {
        css_selector_allowlist: ['[ph-custom-id]'],
      },
    });
    this.posthogInitialized = true;

    this.userSubscription = this.userDataService.currentUser$.subscribe((currentUser) => {
      if (!_.isEmpty(currentUser)) {
        this.user = currentUser;
        this.identifyUser(this.user);
      } else {
        this.user = undefined;
      }
    });

    posthog.onFeatureFlags(async () => {
      if (posthog.isFeatureEnabled('bim')) {
        await this.addToStorage('bim', posthog.isFeatureEnabled('bim'));
      }
    });
  }

  private getEventProperties() {
    const currentClient = this.clientService.getCurrentClient();
    return {
      clientStatus: ClientStatusEnum[currentClient?.status] ?? 'UNKNOWN',
      clientName: currentClient?.name ?? 'UNKNOWN',
      clientId: currentClient?.id ?? 'UNKNOWN',
      clientChangedAt: currentClient?.changedAt ?? 'UNKNOWN',
      userLicense: LicenseType[this.user?.role] ?? 'UNKNOWN',
      userChangedAt: this.user?.changedAt ?? 'UNKNOWN',
      screenWidth: this.platform.width(),
      screenHeight: this.platform.height(),
    };
  }

  public captureEvent(eventName: string, eventProperties: object) {
    posthog.capture(eventName, {...eventProperties, ...this.getEventProperties()});
  }

  public startRecording() {
    posthog.startSessionRecording();
  }

  public stopRecording() {
    posthog.stopSessionRecording();
  }

  public async optInEarlyAccessFeature(key: FeatureFlagKey) {
    posthog.updateEarlyAccessFeatureEnrollment(key, true);
    await this.addToStorage(key, true);
  }

  public async optOutEarlyAccessFeature(key: FeatureFlagKey) {
    posthog.updateEarlyAccessFeatureEnrollment(key, false);
    await this.addToStorage(key, false);
  }

  public async isFeatureEnabled(key: FeatureFlagKey) {
    try {
      const posthogEnabled = posthog.isFeatureEnabled(key);
      return posthogEnabled ?? ((await this.storage.get(STORAGE_KEY)) as Partial<Record<FeatureFlagKey, boolean>>)?.[key];
    } catch (e) {
      return ((await this.storage.get(STORAGE_KEY)) as Partial<Record<FeatureFlagKey, boolean>>)?.[key];
    }
  }

  private async addToStorage(key: FeatureFlagKey, value: boolean) {
    const featureFlagMap: Partial<Record<FeatureFlagKey, boolean>> = (await this.storage.get(STORAGE_KEY)) ?? {};
    await this.storage.set(STORAGE_KEY, {...featureFlagMap, [key]: value});
  }

  private async identifyUser(user: User) {
    const profile = await observableToPromise(this.profileDataService.dataReally.pipe(map((profiles) => profiles.find((userProfile) => userProfile.id === user.profileId))));
    const address = await observableToPromise(this.addressDataService.dataReally.pipe(map((addresses) => addresses.find((userAddress) => userAddress.id === profile?.addressId))));
    const company = await observableToPromise(this.companyDataService.dataReally.pipe(map((companies) => companies.find((userCompany) => userCompany.id === profile?.companyId))));
    const deviceInfo = await Device.getInfo();
    const language = await this.languageService.getSelectedLanguage();
    const orientation = this.platform.isLandscape() ? 'landscape' : 'portrait';
    const isNativeApp = await this.deviceService.isNativeApp();

    if (!user || !address || !company) {
      return;
    }

    posthog.identify(environment.name + '/' + user.id, {
      email: user.email,
      first_name: address.firstName,
      last_name: address.lastName,
      created_at: user.dateJoined,
      darkMode: this.themeService.isDark,
      admin: this.user.isClientAdmin,
      status: LicenseType[user.role],
      bmVersion: environment.version,
      statusClient: ClientStatusEnum[this.clientService.getCurrentClient()?.status],
      companyName: company.name,
      companyId: company.id,
      bmUserId: user.id,
      nativeDevice: isNativeApp,
      os: deviceInfo.operatingSystem,
      osVersion: deviceInfo.osVersion,
      screenOrientation: orientation,
      language,
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  private unsubscribeAll() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = undefined;
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
      this.routerSubscription = undefined;
    }
  }
}
