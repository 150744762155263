<app-modal-header>
  <ion-title>{{ 'moveProjectAttachments.title' | translate }}</ion-title>
  <div class="ion-text-center no-internet-text-container" *ngIf="isOffline$ | async">
    <ion-label class="omg-attention omg-attention-danger">{{'moveProjectAttachments.noInternet' | translate}}</ion-label>
  </div>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="form" action="#">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-item [disabled]="(isOffline$ | async) || moving">
            <ion-label position="floating">{{ 'copyWorkflow.destinationProject' | translate }}</ion-label>
            <ionic-selectable #projectSelectable
                              hasVirtualScroll="true"
                              *ngIf="projects?.length"
                              [isMultiple]="false"
                              required="true"
                              (onChange)="projectOnChange($event)"
                              item-content
                              itemTextField="name"
                              formControlName="project"
                              [items]="projects"
                              itemValueField="id"
                              [canSearch]="true"
                              searchPlaceholder="{{'search' | translate}}"
                              searchFailText="{{'noItemsFound' | translate}}"
                              appSelectableCommon
                              #fs="appSelectableCommon"
                              (onOpen)="onOpen($event)"
                              (onClose)="onClose($event)"
                              [virtualScrollHeaderFn]="getGroupText"
                              [shouldFocusSearchbar]="fs.isDesktop">
              <ng-template ionicSelectableItemTemplate let-project="item" let-isSelected="isItemSelected">
                <app-project-selector-item [project]="project"></app-project-selector-item>
              </ng-template>
              <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
                <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
              </ng-template>
              <ng-template ionicSelectableValueTemplate let-value="value">
                <app-selectable-value [value]="value"></app-selectable-value>
              </ng-template>
              <ng-template ionicSelectableHeaderTemplate>
                <app-selectable-header></app-selectable-header>
              </ng-template>
              <ng-template ionicSelectableFooterTemplate>
                <app-selectable-footer [canClear]="false"></app-selectable-footer>
              </ng-template>
            </ionic-selectable>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismissModal()">{{'cancel' | translate}}</ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!(destinationProject$ | async) || (isOffline$ | async) || moving" (click)="moveAttachments()">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="moving"></ion-spinner>
      {{ 'moveProjectAttachments.move' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>