<ionic-selectable
  #profilesSelectable
  [canSearch]="true"
  [isEnabled]="true"
  searchPlaceholder="{{ 'search' | translate }}"
  searchFailText="{{ 'noItemsFound' | translate }}"
  [items]="profiles$ | async"
  [ngModel]="selectProfiles$ | async"
  (ngModelChange)="handleProfilesChange($event)"
  itemValueField="id"
  itemTextField="searchText"
  groupValueField="company.id"
  groupTextField="company.name"
  class="text-primary"
  [isMultiple]="true"
  appSelectableCommon
  #fs="appSelectableCommon"
  (onOpen)="onOpen($event)"
  (onClose)="onClose($event)"
  [shouldFocusSearchbar]="fs.isDesktop"
>
  <ng-template ionicSelectableMessageTemplate>
    <div class="omg-attention omg-attention-warning attention-full-width">
      {{ "sendProtocol.protocolConfig.nextMeeting.sendOnlyToRecipients" | translate }}
    </div>
  </ng-template>
  <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
    <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
  </ng-template>
  <ng-template ionicSelectableValueTemplate let-value="value">
    <app-selectable-value [value]="value"></app-selectable-value>
  </ng-template>
  <ng-template ionicSelectableHeaderTemplate>
    <app-selectable-header></app-selectable-header>
  </ng-template>
  <ng-template ionicSelectableFooterTemplate>
    <app-selectable-footer></app-selectable-footer>
  </ng-template>
  <ng-template ionicSelectableItemTemplate let-profile="item">
    <ion-label class="participant">
      <ion-text class="participant-name">
        {{ profile.address.firstName }} {{ profile.address.lastName }}
      </ion-text>
      <ion-text class="participant-email">
        {{ profile.address.email }}
      </ion-text>
    </ion-label>
  </ng-template>
</ionic-selectable>

<ion-button
  class="omg-btn-secondary"
  (click)="profilesSelectable.open()"
>
  <fa-icon [icon]="['fal', 'user']" slot="start"></fa-icon>
  <span>
    {{ 'sendProtocol.protocolConfig.nextMeeting.manageSelectedContacts' | translate }}
  </span>
</ion-button>

<ion-grid class="ion-no-padding">
  <ion-row>
    <ion-col size="12" class="mt-3 mb-2">
      <ion-text class="text-primary bold">
        {{ "sendProtocol.protocolConfig.nextMeeting.invitationFor" | translate }}
      </ion-text>
    </ion-col>
  </ion-row>
</ion-grid>

<div *ngIf="meetings$ | async as meetings">
  <app-individual-next-meetings
    [nextMeeting]="nextMeeting"
    [individualNextMeetings]="meetings"
    (individualNextMeetingsChange)="handleMeetingsChange($event)"
  ></app-individual-next-meetings>
  <h3 class="text-secondary ion-text-center" *ngIf="meetings.length === 0">
    {{ 'sendProtocol.protocolConfig.nextMeeting.noContactsSelected' | translate }}
  </h3>
</div>