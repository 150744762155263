import {AsyncPipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule, LoadingController, Platform, ModalController} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {RxLet} from '@rx-angular/template/let';
import {distinctUntilChanged, map, Subject, takeUntil} from 'rxjs';
import {AttachmentBlob} from 'src/app/model/attachments';
import {Nullish} from 'src/app/model/nullish';
import {AttachmentService} from 'src/app/services/attachment/attachment.service';
import {AuthenticationService} from 'src/app/services/auth/authentication.service';
import {LoggingService} from 'src/app/services/common/logging.service';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {OfflineInfoService} from 'src/app/services/common/offline-info.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {AttachmentProjectDataService} from 'src/app/services/data/attachment-project-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {LogActionInstance, SystemEventService} from 'src/app/services/event/system-event.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {PhotoService} from 'src/app/services/photo/photo.service';
import {ProjectRoomAttachmentsSelectionService} from 'src/app/services/project-room/project-room-attachments-selection.service';
import {ProjectRoomAttachmentsService} from 'src/app/services/project-room/project-room-attachments.service';
import {UserService} from 'src/app/services/user/user.service';
import {IMAGE_SIZE_COMPRESSION_LIMIT_IN_BYTES} from 'src/app/shared/constants';
import {AbortError, ErrorWithUserMessage, convertErrorToMessage} from 'src/app/shared/errors';
import {abortableObservable} from 'src/app/utils/abortable-observable';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {convertFileViaBinaryStringToFile, ensureMimeTypeSet, extractCommonAttachmentProperties, isImage, isImageBlob, isQuotaExceededError} from 'src/app/utils/attachment-utils';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {environment} from 'src/environments/environment';
import {MoveProjectRoomAttachmentsModalComponent} from '../move-project-room-attachments-modal/move-project-room-attachments-modal.component';
import {PipesModule} from 'src/app/pipes/pipes.module';
import {TooltipModule} from 'src/app/shared/module/tooltip/tooltip.module';
import _ from 'lodash';
import {AlertService} from 'src/app/services/ui/alert.service';
import {Attachment, AttachmentProject, LicenseType, MIME_TYPE_EXTENSION_WHITELIST, Project, User} from 'submodules/baumaster-v2-common';
import {v4 as uuidv4} from 'uuid';
import {DeviceService} from 'src/app/services/ui/device.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';

const LOG_SOURCE = 'ProjectRoomAttachmentsToolbarComponent';

@Component({
  selector: 'app-project-room-attachments-toolbar',
  templateUrl: './project-room-attachments-toolbar.component.html',
  styleUrls: ['./project-room-attachments-toolbar.component.scss'],
  standalone: true,
  imports: [IonicModule, RxLet, FontAwesomeModule, AsyncPipe, TranslateModule, PipesModule, TooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectRoomAttachmentsToolbarComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  protected allSelected$ = combineLatestAsync([this.projectRoomAttachmentsService.attachments$, this.selectionService.selectedSet$]).pipe(
    map(([attachments, selectedSet]) => selectedSet.size > 0 && attachments.every((att) => selectedSet.has(att.id))),
    distinctUntilChanged()
  );

  protected atLeastOneSelected$ = combineLatestAsync([this.projectRoomAttachmentsService.attachments$, this.selectionService.selectedSet$]).pipe(
    map(([attachments, selectedSet]) => selectedSet.size > 0 && attachments.some((att) => selectedSet.has(att.id))),
    distinctUntilChanged()
  );

  readonly isFeatureEnabled$ = this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.PROFESSIONAL]);
  readonly isDesktop = this.deviceService.isDesktop();
  protected readonly uploadingAttachmentsEnabled$ = combineLatestAsync([
    this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.VIEWER], null, true),
    this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.BASIC, LicenseType.LIGHT, LicenseType.PROFESSIONAL]),
  ]).pipe(map(([isViewerWithReportRights, isNotViewerOrConnected]) => isViewerWithReportRights || isNotViewerOrConnected));
  protected readonly acceptedMimeTypesForUpload = MIME_TYPE_EXTENSION_WHITELIST.join(',');
  private readonly convertForExternalSourcesNeeded = this.platform.is('android');
  private attachments: Array<AttachmentProject> | undefined;
  private currentProject: Project | undefined;
  private currentUser: User | undefined;
  @ViewChild('downloadExportLink', {static: false}) downloadExportLink: ElementRef<HTMLLinkElement>;

  deletionEnabled$ = this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.PROFESSIONAL]);

  constructor(
    protected selectionService: ProjectRoomAttachmentsSelectionService,
    private projectRoomAttachmentsService: ProjectRoomAttachmentsService,
    private featureEnabledService: FeatureEnabledService,
    private platform: Platform,
    private photoService: PhotoService,
    private loadingController: LoadingController,
    private translateService: TranslateService,
    private attachmentProjectDataService: AttachmentProjectDataService,
    private systemEventService: SystemEventService,
    private attachmentService: AttachmentService,
    private loggingService: LoggingService,
    private toastService: ToastService,
    private projectDataService: ProjectDataService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private userService: UserService,
    private networkStatusService: NetworkStatusService,
    private offlineInfoService: OfflineInfoService,
    private modalController: ModalController,
    private alertService: AlertService,
    private deviceService: DeviceService,
    private posthogService: PosthogService
  ) {}

  ngOnInit() {
    this.projectDataService.currentProjectObservable.pipe(takeUntil(this.destroy$)).subscribe((currentProject) => (this.currentProject = currentProject));
    this.userService.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((currentUser) => (this.currentUser = currentUser));
    this.attachmentProjectDataService.data.pipe(takeUntil(this.destroy$)).subscribe((projectAttachments) => (this.attachments = projectAttachments));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async selectAll() {
    const attachments = await observableToPromise(this.projectRoomAttachmentsService.attachments$);
    this.selectionService.select(attachments);
  }

  uploadFileEvent = async (event: any) => {
    const files: FileList = event.target.files;
    const blobFileNames = new Array<{blob: Blob; fileName: string}>();
    let compressingLoading: HTMLIonLoadingElement | undefined;
    try {
      for (let i = 0; i < files.length; i++) {
        let file = files.item(i);
        if (this.convertForExternalSourcesNeeded) {
          file = await convertFileViaBinaryStringToFile(file);
        }
        if (!(await this.photoService.ensureContentIsJpegOrPng(file, 'entry'))) {
          break;
        }
        if (file.size > IMAGE_SIZE_COMPRESSION_LIMIT_IN_BYTES && isImageBlob(file)) {
          if (!compressingLoading) {
            compressingLoading = await this.loadingController.create({
              message: this.translateService.instant('imageCompressionInProgress'),
            });
            await compressingLoading.present();
          }
          const blob = await this.photoService.scaleImage(file);
          blobFileNames.push({blob, fileName: file.name});
        } else {
          blobFileNames.push({blob: file, fileName: file.name});
        }
      }
      const addedAttachments = await this.addAttachments(blobFileNames);
      if (addedAttachments && addedAttachments.length === 1 && isImage(addedAttachments[0])) {
        await this.photoService.showToastMessageImageTakenEditMarkings(addedAttachments[0], this.onTakenPhotoMarkingsChanged, blobFileNames[0].blob);
      } else if (addedAttachments && addedAttachments.length > 1) {
        await this.toastService.info(this.translateService.instant('attachment.multipleAttachmentsSuccess', {count: addedAttachments.length}));
      }
    } finally {
      if (compressingLoading) {
        await compressingLoading.dismiss();
        compressingLoading = undefined;
      }
      // reset the file upload, otherwise you are not able to upload the same file again
      event.target.value = '';
    }
  };

  private onTakenPhotoMarkingsChanged = async (newAttachment: AttachmentBlob | Attachment, markings: Nullish<string>) => {
    if (!this.attachments) {
      return;
    }
    const editedAttachment: AttachmentProject = this.attachments.find((attachment) => attachment.id === newAttachment.id);
    if (editedAttachment) {
      editedAttachment.markings = markings;
      await this.attachmentProjectDataService.update(editedAttachment as AttachmentProject, this.currentProject.id);
    }
  };

  private async addAttachments(values: Array<{blob: Blob; fileName: string; markings?: string | null}>): Promise<Array<AttachmentProject>> {
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Adding project attachments (projectId=${this.currentProject.id})`);

    return this.tryOrLog(
      'addAttachments',
      async () => {
        const blobs = new Array<Blob>();
        const attachments = new Array<AttachmentProject>();
        for (const value of values) {
          const fileName = value.fileName;
          const blob = ensureMimeTypeSet(value.blob, fileName);
          const fileExt = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
          const attachment: AttachmentProject = {
            id: uuidv4(),
            hash: uuidv4(),
            projectId: this.currentProject.id,
            mimeType: blob.type,
            fileName,
            fileExt,
            markings: value.markings,
            changedAt: new Date().toISOString(),
            createdAt: new Date().toISOString(),
            createdById: this.currentUser.id,
            ...(await extractCommonAttachmentProperties(blob)),
          };

          blobs.push(blob);
          attachments.push(attachment);
        }

        await this.attachmentProjectDataService.insert(attachments, this.currentProject.id, {}, blobs);
        logInstance.success(() => `ids=${attachments.map((att) => att.id)}`);
        return attachments;
      },
      {logInstance}
    );
  }

  private async tryOrLog<T>(functionSource: string, fn: () => Promise<T>, {rethrow = false, logInstance}: {rethrow?: boolean; logInstance?: LogActionInstance} = {}): Promise<T> {
    try {
      const result = await fn();
      logInstance?.success();
      return result;
    } catch (error) {
      logInstance?.failure(error);
      let message: string | undefined;
      if (error instanceof ErrorWithUserMessage) {
        const errorWithUserMessage = error as ErrorWithUserMessage;
        message = errorWithUserMessage.userMessage;
      } else {
        message = error.message;
      }
      this.loggingService.error(LOG_SOURCE, `Error in ${functionSource}. "${error?.userMessage}" - "${error?.message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ` ${functionSource}`, error);
      if (isQuotaExceededError(message)) {
        await this.attachmentService.showToastQuotaExceeded();
      } else {
        await this.toastService.savingError();
      }
      if (rethrow) {
        throw error;
      }
    }
  }

  async exportPublicLink(attachmentIds: string[], abortSignal?: AbortSignal): Promise<string> {
    const isAuthenticated = await observableToPromise(this.authenticationService.isAuthenticated$);
    if (!isAuthenticated) {
      throw new Error('Not authenticated.');
    }
    const project = await this.projectDataService.getMandatoryCurrentProject();
    const projectId = project.id;
    const clientId = project.clientId;
    const url = `${environment.serverUrl}api/data/attachments/${projectId}/exportPublicLink?clientId=${clientId}`;
    const publicLink = await observableToPromise(abortableObservable(this.http.post(url, {attachmentIds}, {responseType: 'text'}), abortSignal));
    return `${environment.serverUrl}mediaPublic/selectedAttachments/${publicLink}`;
  }

  async exportAttachments() {
    if (!(await observableToPromise(this.networkStatusService.online$))) {
      if (await this.offlineInfoService.showOfflineAlert(this.translateService.instant('offlineInfo.description3'))) {
        await this.networkStatusService.isOnline();
        this.exportAttachments();
      }
      return;
    }
    const spinner = await this.loadingController.create({
      message: this.translateService.instant('project_room.attachmentToast.exporting'),
      backdropDismiss: true,
      keyboardClose: true,
    });
    try {
      await spinner.present();
      const abortController = new AbortController();
      spinner.onDidDismiss().then((result) => {
        if (result?.role === 'backdrop') {
          abortController.abort();
          this.toastService.info('project_room.attachmentToast.exportingAborted');
        }
      });
      const selectedAttachments = await observableToPromise(this.selectionService.selected$);
      const attachmentIds = selectedAttachments.map((attachment) => attachment.id);
      const url = await this.exportPublicLink(attachmentIds, abortController.signal);
      this.downloadExportLink.nativeElement.href = url;
      setTimeout(() => {
        this.downloadExportLink.nativeElement.click();
      }, 0);
      this.posthogService.captureEvent('[ProjectRoom] successfully downloaded attachments', {
        userName: this.currentUser?.username ?? 'UNKNOWN',
        numberOfAttachments: attachmentIds?.length ?? 0,
      });
      await this.toastService.info('project_room.attachmentToast.exportStartedSuccessfully');
    } catch (error) {
      if (error instanceof AbortError) {
        return;
      }
      const message = error?.message || error;
      await this.toastService.error(this.translateService.instant('project_room.attachmentToast.exportingFailed', {message}));
    } finally {
      await spinner.dismiss();
    }
  }

  async moveSelectedAttachments() {
    if (!(await observableToPromise(this.networkStatusService.online$))) {
      if (await this.offlineInfoService.showOfflineAlert(this.translateService.instant('offlineInfo.description3'))) {
        await this.networkStatusService.isOnline();
        this.moveSelectedAttachments();
      }
      return;
    }
    const selectedAttachments = await observableToPromise(this.selectionService.selected$);
    if (!selectedAttachments.length) {
      return;
    }
    const attachmentsFromProject = selectedAttachments.filter(
      (attachment) =>
        !_.has(attachment, 'protocolEntryId') && !_.has(attachment, 'reportCompanyId') && !_.has(attachment, 'activityId') && !_.has(attachment, 'materialId') && !_.has(attachment, 'equipmentId')
    );
    const someAttachmentsAreFromProject = attachmentsFromProject.length && attachmentsFromProject.length < selectedAttachments.length;
    if (!attachmentsFromProject.length) {
      await this.alertService.ok({
        header: 'moveProjectAttachments.noValid.header',
        message: 'moveProjectAttachments.noValid.message',
        confirmLabel: 'close',
      });
      return;
    }
    if (someAttachmentsAreFromProject) {
      const confirm = await this.alertService.confirm({
        header: 'moveProjectAttachments.someValid.header',
        message: 'moveProjectAttachments.someValid.message',
        confirmLabel: 'moveProjectAttachments.move',
        confirmButton: {
          color: 'text-primary',
          fill: 'solid',
        },
      });
      if (!confirm) {
        return;
      }
      this.selectionService.select(attachmentsFromProject);
    }
    const modal = await this.modalController.create({
      component: MoveProjectRoomAttachmentsModalComponent,
      canDismiss: true,
      backdropDismiss: false,
      componentProps: {
        attachmentIds: attachmentsFromProject.map((att) => att.id),
      },
    });
    await modal.present();
    await modal.onDidDismiss();
  }

  async deleteCurrentSelection() {
    const selectedAttachments = await observableToPromise(this.selectionService.selected$);
    let attachmentsToDelete = selectedAttachments;
    let showAlertCantDeleteAll = false;
    for (const attachment of selectedAttachments) {
      if (_.has(attachment, 'protocolEntryId') || _.has(attachment, 'reportCompanyId') || _.has(attachment, 'activityId') || _.has(attachment, 'equipmentId') || _.has(attachment, 'materialId')) {
        const attachmentId = attachment.id;
        showAlertCantDeleteAll = true;
        attachmentsToDelete = attachmentsToDelete.filter((attachment) => attachment.id !== attachmentId);
      }
    }
    let confirm = false;
    if (showAlertCantDeleteAll) {
      confirm = await this.alertService.confirm({
        header: 'project_room.attachmentPage.deleteHeader1',
        message: this.translateService.instant('project_room.attachmentPage.deleteMessage1', {count: attachmentsToDelete.length}),
        confirmLabel: 'button.delete',
        cancelLabel: 'cancel',
        confirmButton: {
          color: 'danger',
          fill: 'solid',
        },
      });
    } else {
      confirm = await this.alertService.confirm({
        header: 'project_room.attachmentPage.deleteHeader2',
        message: this.translateService.instant('project_room.attachmentPage.deleteMessage2', {count: attachmentsToDelete.length}),
        confirmLabel: 'button.delete',
        cancelLabel: 'cancel',
        confirmButton: {
          color: 'danger',
          fill: 'solid',
        },
      });
    }
    if (!confirm) {
      return;
    }
    if (!attachmentsToDelete.length) {
      return;
    }
    const firstAttachment = attachmentsToDelete[0] as AttachmentProject;
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Deleting multiple project attachments (projectId=${firstAttachment.projectId})`);
    try {
      await this.attachmentProjectDataService.delete(attachmentsToDelete as AttachmentProject[], firstAttachment.projectId);
      logInstance.success();
      this.posthogService.captureEvent('[ProjectRoom] successfully deleted attachments', {
        userName: this.currentUser?.username ?? 'UNKNOWN',
        numberOfAttachments: attachmentsToDelete?.length ?? 0,
      });
      await this.toastService.info('success_deleting_message');
    } catch (e) {
      logInstance.failure(e);
      await this.toastService.error('error_deleting_message');
      this.loggingService.error(LOG_SOURCE, `error in deleteCurrentSelection. ${convertErrorToMessage(e)}`);
    }
  }
}
