import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import async, {AsyncResultCallback, QueueObject} from 'async';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {Observable, of, ReplaySubject, Subject, TimeoutError as RxTimeoutError, timer} from 'rxjs';
import {concatMap, delay, filter, groupBy, map, mergeMap, take, throttleTime, timeout} from 'rxjs/operators';
import {observableToPromise} from '../utils/async-utils';
import {LoggingService} from './common/logging.service';
import {LOCAL_STORAGE_STORAGE_DRIVER, LOCAL_STORAGE_STORAGE_DRIVER_VALUE_INDEXEDDB, LOCAL_STORAGE_STORAGE_DRIVER_VALUE_SQLITE, STORAGE_KEY_PROJECT_SEPARATOR} from '../shared/constants';
import {convertErrorToMessage} from '../shared/errors';

type AffectedKey = string | null;

const STORAGE_PREFIX = 'DB';

const getBucketName = (prefix: string, key: string): string => {
  if (!key.includes(STORAGE_KEY_PROJECT_SEPARATOR)) {
    return prefix;
  }
  const [bucketName] = key.split(STORAGE_KEY_PROJECT_SEPARATOR).reverse();
  return `${prefix}_${bucketName}`;
};

interface StorageMutationEvent {
  createdAt: number;
  affectedKey: AffectedKey;
  throttleTimeInMs?: number;
}

interface StoragePersistedEvent {
  persistedAt: number;
  affectedKey: AffectedKey;
}

export interface StorageMutationOptions {
  /**
   * Indicates if the mutation should be immediate. `true` by default.
   */
  immediate: boolean;
  /**
   * Defines timeout value in ms, after which the mutation will be persisted to the storage.
   * 5s by default (set in DEFAULT_THROTTLE_TIMEOUT_IN_MS)
   *
   * Ignored if `immediate` is set to `true`
   */
  throttleTimeInMs: number;
  /**
   * If `true`, mutation won't resolve until the value is written to the persistent storage.
   * `true` by default.
   */
  ensureStored: boolean;
}

const DEFAULT_THROTTLE_TIMEOUT_IN_MS = 5 * 1000;
const IMMEDIATE_MUTATION_TIMEOUT_IN_MS = 100;
const WAIT_UNTIL_STORED_TIMEOUT_IN_MS = 10 * 1000;

const LOG_SOURCE = 'StorageService';

/**
 * Service to access the persistent storage.
 *
 * This service is "wrapped" with the cache memory for performance reasons.
 * That's why the storage itself is read only at the initialization phase.
 * If any write event occurs (set/clear/remove) then the write to the persistent
 * storage is scheduled.
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private static readonly synchronizedStorageAccessQueue: QueueObject<() => Promise<any>> = async.queue<any>(async (task: () => Promise<any>, callback: AsyncResultCallback<any>) => {
    try {
      const result = await task();
      callback(undefined, result);
    } catch (error) {
      callback(error);
    }
  }, 1);

  /**
   * Persistent storage
   */
  private myStorage: Storage | null = null;
  private readonly initializedPromise: Promise<void>;
  public driver: string | null = null;
  private isInitialized: boolean | undefined;
  private initializeError: Error | undefined;

  private readonly objectKey = STORAGE_PREFIX;
  /**
   * Local memory (cache) storage
   */
  private memoryStorage: {[key: string]: any} = {};
  /**
   * The time clear storage event started
   */
  private clearStorageDate: number | null = null;
  /**
   * Last time the persistent storage sync was run
   */
  private lastSyncDate = new Map<AffectedKey, number>();
  /**
   * Last time the mutation to the memory storage was made
   */
  private lastSaveDate = new Map<AffectedKey, number>();
  private hasPendingChangesMap = new Map<AffectedKey, boolean>();

  /**
   * Emits a UNIX epoch time every time the persist method finishes write.
   * Value represents the time when the snapshot was taken before had been written to the storage.
   */
  private finishedPersistSubject = new ReplaySubject<StoragePersistedEvent>(1);

  /**
   * Subject of mutations events
   */
  private mutationSubject = new Subject<StorageMutationEvent>();

  /**
   * Observable that emits value when the persist should occur
   */
  private mutation$: Observable<StorageMutationEvent> = this.mutationSubject.pipe(
    // Slow down incoming messages by its' value in ms
    mergeMap((event) => {
      if (event.throttleTimeInMs) {
        return timer(event.throttleTimeInMs).pipe(map(() => event));
      }
      return of(event);
    }),
    // Group mutation events by the affected key
    groupBy((event) => event.affectedKey),
    mergeMap((event) =>
      event.pipe(
        // Make sure immediate mutation are after `lastSaveDate` - to avoid eventual problems with `lastSaveDate === lastSyncDate`
        // Also gives a "breath" for multiple throttled requests created in sequence
        // Throttling is made individually per affected key
        throttleTime(IMMEDIATE_MUTATION_TIMEOUT_IN_MS, undefined, {
          leading: false,
          trailing: true,
        }),
        delay(1)
      )
    )
  );

  public static async runInSynchronizedStorageAccess<R>(functionToCall: () => Promise<R>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      StorageService.synchronizedStorageAccessQueue.push<R>(functionToCall, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  }

  constructor(
    private storage: Storage,
    private platform: Platform,
    private loggingService: LoggingService
  ) {
    this.initializedPromise = new Promise<void>(async (resolve, reject) => {
      try {
        await this.init();
        this.isInitialized = true;
        this.initializeError = undefined;
        this.loggingService.infoWithEvent(LOG_SOURCE, 'constructor', 'initializedPromise resolved successfully.', {isEventLogHigherPriority: true, logEvenWheNotAuthenticated: true});
        resolve();
      } catch (e) {
        this.isInitialized = false;
        this.initializeError = e;
        this.loggingService.errorWithEvent(LOG_SOURCE, 'constructor', `initializedPromise failed with error: ${convertErrorToMessage(e)}`, {logEvenWheNotAuthenticated: true});
        reject(e);
      }
    });
    // This is responsible for updating pending changes status
    this.finishedPersistSubject.subscribe((event) => {
      const saveDate = this.lastSaveDate.get(event.affectedKey) ?? -Infinity;
      const syncDate = this.lastSyncDate.get(event.affectedKey) ?? -Infinity;
      const syncAllDate = this.lastSyncDate.get(null) ?? -Infinity;

      if (saveDate < Math.max(syncDate, syncAllDate)) {
        this.hasPendingChangesMap.set(event.affectedKey, false);
      }
    });
  }

  /**
   * Checks, if the memory storage has a pending changes, that should be saved
   * to the persistent storage.
   *
   * This function doesn't need to check differences between the actual storage and memory storage.
   * Since all other services uses this StorageService, we are able to tell when the mutation to the
   * memory storage happens. By storing the information when the mutation happened and also when the
   * write to the persistent storage happened, this function can tell if there are outstanding changes
   * to save by simply comparing the last mutation date and the last persistent storage write.
   *
   * @param affectedKey If provided, will check pending changes just for this key. Otherwise checks whole storage
   * @returns true, if there are pending changes in the memory storage. Otherwise false.
   */
  hasPendingChanges(affectedKey?: AffectedKey) {
    if (affectedKey !== undefined) {
      return this.hasPendingChangesMap.get(affectedKey) ?? true;
    }

    if (!this.hasPendingChangesMap.size) {
      return true;
    }

    // Checks if any bucket has pending changes
    return Array.from(this.hasPendingChangesMap.values()).some((v) => v);
  }

  private initInterval() {
    this.mutation$
      .pipe(
        // concatMap makes sure that consequent update requests are handled in order, one at the time
        concatMap(async (event) => {
          // If clear storage was ever scheduled, discard all mutation events **before** the clear storage happened
          if (this.clearStorageDate && event.createdAt <= this.clearStorageDate) {
            return;
          }
          if (!this.hasPendingChanges(event.affectedKey)) {
            this.loggingService.debug(LOG_SOURCE, 'Writing memory storage to the persistent storage...: Last save time was before the last sync date; no need to write to the storage...');
            return;
          }
          await this.persistSynchronized(event.affectedKey);
        })
      )
      .subscribe(() => {});
  }

  async persistPendingChanges() {
    await this.initializedPromise;
    return StorageService.runInSynchronizedStorageAccess(async () => {
      for (const key of Array.from(this.hasPendingChangesMap.keys())) {
        if (this.hasPendingChangesMap.get(key)) {
          // We want to synchronously schedule persist for each affected keys, to make sure IndexedDB/SQLite transactions
          // for persist are not mixed with other persist events, which can result in longer invocation of this method
          await this.persist(key);
        }
      }
    });
  }

  private async persistSynchronized(affectedKey: AffectedKey = null) {
    return await StorageService.runInSynchronizedStorageAccess(() => this.persist(affectedKey));
  }

  private async persist(affectedKey: AffectedKey = null) {
    const persistedAt = Date.now();
    this.lastSyncDate.set(affectedKey, persistedAt);

    if (affectedKey === null) {
      // Persists whole memory storage to the persistent storage
      const persistedKeys = await this.myStorage.keys();
      const currentBuckets = Object.keys(this.memoryStorage);

      // Checks if there are buckets, that should be deleted
      const bucketsToDelete = persistedKeys.filter((key) => key.startsWith(this.objectKey)).filter((key) => !currentBuckets.includes(key));

      // Removes buckets that should be deleted
      await Promise.all(
        bucketsToDelete.map(async (key) => {
          await this.myStorage.remove(key);
        })
      );
      // Persists other buckets
      await Promise.all(
        currentBuckets.map(async (key) => {
          await this.myStorage.set(key, this.memoryStorage[key]);
        })
      );
    } else {
      await this.myStorage.set(affectedKey, this.memoryStorage[affectedKey]);
    }

    this.finishedPersistSubject.next({
      persistedAt,
      affectedKey,
    });
  }

  async init() {
    const start = Date.now();
    this.loggingService.infoWithEvent(LOG_SOURCE, 'init', 'method called', {isEventLogHigherPriority: true, logEvenWheNotAuthenticated: true});
    if (this.platform.is('android') || this.platform.is('ios')) {
      if (localStorage.getItem(LOCAL_STORAGE_STORAGE_DRIVER) === LOCAL_STORAGE_STORAGE_DRIVER_VALUE_INDEXEDDB) {
        // no need to change the default
      } else {
        this.loggingService.infoWithEvent(LOG_SOURCE, 'init', 'changing driver to CordovaSQLiteDriver', {isEventLogHigherPriority: true, logEvenWheNotAuthenticated: true});
        await this.storage.defineDriver(CordovaSQLiteDriver);
      }
    }
    const storage = await this.storage.create();

    const keys = await storage.keys();

    await Promise.all(
      keys.map(async (key) => {
        if (key.startsWith(this.objectKey)) {
          this.memoryStorage[key] = (await storage.get(key)) || {};
        }
      })
    );

    this.myStorage = storage;
    this.driver = storage.driver;

    this.initInterval();
    this.loggingService.infoWithEvent(LOG_SOURCE, 'init', `method completed in ${Date.now() - start} ms: keys=[${keys.join()}]`, {isEventLogHigherPriority: true, logEvenWheNotAuthenticated: true});
  }

  public isSwitchingStorageDriverSupported(): boolean {
    return (this.platform.is('android') || this.platform.is('ios')) && this.platform.is('capacitor');
  }

  public isStorageDriverToIndexedDb(): boolean {
    return !!(localStorage.getItem(LOCAL_STORAGE_STORAGE_DRIVER) === LOCAL_STORAGE_STORAGE_DRIVER_VALUE_INDEXEDDB);
  }

  public async setStorageDriverToIndexedDb(): Promise<boolean> {
    if (!this.isSwitchingStorageDriverSupported()) {
      throw new Error(`Platform (${this.platform.platforms().join(',')}) does not support changing the StorageDriver.`);
    }
    if (localStorage.getItem(LOCAL_STORAGE_STORAGE_DRIVER) === LOCAL_STORAGE_STORAGE_DRIVER_VALUE_INDEXEDDB) {
      this.loggingService.warn(LOG_SOURCE, 'setStorageDriverToIndexedDb does not do anything because it is already set to IndexedDb');
      return false;
    }
    localStorage.setItem(LOCAL_STORAGE_STORAGE_DRIVER, LOCAL_STORAGE_STORAGE_DRIVER_VALUE_INDEXEDDB);
    await this.storageDriverSwitched();
    return true;
  }

  public async setStorageDriverToSQLite(): Promise<boolean> {
    if (!this.isSwitchingStorageDriverSupported()) {
      throw new Error(`Platform (${this.platform.platforms().join(',')}) does not support changing the StorageDriver.`);
    }
    if (!localStorage.getItem(LOCAL_STORAGE_STORAGE_DRIVER)) {
      this.loggingService.warn(LOG_SOURCE, 'setStorageDriverToSQLite does not do anything because there is no value configured (should be IndexedDB).');
      return false;
    } else if (localStorage.getItem(LOCAL_STORAGE_STORAGE_DRIVER) === LOCAL_STORAGE_STORAGE_DRIVER_VALUE_SQLITE) {
      this.loggingService.warn(LOG_SOURCE, `setStorageDriverToSQLite does not do anything because ${LOCAL_STORAGE_STORAGE_DRIVER_VALUE_SQLITE} is already configured.`);
      return false;
    }
    localStorage.setItem(LOCAL_STORAGE_STORAGE_DRIVER, LOCAL_STORAGE_STORAGE_DRIVER_VALUE_SQLITE);
    await this.storageDriverSwitched();
    return true;
  }

  private async storageDriverSwitched() {
    await this.clear();
    await this.storage.clear();
  }

  private getBucketName(key: string): string {
    return getBucketName(this.objectKey, key);
  }

  /**
   * Finds and returns proper bucket for provided key.
   *
   * @param key Name of the key for which the bucket should be found
   * @returns Bucket object
   */
  private getBucketByKey(key: string): any {
    const bucketName = this.getBucketName(key);
    if (!this.memoryStorage[bucketName]) {
      this.memoryStorage[bucketName] = {};
    }

    return this.memoryStorage[bucketName];
  }

  public async get(key: string): Promise<any> {
    await this.initializedPromise;

    const bucket = this.getBucketByKey(key);

    // `?? null` - mimics the behaviour of @ionic/storage
    return bucket[key] ?? null;
  }

  public async getAllBeginsWith(beginsWith: string): Promise<{[key: string]: any}> {
    await this.initializedPromise;

    const result = {};

    Object.keys(this.memoryStorage).forEach((bucketName) => {
      Object.keys(this.memoryStorage[bucketName]).forEach((key) => {
        if (key.startsWith(beginsWith)) {
          result[key] = this.memoryStorage[bucketName][key];
        }
      });
    });

    return result;
  }

  private getMutationOptions(mutationOptions: Partial<StorageMutationOptions> = {}): StorageMutationOptions {
    return {
      ensureStored: mutationOptions.ensureStored ?? true,
      immediate: mutationOptions.immediate ?? true,
      throttleTimeInMs: mutationOptions.throttleTimeInMs ?? DEFAULT_THROTTLE_TIMEOUT_IN_MS,
    };
  }

  private async waitUntilStored(affectedKey: AffectedKey, saveDate: number): Promise<unknown> {
    // This observable passes the first sync date that has been processed after save date
    const persistedAfterSaveDate$ = this.finishedPersistSubject.pipe(
      filter((event) => event.persistedAt > saveDate && (event.affectedKey === null || event.affectedKey === affectedKey)),
      take(1),
      timeout({first: WAIT_UNTIL_STORED_TIMEOUT_IN_MS})
    );

    try {
      // Wait until the sync after save date happens
      return await observableToPromise(persistedAfterSaveDate$);
    } catch (e) {
      if (e instanceof RxTimeoutError) {
        this.loggingService.warn(LOG_SOURCE, `waitUntilStored ran into a timeout. - affectedKey:${affectedKey}, saveDate:${saveDate}`);
        return;
      }

      throw e;
    }
  }

  /**
   * Properly emits the mutation occurence, with respect of mutation options.
   *
   * Also waits for the mutation to be persisted, if `options.ensureStored` is passed.
   *
   * @param options Mutation options
   * @param affectedKey Key affected by the mutation. If `null`, all keys are affected
   * @param saveDate The save date of corresponding mutation. Epoch UNIX time
   */
  private async emitMutation(options: StorageMutationOptions, affectedKey: AffectedKey, saveDate: number) {
    this.mutationSubject.next({
      affectedKey,
      throttleTimeInMs: options.immediate ? undefined : options.throttleTimeInMs,
      createdAt: saveDate,
    });

    if (options.ensureStored) {
      await this.waitUntilStored(affectedKey, saveDate);
    }
  }

  /**
   * Stores save date, runs mutation and triggers mutation event.
   *
   * Assumes that the mutation took place after `mutate` resolves.
   *
   * @param mutate Function to call, that will mutate the storage
   * @param mutationOptions Mutation options
   * @param affectedKey Key affected by the mutation. If `null`, all keys are affected
   * @returns Value resolved by `mutate` function
   */
  private async runMutation<T>(mutate: (mutationOptions: StorageMutationOptions) => Promise<T>, mutationOptions: Partial<StorageMutationOptions> = {}, affectedKey: AffectedKey = null): Promise<T> {
    await this.initializedPromise;

    const options = this.getMutationOptions(mutationOptions);

    const saveDate = Date.now();
    this.lastSaveDate.set(affectedKey, saveDate);
    this.hasPendingChangesMap.set(affectedKey, true);

    const result = await mutate(options);

    await this.emitMutation(options, affectedKey, saveDate);

    return result;
  }

  public set(key: string, value: any, mutationOptions?: Partial<StorageMutationOptions>): Promise<any> {
    return this.runMutation(
      async () => {
        const bucket = this.getBucketByKey(key);
        bucket[key] = value;

        return value;
      },
      mutationOptions,
      this.getBucketName(key)
    );
  }

  public remove(key: string, mutationOptions?: Partial<StorageMutationOptions>): Promise<any> {
    return this.runMutation(
      async () => {
        const bucket = this.getBucketByKey(key);
        delete bucket[key];
      },
      mutationOptions,
      this.getBucketName(key)
    );
  }

  /**
   * Clears the storage and discards any pending mutations that happened before the clear event.
   *
   * Because the storage service may not and will not process the mutation events immediately,
   * clear must stop all mutation events that happended before the clear event. This method achieves it by setting
   * clearStorageDate, which acts as a "show-stopper" - all events with createdAt before that date will be discarded.
   *
   * Clear action is always immediate, is never throttled, and always waits until it's written in persistent storage.
   */
  public async clear(): Promise<void> {
    await this.initializedPromise;

    const saveDate = Date.now();
    this.lastSaveDate.set(null, saveDate);
    this.clearStorageDate = saveDate;
    this.hasPendingChangesMap.set(null, true);

    this.memoryStorage = {};

    await new Promise((res) => {
      // [BM2-1318: issue from comment]
      // Wait at least 1 ms before clear, due to the persist event date to be the exact same as save date
      setTimeout(() => {
        res(this.persistSynchronized(null));
      }, 1);
    });
  }

  public async keys(): Promise<string[]> {
    await this.initializedPromise;

    return Object.values(this.memoryStorage).reduce((acc, bucket) => {
      acc.push(...Object.keys(bucket));

      return acc;
    }, []);
  }

  public async length(): Promise<number> {
    return (await this.keys()).length;
  }

  public async forEach(iteratorCallback: (value: any, key: string, iterationNumber: number) => any): Promise<void> {
    const entries = Object.entries(this.memoryStorage);
    for (let index = 0; index < entries.length; index++) {
      const [key, value] = entries[index];
      if (iteratorCallback(value, key, index) !== undefined) {
        return;
      }
    }
  }

  public async initStorageDriverInLocalStorageToDefault(): Promise<boolean> {
    const isPlatformIos = this.platform.is('ios');
    const isPlatformAndroid = this.platform.is('android');
    const currentLocalStorageDriver: string | null = localStorage.getItem(LOCAL_STORAGE_STORAGE_DRIVER);
    let changed = false;
    if (!currentLocalStorageDriver) {
      if (this.isSwitchingStorageDriverSupported() && isPlatformAndroid) {
        localStorage.setItem(LOCAL_STORAGE_STORAGE_DRIVER, LOCAL_STORAGE_STORAGE_DRIVER_VALUE_INDEXEDDB);
        this.loggingService.infoWithEvent(LOG_SOURCE, 'initStorageDriverInLocalStorageToDefault', `changed storageDriver value to "${LOCAL_STORAGE_STORAGE_DRIVER_VALUE_INDEXEDDB}".`);
        changed = true;
      } else if (this.isSwitchingStorageDriverSupported() && isPlatformIos) {
        localStorage.setItem(LOCAL_STORAGE_STORAGE_DRIVER, LOCAL_STORAGE_STORAGE_DRIVER_VALUE_SQLITE);
        this.loggingService.infoWithEvent(LOG_SOURCE, 'initStorageDriverInLocalStorageToDefault', `set value of storageDriver explicitly to "${LOCAL_STORAGE_STORAGE_DRIVER_VALUE_SQLITE}".`);
        changed = false;
      } else {
        localStorage.setItem(LOCAL_STORAGE_STORAGE_DRIVER, LOCAL_STORAGE_STORAGE_DRIVER_VALUE_INDEXEDDB);
        this.loggingService.infoWithEvent(LOG_SOURCE, 'initStorageDriverInLocalStorageToDefault', `set value of storageDriver explicitly to "${LOCAL_STORAGE_STORAGE_DRIVER_VALUE_INDEXEDDB}".`);
        changed = false;
      }
    }
    if (changed) {
      this.loggingService.infoWithEvent(LOG_SOURCE, 'initStorageDriverInLocalStorageToDefault', `Reloading page since value of ${LOCAL_STORAGE_STORAGE_DRIVER} was changed.`);
      await new Promise((res) => setTimeout(res, 1000));
      // Reload is necessary in order to refresh the storage instance
      window.location.reload();
    }
    return changed;
  }

  public async getStatusString(): Promise<string> {
    const isInitialized = this.isInitialized === undefined ? 'undefined' : `${this.isInitialized}`;
    const storageKeys = this.isInitialized && this.myStorage ? `[${(await this.myStorage.keys()).join()}]` : 'undefined';
    const initializeError = this.initializeError ? convertErrorToMessage(this.initializeError) : 'undefined';
    return `StorageService.status(isInitialized="${isInitialized}", initialError=${initializeError}, driver="${this.driver}", storageKeys=${storageKeys})`;
  }
}
