import {Injectable} from '@angular/core';
import {StorageService} from '../storage.service';
import {BehaviorSubject, map, switchMap} from 'rxjs';
import {IdType} from 'submodules/baumaster-v2-common/dist';
import {PdfPlanFolderColumnSettings} from 'src/app/model/pdf-plan-folder-column-settings';
import {ProjectDataService} from '../data/project-data.service';
import {AuthenticationService} from '../auth/authentication.service';
import {STORAGE_KEY_PROJECT_SEPARATOR, StorageKeyEnum} from 'src/app/shared/constants';

const STORAGE_KEY = StorageKeyEnum.PDF_PLAN_FOLDER_COLUMN_SETTINGS;

const defaultValue: PdfPlanFolderColumnSettings = {
  showColumns: {
    date: true,
    index: true,
    locationId: true,
    scale: true,
    tags: true,
    unitId: true,
  },
};

@Injectable({
  providedIn: 'root',
})
export class ProjectRoomPdfPlanFolderColumnsService {
  private readonly dataByProjectIdSubject = new BehaviorSubject<Record<IdType, PdfPlanFolderColumnSettings>>({});

  readonly dataByProjectId$ = this.dataByProjectIdSubject.asObservable();
  readonly data = this.projectDataService.currentProjectObservable.pipe(switchMap((project) => this.dataByProjectId$.pipe(map((data) => data[project?.id] ?? defaultValue))));

  constructor(
    private storage: StorageService,
    private projectDataService: ProjectDataService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      if (isAuthenticated) {
        this.dataByProjectIdSubject.next(await this.getDataByProjectIdFromStorage());
      } else {
        await this.removeAllStorageData();
        this.clearDataSubjectByProjectId();
      }
    });
  }

  private async getDataByProjectIdFromStorage() {
    const storageKeysToGet = (await this.storage.keys()).filter((key) => this.isStorageKey(key));

    const dataByProjectId: Record<IdType, PdfPlanFolderColumnSettings> = {};

    for (const key of storageKeysToGet) {
      const data: PdfPlanFolderColumnSettings = await this.storage.get(key);
      const projectId = this.extractProjectIdFromStorageKey(key);

      dataByProjectId[projectId] = data;
    }

    return dataByProjectId;
  }

  private clearDataSubjectByProjectId() {
    this.dataByProjectIdSubject.next({});
  }

  private getStorageKey(projectId: IdType): string {
    return STORAGE_KEY + STORAGE_KEY_PROJECT_SEPARATOR + projectId;
  }

  private isStorageKey(key: string) {
    return key.startsWith(STORAGE_KEY + STORAGE_KEY_PROJECT_SEPARATOR);
  }

  private extractProjectIdFromStorageKey(key: string) {
    const index = key.lastIndexOf(STORAGE_KEY_PROJECT_SEPARATOR);
    if (index === -1) {
      throw new Error(`StorageKey "${key}" is supposed to have a suffix with a projectId but no "_" was found.`);
    }
    return key.substring(index + 1);
  }

  private async removeAllStorageData() {
    const storageKeysToDelete = (await this.storage.keys()).filter((key) => this.isStorageKey(key));

    for (const key of storageKeysToDelete) {
      await this.storage.remove(key);
    }
  }

  public async upsert(settings: PdfPlanFolderColumnSettings, projectId?: IdType): Promise<void> {
    if (!projectId) {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }

    const key = this.getStorageKey(projectId);

    await this.storage.set(key, settings);

    const {value} = this.dataByProjectIdSubject;
    this.dataByProjectIdSubject.next({
      ...value,
      [projectId]: settings,
    });
  }
}
