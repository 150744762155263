import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {filter, map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {IdType, LicenseType, PdfPlan, PdfPlanFolder, PdfPlanMarkerProtocolEntry, PdfPlanPage, PdfPlanPageMarking, PdfPlanVersion} from 'submodules/baumaster-v2-common';
import {PdfPlanFolderDataService} from '../data/pdf-plan-folder-data.service';
import {PdfPlanDataService} from '../data/pdf-plan-data.service';
import {PdfPlanMarkerProtocolEntryDataService} from '../data/pdf-plan-marker-protocol-entry-data.service';
import {PdfPlanPageDataService} from '../data/pdf-plan-page-data.service';
import {PdfPlanPageMarkingDataService} from '../data/pdf-plan-page-marking-data.service';
import {FeatureEnabledService} from '../feature/feature-enabled.service';
import * as _ from 'lodash';
import {PdfPlanFolderModalComponent} from 'src/app/components/project-room/pdf-plan-folder-modal/pdf-plan-folder-modal.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {PdfPlanService} from '../pdf/pdf-plan.service';
import {OmgToastService} from '../ui/omg-toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {ALL_FOLDERS_PAGE_SLUG, ToastDurationInMs} from 'src/app/shared/constants';
import {Nullish} from 'src/app/model/nullish';
import {PdfPlanVersionDataService} from '../data/pdf-plan-version-data.service';

const traverseRoute = (route: ActivatedRoute): ActivatedRoute => {
  if (route.firstChild) {
    return traverseRoute(route.firstChild);
  }

  return route;
};

export interface PdfPlanFolderWithDeletable extends PdfPlanFolder {
  pdfPlanHolderCount: number;
  deletable: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PdfPlanFolderService {
  routeFolderId$: Observable<Nullish<string>> = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    startWith(null),
    switchMap(() => traverseRoute(this.router.routerState.root).params),
    map(({folderId}) => folderId),
    shareReplay(1)
  );

  private readonly allPdfPlanFolderWithDeletable$ = this.getPdfPlanFolderWithDeletable$(of(undefined)).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private pdfPlanFolderDataService: PdfPlanFolderDataService,
    private pdfPlanDataService: PdfPlanDataService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private pdfPlanPageDataService: PdfPlanPageDataService,
    private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
    private pdfPlanPageMarkingDataService: PdfPlanPageMarkingDataService,
    private featureEnabledService: FeatureEnabledService,
    private router: Router,
    private modalController: ModalController,
    private pdfPlanService: PdfPlanService,
    private toastService: OmgToastService
  ) {}

  getPdfPlanFolderWithDeletable$(query$?: Observable<string | undefined>): Observable<PdfPlanFolderWithDeletable[]> {
    if (!query$) {
      return this.allPdfPlanFolderWithDeletable$;
    }

    return combineLatestAsync([
      this.pdfPlanFolderDataService.data,
      this.pdfPlanDataService.data.pipe(map((pdfPlans) => _.groupBy(pdfPlans, 'folderId'))),
      this.pdfPlanVersionDataService.data.pipe(map((pdfPlanVersions) => _.groupBy(pdfPlanVersions, 'pdfPlanId'))),
      this.pdfPlanPageDataService.data.pipe(map((pdfPlanPages) => _.groupBy(pdfPlanPages, 'pdfPlanVersionId'))),
      this.pdfPlanMarkerProtocolEntryDataService.data.pipe(map((pdfPlanMarkers) => _.groupBy(pdfPlanMarkers, 'pdfPlanPageId'))),
      this.pdfPlanPageMarkingDataService.data.pipe(map((pdfPlanPageMarkings) => _.groupBy(pdfPlanPageMarkings, 'pdfPlanPageId'))),
      this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]),
    ]).pipe(
      switchMap(
        ([pdfPlanFolders, pdfPlansByFolderId, pdfPlanVersionsByPdfPlanId, pdfPlanPagesByPlanVersionId, pdfPlanMarkersByPdfPlanPageId, pdfPlanPageMarkingsByPdfPlanPageId, isNotConnectedOrViewer]) =>
          query$.pipe(
            map((query) => {
              const filteredPdfPlanFolders = pdfPlanFolders
                .filter((pdfPlanFolder) => !query || pdfPlanFolder.name.toLowerCase().includes(query.toLowerCase()))
                .filter((pdfPlanFolder) => {
                  if (isNotConnectedOrViewer) {
                    return true;
                  }

                  const pdfPlanHoldersForConnectedOrViewer = (pdfPlansByFolderId[pdfPlanFolder.id] ?? []).filter((holder) => holder.active);

                  return pdfPlanHoldersForConnectedOrViewer.length > 0;
                });

              const sortedFolders = filteredPdfPlanFolders;

              return sortedFolders.map((pdfPlanFolder) =>
                this.toPdfPlanFolderWithDeletable(
                  pdfPlanFolder,
                  pdfPlansByFolderId,
                  pdfPlanVersionsByPdfPlanId,
                  pdfPlanPagesByPlanVersionId,
                  pdfPlanMarkersByPdfPlanPageId,
                  pdfPlanPageMarkingsByPdfPlanPageId
                )
              );
            })
          )
      )
    );
  }

  private toPdfPlanFolderWithDeletable(
    pdfPlanFolder: PdfPlanFolder,
    pdfPlansByFolderId: Record<IdType, PdfPlan[]>,
    pdfPlanVersionsByPdfPlanId: Record<IdType, PdfPlanVersion[]>,
    pdfPlanPagesByPlanVersionId: Record<IdType, PdfPlanPage[]>,
    pdfPlanMarkersByPdfPlanPageId: Record<IdType, PdfPlanMarkerProtocolEntry[]>,
    pdfPlanPageMarkingsByPdfPlanPageId: Record<IdType, PdfPlanPageMarking[]>
  ): PdfPlanFolderWithDeletable {
    const pdfPlans = pdfPlansByFolderId[pdfPlanFolder.id] ?? [];
    const pdfPlanVersions = _.flatten(pdfPlans.map((pdfPlan) => pdfPlanVersionsByPdfPlanId[pdfPlan.id] ?? []));
    const pdfPlanPages = _.flatten(pdfPlanVersions.map((pdfPlanVersion) => pdfPlanPagesByPlanVersionId[pdfPlanVersion.id] ?? []));
    const pdfPlanMarkersReferenced = _.flatten(pdfPlanPages.map((pdfPlanPage) => pdfPlanMarkersByPdfPlanPageId[pdfPlanPage.id] ?? []));
    const pdfPlanPageMarkingsReferenced = _.flatten(pdfPlanPages.map((pdfPlanPage) => pdfPlanPageMarkingsByPdfPlanPageId[pdfPlanPage.id] ?? []));

    const deletable = !pdfPlanMarkersReferenced?.length && !pdfPlanPageMarkingsReferenced?.length;
    const pdfPlanHolderCount = pdfPlans.length;

    return {
      ...pdfPlanFolder,
      pdfPlanHolderCount,
      deletable,
    } as PdfPlanFolderWithDeletable;
  }

  async navigateToAllPlansFolder(replaceUrl = false) {
    await this.router.navigate(['/project-room', 'pdf-plan-folders', ALL_FOLDERS_PAGE_SLUG], {
      replaceUrl,
    });
  }

  async navigateToFolder(pdfPlanFolderOrId: IdType | PdfPlanFolder, replaceUrl = false): Promise<void> {
    const id = typeof pdfPlanFolderOrId !== 'string' ? pdfPlanFolderOrId.id : pdfPlanFolderOrId;
    await this.router.navigate(['/project-room', 'pdf-plan-folders', id], {
      replaceUrl,
    });
  }

  async addNewFolder(waitForDismiss: true): Promise<boolean>;
  async addNewFolder(waitForDismiss?: false): Promise<void>;
  async addNewFolder(waitForDismiss = false): Promise<boolean | void> {
    const modal = await this.modalController.create({
      component: PdfPlanFolderModalComponent,
      cssClass: 'omg-modal',
      componentProps: {
        createMode: true,
      },
    });
    const dismissPromise = modal.onDidDismiss().then(async (result) => {
      if (result.data) {
        const pdfPlanFolder: PdfPlanFolder = result.data;
        await this.navigateToFolder(pdfPlanFolder);
      }

      return Boolean(result.data);
    });
    await modal.present();
    if (waitForDismiss) {
      return await dismissPromise;
    }
  }

  async editFolder(pdfPlanFolder: PdfPlanFolderWithDeletable) {
    const modal = await this.modalController.create({
      component: PdfPlanFolderModalComponent,
      cssClass: 'omg-modal',
      componentProps: {
        createMode: false,
        pdfPlanFolder,
      },
    });
    await modal.present();
  }

  async deletePdfPlanFolder(pdfPlanFolder: PdfPlanFolderWithDeletable) {
    try {
      await this.pdfPlanService.deletePdfPlanFolder(pdfPlanFolder);
      await this.toastService.info('project_room.pdf_plan_folder.deleteSuccessful');
    } catch (error) {
      await this.toastService.toastWithTranslateParams('project_room.pdf_plan_folder.deleteFailed', {message: convertErrorToMessage(error) || ''}, ToastDurationInMs.ERROR);
      throw error;
    }
  }
}
