<app-modal-header [dismissModalOnClose]="false" (close)="dismissModal('cancel')">
  <ion-title>{{ 'buyingWorkflow.workflowName' | translate }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="countryForm" action="#">
    <ion-grid class="grid-gap-2">
      <ion-row>
        <ion-col size="12">
          <div class="omg-modal-header">
            {{ 'clientDataSettings.country' | translate }}
          </div>
        </ion-col>
        <ion-col size="12">
          <div class="text-primary">
            {{ 'buyingWorkflow.countryText' | translate }}
          </div>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="floating" class="text-primary omg-required">{{ 'clientDataSettings.country' | translate }}</ion-label>
            <ionic-selectable #countrySelectable
              item-content
              class="text-primary"
              [items]="countries$ | async"
              formControlName="country"
              itemValueField="id"
              itemTextField="name"
              searchPlaceholder="{{'search' | translate}}"
              searchFailText="{{'noItemsFound' | translate}}"
              [canSearch]="true"
              (onOpen)="onOpen($event)"
              (onClose)="onClose($event)"
              appSelectableCommon
              #fs="appSelectableCommon"
              [shouldFocusSearchbar]="fs.isDesktop"
              [hasVirtualScroll]="true"
              virtualScrollApproxItemHeight="48px"
              [virtualScrollHeaderFn]="getGroupText">
              <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
                <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
              </ng-template>
              <ng-template ionicSelectableValueTemplate let-value="value">
                <app-selectable-value [value]="value"></app-selectable-value>
              </ng-template>
              <ng-template ionicSelectableHeaderTemplate>
                <app-selectable-header></app-selectable-header>
              </ng-template>
              <ng-template ionicSelectableFooterTemplate>
                <app-selectable-footer></app-selectable-footer>
              </ng-template>
            </ionic-selectable>
          </ion-item>
        </ion-col>
      </ion-row>
      <div class="d-flex flex-column ion-align-items-center pt-8">
        <img class="brian" [src]="'/assets/images/brian-goes-international-' + (imageNameSuffix$ | async) + '.png'" />
      </div>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismissModal('cancel')">{{'button.cancel' | translate}}</ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!countryForm.valid" (click)="dismissModal('confirm')">{{'next' | translate}}</ion-button>
  </ion-buttons>
</app-modal-footer>
