<app-modal-header>
  <ion-title class="ion-text-center">
    {{'unitContacts.unitContacts' | translate}}
  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <div>
    @if (unitContacts.length) {
      @for (unitContact of unitContacts; track unitContact.profile.id) {
        <div [class.mt-2]="0" class="d-flex flex-column gap-2 pb-3 pt-3 bottom-border">
          <div class="mobile-email-row d-flex ion-align-items-center gap-3 omg-t-body-bold">
            <ion-button class="omg-btn-secondary" (click)="openContact({event: $event, unitContact})">
              <fa-icon slot="icon-only" [icon]="['fal6', 'shelter']"></fa-icon>
            </ion-button>
            <span class="flex-grow-1 wrap-text">{{unitContact.address.firstName}} {{unitContact.address.lastName}}</span>
          </div>
          @if (unitContact.address.mobile || unitContact.address.phone) {
          <div class="mobile-email-row d-flex ion-align-items-center gap-3">
            <ion-button [href]="('tel:' + (unitContact.address.mobile || unitContact.address.phone))" class="omg-btn-secondary">
              <fa-icon slot="icon-only" [icon]="['fal', 'phone']"></fa-icon>
            </ion-button>
            <span class="flex-grow-1 wrap-text">{{ unitContact.address.mobile || unitContact.address.phone }}</span>
          </div>
          }
          @if (unitContact.address.email) {
          <div class="mobile-email-row d-flex ion-align-items-center gap-3">
            <ion-button [href]="('mailto:' + unitContact.address.email)" class="omg-btn-secondary">
              <fa-icon slot="icon-only" [icon]="['fal', 'envelope']"></fa-icon>
            </ion-button>
            <span class="flex-grow-1 wrap-text">{{ unitContact.address.email }}</span>
          </div>
          }
        </div>
      }
    }
    @else {
      <div class="d-flex flex-column gap-2 ion-align-items-center">
        <img class="brian" [src]="'/assets/images/brian-table-owners-' + (imageNameSuffix$ | async) + '.png'" />
        <div class="d-flex flex-column ion-align-items-center ion-text-center">
          <div class="omg-t-header-2-bold">{{'unitContacts.emptyHeader' | translate}}</div>
          <div class="omg-t-body text-secondary omg-f-italic">{{ 'unitContacts.emptyMessage' | translate:{unit: unit.breadcrumbsName} }}</div>
        </div>
      </div>
    }
  </div>
  @if (unitContacts.length) {
    <div class="omg-t-body-bold text-grey pb-1 pt-3">
      {{ ('unit' | translate) + ':' }}
    </div>
    <div>
      <bdi>{{unit.breadcrumbsName}}</bdi>
    </div>
  }
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="close()">{{'close' | translate}}</ion-button>
  </ion-buttons>
</app-modal-footer>
