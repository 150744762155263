<div class="d-flex gap-2 ion-align-items-center ion-justify-content-end" *rxLet="isFeatureEnabled$; let isFeatureEnabled">
  <ion-button class="omg-btn-secondary" (click)="deleteCurrentSelection()" [disabled]="!((this.selectionService.selectedSet$ | async).size > 0 && (deletionEnabled$ | async))">
    <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
  </ion-button>
  @if (isFeatureEnabled) {
    <div *rxLet="atLeastOneSelected$; let atLeastOneSelected">
      <ion-button id="protocol-entries-select-mode-move-to" [disabled]="!atLeastOneSelected" class="omg-btn-secondary" (click)="moveSelectedAttachments()">
        <fa-icon [icon]="['fal','arrow-from-left']"></fa-icon>
      </ion-button>
      <app-tooltip target="protocol-entries-select-mode-move-to" placement="bottom">
        {{ 'move_to' | translate }}
      </app-tooltip>
    </div>
  }
  @if (isDesktop) {
    <a href="#" target="_blank" download class="ion-hide" #downloadExportLink></a>
    <ion-button class="omg-btn-secondary" (click)="exportAttachments()" [disabled]="!((this.selectionService.selectedSet$ | async).size > 0)">
      <fa-icon slot="start" [icon]="['fal', 'download']"></fa-icon>
      <span class="ion-hide-sm-down">{{ 'project_room.pdf_plan_folder.toolbar.download' | translate }}</span>
    </ion-button>
  }
  @if (uploadingAttachmentsEnabled$ | async) {
    <input type="file" [accept]="acceptedMimeTypesForUpload" (change)="uploadFileEvent($event)" multiple #fileUpload class="ion-hide">
    <ion-button class="omg-btn-primary" (click)="fileUpload.click()">
      <fa-icon slot="start" [icon]="['fal', 'plus']"></fa-icon>
      <span class="ion-hide-sm-down">{{ 'upload' | translate }}</span>
    </ion-button>
  }
</div>
