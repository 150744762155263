import {ColumnConfig} from '../ColumnConfig';
import {ParticipantColumnSet} from './ParticipantColumnSet';
import {AttachmentReportSignatureColumnSet} from './AttachmentReportSignatureColumnSet';
import {NextMeetingColumnSet} from './NextMeetingColumnSet';
import {AttachmentProtocolSignatureColumnSet} from './AttachmentProtocolSignatureColumnSet';

export const PdfPreviewColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'index_number', prop: 'indexNumber'},
  {name: 'name', def: null},
  {name: 'date', cast: 'Timestamp', def: null},
  {name: 'time_from', prop: 'timeFrom', cast: 'Time', def: null},
  {name: 'time_until', prop: 'timeUntil', cast: 'Time', def: null},
  {name: 'location', def: null},
  {name: 'sort_entries_by', prop: 'sortEntriesBy', def: null},
  {name: 'is_search_pdf', prop: 'isSearchPdf'},
  {name: 'custom_text', prop: 'customText', def: null},
  {name: 'created_by_id', prop: 'createdById', def: null},
  {name: 'protocol_id', prop: 'protocolId', def: null},
  {name: 'type_id', prop: 'typeId', def: null},
  {name: 'project_id', prop: 'projectId', def: null},
  {name: 'report_id', prop: 'reportId', def: null},
  {name: 'company_id', prop: 'companyId', def: null},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'is_closed', prop: 'isClosed', def: null},
  {name: 'is_closed_migrated', prop: 'isClosedMigrated', def: null},
  {name: 'search_project_ids', prop: 'searchProjectIds', def: null},
  {name: 'participants', fk: 'pdfpreviewId', mappedBy: ParticipantColumnSet, transient: true},
  {name: 'attachmentReportSignatures', fk: 'pdfPreviewId', mappedBy: AttachmentReportSignatureColumnSet, transient: true},
  {name: 'attachmentProtocolSignatures', fk: 'pdfPreviewId', mappedBy: AttachmentProtocolSignatureColumnSet, transient: true},
  {name: 'nextMeetings', fk: 'pdfpreviewId', mappedBy: NextMeetingColumnSet, transient: true},
];
