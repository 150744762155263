<app-modal-header [dismissModalOnClose]="false" (close)="dismissModal()">
  <ion-title>{{ 'buyingWorkflow.contactSales' | translate }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="contactForm" action="#">
    <ion-grid class="grid-gap-2">
      <ion-row>
        <ion-col size="12">
          <div class="omg-modal-header">
            {{ 'companyForm.contact_data' | translate }}
          </div>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-input label="{{ 'contactForm.firstName' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="firstName" maxlength="50"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-input label="{{ 'contactForm.lastName' | translate }}" label-placement="floating" class="text-primary omg-required"  type="text" formControlName="lastName" maxlength="50"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-item>
            <ion-input label="{{ 'connectedUserInvite.confirm.companyName' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="companyName" maxlength="101"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-input label="{{ 'companyForm.address.phone' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="phone" maxlength="25"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="pb-3">
        <ion-col size="12">
          <ion-item>
            <ion-input label="{{ 'contactForm.email' | translate }}" label-placement="floating" class="text-primary omg-required" type="text" formControlName="email" maxlength="254"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="pb-3">
        <ion-col size="12">
          <div class="omg-modal-header">
            {{ 'buyingWorkflow.licenseAndPrice' | translate }}
          </div>
        </ion-col>
        <ion-col size="12">
          <div class="text-primary">
            {{ 'buyingWorkflow.licenseText' | translate }}
          </div>
        </ion-col>
        <ion-col size="12" class="pt-3">
          <ion-button class="omg-btn-secondary" (click)="openPricingPage()">
            <fa-icon [icon]="['fal', 'external-link']" slot="start"></fa-icon>
            <span>{{ 'buyingWorkflow.pricingButton' | translate }}</span>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <div class="text-primary">
            {{ 'buyingWorkflow.additionalText' | translate }}
          </div>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-textarea label="{{ 'buyingWorkflow.textBox' | translate }}" label-placement="floating" rows="1" auto-grow="true" formControlName="note"></ion-textarea>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="dismissModal()">{{'button.cancel' | translate}}</ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!contactForm.valid" (click)="sendSalesEmail()">{{'send' | translate}}</ion-button>
  </ion-buttons>
</app-modal-footer>
