import {Injectable, inject} from '@angular/core';
import _ from 'lodash';
import {BehaviorSubject, Observable, map, switchMap} from 'rxjs';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {
  Attachment,
  AttachmentChat,
  AttachmentProject,
  AttachmentProtocolEntry,
  AttachmentReportActivity,
  AttachmentReportCompany,
  AttachmentReportEquipment,
  AttachmentReportMaterial,
  IdType,
  convertISOStringToDate,
} from 'submodules/baumaster-v2-common';
import {AttachmentChatDataService} from '../data/attachment-chat-data.service';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {AttachmentProjectDataService} from '../data/attachment-project-data.service';
import {AttachmentReportActivityDataService} from '../data/attachment-report-activity-data.service';
import {AttachmentReportCompanyDataService} from '../data/attachment-report-company-data.service';
import {AttachmentReportEquipmentDataService} from '../data/attachment-report-equipment-data.service';
import {AttachmentReportMaterialDataService} from '../data/attachment-report-material-data.service';
import {UserService} from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectRoomAttachmentsService {
  private readonly projectIdSubject = new BehaviorSubject<IdType | undefined>(undefined);

  set projectId(projectId: IdType | undefined) {
    this.projectIdSubject.next(projectId);
  }

  get projectId(): IdType | undefined {
    return this.projectIdSubject.value;
  }

  attachments$ = this.projectIdSubject.pipe(switchMap((projectId) => (projectId ? this.getProjectRoomAttachmentsInProject$(projectId) : this.getProjectRoomAttachments$())));

  private readonly userService = inject(UserService);
  private readonly attachmentEntryDataService = inject(AttachmentEntryDataService);
  private readonly attachmentChatDataService = inject(AttachmentChatDataService);
  private readonly attachmentProjectDataService = inject(AttachmentProjectDataService);
  private readonly attachmentReportCompanyDataService = inject(AttachmentReportCompanyDataService);
  private readonly attachmentReportActivityDataService = inject(AttachmentReportActivityDataService);
  private readonly attachmentReportMaterialDataService = inject(AttachmentReportMaterialDataService);
  private readonly attachmentReportEquipmentDataService = inject(AttachmentReportEquipmentDataService);

  constructor() {}

  getProjectRoomAttachmentsInProject$(projectId: IdType): Observable<Attachment[]> {
    return this.getProjectRoomAttachments$(
      this.attachmentEntryDataService.getDataForProject$(projectId),
      this.attachmentChatDataService.getDataForProject$(projectId),
      this.attachmentProjectDataService.getDataForProject$(projectId),
      this.attachmentReportCompanyDataService.getDataForProject$(projectId),
      this.attachmentReportActivityDataService.getDataForProject$(projectId),
      this.attachmentReportMaterialDataService.getDataForProject$(projectId),
      this.attachmentReportEquipmentDataService.getDataForProject$(projectId)
    );
  }

  getProjectRoomAttachments$(
    attachmentEntryData = this.attachmentEntryDataService.data,
    attachmentChatData = this.attachmentChatDataService.data,
    attachmentProjectData = this.attachmentProjectDataService.data,
    attachmentReportCompanyData = this.attachmentReportCompanyDataService.data,
    attachmentReportActivityData = this.attachmentReportActivityDataService.data,
    attachmentReportMaterialData = this.attachmentReportMaterialDataService.data,
    attachmentReportEquipmentData = this.attachmentReportEquipmentDataService.data
  ): Observable<Array<Attachment>> {
    function mergeAttachments(
      attachmentProtocolEntries: Array<AttachmentProtocolEntry>,
      attachmentChats: Array<AttachmentChat>,
      attachmentProjects: Array<AttachmentProject>,
      attachmentsInReports: Array<AttachmentReportCompany | AttachmentReportActivity | AttachmentReportMaterial | AttachmentReportEquipment>
    ): Array<Attachment> {
      return _.orderBy(
        _.concat(attachmentProtocolEntries, attachmentChats, attachmentProjects, attachmentsInReports),
        (attachment) => (attachment?.originalCreationDate ? new Date(attachment.originalCreationDate) : convertISOStringToDate(attachment.createdAt)),
        'desc'
      );
    }

    return combineLatestAsync([
      attachmentEntryData,
      attachmentChatData,
      attachmentProjectData,
      attachmentReportCompanyData,
      attachmentReportActivityData,
      attachmentReportMaterialData,
      attachmentReportEquipmentData,
      this.userService.hasCurrentUserPermissionForReport$,
    ]).pipe(
      map(
        ([
          attachmentProtocolEntries,
          attachmentChats,
          attachmentProjects,
          attachmentReportCompanies,
          attachmentReportActivities,
          attachmentReportMaterials,
          attachmentReportEquipments,
          hasPermissionForReport,
        ]: [
          Array<AttachmentProtocolEntry>,
          Array<AttachmentChat>,
          Array<AttachmentProject>,
          Array<AttachmentReportCompany>,
          Array<AttachmentReportActivity>,
          Array<AttachmentReportMaterial>,
          Array<AttachmentReportEquipment>,
          boolean,
        ]) =>
          mergeAttachments(
            attachmentProtocolEntries,
            attachmentChats,
            attachmentProjects,
            hasPermissionForReport ? [...attachmentReportCompanies, ...attachmentReportActivities, ...attachmentReportMaterials, ...attachmentReportEquipments] : []
          )
      )
    );
  }
}
