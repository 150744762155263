<app-modal-header>
  <app-modal-step-indicator
    [stepsCount]="pdfWorkflowStepConfigs.length"
    [current]="currentStepIndex"
    [workflowName]="workflowName$ | async"
    [workflowStep]="currentStep | pdfStepTranslationKey | translate"
  ></app-modal-step-indicator>
  <div class="ion-text-center no-internet-text-container" *ngIf="!(networkConnected$ | async) || filteredProtocolEntries?.length > 0 || reportGroup">
    <ion-label class="omg-attention omg-attention-danger" *ngIf="!(networkConnected$ | async)">{{'sendProtocol.textNoInternet' | translate}}</ion-label>
    <ion-label class="omg-attention omg-attention-warning" *ngIf="filteredProtocolEntries?.length > 1 && showPreviewButton() && currentStep === 'PROTOCOL_CONFIGURATION'">{{ 'sendProtocol.filterProtocolEntries'|translate:{ shown: filteredProtocolEntries?.length} }}</ion-label>
    <ion-label class="omg-attention omg-attention-warning" *ngIf="filteredProtocolEntries?.length === 1 && showPreviewButton() && currentStep === 'PROTOCOL_CONFIGURATION'">{{ 'sendProtocol.filterProtocolEntry'|translate }}</ion-label>
    <ion-label class="omg-attention omg-attention-warning" *ngIf="reportGroup && reportGroupWeekNumber && showPreviewButton()">{{ 'sendProtocol.reportGroupWeek'|translate: {pages: reportGroup.reports.length, year: reportGroupYear, weekNumber: reportGroupWeekNumber} }}</ion-label>
    <ion-label class="omg-attention omg-attention-warning" *ngIf="reportGroup && reportGroupMonth && showPreviewButton()">{{ 'sendProtocol.reportGroupMonth'|translate: {pages: reportGroup.reports.length, year: reportGroupYear, month: reportGroupMonth | monthName} }}</ion-label>
  </div>
  <div class="d-flex gap-2" *ngIf="currentStep === 'MAILING_LIST'">
    <ion-searchbar class="slim-searchbar" #autofocus [(ngModel)]="searchTextInput" (ngModelChange)="onSearchTextChanged()" debounce="100"
                   placeholder="{{'sendProtocol.mailingList.searchName' | translate}}"></ion-searchbar>
    <ion-button class="omg-btn-secondary" *ngIf="workflowType !== WorkflowTypeEnum.GlobalSearch && currentStep === 'MAILING_LIST'" (click)="openCompanyOrderModal()">
      {{ 'pdfWorkflow.companyOrder.changeOrder' | translate }}
    </ion-button>
  </div>
  <div *ngIf="currentStep === 'MAILING_LIST'" class="d-flex ion-justify-content-between omg-modal-list-header omg-modal-section-header-small">
    <div>{{'sendProtocol.mailingList.companiesAndContacts' | translate}}</div>
    <div>
      <ion-label>{{'sendProtocol.mailingList.recipient' | translate}}</ion-label>
      <ion-label *ngIf="workflowType !== WorkflowTypeEnum.Report">/ {{'sendProtocol.mailingList.participant' | translate}}</ion-label>
    </div>
  </div>
  
</app-modal-header>

<ion-content class="with-padding">
  <app-pdf-mailing-list #appPdfMailingList
                        *ngIf="currentStep === 'MAILING_LIST'"
                        [hideHeader]="true"
                        [workflowType]="workflowType"
                        [protocol]="protocol"
                        [report]="report"
                        [reports]="reportGroup ? reportGroup.reports : undefined"
                        [searchTextInput]="searchTextInput"
                        [skipInactiveContacts]="true"
                        [skipInactiveCompanies]="true"
                        (allParticipantLoaded)="onParticipantsLoaded($event.participants)"
                        (mailingListCompaniesChanged)="onMailingLIstCompaniesChanged($event)"
                        (presentChanged)="onPresentChanged($event.employee)"
                        (recipientChanged)="onRecipientChanged($event.employee)"
                        [sortCompaniesByNameOnly]="workflowType === WorkflowTypeEnum.GlobalSearch"
                        [showCompanyPin]="workflowType !== WorkflowTypeEnum.GlobalSearch"></app-pdf-mailing-list>

  <ng-container *ngIf="currentStep === 'PROTOCOL_CONFIGURATION'">
    <app-pdf-configuration [workflowType]="workflowType"
                           [isContinuousProtocol]="isContinuousProtocol"
                           [hasCarriedOverEntries]="hasCarriedOverEntries"
                           [protocolSortEntriesBy]="protocol?.sortEntriesBy"
                           [weatherActivated]="!!protocol?.weatherActivated"
                           [pdfProtocolSettings]="pdfProtocolSettings"
                           [enableUnitContactsForProtocol]="hasMailingListUnitContacts === false"
                           [protocol]="protocol"
                           [(form)]="protocolConfigurationForm"
                           [hasProtocolEntriesFiltered]="filteredProtocolEntries?.length > 0 || tempFilteredProtocolEntries?.length > 0"
                           [(formDirty)]="protocolConfigurationFormDirty"
                           [protocolTypeName]="protocolTypeName"></app-pdf-configuration>

  </ng-container>

  <app-pdf-advanced-settings [hidden]="currentStep !== 'ADVANCED_SETTINGS'"
                             [workflowType]="workflowType"
                             [form]="emailSettingsForm"
                             [protocol]="protocol"
                             [report]="report"
                             [reportGroup]="reportGroup"
                             [participants]="allParticipants"
                             [(nextMeeting)]="nextMeeting"
                             (nextMeetingChange)="detectChanges()"
                             [(individualNextMeetings)]="individualNextMeetings"
                             [showSignatures]="showProtocolSignaturesChecked"
                             [includeUnitProfiles]="hasMailingListUnitContacts"
                             [mailingListUnitContactProfileIds]="mailingListUnitContactProfileIds"
                             (showSignaturesChange)="showProtocolSignaturesCheckedChange($event)"
                             [(showReportSignatures)]="showReportSignatureChecked"
                             [(showStaffTimeBlocks)]="showStaffTimeBlocksChecked"
                             [(appendActivityAttachments)]="appendActivityAttachments"
                             [(appendOtherAttachments)]="appendOtherAttachments"
                             [(appendMaterialAttachments)]="appendMaterialAttachments"
                             [(appendEquipmentAttachments)]="appendEquipmentAttachments"
                             [pdfProtocolSignatures]="pdfProtocolSignatures"
                             (pdfProtocolSignaturesChange)="pdfProtocolSignaturesChange($event)"></app-pdf-advanced-settings>

  <app-pdf-email-settings *ngIf="currentStep === 'EMAIL_SETTINGS'"
                          [language]="currentProjectLanguage$ | async"
                          [reportType]="reportType$ | async"
                          [workflowType]="workflowType"
                          [(form)]="emailSettingsForm"
                          (resetForm)="applyDataToEmailSettings()"
                          [(formDirty)]="emailFormDirty"
                          [hideResetButton]="workflowType === WorkflowTypeEnum.GlobalSearch"
                          [recipientEmails]="recipientEmails$ | async"
                          (saveAsDefaultSettings)="saveEmailSettingsProtocolSettings()"
                          (saveSignatureAsDefaultSettings)="saveSignature()"
                          [pendingSettingsSaving]="pendingSettingsSaving"
                          [userEmailSignature]="userEmailSignature"
                          (attachmentUserEmailSignatureCreate)="handleAttachmentUserEmailSignatureCreate($event)"></app-pdf-email-settings>
</ion-content>


<app-modal-footer>
  <ion-buttons slot="start">
    <ion-button class="omg-btn-secondary" (click)="back()" *ngIf="currentWorkflowConfig?.previousStep !== null">{{'back' | translate}}</ion-button>
    <ion-button *ngIf="workflowType === WorkflowTypeEnum.Protocol && currentStep === 'ADVANCED_SETTINGS'" class="omg-btn-secondary" (click)="saveAdvancedSettingsProtocolSettings()" [disabled]="pendingSettingsSaving">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="pendingSettingsSaving"></ion-spinner>
      <fa-icon slot="start" [icon]="['fal', 'star']"></fa-icon>
      <span class="ion-hide-lg-down">{{ 'sendProtocol.advancedSettings.saveAsDefaultAdvancedSettings' | translate }}</span>
    </ion-button>
    <ion-button *ngIf="workflowType === WorkflowTypeEnum.Protocol && currentStep === 'ADVANCED_SETTINGS'" class="omg-btn-secondary" (click)="resetAdvancedSettingsProtocolSettings()">
      <fa-icon slot="start" [icon]="['fal', 'undo']"></fa-icon>
      <span class="ion-hide-lg-down">{{ 'sendProtocol.resetValues' | translate }}</span>
    </ion-button>
    <ion-button *ngIf="workflowType === WorkflowTypeEnum.Report && currentStep === 'ADVANCED_SETTINGS'" class="omg-btn-secondary" (click)="applyDataToAdvancedSettingsReportSettings()">
      <fa-icon slot="start" [icon]="['fal', 'undo']"></fa-icon>
      <span class="ion-hide-sm-down">{{ 'sendProtocol.resetValues' | translate }}</span>
    </ion-button>
    <ion-button *ngIf="workflowType === WorkflowTypeEnum.Protocol && currentStep === 'PROTOCOL_CONFIGURATION'" class="omg-btn-secondary" (click)="saveLayoutProtocolSettings()" [disabled]="pendingSettingsSaving">
        <ion-spinner slot="start" class="spinner-center-button" *ngIf="pendingSettingsSaving"></ion-spinner>
        <fa-icon slot="start" [icon]="['fal', 'star']"></fa-icon>
        <span class="ion-hide-sm-down ion-hide-lg-up">{{ 'sendProtocol.emailSettings.saveAsDefaultLayoutSettingsShort' | translate }}</span>
        <span class="ion-hide-lg-down">{{ 'sendProtocol.emailSettings.saveAsDefaultLayoutSettings' | translate }}</span>
      </ion-button>
      <ion-button *ngIf="workflowType === WorkflowTypeEnum.Protocol && currentStep === 'PROTOCOL_CONFIGURATION'" class="omg-btn-secondary" (click)="resetLayoutProtocolSettings()">
        <fa-icon slot="start" [icon]="['fal', 'undo']"></fa-icon>
        <span class="ion-hide-lg-down">{{'sendProtocol.resetValues' | translate }}</span>
      </ion-button>
  </ion-buttons>

  <ion-buttons slot="end">
    <ion-button class="omg-btn-secondary" (click)="generatePreview()" *ngIf="showPreviewButton() && !showPreviewSendInsteadOfSend()" [disabled]="(nextMeeting?.show && nextMeetingComponent && !nextMeetingComponent.isValid())">
      <fa-icon slot="start" [icon]="['fal','file-pdf']"></fa-icon>
      <span class="ion-hide-sm-down">{{ 'preview' | translate}}</span>
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="next()" *ngIf="currentWorkflowConfig.nextStep !== null && !showPreviewSendInsteadOfSend()" [disabled]="loading || hasInvalidReportName()">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="loading"></ion-spinner>
      <span>{{'next' | translate}}</span>
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="generatePreview()" *ngIf="currentWorkflowConfig?.nextStep !== null && showPreviewSendInsteadOfSend()" [disabled]="!(networkConnected$ | async) || (nextMeeting?.show && nextMeetingComponent && !nextMeetingComponent.isValid()) || loading || hasInvalidReportName()">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="loading"></ion-spinner>
      <span>{{'previewNext' | translate}}</span>
    </ion-button>
    <ion-item class="omg-form-checkbox" (click)="checkCloseProtocol()" *ngIf="showCloseCheckbox() && !showPreviewSendInsteadOfSend() && (canCloseProtocol$ | async)">
     <ion-checkbox label-placement="end" justify="start" [ngModel]="closeProtocolAfterSend">
       <span class="ion-hide">&nbsp;</span>
       <span class="ion-hide-sm-down">{{ (workflowType === WorkflowTypeEnum.Protocol ? 'sendProtocol.closeProtocol': 'sendProtocol.closeReport') | translate }}</span>
       <span class="ion-hide-sm-up"><fa-icon [icon]="['fal','lock-alt']"></fa-icon></span>
     </ion-checkbox>
    </ion-item>
    <ion-button class="omg-btn-primary" (click)="triggerSendPdf()" *ngIf="showSendButton()" [disabled]="!(networkConnected$ | async) || (nextMeeting?.show && nextMeetingComponent && !nextMeetingComponent.isValid())">
      <span class="ion-hide-sm-down">{{'send' | translate}}</span>
      <fa-icon class="ion-hide-sm-up" [icon]="['fal','paper-plane']"></fa-icon>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
