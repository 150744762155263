import {CommonModule} from '@angular/common';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {IonicModule, IonInput} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import _ from 'lodash';
import {map, Observable, Subscription, switchMap} from 'rxjs';
import {Nullish} from 'src/app/model/nullish';
import {ProtocolEntryOrOpen} from 'src/app/model/protocol';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {ProtocolEntryDataService} from 'src/app/services/data/protocol-entry-data.service';
import {ProtocolTypeDataService} from 'src/app/services/data/protocol-type-data.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {ProtocolEntryService} from 'src/app/services/protocol/protocol-entry.service';
import {UserService} from 'src/app/services/user/user.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {Protocol, ProtocolEntry, ProtocolType, User} from 'submodules/baumaster-v2-common';

const LOG_SOURCE = 'EntryEditNumberModal';
const ONLY_POSITIVE_NUMBER = /^[0-9]\d*$/;

@Component({
  selector: 'app-entry-edit-number-modal',
  templateUrl: './entry-edit-number-modal.component.html',
  styleUrls: ['./entry-edit-number-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, UiModule, IonicModule, TranslateModule, ReactiveFormsModule, FormsModule],
})
export class EntryEditNumberModalComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;

  entryNumberForm: UntypedFormGroup = this.formBuilder.group({
    number: [null, Validators.compose([Validators.required, Validators.pattern(ONLY_POSITIVE_NUMBER), Validators.max(999), this.numberValidator()])],
  });

  @Input()
  entryId?: Nullish<string>;

  @Input()
  isCarriedOver?: Nullish<boolean>;

  @Input()
  isProtocolClosed: boolean;

  @Input()
  clickedFrom?: Nullish<string>;

  public maxNumber: number | undefined;
  public selectedProtocolEntry: ProtocolEntry | undefined;
  public protocol$: Observable<Protocol> | undefined;
  public protocolType$: Observable<ProtocolType> | undefined;
  private selectedProtocolEntrySubscription: Subscription | undefined;
  private protocolEntriesSubscription: Subscription | undefined;
  private protocolSubEntriesSubscription: Subscription | undefined;
  private allProtocolEntriesSubscription: Subscription | undefined;
  private currentUserSubscription: Subscription | undefined;
  private parentProtocolEntriesData: Array<ProtocolEntryOrOpen> | undefined;
  private protocolSubEntriesData: Array<ProtocolEntryOrOpen> | undefined;
  public allProtocolEntriesData: Array<ProtocolEntryOrOpen> | undefined;
  public changeInProgress = false;
  private currentUser: User | undefined;

  @ViewChild('inputNumber') inputNumber: IonInput;
  constructor(
    private formBuilder: FormBuilder,
    private protocolEntryDataService: ProtocolEntryDataService,
    private loggingService: LoggingService,
    private projectDataService: ProjectDataService,
    private toastService: ToastService,
    private protocolDataService: ProtocolDataService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolEntryService: ProtocolEntryService,
    private userService: UserService,
    private posthogService: PosthogService
  ) {}

  async ngOnInit() {
    const selectedProtocolEntry$ = this.protocolEntryDataService.getById(this.entryId);
    this.selectedProtocolEntrySubscription = selectedProtocolEntry$.subscribe((protocolEntry) => {
      this.selectedProtocolEntry = protocolEntry;
      this.protocol$ = this.protocolDataService.getById(protocolEntry.protocolId);
      if (!this.changeInProgress && this.entryNumberForm.get('number').pristine) {
        this.entryNumberForm.get('number').setValue(this.selectedProtocolEntry.number);
      }
    });
    this.protocolType$ = this.protocolTypeDataService.getById((await observableToPromise(this.protocol$))?.typeId);
    this.protocolEntriesSubscription = selectedProtocolEntry$
      .pipe(
        switchMap((selectedProtocolEntry) =>
          this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(this.selectedProtocolEntry?.protocolId).pipe(
            map((protocolEntries) => {
              return {selectedProtocolEntry, protocolEntries};
            })
          )
        )
      )
      .subscribe(({selectedProtocolEntry, protocolEntries}) => {
        if (!selectedProtocolEntry || !protocolEntries) {
          // This is in case selectedProtocolEntry$ does not resolve (protocolEntryId does not exist).
          this.allProtocolEntriesData = undefined;
          this.maxNumber = undefined;
          this.parentProtocolEntriesData = undefined;
          this.protocolSubEntriesData = undefined;
          return;
        }
        this.allProtocolEntriesData = protocolEntries;
        this.parentProtocolEntriesData = protocolEntries.filter((entry) => !entry.parentId && !entry.originalProtocolId);
        if (selectedProtocolEntry.parentId) {
          this.protocolSubEntriesData = protocolEntries.filter((entry) => entry.parentId === selectedProtocolEntry.parentId && !entry.originalProtocolId);
        } else {
          this.protocolSubEntriesData = undefined;
        }
        this.maxNumber = this.getNextNumber(selectedProtocolEntry);
      });
    this.currentUserSubscription = this.userService.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
    });
  }

  private getNextNumber(selectedProtocolEntry?: ProtocolEntry): number | undefined {
    if (!selectedProtocolEntry) {
      selectedProtocolEntry = this.selectedProtocolEntry;
    }
    if (!selectedProtocolEntry) {
      return undefined;
    }
    const entries = selectedProtocolEntry.parentId ? this.protocolSubEntriesData : this.parentProtocolEntriesData;
    if (!entries) {
      return undefined;
    }
    if (!entries?.length) {
      return 1;
    }
    const numbers = _.sortBy(entries.map((protocolEntry) => protocolEntry.number));
    return numbers[numbers.length - 1] + 1;
  }

  private numberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: number | null = control.value;
      if (value === null) {
        return null;
      }
      if (!this.selectedProtocolEntry) {
        return null;
      }
      const entryNumber: number = control.value;
      if (entryNumber > this.maxNumber) {
        return {numberTooHigh: true};
      }
      if (this.selectedProtocolEntry && this.selectedProtocolEntry.number === entryNumber) {
        return null;
      }
      const protocolEntries = this.selectedProtocolEntry.parentId ? this.protocolSubEntriesData : this.parentProtocolEntriesData;
      if (protocolEntries.some((protocol) => protocol.number === entryNumber) && entryNumber < this.maxNumber) {
        return {numbersWillChange: true};
      }
    };
  }

  public async changeEntryNumber() {
    const entryNumber: number = +this.inputNumber.value;
    const protocolEntries = this.selectedProtocolEntry.parentId ? this.protocolSubEntriesData : this.parentProtocolEntriesData;
    if (!protocolEntries.length) {
      return;
    }
    const numberAlreadyUsed = () => {
      if (protocolEntries.some((protocol) => protocol.number === entryNumber)) {
        return true;
      }
      return false;
    };
    const currentProject = await this.projectDataService.getCurrentProject();
    try {
      this.changeInProgress = true;
      if (entryNumber < this.maxNumber && numberAlreadyUsed()) {
        const filteredProtocolEntries = protocolEntries.filter((entry) => entry.number >= entryNumber || (entry.number > entryNumber && entry.number < this.selectedProtocolEntry.number));

        function reorderEntriesFn(protocolEntries: ProtocolEntry[]): ProtocolEntry[] {
          let entriesToUpdate: ProtocolEntry[] = [];
          for (const filteredEntry of protocolEntries) {
            const newNumber = filteredEntry.number + 1;
            entriesToUpdate.push({...filteredEntry, number: newNumber});
          }
          return entriesToUpdate;
        }

        await this.protocolEntryDataService.update(reorderEntriesFn(filteredProtocolEntries), currentProject.id);
      }
      if (entryNumber !== this.selectedProtocolEntry.number) {
        this.selectedProtocolEntry.number = entryNumber;
        await this.protocolEntryDataService.update(this.selectedProtocolEntry, currentProject.id);
      }
      this.posthogService.captureEvent('[Entry] successfully edited numbers', {
        userName: this.currentUser?.username ?? 'UNKNOWN',
        protocolId: this.selectedProtocolEntry.protocolId,
        protocolName: (await observableToPromise(this.protocol$))?.name ?? 'UNKNOWN',
        clickedFrom: this.clickedFrom ?? 'UNKNOWN',
      });
      await this.toastService.savingSuccess();
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error changeEntryNumber. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
    } finally {
      this.changeInProgress = false;
      await this.dismissModal();
      await this.protocolEntryService.checkAndHandleGapsBetweenProtocolEntries('fillGapsEntries.actions.number', this.selectedProtocolEntry.protocolId);
    }
  }

  async dismissModal() {
    await this.modal.dismiss();
  }

  ngOnDestroy() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
    if (this.selectedProtocolEntrySubscription) {
      this.selectedProtocolEntrySubscription.unsubscribe();
      this.selectedProtocolEntrySubscription = null;
    }
    if (this.protocolEntriesSubscription) {
      this.protocolEntriesSubscription.unsubscribe();
      this.protocolEntriesSubscription = null;
    }
    if (this.protocolSubEntriesSubscription) {
      this.protocolSubEntriesSubscription.unsubscribe();
      this.protocolSubEntriesSubscription = undefined;
    }
    if (this.allProtocolEntriesSubscription) {
      this.allProtocolEntriesSubscription.unsubscribe();
      this.allProtocolEntriesSubscription = undefined;
    }
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
      this.currentUserSubscription = undefined;
    }
  }
}
