<ion-item
  [class.active]="(shouldMarkActive$ | async) && active"
  (click)="goToProtocolEntries()"
>
  <ion-grid class="ion-no-padding protocol-detail">
    <ion-row>
      <ion-col class="protocol-detail__tagline">
        <div class="left">
          <ion-text color="primary"
                    class="protocol-details-short-name-number size-sm">
            <app-protocol-short-name-number [protocol]="protocol"></app-protocol-short-name-number>
          </ion-text>
          <ion-note *ngIf="(protocolType | async)?.layoutId" class="protocol-details-layout-name">
            {{ ('protocolLayoutShort.' + (protocolLayouts ? protocolLayouts[(protocolType | async).layoutId] : undefined)?.name) | translate}}
          </ion-note>
        </div>
        <div class="right">
          <div class="unit pr-2" *ngIf="protocol?.unitId" (click)="openUnitContactDetails($event, protocol.unitId)">
            <bdi color="text-dark" class="omg-t-xs-body-bold">
              {{ unitsById?.[protocol.unitId]?.name }}
            </bdi>
          </div>
          <fa-icon class="icon-color pr-1" *ngIf="protocol?.unitId" [fixedWidth]="true" [icon]="['fal6', 'shelter']" (click)="openUnitContactDetails($event, protocol.unitId)"></fa-icon>
          <div class="protocol-detail-more-button">
            <ion-button (click)="onClickEllipsis($event)" class="omg-btn-text">
              <fa-icon [icon]="['far', 'ellipsis-h']" class="protocol-details-open-context-menu-button"></fa-icon>
            </ion-button>
          </div>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <div class="protocol-title-container">
          <ion-text class="title-text-wrap-two-lines">{{protocol.name}}</ion-text>
        </div>
      </ion-col>
    </ion-row>

    <ion-row class="protocol-detail__footer">
      <ion-col size="auto" class="pr-2 protocol-detail__footer-date">
        <ion-text class="omg-t-medium-body">{{protocol.date | date:'dd.MM.yyyy'}}</ion-text>
      </ion-col>

      <ion-col size="auto" class="ion-text-center protocol-detail-options">
          <ion-text class="size-xs text-grey">{{ 'Entries' | translate }}</ion-text>
          <span class="badge primary">{{ (protocolEntriesData | async)?.length || 0 }}</span>
          <ion-note class="size-xs ml-2 text-grey">{{ 'Open' | translate }}</ion-note>
          <span class="badge" [class.red-badge]="(protocolEntriesNotDone | async)?.length > 0">{{ (protocolEntriesNotDone | async)?.length || 0 }} </span>
      </ion-col>

      <ion-col>
        <ion-row class="ion-float-right protocol-details-action-options">
          <fa-icon (click)="logEvent($event, 'lock')" [icon]="(protocol.closedAt | isEmpty) ? ['fal', 'lock-open-alt'] : ['fal', 'lock-alt']" class="big-icon {{ (protocol.closedAt | isEmpty) ? '' : 'primary'}}"></fa-icon>
          <fa-icon (click)="logEvent($event, 'envelope')" [icon]="['fal', 'envelope']" class="big-icon margin-left-5" [ngClass]="(wasSentByMail$ | async) ? 'primary' : ''"></fa-icon>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>
