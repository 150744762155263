import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, shareReplay} from 'rxjs/operators';
import {Nullish} from 'src/app/model/nullish';
import {PdfPlanFolderService, PdfPlanFolderWithDeletable} from 'src/app/services/project-room/pdf-plan-folder.service';
import {PdfPlanHolderService} from 'src/app/services/project-room/pdf-plan-holder.service';
import {OmgToastService} from 'src/app/services/ui/omg-toast.service';
import {PopoverService} from 'src/app/services/ui/popover.service';
import {ALL_FOLDERS_PAGE_SLUG} from 'src/app/shared/constants';
import {comparePrimitiveArrays} from 'src/app/utils/compare-utils';
import {addWeekDays} from 'src/app/utils/date-utils';
import {trackById} from 'src/app/utils/track-by-id';
import {PdfPlanFolder} from 'submodules/baumaster-v2-common';
import {LoadingService} from '../../../services/common/loading.service';
import {AlertService} from '../../../services/ui/alert.service';

@Component({
  selector: 'app-pdf-plan-folder-list',
  templateUrl: './pdf-plan-folder-list.component.html',
  styleUrls: ['./pdf-plan-folder-list.component.scss'],
})
export class PdfPlanFolderListComponent {
  readonly trackById = trackById;
  readonly ALL_FOLDERS_PAGE_SLUG = ALL_FOLDERS_PAGE_SLUG;
  pdfPlanFolders$ = this.pdfPlanFolderService.getPdfPlanFolderWithDeletable$(
    this.activatedRoute.queryParams.pipe(map(({q}) => (this.router.isActive(`/project-room/pdf-plan-folders?q=${q}`, true) ? q : undefined)))
  );
  routeFolderId$ = this.pdfPlanFolderService.routeFolderId$;
  allPdfPlanHoldersCount$: Observable<string> = this.pdfPlanHolderService.getAllPdfPlans$().pipe(map((holders) => (holders.length > 999 ? '999+' : `${holders.length}`)));

  folderIdsWithNewPlans$: Observable<Array<string>> = this.pdfPlanHolderService.getAllPdfPlans$().pipe(
    distinctUntilChanged((a, b) => {
      const mapFn = (value: (typeof a)[number]) => {
        let date = value.latestPdfPlanVersion?.createdAt ?? value.createdAt;
        if (date instanceof Date) {
          date = date.toISOString();
        }

        return `${value.folderId}_${date}`;
      };
      return comparePrimitiveArrays(a.map(mapFn), b.map(mapFn));
    }),
    map((holders) => {
      const now = new Date();
      const newThreshold = addWeekDays(now, -1, []);
      return holders
        .filter((holder) => new Date(holder.latestPdfPlanVersion ? holder.latestPdfPlanVersion.createdAt : holder.createdAt).getTime() >= newThreshold.getTime())
        .map((holder) => holder.folderId);
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    public popoverController: PopoverController,
    private pdfPlanFolderService: PdfPlanFolderService,
    private pdfPlanHolderService: PdfPlanHolderService,
    private router: Router,
    private popoverService: PopoverService,
    private toastService: OmgToastService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private loadingService: LoadingService
  ) {}

  async navigateToFolder(pdfPlanFolder?: Nullish<PdfPlanFolder>) {
    if (!pdfPlanFolder) {
      return await this.pdfPlanFolderService.navigateToAllPlansFolder();
    }
    return await this.pdfPlanFolderService.navigateToFolder(pdfPlanFolder);
  }

  async onClickEllipsis(event: Event, pdfPlanFolder: PdfPlanFolderWithDeletable) {
    if (pdfPlanFolder) {
      event.stopPropagation();
      const result = await this.popoverService.openActions(event, [
        {
          role: 'edit',
          label: 'edit',
          icon: ['fal', 'pencil'],
        },
        {
          role: 'delete',
          label: 'delete',
          icon: ['fal', 'trash-alt'],
          lookDisabled: !pdfPlanFolder.deletable,
        },
      ]);
      if (result === 'delete') {
        if (!pdfPlanFolder.deletable) {
          await this.toastService.infoWithMessageAndHeader('project_room.pdf_plan_folder.delete_not_available.header', 'project_room.pdf_plan_folder.delete_not_available.message');
          return;
        }

        if (!(await this.confirmDelete(pdfPlanFolder.name))) {
          return;
        }
        await this.loadingService.withLoading(
          async () => {
            await this.pdfPlanFolderService.deletePdfPlanFolder(pdfPlanFolder);
          },
          {message: this.translateService.instant('project_room.pdf_plan_folder.deleting')}
        );
      } else if (result === 'edit') {
        this.pdfPlanFolderService.editFolder(pdfPlanFolder);
      }
    }
  }

  private async confirmDelete(planName: string): Promise<boolean> {
    return await this.alertService.confirm({
      message: `${this.translateService.instant('project_room.pdf_plan_folder.confirmDelete', {planName})}`,
      confirmButton: {
        color: 'danger',
        fill: 'solid',
      },
      confirmLabel: 'button.delete',
    });
  }
}
