import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {Country, User} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {ClientService} from '../client/client.service';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {map} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

const REST_ENDPOINT_URI = 'api/data/countries/';

const prioritizedNames = ['Österreich', 'Deutschland', 'Schweiz', 'United Kingdom'];
const prioritizedNamesSet = new Set<string>(prioritizedNames);

@Injectable({
  providedIn: 'root',
})
export class CountryDataService extends AbstractClientAwareDataService<Country> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    clientService: ClientService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService,
    private translateService: TranslateService
  ) {
    super(StorageKeyEnum.COUNTRY, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, clientService, loggingService, integrityResolverService);
  }

  readonly dataSorted$ = this.data.pipe(
    map((items) =>
      items.sort((a: {name: string}, b: {name: string}) => {
        const indexA = prioritizedNames.indexOf(a.name);
        const indexB = prioritizedNames.indexOf(b.name);

        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        if (indexA !== -1) {
          return -1;
        }

        if (indexB !== -1) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      })
    )
  );

  readonly countryNamesSorted$ = this.dataSorted$.pipe(map((countries) => countries.map((country) => country.name)));

  getGroupText = (item: Country, index: number, countries: Array<Country>): string | null => {
    if (index < 0 || index > countries.length - 1) {
      return null; // should not really happen, that index is outside the countries length, but it does.
    }
    const isCurrentPrioritizedName = prioritizedNamesSet.has(item?.name);
    if (isCurrentPrioritizedName) {
      return null;
    }
    if (index === 0 || prioritizedNamesSet.has(countries[index - 1].name)) {
      return this.translateService?.instant('moreCountries');
    }
    return null;
  };

  getGroupTextName = (item: string, index: number, countries: Array<string>): string | null => {
    if (index < 0 || index > countries.length - 1) {
      return null; // should not really happen, that index is outside the countries length, but it does.
    }
    const isCurrentPrioritizedName = prioritizedNamesSet.has(item);
    if (isCurrentPrioritizedName) {
      return null;
    }
    if (index === 0 || prioritizedNamesSet.has(countries[index - 1])) {
      return this.translateService?.instant('moreCountries');
    }
    return null;
  };

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
