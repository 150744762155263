import {Injectable} from '@angular/core';
import {IdType, MoveAttachmentsProjectReq} from 'submodules/baumaster-v2-common';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {LoggingService} from '../common/logging.service';
import {ToastService} from '../common/toast.service';
import {SystemEventService} from '../event/system-event.service';
import {ProjectDataService} from '../data/project-data.service';
import {environment} from 'src/environments/environment';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {HttpClient} from '@angular/common/http';
import {SyncStrategy} from '../sync/sync-utils';
import {SyncService} from '../sync/sync.service';
import {AttachmentProjectDataService} from '../data/attachment-project-data.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {isAttachmentScheduledForUpload} from 'src/app/utils/attachment-utils';
import {PosthogService} from '../posthog/posthog.service';
import {UserService} from '../user/user.service';

const LOG_SOURCE = 'MoveProjectRoomAttachmentsService';

@Injectable({
  providedIn: 'root',
})
export class MoveProjectRoomAttachmentsService {
  private readonly httpOptions = {
    headers: {
      Accept: 'application/json; version=6',
    },
  };
  constructor(
    private loggingService: LoggingService,
    private toastService: ToastService,
    private systemEventService: SystemEventService,
    private projectDataService: ProjectDataService,
    private http: HttpClient,
    private syncService: SyncService,
    private attachmentProjectDataService: AttachmentProjectDataService,
    private attachmentSettingService: AttachmentSettingService,
    private posthogService: PosthogService,
    private userService: UserService
  ) {}

  private async getMoveUrl(): Promise<string> {
    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    return environment.serverUrl + `api/data/attachmentProject/move?projectId=${projectId}`;
  }

  public async moveAttachments(attachmentIds: IdType[], targetProjectId: IdType): Promise<void> {
    if (!attachmentIds.length) {
      return;
    }
    try {
      await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES);
      const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
      const attachments = await observableToPromise(this.attachmentProjectDataService.getByIds(attachmentIds));
      for (const attachment of attachments) {
        if (await isAttachmentScheduledForUpload(attachment, fileAccessUtil)) {
          throw new Error(`Attachment with id ${attachment.id} is not uploaded yet`);
        }
      }
      const body: MoveAttachmentsProjectReq = {
        sourceIds: attachmentIds,
        targetProjectId,
      };
      const url = await this.getMoveUrl();
      const success = await observableToPromise(this.http.post(url, body, this.httpOptions));
      if (success) {
        const currentUser = await observableToPromise(this.userService.currentUser$);
        this.posthogService.captureEvent('[ProjectRoom] successfully moved attachments', {
          userName: currentUser?.username ?? 'UNKNOWN',
          numberOfAttachments: attachmentIds?.length ?? 0,
        });
        await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES, {additionalProjectIdsToSync: [targetProjectId]});
      }
    } catch (e) {
      this.loggingService.error(LOG_SOURCE, `moveAttachments failed - ${convertErrorToMessage(e)}`);
      this.systemEventService.logErrorEvent(LOG_SOURCE, `moveAttachments failed - ${convertErrorToMessage(e)}`);
      this.toastService.error('error_saving_message');
      throw e;
    }
  }
}
