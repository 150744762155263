import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IonicSelectableComponent} from 'ionic-selectable';
import ProjectForDisplay from 'src/app/model/ProjectForDisplay';
import {ProjectService} from 'src/app/services/project/project.service';
import {BehaviorSubject, map, Observable, Subscription} from 'rxjs';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {IdType, ProjectStatusEnum} from 'submodules/baumaster-v2-common';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {MoveProjectRoomAttachmentsService} from 'src/app/services/project-room/move-project-room-attachments.service';
import {LoggingService} from 'src/app/services/common/logging.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from 'src/app/services/common/selectable-util.service';
import {LoadingService} from 'src/app/services/common/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {OfflineInfoService} from 'src/app/services/common/offline-info.service';
import {AlertService} from 'src/app/services/ui/alert.service';

const LOG_SOURCE = 'MoveProjectRoomAttachmentsModalComponent';

@Component({
  selector: 'app-move-project-room-attachments-modal',
  templateUrl: './move-project-room-attachments-modal.component.html',
  styleUrls: ['./move-project-room-attachments-modal.component.scss'],
})
export class MoveProjectRoomAttachmentsModalComponent implements OnInit, OnDestroy {
  protected modal: HTMLIonModalElement;

  @Input()
  attachmentIds: Array<IdType>;

  public form: UntypedFormGroup = this.formBuilder.group({
    project: '',
  });
  public destinationProjectSubject = new BehaviorSubject<ProjectForDisplay | undefined>(undefined);
  public destinationProject$: Observable<ProjectForDisplay | undefined> = this.destinationProjectSubject.asObservable();
  public projects: ProjectForDisplay[] | undefined;
  public moving = false;
  private projectsSubscription: Subscription | undefined;
  readonly isOffline$ = this.networkStatusService.online$.pipe(map((online) => !online));
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  constructor(
    private projectService: ProjectService,
    private projectDataService: ProjectDataService,
    private formBuilder: FormBuilder,
    private networkStatusService: NetworkStatusService,
    private moveProjectAttachmentsService: MoveProjectRoomAttachmentsService,
    private loggingService: LoggingService,
    private systemEventService: SystemEventService,
    private toastService: ToastService,
    private selectableUtilService: SelectableUtilService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private offlineInfoService: OfflineInfoService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.projectsSubscription = combineLatestAsync([this.projectService.activeProjectsForDisplay$, this.projectDataService.currentProjectObservable]).subscribe(([projects, currentProject]) => {
      this.projects = projects.filter((project) => project.id !== currentProject.id && (!project.status || project.status === ProjectStatusEnum.ACTIVE));
    });
  }

  ngOnDestroy() {
    this.projectsSubscription?.unsubscribe();
    this.projectsSubscription = undefined;
  }

  public async projectOnChange(event: {component: IonicSelectableComponent; value: ProjectForDisplay}) {
    this.destinationProjectSubject.next(event.value);
  }

  public async onOpen($event: {component: IonicSelectableComponent}) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  public async onClose($event: {component: IonicSelectableComponent}) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }

  public getGroupText = (projectForDisplay: ProjectForDisplay, index: number, projectsForDisplay: ProjectForDisplay[]) => {
    return this.projectService.getProjectGroupText(projectForDisplay, index, projectsForDisplay);
  };

  public async moveAttachments() {
    if (await observableToPromise(this.isOffline$)) {
      if (await this.offlineInfoService.showOfflineAlert(this.translateService.instant('offlineInfo.description3'))) {
        await this.networkStatusService.isOnline();
        this.moveAttachments();
      }
      return;
    }
    if (this.moving) {
      return;
    }
    try {
      this.moving = true;
      const destinationProject = this.destinationProjectSubject.getValue();
      await this.projectService.ensureProjectDataOfflineAvailable(destinationProject.id, {temporarily: true, modalAllowInBackground: false});
      if (!destinationProject) {
        return;
      }
      await this.loadingService.withLoading(
        async () => {
          await this.moveProjectAttachmentsService.moveAttachments(this.attachmentIds, destinationProject.id);
          await this.toastService.info(this.translateService.instant('moveProjectAttachments.success', {projectName: destinationProject.name}));
        },
        {
          message: this.translateService.instant('moveProjectAttachments.inProgress'),
        }
      );
      this.dismissModal();
    } catch (e) {
      this.loggingService.error(LOG_SOURCE, `moveAttachments failed with error: ${convertErrorToMessage(e)}`);
      this.systemEventService.logErrorEvent(`${LOG_SOURCE} - moveAttachments`, e);
      this.dismissModal();
      await this.alertService.ok({
        header: this.translateService.instant('moveProjectAttachments.alertAttachmentsNotSynced.header'),
        message: this.translateService.instant('moveProjectAttachments.alertAttachmentsNotSynced.message'),
      });
    } finally {
      this.moving = false;
    }
  }

  public dismissModal() {
    this.modal.dismiss('cancel');
  }
}
