import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {Observable, map, shareReplay, switchMap} from 'rxjs';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {getUserNameFromAddress} from 'src/app/utils/user-name-utils';
import {Address, IdType, Profile, UnitProfile} from 'submodules/baumaster-v2-common';
import {AddressDataService} from '../data/address-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {UnitProfileDataService} from '../data/unit-profile-data.service';

export interface UnitContact {
  /**
   * Equals to profile id
   */
  id: IdType;
  /**
   * First name and last name
   */
  name: string;
  profile: Profile;
  address: Address;
}

export interface UnitContactWithUnitProfiles extends UnitContact {
  unitProfiles: UnitProfile[];
}

@Injectable({
  providedIn: 'root',
})
export class UnitContactsService {
  allUnitContacts$: Observable<UnitContact[]> = combineLatestAsync([this.profileDataService.dataGroupedById, this.addressDataService.dataGroupedById, this.projectProfileDataService.data]).pipe(
    map(([profileById, addressById, projectProfiles]) => {
      const contacts: UnitContact[] = projectProfiles.reduce((acc, {profileId}) => {
        const profile = profileById[profileId];
        if (!profile || profile.type !== 'UNIT_CONTACT' || profile.isActive === false) {
          return acc;
        }
        const address = addressById[profile.addressId];
        if (!address) {
          return acc;
        }

        acc.push({id: profile.id, name: getUserNameFromAddress(this.translateService, address), profile, address});

        return acc;
      }, []);

      return _.orderBy(contacts, ['name']);
    })
  );

  unitContactsByUnitId$ = this.unitProfileDataService.data.pipe(
    switchMap((allUnitProfiles) =>
      this.allUnitContacts$.pipe(
        map((unitContacts) => {
          const unitContactByProfileId = _.keyBy(unitContacts, 'profile.id');
          const unitContactsWithUnitProfilesByUnitId: Record<IdType, UnitContactWithUnitProfiles[]> = {};
          for (const unitProfile of allUnitProfiles) {
            const unitContact = unitContactByProfileId[unitProfile.profileId];
            if (!unitContact) {
              continue;
            }
            if (!unitContactsWithUnitProfilesByUnitId[unitProfile.unitId]) {
              unitContactsWithUnitProfilesByUnitId[unitProfile.unitId] = [];
            }
            const aggregate = unitContactsWithUnitProfilesByUnitId[unitProfile.unitId];
            aggregate.push({...unitContact, unitProfiles: [unitProfile]});
          }

          return unitContactsWithUnitProfilesByUnitId;
        })
      )
    ),
    shareReplay({bufferSize: 1, refCount: true})
  );

  constructor(
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private projectProfileDataService: ProjectProfileDataService,
    private unitProfileDataService: UnitProfileDataService,
    private translateService: TranslateService
  ) {}

  async removeContacts(contacts: UnitContact[], projectId: IdType) {
    const profileIds = _.compact(contacts.map(({id}) => id));
    if (!profileIds.length) {
      return;
    }

    await this.projectProfileDataService.delete((projectProfiles) => projectProfiles.filter((p) => profileIds.includes(p.profileId)), projectId);
    await this.profileDataService.update((profiles) => profiles.filter((p) => profileIds.includes(p.id)).map((profile) => ({...profile, isActive: false})));
  }
}
