import {fabric} from 'fabric';
import {Image} from 'fabric/fabric-impl';
export interface CanvasOptions {
  element: HTMLElement;
  size: SizeType;
  fill?: 'cover' | 'contain';
  width?: number;
  height?: number;
}

export interface CanvasDimension {
  width: number;
  height: number;
  zoomFactor: number;
}

export type SizeType = 'small' | 'large' | 'large-square' | 'screenshot-preview' | 'signature' | 'card';

export function calculateOptimalDimension(imageWidth, imageHeight, viewportWidth, viewportHeight): CanvasDimension {
  let factor: number;
  let calculatedWidth: number, calculatedHeight: number;

  if (imageWidth > viewportWidth || imageHeight > viewportHeight) {
    if (imageWidth / imageHeight > viewportWidth / viewportHeight) {
      calculatedWidth = viewportWidth;
      calculatedHeight = (imageHeight / imageWidth) * viewportWidth;
    } else {
      calculatedHeight = viewportHeight;
      calculatedWidth = (imageWidth / imageHeight) * viewportHeight;
    }
    factor = calculatedHeight / imageHeight;
  } else {
    calculatedWidth = imageWidth;
    calculatedHeight = imageHeight;
    factor = 1;
  }
  return {width: calculatedWidth, height: calculatedHeight, zoomFactor: factor};
}

// Source: https://github.com/fabricjs/fabric.js/discussions/7052
export function enclose(canvas: fabric.Canvas, object: Image) {
  let {br: brRaw, tl: tlRaw} = object.aCoords;
  if (object.scaleX) {
    brRaw = brRaw.clone().setX(brRaw.x / object.scaleX);
    tlRaw = tlRaw.clone().setX(tlRaw.x / object.scaleX);
  }
  if (object.scaleY) {
    brRaw = brRaw.clone().setY(brRaw.y / object.scaleY);
    tlRaw = tlRaw.clone().setY(tlRaw.y / object.scaleY);
  }
  const T = canvas.viewportTransform;
  const br = fabric.util.transformPoint(brRaw, T);
  const tl = fabric.util.transformPoint(tlRaw, T);
  const {x: left, y: top} = tl;
  const {x: right, y: bottom} = br;
  const {width, height} = canvas;
  // calculate how far to translate to line up the edge of the object with
  // the edge of the canvas
  const dLeft = Math.abs(right - width);
  const dRight = Math.abs(left);
  const dUp = Math.abs(bottom - height);
  const dDown = Math.abs(top);
  // if the object is larger than the canvas, clamp translation such that
  // we don't push the opposite boundary past the edge
  const maxDx = Math.min(dLeft, dRight);
  const maxDy = Math.min(dUp, dDown);
  const leftIsOver = left < 0;
  const rightIsOver = right > width;
  const topIsOver = top < 0;
  const bottomIsOver = bottom > height;
  const translateLeft = rightIsOver && !leftIsOver;
  const translateRight = leftIsOver && !rightIsOver;
  const translateUp = bottomIsOver && !topIsOver;
  const translateDown = topIsOver && !bottomIsOver;
  const dx = translateLeft ? -maxDx : translateRight ? maxDx : 0;
  const dy = translateUp ? -maxDy : translateDown ? maxDy : 0;
  if (dx || dy) {
    console.log(`in enclose - dx:${dx}, dy:${dy}, Tx:${T[4]}, Ty:${T[5]}`);
    T[4] += dx;
    T[5] += dy;
    canvas.requestRenderAll();
  }

  return {dx, dy};
}
