import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MbscDatepickerOptions} from '@mobiscroll/angular-ivy';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {NextMeetingForm, NextMeetingInputForm} from '../next-meeting.interface';
import {MobiscrollService} from '../../../../services/common/mobiscroll.service';
import {TimeRangeValidator} from '../../../../utils/validation-utils';
import _ from 'lodash';

@Component({
  selector: 'app-next-meeting-form',
  templateUrl: './next-meeting-form.component.html',
  styleUrls: ['./next-meeting-form.component.scss'],
})
export class NextMeetingFormComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();

  @Input()
  nextMeeting!: NextMeetingForm;
  @Output()
  nextMeetingChange = new EventEmitter<NextMeetingForm>();

  rangePickerSettings: MbscDatepickerOptions = {
    timeFormat: this.mobiscrollService.TIME_FORMAT,
    returnFormat: 'locale',
    controls: ['time'],
    stepMinute: 15,
  };

  get nextMeetingDateValue(): string {
    if (this.nextMeeting.timeStart && this.nextMeeting.timeEnd) {
      return `${this.nextMeeting.timeStart} - ${this.nextMeeting.timeEnd}`;
    }
    return '';
  }

  datePickerSettings: MbscDatepickerOptions = {
    dateFormat: this.mobiscrollService.DATE_FORMAT,
    returnFormat: 'iso8601',
    min: new Date(),
  };

  form: UntypedFormGroup;
  isFormValid = true;

  public mbscThemeVariant$ = this.mobiscrollService.themeVariant$;
  public mbscLocale$ = this.mobiscrollService.locale$;
  public MBSC_DATE_FORMAT = this.mobiscrollService.DATE_FORMAT;

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private mobiscrollService: MobiscrollService
  ) {
    this.initializeForm();
  }

  private initializeForm() {
    this.form = this.fb.group({
      show: true,
      date: [null, [Validators.required]],
      timeStartEnd: [
        [null, null],
        [Validators.required, TimeRangeValidator],
      ],
      subject: null,
      body: null,
      groupMeeting: true,
      sendCalendarMail: false,
    });

    this.form.valueChanges.pipe(debounceTime(0), takeUntil(this.destroy$)).subscribe((nextMeetingInputForm: NextMeetingInputForm) => {
      const copy = {...nextMeetingInputForm};
      const [timeStart, timeEnd] = nextMeetingInputForm.timeStartEnd?.length ? nextMeetingInputForm.timeStartEnd : [null, null];
      const nextMeeting: NextMeetingForm = {..._.omit(nextMeetingInputForm, 'timeStartEnds'), timeStart, timeEnd};
      if (!nextMeeting.show) {
        nextMeeting.date = null;
        nextMeeting.timeEnd = null;
        nextMeeting.timeStart = null;
      } else if (!nextMeeting.date) {
        nextMeeting.date = null;
        nextMeeting.timeEnd = null;
        nextMeeting.timeStart = null;
      } else if (!nextMeeting.timeStart || !nextMeeting.timeEnd) {
        const now = new Date();

        nextMeeting.timeEnd = `${now.getHours() + 1}`.padStart(2, '0') + ':00';
        nextMeeting.timeStart = `${now.getHours()}`.padStart(2, '0') + ':00';

        if (nextMeeting.timeEnd === '00:00') {
          nextMeeting.timeEnd = '23:59';
        }
        this.form.controls.timeStartEnd.setValue([nextMeeting.timeStart, nextMeeting.timeEnd]);
      }

      if (copy.date !== nextMeeting.date || timeEnd !== nextMeeting.timeEnd || timeStart !== nextMeeting.timeStart) {
        this.form.patchValue(
          {
            date: nextMeeting.date,
            timeStart: nextMeeting.timeStart,
            timeEnd: nextMeeting.timeEnd,
          },
          {
            emitEvent: false,
          }
        );
      }

      this.nextMeeting = nextMeeting;
      this.nextMeetingChange.emit(this.nextMeeting);
    });
  }

  ngOnInit() {
    this.form.patchValue(
      {
        ...this.nextMeeting,
      },
      {
        emitEvent: false,
      }
    );
  }

  ngAfterViewInit(): void {
    this.form.get('date').markAsTouched({onlySelf: true});
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nextMeeting && !changes.nextMeeting.firstChange) {
      this.form.patchValue(
        {
          ...this.nextMeeting,
        },
        {
          emitEvent: false,
        }
      );
    }
  }
}
