<ion-button class="omg-btn-text close-button" (click)="dismiss()">
  <fa-icon [icon]="['fal', 'times']"></fa-icon>
</ion-button>
<ion-list class="ion-no-padding">
  <ng-container *ngFor="let action of actions; let first = first; let last = last">
    <input type="file" [accept]="acceptedMimeTypesForUpload" (change)="action.uploadFileHandler?.($event)" multiple #fileUpload class="ion-hide">
    <ion-item
      [disabled]="action.disabled"
      [button]="getIsEnabled$(action) | async"
      [class.look-disabled]="!(getIsEnabled$(action) | async)"
      (click)="action.uploadFileHandler ? fileUpload.click() : execute(action)"
      lines="none"
      [ngClass]="action.itemClass"
      [class.first-item-margin]="first"
      [class.last-item-margin]="last"
    >
      <div [appLayout]="0.75" class="icon-label">
        <fa-icon *ngIf="action.icon" [fixedWidth]="true" [icon]="action.icon" [ngClass]="action.iconClass"></fa-icon>
        <fa-layers [fixedWidth]="true" *ngIf="action.layers" [ngClass]="action.layersClass">
          <fa-icon *ngFor="let layer of action.layers" [icon]="layer.icon" [ngClass]="layer.iconClass" [transform]="layer.transform"></fa-icon>
        </fa-layers>
        <span class="flex-grow-1">{{ action.label | translate }}</span>
        <fa-icon *ngIf="action?.externalLink" [icon]="['fal', 'external-link']" class="endIcon"></fa-icon>
      </div>
    </ion-item>
  </ng-container>
  <div *ngIf="showCheckbox" class="line ml-3 mr-3"></div>
  <div *ngIf="showCheckbox" [appLayout]="0.75" class="icon-label mb-2">
    <ion-item lines="none" class="no-hover">
      <ion-checkbox class="omg-checkbox" label-placement="end" justify="start" [(ngModel)]="checkboxEnabled">
        {{ checkboxTranslationToken | translate }}
      </ion-checkbox>
     </ion-item>
  </div>
</ion-list>
