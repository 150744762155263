import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {StorageKeyEnum} from '../../shared/constants';
import {IdType, Participant, User} from 'submodules/baumaster-v2-common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '../user/user.service';
import {StorageService} from '../storage.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {observableToPromise} from '../../utils/async-utils';
import {filterParticipantForGlobalSearch} from 'src/app/utils/participant-utils';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';

const REST_ENDPOINT_URI = 'api/data/participants';

@Injectable({
  providedIn: 'root',
})
export class ParticipantDataService extends AbstractProjectAwareDataService<Participant> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(
      StorageKeyEnum.PARTICIPANT,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService
    );
  }

  public getByReportId(reportId: IdType): Observable<Array<Participant>> {
    return this.data.pipe(
      map((participants) => participants.filter((participant) => participant.reportId === reportId && (participant.pdfpreviewId === null || participant.pdfpreviewId === undefined)))
    );
  }

  public getByProtocolId(protocolId: IdType): Observable<Array<Participant>> {
    return this.data.pipe(map((participants) => participants.filter((participant) => participant.protocolId === protocolId && !participant.pdfpreviewId)));
  }

  public getByPdfPreviewId(protocolId: IdType, pdfPreviewId: IdType): Observable<Array<Participant>> {
    return this.data.pipe(map((participants) => participants.filter((participant) => participant.protocolId === protocolId && participant.pdfpreviewId === pdfPreviewId)));
  }

  public getByPdfPreviewIds(pdfPreviewIds: IdType[]): Observable<Array<Participant>> {
    return this.data.pipe(map((participants) => participants.filter((participant) => pdfPreviewIds.includes(participant.pdfpreviewId))));
  }

  public getByProtocolIdAcrossProjects(protocolId: IdType): Observable<Array<Participant>> {
    return this.dataAcrossProjects$.pipe(
      map((participants) => participants.filter((participant) => participant.protocolId === protocolId && (participant.pdfpreviewId === null || participant.pdfpreviewId === undefined)))
    );
  }

  public getAllByProtocolId(protocolId: IdType): Observable<Array<Participant>> {
    return this.data.pipe(map((participants) => participants.filter((participant) => participant.protocolId === protocolId)));
  }

  public getAllByReportId(reportId: IdType): Observable<Array<Participant>> {
    return this.data.pipe(map((participants) => participants.filter((participant) => participant.reportId === reportId)));
  }

  getByPdfPreviewIdAndProtocolId(pdfPreviewId: IdType, protocolId: IdType): Observable<Array<Participant>> {
    return this.data.pipe(map((participants) => participants.filter((participant) => participant.pdfpreviewId === pdfPreviewId && participant.protocolId === protocolId)));
  }

  getByPdfPreviewIdAndReportId(pdfPreviewId: IdType, reportId: IdType): Observable<Array<Participant>> {
    return this.data.pipe(map((participants) => participants.filter((participant) => participant.pdfpreviewId === pdfPreviewId && participant.reportId === reportId)));
  }

  getOwnParticipants(profileId: IdType): Observable<Array<Participant>> {
    return this.data.pipe(map((participants) => participants.filter((participant) => participant.profileId === profileId && participant.mailingList)));
  }

  getByProfileId(profileId: IdType): Observable<Array<Participant>> {
    return this.data.pipe(map((participants) => participants.filter((participant) => participant.profileId === profileId)));
  }

  getForGlobalSearchByProjectId(): Observable<Map<IdType, Array<Participant>>> {
    return this.dataByProjectId$.pipe(
      map((participantsByProjectId) => {
        const filteredMap = new Map<IdType, Array<Participant>>();
        for (const projectId of participantsByProjectId.keys()) {
          const participants = participantsByProjectId.get(projectId).filter(filterParticipantForGlobalSearch);
          if (participants.length) {
            filteredMap.set(projectId, participants);
          }
        }
        return filteredMap;
      })
    );
  }

  async deleteGlobalSearchForCurrentProject(): Promise<void> {
    const participants = await observableToPromise(this.getForGlobalSearchCurrentProject());
    await this.delete(participants, this.currentProjectId);
  }

  getForGlobalSearchCurrentProject(): Observable<Array<Participant>> {
    return this.data.pipe(map((participants) => participants.filter(filterParticipantForGlobalSearch)));
  }

  getForGlobalSearchAndPdfPreview(pdfPreviewId: IdType): Observable<Array<Participant>> {
    return this.dataAcrossProjects$.pipe(map((participants) => participants.filter((participant) => !participant.protocolId && !participant.reportId && participant.pdfpreviewId === pdfPreviewId)));
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
