import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {Nullish} from '../model/nullish';
import {WithParamsTranslateService} from '../services/common/with-params-translate.service';
import {Translatable} from '../model/translatable';

@Pipe({
  name: 'translateInLanguage',
  standalone: true,
  pure: true,
})
export class TranslateInLanguagePipe implements PipeTransform {
  constructor(private withParamsTranslateService: WithParamsTranslateService) {}

  transform(value: Nullish<Translatable>, language: Nullish<string>): Observable<Nullish<string>> {
    return this.withParamsTranslateService.translateInLanguage(value, language);
  }
}
