<ng-template #toolbar>
  <div class="d-flex gap-2 pt-2">
    <ion-searchbar #autofocus debounce="200" [ngModel]="searchText()" (ngModelChange)="searchText.set($event)"
      show-clear-button="never" placeholder="{{'search' | translate}}" class="slim-searchbar">
      <ion-button class="omg-btn-text clear-button" (click)="clearClick.emit()">
        <fa-icon slot="icon-only" [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-searchbar>
  </div>
</ng-template>

<ion-list class="omg-boundary project-list">
  @if (filteredSelectorUnitsWithPathAndLevel().length === 0) {
    @if (selectorUnitLevels.length > 0) {
    <div class="p-4">
    @if (searchText().length > 0) {
      {{ 'noItemsFoundClickCreate' | translate:{searchText: searchText()} }}
    } @else {
      {{ 'noItemsFound' | translate }}
    }
    </div>
    } @else {
    <app-unit-selector-empty></app-unit-selector-empty>
    }
  }
  <rx-virtual-scroll-viewport autosize [tombstoneSize]="49">
    <div class="unit-selector-list-item full-width cursor-pointer list-item pl-7 pr-7 d-flex ion-align-items-center" *rxVirtualFor="let unit of filteredSelectorUnitsWithPathAndLevel; trackBy 'id'; strategy 'local'" (click)="selectUnit(unit)">
      <div class="d-inline-flex gap-4 ion-align-items-start">
        <span class="bau-radio" [class.checked]="unit.isSelected"></span>
        <div class="d-flex flex-column gap-1">
          <span>
            {{unit.name}}
          </span>
          @if (unit.path.length) {
          <div class="d-flex gap-2 omg-t-medium-body text-grey">
            @for (path of unit.path; track path.id; let last = $last) {
            {{path.name}} &gt; 
            }
            {{unit.name}}
          </div>
          }
        </div>
      </div>
    </div>
  </rx-virtual-scroll-viewport>
</ion-list>

<ng-template #footer let-cancel="cancel" let-reset="reset" let-apply="apply" let-applyLabel="applyLabel" let-applyIcon="applyIcon">
  <ion-buttons class="unit-selector-tree-footer-buttons" slot="end">
    <ion-button class="omg-btn-text ion-hide-sm-down" (click)="cancel()">
      {{ 'cancel' | translate }}
    </ion-button>
    <div class="ion-hide-sm-up"></div>
    @if (canReset) {
    <ion-button class="omg-btn-primary" (click)="reset()">
      {{ 'reset' | translate }}
    </ion-button>
    } @else {
    <ion-button class="omg-btn-primary" (click)="apply()" [disabled]="!selectedUnit()">
      <fa-icon slot="start" [icon]="applyIcon"></fa-icon>
      <span>{{ applyLabel | translate }}</span>
    </ion-button>
    }
  </ion-buttons>
</ng-template>
