<ion-grid class="ion-no-padding">
  <ion-row>
    <ion-item lines="none" class="omg-form-checkbox">
      <ion-checkbox
          label-placement="end"
          justify="start"
          [ngModel]="showSignatures"
          (ngModelChange)="onShowChange($event)"
          [disabled]="!(isFeatureEnabled$ | async)"
      >
        <ion-label>
          <div>{{ "sendProtocol.protocolConfig.signature.sign" | translate }}</div>
          <ion-note *ngIf="!(isFeatureEnabled$ | async)">
            {{ "sendProtocol.protocolConfig.signature.noPermission" | translate }}
          </ion-note>
        </ion-label>
      </ion-checkbox>
    </ion-item>
  </ion-row>
  <ion-row *ngIf="showSignatures">
    <ion-col class="ion-justify-content-center">
      <ionic-selectable
        #profilesSelectable
        [canSearch]="true"
        [isEnabled]="true"
        searchPlaceholder="{{ 'search' | translate }}"
        searchFailText="{{ 'noItemsFound' | translate }}"
        [items]="profileCompanyAddresses$ | async"
        [ngModel]="selectedProfileCompanyAddresses$ | async"
        (ngModelChange)="handleProfileCompanyAddressesChange($event)"
        itemValueField="id"
        itemTextField="searchText"
        groupValueField="company.id"
        groupTextField="company.name"
        class="text-primary"
        [isMultiple]="true"
        appSelectableCommon
        #fs="appSelectableCommon"
        (onOpen)="onOpen($event)"
        (onClose)="onClose($event)"
        [shouldFocusSearchbar]="fs.isDesktop"
      >
        <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
          <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
        </ng-template>
        <ng-template ionicSelectableValueTemplate let-value="value">
          <app-selectable-value [value]="value"></app-selectable-value>
        </ng-template>
        <ng-template ionicSelectableHeaderTemplate>
          <app-selectable-header></app-selectable-header>
        </ng-template>
        <ng-template ionicSelectableFooterTemplate>
          <app-selectable-footer></app-selectable-footer>
        </ng-template>
        <ng-template ionicSelectableItemTemplate let-profile="item">
          <ion-label class="participant">
            <ion-text class="participant-name">
              {{ profile.address.firstName }} {{ profile.address.lastName }}
            </ion-text>
            <ion-note>
              {{ profile.address.email }}
            </ion-note>
          </ion-label>
        </ng-template>
      </ionic-selectable>
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size="12" size-sm="6" class="default-buttons">
            <ion-button
                class="omg-btn-secondary"
                (click)="profilesSelectable.open()"
                [disabled]="operationInProgress"
            >
            <fa-icon [icon]="['fal', 'user']" slot="start"></fa-icon>
            <span>
              {{ 'sendProtocol.protocolConfig.signature.editSigner' | translate }}
            </span>
            </ion-button>
            <ion-button
                class="omg-btn-secondary"
                (click)="addCustomSigner()"
                [disabled]="operationInProgress"
            >
            <fa-icon [icon]="['fal', 'plus']" slot="start"></fa-icon>
            <span>
              {{ 'sendProtocol.protocolConfig.signature.addCustomSigner' | translate }}
            </span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="pdfProtocolSignatures.length && showSignatures">
    <ion-col size="12" class="mt-2">
      <strong class="text-primary">
        {{ 'sendProtocol.protocolConfig.signature.signatories' | translate }}
      </strong>
    </ion-col>
    <ion-col size="12" class="mt-2">
      <div class="signatures-outer-container">
        <ion-grid class="ion-no-padding">
          <ion-row class="signatures-header pl-2 pr-2 ion-align-items-center ion-hide-lg-down">
            <ion-col size="4">
              <span class="omg-t-medium-body-bold">{{ (mailingListUnitContactProfileIds?.length ? 'sendProtocol.protocolConfig.signature.companyOwnerTenantLabel'  : 'Company') | translate}}</span>
            </ion-col>
            <ion-col size="4">
              <span class="omg-t-medium-body-bold">{{ 'sendProtocol.protocolConfig.signature.signerNameLabel' | translate }}</span>
            </ion-col>
            <ion-col size="4">
              <span class="omg-t-medium-body-bold">{{ 'sendProtocol.protocolConfig.signature.sign' | translate }}</span>
            </ion-col>
          </ion-row>
          <app-pdf-protocol-signature *ngFor="let pdfProtocolSignature of pdfProtocolSignatures; let i = index; trackBy: trackByProfileOrName"
                                      [pdfProtocolSignature]="pdfProtocolSignature"
                                      [readonly]="!(isFeatureEnabled$ | async) || operationInProgress"
                                      [even]="i % 2 === 0"
                                      (pdfProtocolSignatureChange)="handlePdfProtocolSignatureChange($event, i)"
                                      (pdfProtocolSignatureDelete)="handlePdfProtocolSignatureDelete($event, i)"
          ></app-pdf-protocol-signature>
        </ion-grid>
      </div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!pdfProtocolSignatures.length && showSignatures">
    <ion-col size="12" class="ion-justify-content-center">
      <h3 class="text-secondary ion-text-center">
        {{ 'sendProtocol.protocolConfig.signature.noContactsSelected' | translate }}
      </h3>
    </ion-col>
  </ion-row>
</ion-grid>
