import {
  AttachmentBim,
  BimVersion,
  CustomPdfConfiguration,
  EmailSettings,
  IdType,
  IndividualNextMeetingRequest,
  LicenseType,
  NextMeetingRequest,
  Participant,
  PdfProtocolSetting,
  ProtocolEntryStatus,
  SendPdfProtocolAdditionalInfo,
  SendPdfProtocolOption,
  SyncPerformance,
  SystemEvent,
  TwoFactorDevice,
  TwoFactorDevicePublic,
  TwoFactorDeviceRecovery,
  TwoFactorDeviceTotp,
  UserConnectionInvite,
  UserEmailSignature,
  UserInvite,
  UserRegistration,
  WeatherEnum,
} from './models';
import {GlobalSearchFilter} from './pdf/protocol/pdfProtocol.model';

type ErrorCodeType = ErrorCodeAuthenticationType;
type ErrorCodeAuthenticationType =
  | 'AUTHENTICATION_INVALID_USERNAME_PASSWORD'
  | 'AUTHENTICATION_PASSWORD_CHANGE_REQUIRED'
  | 'AUTHENTICATION_TIMED_BLOCK'
  | 'AUTHENTICATION_TWO_FACTOR_REQUIRED'
  | 'AUTHENTICATION_TWO_FACTOR_INVALID'
  | 'AUTHENTICATION_TWO_FACTOR_NOT_CONFIGURED'
  | 'SYNC_EMPTY_RESULT'
  | 'VERSION_CHANGED'
  | 'DELETE_PROTOCOL_FAILED_PROTOCOL_ENTRY_EXISTS'
  | 'DELETE_PROTOCOL_FAILED_PROTOCOL_IS_CLOSED'
  | 'DELETE_PROTOCOL_FAILED_PROTOCOL_SYSTEM_RESERVED'
  | 'DELETE_REPORT_FAILED_REPORT_IS_CLOSED'
  | 'RESET_PASSWORD_LINK_EXPIRED'
  | 'RESET_PASSWORD_LINK_ALREADY_USED'
  | 'USER_INVITE_USER_EXISTS'
  | 'USER_INVITE_LINK_EXPIRED'
  | 'USER_INVITE_LINK_NOT_FOUND'
  | 'USER_REGISTRATION_LINK_EXPIRED'
  | 'USER_REGISTRATION_LINK_NOT_FOUND'
  | 'USER_LICENSES_EXHAUSTED'
  | 'USER_CONNECTION_ALREADY_CONNECTED'
  | 'USER_CONNECTION_INVITE_EMAIL_CHANGED'
  | 'USER_CONNECTION_INVITE_CLIENT_NAME_EXISTS'
  | 'USER_CONNECTION_INVITE_USER_INVITE_EXISTS'
  | 'MISSING_ATTACHMENTS'
  | 'CREATE_DEMO_ACCOUNT_CLIENT_EXISTS'
  | 'CREATE_DEMO_ACCOUNT_USER_EXISTS'
  | 'NAME_EXISTS'
  | 'COPY_PROJECT_NAME_EXISTS'
  | 'COPY_PROJECT_NUMBER_EXISTS'
  | 'COPY_PROJECT_IN_PROGRESS'
  | 'PARTICIPANT_ADDRESS_DELETED_OR_EMAIL_MISSING'
  | 'INVALID_LOCATION_FOR_WEATHER_API'
  | 'UNPROCESSABLE_IMAGE_FOR_PDF_GENERATION'
  | 'PUBLISH_VERSION_NOT_ALL_MARKERS_MIGRATED'
  | 'UNIT_IMPORT_INVALID_EXCEL'
  | 'UNIT_IMPORT_EXCEL_EXISTING_DATA_MISMATCH'
  | 'CREATE_USER_REGISTRATION_USER_EXISTS';

const errorCodeMessages: {[key in ErrorCodeType]: string} = {
  AUTHENTICATION_INVALID_USERNAME_PASSWORD: 'Username does not exist or password does not match.',
  AUTHENTICATION_PASSWORD_CHANGE_REQUIRED: 'Username and password are correct, but the user needs to change his password.',
  AUTHENTICATION_TIMED_BLOCK: 'User is blocked from authentication for period of time, probably due to too many failed login attempts.',
  AUTHENTICATION_TWO_FACTOR_REQUIRED: 'First factor of authentication is correct, but 2FA code is required',
  AUTHENTICATION_TWO_FACTOR_INVALID: 'First factor of authentication is correct, but 2FA code is not',
  AUTHENTICATION_TWO_FACTOR_NOT_CONFIGURED: 'User attempted to use endpoint, that requires 2FA activated, but 2FA is not active for that user.',
  SYNC_EMPTY_RESULT: 'Synchronization of data failed.',
  VERSION_CHANGED: 'Version has changed for the current write/upload process since the last read.',
  DELETE_PROTOCOL_FAILED_PROTOCOL_ENTRY_EXISTS: 'Unable to delete the protocol because at least one protocol entry exists.',
  DELETE_PROTOCOL_FAILED_PROTOCOL_IS_CLOSED: 'Unable to delete protocol because it is already closed.',
  DELETE_PROTOCOL_FAILED_PROTOCOL_SYSTEM_RESERVED: 'Unable to delete the protocol because it is system reserved.',
  DELETE_REPORT_FAILED_REPORT_IS_CLOSED: 'Unable to delete report because it is already closed.',
  RESET_PASSWORD_LINK_EXPIRED: 'Unable to reset password as the link already expired.',
  RESET_PASSWORD_LINK_ALREADY_USED: 'Unable to reset password as the link was already used.',
  USER_INVITE_USER_EXISTS: 'Unable to send an invitation to that user because a user with that username/email already exists.',
  USER_INVITE_LINK_EXPIRED: 'The invitation link has already expired.',
  USER_INVITE_LINK_NOT_FOUND: 'The invitation link is not valid anymore. It may have been consumed already, a new link has been sent or it has been revoked.',
  USER_REGISTRATION_LINK_EXPIRED: 'The registration link has already expired.',
  USER_REGISTRATION_LINK_NOT_FOUND: 'The registration link is not valid anymore. It may have been consumed already, a new link has been sent or it has been revoked.',
  USER_LICENSES_EXHAUSTED: 'There are not enough user licenses left for the requested operation.',
  USER_CONNECTION_ALREADY_CONNECTED: 'The profile is already connected to a user.',
  USER_CONNECTION_INVITE_EMAIL_CHANGED: 'The invitation was sent to a different email than it is now. The invitation needs to be sent again.',
  USER_CONNECTION_INVITE_CLIENT_NAME_EXISTS: 'The client name (company name) already exists.',
  USER_CONNECTION_INVITE_USER_INVITE_EXISTS: 'There is already a pending userInvite for that email-address, which needs to be accepted first.',
  MISSING_ATTACHMENTS: 'One or more attachments are missing on the server.',
  CREATE_DEMO_ACCOUNT_CLIENT_EXISTS: 'A client with that name (company name) already exists.',
  CREATE_DEMO_ACCOUNT_USER_EXISTS: 'A user with that name (email-address) already exists.',
  CREATE_USER_REGISTRATION_USER_EXISTS: 'A user with that name (email-address) already exists',
  NAME_EXISTS: 'That name already exists.',
  COPY_PROJECT_NAME_EXISTS: 'A project with that name already exists.',
  COPY_PROJECT_NUMBER_EXISTS: 'A project with that number already exists.',
  COPY_PROJECT_IN_PROGRESS: 'Copy project is in progress for that source project',
  PARTICIPANT_ADDRESS_DELETED_OR_EMAIL_MISSING: 'Some participants have address deleted or e-mail address missing.',
  INVALID_LOCATION_FOR_WEATHER_API: 'Unable to find weather data for the location (location).',
  UNPROCESSABLE_IMAGE_FOR_PDF_GENERATION: 'Unable to generate PDF due to the unprocessable image (not JPEG nor PNG)',
  PUBLISH_VERSION_NOT_ALL_MARKERS_MIGRATED: 'Unable to publish new version, as not all markers have been migrated',
  UNIT_IMPORT_INVALID_EXCEL: 'The Excel contains invalid data.',
  UNIT_IMPORT_EXCEL_EXISTING_DATA_MISMATCH: 'There is already data in the database and the Excel file contains mismatching data.',
};

interface ErrorResponse<T extends ErrorCodeType> {
  isError: true;
  errorCode: T;
  errorMessage?: string;
  errorData?: Map<string, any>;
  internalErrorMessage?: string;
}

export interface TwoFactorAuthenticateTotp {
  method: 'totp';
  code: string;
}

export interface TwoFactorAuthenticateRecovery {
  method: 'recovery_code';
  code: string;
}

export type TwoFactorAuthenticate = TwoFactorAuthenticateTotp | TwoFactorAuthenticateRecovery;

interface AuthenticateReq {
  username: string;
  password: string;
  twoFactor?: TwoFactorAuthenticate;
}

export interface AuthenticateRefreshTokenLegacyReq {
  token: string;
}

export interface AuthenticateRefreshTokenJwtReq {
  username: string;
  refreshToken: string;
}

export type AuthenticateRefreshTokenReq = AuthenticateRefreshTokenLegacyReq | AuthenticateRefreshTokenJwtReq;

export interface AuthenticateTokenDetailsRes {
  token: string;
  refreshToken: string;
  tokenExpiresAt: number;
}

export interface AuthenticateLegacyRes {
  userId: string;
  token: string;
  isSuperAdmin?: boolean;
  impersonated?: boolean;
}

interface AuthenticateRes extends AuthenticateTokenDetailsRes {
  userId: string;
  isSuperAdmin?: boolean;
  impersonated?: boolean;
}

interface ImpersonateReq {
  username: string;
}

type AuthenticateErrorRes = ErrorResponse<ErrorCodeAuthenticationType>;

export interface ResetPasswordSendReq {
  username: string;
}

export interface ResetPasswordSendRes {
  resetPasswordLink: string;
}

export interface ResetPasswordConfirmReq {
  resetPasswordLink: string;
  password: string;
}

export interface UserInviteReq {
  userInvite: UserInvite;
}

export interface UserInviteRes {
  userInviteLink: string;
  userInvite: UserInvite;
}

export interface UserInviteConfirmReq {
  userInviteLink: string;
  password: string;
}

export interface UserConnectionInviteReq {
  profileId: IdType;
  language?: string;
  projectId?: IdType;
}

export interface UserConnectionInviteRes {
  userConnectionInviteLink: string;
  userConnectionInvite: UserConnectionInvite;
}

export interface UserConnectionInviteConfirmReq {
  userConnectionInviteLink: string;
  password?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  companyName?: string | null;
  phone?: string | null;
  salutation?: string | null;
}

export interface UserConnectionInviteConfirmRes {
  userCreated: boolean;
}

export interface UserRegistrationConfirmReq {
  userRegistrationLink: string;
  password: string;
}

export type ParticipantForPdfProtocol = Pick<Participant, 'id' | 'mailingList' | 'present'>;

export interface PdfProtocolSendReq {
  sendPdfProtocolOption: SendPdfProtocolOption;
  sendPdfProtocolAdditionalInfo: SendPdfProtocolAdditionalInfo;
  pdfProtocolSetting?: PdfProtocolSetting;
  nextMeeting?: NextMeetingRequest;
  individualNextMeetings?: IndividualNextMeetingRequest[];
  participant?: Participant; // for individual email
  participants?: ParticipantForPdfProtocol[];
  customPdfConfig?: CustomPdfConfiguration;
  emailSettings?: EmailSettings;
  filteredEntryIds?: IdType[];
}

export interface PdfProtocolSendRes {
  success: boolean;
}

export interface PdfGlobalSearchSendReq {
  pdfTitle: string;
  language: string;
  sendPdfProtocolAdditionalInfo: SendPdfProtocolAdditionalInfo;
  pdfProtocolSetting?: PdfProtocolSetting;
  protocolEntryIds: Array<IdType>;
  searchFilter?: GlobalSearchFilter[];
  emailSettings?: EmailSettings;
  pdfBannersFromProjectId?: IdType;
}

export interface ReportGroup {
  reportIds: IdType[];
  year: number;
  month?: number;
  weekNumber?: number;
}

export interface PdfReportSendReq {
  participants?: Array<Participant>;
  customPdfConfig?: CustomPdfConfiguration;
  closeReport?: boolean;
  pdfProtocolSetting?: PdfProtocolSetting;
  emailSettings?: EmailSettings;
  reportGroup?: ReportGroup;
  showTimeBlocks?: boolean;
  appendActivityAttachments?: boolean;
  appendOtherAttachments?: boolean;
  appendMaterialAttachments?: boolean;
  appendEquipmentAttachments?: boolean;
}

export interface PdfReportSendRes {
  success: boolean;
}

export interface PdfGlobalSearchSendRes {
  success: boolean;
}

export interface StatusReportSystemInfo {
  environment: {
    version: string;
    production: boolean;
    serverUrl: string;
    logLevel: string;
  };
  storageDriver: string | null;
  webWorkerSupported: boolean;
  cacheStorageSupported: boolean;
  fileAccessUtilClassName?: string;
  device: {
    model?: string;
    platform: string;
    operatingSystem: string;
    osVersion: string;
    appVersion: string;
    appBuild: string;
    webViewVersion?: string;
    manufacturer?: string;
    userAgent?: string;
    memUsed?: number;
    diskTotal?: number;
    diskFree?: number;
    deviceId?: string;
  };
  platform: {
    width?: number;
    height?: number;
    orientation: 'landscape' | 'portrait';
    platforms: string[];
  };
  user?: {
    id: IdType;
    license: LicenseType;
    reportPlugin: boolean;
  };
  storageQuota?: {
    quota?: number;
    usage?: number;
    fallbackImplementation?: boolean;
    usageDetails: {
      caches?: number;
      indexedDB?: number;
      serviceWorkerRegistrations?: number;
    };
    persistent?: boolean;
  };
}

export interface StatusReportLocalChangeItem<T> {
  changedAt: string;
  value: T;
  oldValue?: T; // This is the previous value, which is not set for inserts value
  originalValue?: T; // This is the value before the first change. Usually the one, from the server
}

export interface StatusReportLocalChangeItems<T> {
  inserted: Array<StatusReportLocalChangeItem<T>>;
  updated: Array<StatusReportLocalChangeItem<T>>;
  deleted: Array<StatusReportLocalChangeItem<T>>;
}

export interface StatusReportLocalChanges {
  nonClientAware?: {[key: string]: StatusReportLocalChangeItems<unknown>};
  clientAware?: {[key: string]: StatusReportLocalChangeItems<unknown>};
  projectAware?: {[key: string]: {[key: string]: StatusReportLocalChangeItems<unknown>}};
  numberAttachmentsInCacheCache?: number;
  numberAttachmentsInUploadQueue?: number;
  numberAttachmentsInErrorQueue?: number;
  attachmentsInUploadQueue?: string[];
  attachmentsInErrorQueue?: string[];
}

export interface StatusReportReq {
  systemInfo: StatusReportSystemInfo;
  localChanges: StatusReportLocalChanges;
  events: Array<SystemEvent>;
}

export interface StatusReportRes {
  statusReportId: number;
}

export interface DemoAccountCreateReq {
  salutation?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  companyName: string;
  language?: string | null;
  source?: string | null;
  additional?: {
    zohoAccount?: {[key: string]: any} | null;
    zohoContact?: {[key: string]: any} | null;
    zohoDeal?: {[key: string]: any} | null;
  } | null;
  createToken?: string;
}

export interface DemoAccountCreateRes {
  clientId: string;
}

export type UserRegistrationCreateReq = Pick<UserRegistration, 'salutation' | 'firstName' | 'lastName' | 'email' | 'phone' | 'companyName' | 'language' | 'source' | 'additional'>;

export interface UserRegistrationCreateRes {
  registrationLink: string;
  userRegistration: UserRegistration;
}

export interface UpdateClientNameReq {
  clientName: string;
}

export interface UpdateUsernameReq {
  username: string;
}

export interface CopyProjectRes {
  projectId: IdType;
  changedAt: string;
}

export interface ImportPlanRadarFieldMappings {
  type: string[];
  craft: string[];
  description: string[];
  costs: string[];
  location?: string[];
  startDate?: string[];
  chat?: string[];
  nameableDropdownId?: string[];
}

export interface ImportPlanRadarValueMappings {
  status?: Record<string, ProtocolEntryStatus>;
}

export type ImportPlanRadarEntryProcessorType = 'Tecklenburg';

export interface ImportPlanRadarReq {
  apiToken: string;
  customerId: string;
  projectId?: string; // default all
  attachments?: boolean; // default true
  loadAttachmentsWithTickets?: boolean;
  plans?: boolean; // default true
  protocolTypeName?: string;
  protocolEntryTypeName?: string;
  fieldMappings?: Partial<ImportPlanRadarFieldMappings>;
  valueMappings?: ImportPlanRadarValueMappings;
  entryProcessor?: ImportPlanRadarEntryProcessorType;
  ignoreErrors?: {
    plans?: boolean; // default false
  };
}

export interface ImportPlanRadarRes {
  importedProjectIds: IdType[];
}

export const ENTRY_MAIL_REQ_DEFAULT: Partial<EntryMailReq> = {
  includePdfPlanMarkers: true,
  includeBimMarkers: true,
  includeZipFileLink: true,
};

export interface EntryMailReq {
  entryIds: Array<IdType>;
  recipientProfileIds: Array<IdType>;
  language?: string;
  subject?: string;
  text?: string;
  emailSignature?: UserEmailSignature;
  mailTextUnderContent?: string;
  ignoreMissingAttachments?: boolean;
  includePdfPlanMarkers?: boolean;
  includeBimMarkers?: boolean;
  includeZipFileLink?: boolean;
}

export interface WeatherForLocationReq {
  startTime: string;
  endTime: string;
  location: string;
}

export interface WeatherForLocationRes {
  weather: WeatherEnum | null;
  humidity: number;
  windspeed: number;
  maxTemp: number;
  minTemp: number;
  address: string;
}

export interface TwoFactorDeviceSummaryActivatedOrInDeactivation {
  twoFactorStatus: 'activated' | 'in_deactivation';
  device: TwoFactorDevicePublic;
  hasRecoveryCodesGenerated: boolean;
  hasPendingTwoFactorDeviceReplacement: boolean;
}

export interface TwoFactorDeviceSummaryConfigured {
  twoFactorStatus: 'configured';
  device: TwoFactorDevicePublic;
}

export interface TwoFactorDeviceSummaryInitialized {
  twoFactorStatus: 'initialized';
  device: TwoFactorDevicePublic;
}

export interface TwoFactorDeviceSummaryNotConfigured {
  twoFactorStatus: 'not-configured';
}

export type TwoFactorDeviceSummaryRes = TwoFactorDeviceSummaryActivatedOrInDeactivation | TwoFactorDeviceSummaryConfigured | TwoFactorDeviceSummaryInitialized | TwoFactorDeviceSummaryNotConfigured;

export interface TwoFactorDeviceConfigureInitializedStateTotpRes {
  device: TwoFactorDevicePublic;
  totp: TwoFactorDeviceTotp;
  username: string;
  step: 'initialized';
}

export interface TwoFactorDeviceConfigureConfiguredStateTotpRes {
  device: TwoFactorDevicePublic;
  step: 'configured';
}

export type TwoFactorDeviceConfigureStateTotpRes = TwoFactorDeviceConfigureInitializedStateTotpRes | TwoFactorDeviceConfigureConfiguredStateTotpRes;

export interface TwoFactorDeviceInitTotpRes {
  device: TwoFactorDevicePublic;
  totp: TwoFactorDeviceTotp;
  username: string;
}

export interface TwoFactorDeviceConfigureTotpReq {
  code: string;
}

export interface TwoFactorDeviceConfigureTotpRes {
  device: TwoFactorDevicePublic;
}

export interface TwoFactorDeviceConcludeReq {
  activationCode: string;
}

export interface TwoFactorDeviceConcludeRes {
  device: TwoFactorDevicePublic;
  recoveryDevice: TwoFactorDevice;
  recoveryCodes: TwoFactorDeviceRecovery;
  newToken: AuthenticateRes;
}

export interface TwoFactorDeviceRecoveryRes {
  recovery: TwoFactorDeviceRecovery;
}

export interface TwoFactorDeviceConcludeDeactivationReq {
  deactivationCode: string;
}

export interface UsersWithTwoFactorRes {
  userIds: IdType[];
}

export interface AutodeskAuthToken {
  access_token: string;
  expires_in: number;
  expires_at: number;
  token_type: string;
  refresh_token?: string | undefined;
}

export interface AutodeskModel {
  name: string;
  urn: string;
}

export interface UploadBimVersionResponse {
  bimVersion: BimVersion;
  attachmentBim: AttachmentBim;
}

export interface PublishBimVersionRequest {
  ignorePending: boolean;
}

export interface SyncPerformancesRequest {
  syncPerformances: Omit<SyncPerformance, 'createdById' | 'deviceUuid'>[];
}

export interface MoveAttachmentsProjectReq {
  sourceIds: Array<IdType>;
  targetProjectId: IdType;
}

export {ErrorResponse, ErrorCodeType, errorCodeMessages};
export {AuthenticateReq, AuthenticateRes, AuthenticateErrorRes, ErrorCodeAuthenticationType, ImpersonateReq};
