import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnDestroy, QueryList, SimpleChanges, ViewChildren} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {MbscCalendarOptions, MbscDatepicker, MbscModule} from '@mobiscroll/angular-ivy';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {DashboardFilterMultiButtonItem} from 'src/app/model/dashboard-filter';
import {ProtocolEntrySearchFilter} from 'src/app/model/protocol-entry-search-filter';
import {ActivableWithNameIdAware} from 'src/app/services/entry/abstract-entry-filter-by.service';
import {EMPTY_PROTOCOL_ENTRY_SEARCH_FILTER} from 'src/app/services/search/protocol-entry-search-filter.service';
import {SelectableInputModule} from 'src/app/shared/module/selectable-input/selectable-input.module';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {compareObjectsWithPrimitiveValues} from 'src/app/utils/compare-utils';
import {convertToGanttEndDate, convertToGanttStartDate} from 'src/app/utils/date-utils';
import {Company, Craft, NameableDropdown, ProtocolEntryIconStatus, ProtocolEntryLocation, ProtocolEntryPriorityType, ProtocolEntryType, UnitForBreadcrumbs} from 'submodules/baumaster-v2-common';
import {EntryFilterMultiButtonsComponent} from '../entry-filter-multi-buttons/entry-filter-multi-buttons.component';
import {EntryFilterSelectableComponent} from '../entry-filter-selectable/entry-filter-selectable.component';
import {MobiscrollService} from '../../../services/common/mobiscroll.service';
import {allCompaniesFromCompanyIdToAllCompaniesFilter, EMPTY_FILTER_ID, passAllCompaniesToCompanyIdFilter, PROJECT_TEAM_PSEUDO_ID} from 'src/app/utils/filter-utils';
import {UnitDataService} from 'src/app/services/data/unit-data.service';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {Nullish} from 'src/app/model/nullish';
import {SelectorUnitLevel} from 'src/app/model/selector-unit';

const mapFilter = (fb: UntypedFormBuilder, filter) =>
  _.mapValues(filter, (obj) => {
    if (Array.isArray(obj)) {
      return fb.control(obj);
    }
    if (obj === null) {
      return fb.control(obj);
    }
    if (obj instanceof Date) {
      return fb.control(obj);
    }
    if (typeof obj === 'object') {
      return fb.group(mapFilter(fb, obj));
    }
    return fb.control(obj);
  });

type CompanyWithProjectTeamAndDeleted = Company & {
  isDeletedOrNotInProject?: boolean;
  isProjectTeam?: boolean;
};

@Component({
  selector: 'app-entry-filter-form',
  templateUrl: './entry-filter-form.component.html',
  styleUrls: ['./entry-filter-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiModule,
    IonicModule,
    SelectableInputModule,
    TranslateModule,
    FormsModule,
    MbscModule,
    EntryFilterSelectableComponent,
    EntryFilterMultiButtonsComponent,
    FontAwesomeModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntryFilterFormComponent),
      multi: true,
    },
  ],
})
export class EntryFilterFormComponent implements ControlValueAccessor, OnDestroy, OnChanges {
  protected readonly PROJECT_TEAM_PSEUDO_ID = PROJECT_TEAM_PSEUDO_ID;
  protected readonly EMPTY_FILTER_ID = EMPTY_FILTER_ID;

  @Input()
  showRecentlyUsedInCurrentProject: boolean;
  @Input()
  nameableDropdownName: string;
  @Input()
  companies: CompanyWithProjectTeamAndDeleted[];
  @Input()
  crafts: Craft[];
  @Input()
  profiles: ActivableWithNameIdAware[];
  @Input()
  entryTypes: ProtocolEntryType[];
  @Input()
  customFields: NameableDropdown[];
  @Input()
  locations: ProtocolEntryLocation[];
  @Input()
  statuses: DashboardFilterMultiButtonItem<ProtocolEntryIconStatus>[];
  @Input()
  priorities: DashboardFilterMultiButtonItem<ProtocolEntryPriorityType>[];
  @Input()
  units: UnitForBreadcrumbs[];
  @Input()
  unitLevels: SelectorUnitLevel[];

  protected companiesWithProjectTeam: CompanyWithProjectTeamAndDeleted[] = [];
  protected shouldIncludeSubUnits = true;
  protected selectedUnit: UnitForBreadcrumbs | undefined;

  datePickerSettings: Observable<MbscCalendarOptions> = this.translateService.get(['cancel', 'done']).pipe(
    distinctUntilChanged(compareObjectsWithPrimitiveValues),
    map(({cancel: cancelLabel, done: doneLabel}) => ({
      returnFormat: 'jsdate' as const,
      dateFormat: 'YYYY-MM-DD',
      buttons: [
        {text: cancelLabel, handler: 'cancel'},
        {text: doneLabel, handler: 'set'},
      ],
    }))
  );
  @ViewChildren('dateStartPicker') dateStartPickers: QueryList<MbscDatepicker>;

  readonly ProtocolEntryIconStatus = ProtocolEntryIconStatus;
  private readonly destroy$ = new Subject<void>();
  value?: ProtocolEntrySearchFilter;
  emptyDateFilter = false;

  form: UntypedFormGroup;

  private propagateOnChange = (__: any) => {};
  private propagateOnTouched = () => {};

  public mbscThemeVariant$ = this.mobiscrollService.themeVariant$;
  public mbscLocale$ = this.mobiscrollService.locale$;
  public MBSC_DATE_FORMAT = this.mobiscrollService.DATE_FORMAT;

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private mobiscrollService: MobiscrollService,
    private unitDataService: UnitDataService
  ) {
    this.form = this.fb.group(mapFilter(this.fb, _.cloneDeep(EMPTY_PROTOCOL_ENTRY_SEARCH_FILTER)));
    this.form.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(_.isEqual)).subscribe((newValue: ProtocolEntrySearchFilter) => {
      this.value = allCompaniesFromCompanyIdToAllCompaniesFilter(newValue);
      this.propagateOnChange(this.value);
      this.propagateOnTouched();
      if (newValue.entry.unitId?.in?.length < 1) {
        this.selectedUnit = undefined;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.companies) {
      this.companiesWithProjectTeam = this.getCompaniesWithProjectTeam();
    }
    if (changes.units) {
      if (!this.selectedUnit && this.value && this.value.entry.unitId?.in?.length > 0) {
        this.selectedUnit = this.units.find((unit) => unit.id === this.value.entry.unitId?.in?.slice(-1).pop());
      }
    }
  }

  private getCompaniesWithProjectTeam(): CompanyWithProjectTeamAndDeleted[] {
    const projectTeam = {id: PROJECT_TEAM_PSEUDO_ID, name: this.translateService.instant('project_team'), isProjectTeam: true} as CompanyWithProjectTeamAndDeleted;
    return this.companies ? [projectTeam, ...this.companies] : [projectTeam];
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onEmptyDateFilterChange() {
    this.emptyDateFilter = !this.emptyDateFilter;
    if (this.emptyDateFilter) {
      this.form.setValue({
        ...this.value,
        entry: {
          ...this.value.entry,
          todoUntil: {
            ...this.value.entry.todoUntil,
            gte: EMPTY_FILTER_ID,
            lte: EMPTY_FILTER_ID,
          },
        },
      });
    } else {
      this.form.setValue({
        ...this.value,
        entry: {
          ...this.value.entry,
          todoUntil: {
            ...this.value.entry.todoUntil,
            gte: null,
            lte: null,
          },
        },
      });
    }
  }

  writeValue(value: any): void {
    if (!value) {
      value = _.cloneDeep(EMPTY_PROTOCOL_ENTRY_SEARCH_FILTER);
    }
    if (_.isEqual(this.value, value)) {
      return;
    }

    this.value = value;
    this.form.setValue(passAllCompaniesToCompanyIdFilter(value));
  }
  registerOnChange(fn: any): void {
    this.propagateOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  updateDateValue(value: string | Date | null, mode: 'start' | 'end') {
    return (mode === 'start' ? convertToGanttStartDate : convertToGanttEndDate)(value) ?? null;
  }

  resetDatePickerValueByNameFn(formFieldName: string): (event: any) => void {
    return (event: any) => {
      this.form.get(formFieldName).setValue(null);
      this.closeAllDatePickers();
    };
  }

  private closeAllDatePickers() {
    if (!this.dateStartPickers?.length) {
      return;
    }
    this.dateStartPickers.forEach((datePicker) => datePicker.close());
  }

  public readonly resetDatePickerFunctions = {
    'entry.todoUntil.gte': this.resetDatePickerValueByNameFn('entry.todoUntil.gte'),
    'entry.todoUntil.lte': this.resetDatePickerValueByNameFn('entry.todoUntil.lte'),
    'entry.createdAt.gte': this.resetDatePickerValueByNameFn('entry.createdAt.gte'),
    'entry.createdAt.lte': this.resetDatePickerValueByNameFn('entry.createdAt.lte'),
  };

  async unitFilterChanged(unit: Nullish<UnitForBreadcrumbs>) {
    if (!unit) {
      this.form.get('entry.unitId.in').setValue([]);
      return;
    }
    if (this.shouldIncludeSubUnits) {
      const allSubunits = await observableToPromise(this.unitDataService.getAllSubUnits(unit.id));
      const unitIds = allSubunits.map((unit) => unit.id);
      unitIds.push(unit.id);
      this.form.get('entry.unitId.in').setValue(unitIds);
    } else {
      this.form.get('entry.unitId.in').setValue([unit.id]);
    }
  }

  async shouldIncludeSubUnitsChanged() {
    await this.unitFilterChanged(this.selectedUnit);
  }
}
