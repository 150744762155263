import {IndividualNextMeetingColumnSet} from './IndividualNextMeetingColumnSet';
import {ColumnConfig} from '../ColumnConfig';

export const NextMeetingColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'date', prop: 'date', cast: 'Date'},
  {name: 'time_start', prop: 'timeStart', cast: 'Time'},
  {name: 'time_end', prop: 'timeEnd', cast: 'Time'},
  {name: 'subject', prop: 'subject', def: null},
  {name: 'body', prop: 'body', def: null},
  {name: 'group_meeting', prop: 'groupMeeting'},
  {name: 'protocol_id', prop: 'protocolId'},
  {name: 'pdfpreview_id', prop: 'pdfpreviewId', def: null},
  {name: 'created_by_id', prop: 'createdById'},
  {name: 'send_calendar_mail', prop: 'sendCalendarMail'},
  {name: 'individualNextMeetings', fk: 'nextMeetingId', mappedBy: IndividualNextMeetingColumnSet, transient: true},
];
