import {Injectable} from '@angular/core';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {ProjectDataService} from './project-data.service';
import {StorageKeyEnum} from 'src/app/shared/constants';
import {IdType, LicenseType, PdfReportSetting, User} from 'submodules/baumaster-v2-common';
import {map, Observable} from 'rxjs';

const REST_ENDPOINT_URI = 'api/data/pdfReportSettings';

@Injectable({
  providedIn: 'root',
})
export class PdfReportSettingDataService extends AbstractProjectAwareDataService<PdfReportSetting> {
  constructor(
    http: HttpClient,
    storage: StorageService,
    authenticationService: AuthenticationService,
    userService: UserService,
    protected projectDataService: ProjectDataService,
    protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
    loggingService: LoggingService,
    integrityResolverService: IntegrityResolverService
  ) {
    super(
      StorageKeyEnum.PDF_REPORT_SETTING,
      REST_ENDPOINT_URI,
      [],
      http,
      storage,
      authenticationService,
      userService,
      projectDataService,
      loggingService,
      projectAvailabilityExpirationService,
      integrityResolverService,
      '2.31.0'
    );
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return currentUser.role === LicenseType.BASIC || currentUser.role === LicenseType.PROFESSIONAL || currentUser.assignedReportRights;
  }

  getByReportTypeId(reportTypeId: IdType): Observable<PdfReportSetting> {
    return this.data.pipe(
      map((settings) => {
        return settings.find((setting) => setting.reportTypeId === reportTypeId);
      })
    );
  }
}
