import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {SystemEvent, SystemEventCategoryEnum} from 'submodules/baumaster-v2-common';
import {convertErrorToMessage} from '../../shared/errors';

export enum LogLevel {
  'NONE',
  'DEBUG',
  'INFO',
  'WARN',
  'ERROR',
}

/** Maximum allowed elements in systemEventQueue. This is to avoid out of memory error in case the elements are never read */
const SYSTEM_EVENTS_MAX_LIMIT = 1000;
type MessageOrFunction = string | (() => string);

export interface LogOptions {
  /** If set to true, eventLogs are logged as priority one level higher. E.g. logInfo is called but isInfoEnabled=false but isWarnEnabled=true, console is not logged but systemEvent. */
  isEventLogHigherPriority: boolean;
  logEvenWheNotAuthenticated: boolean;
}

const DEFAULT_LOG_OPTIONS: LogOptions = {
  isEventLogHigherPriority: false,
  logEvenWheNotAuthenticated: false,
};

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private readonly logLevel: LogLevel;
  private systemEventQueue = new Array<SystemEvent>();

  constructor() {
    const envLogLevel = LogLevel[environment.logLevel];
    if (envLogLevel === undefined) {
      throw new Error(`Unable to convert environment logLevel "${environment.logLevel}" to enum LogLevel. Looks like an invalid value.`);
    }
    this.logLevel = envLogLevel;
  }

  public isDebugEnabled(): boolean {
    return this.logLevel <= LogLevel.DEBUG;
  }

  public isInfoEnabled(): boolean {
    return this.logLevel <= LogLevel.INFO;
  }

  public isWarningEnabled(): boolean {
    return this.logLevel <= LogLevel.WARN;
  }

  public isErrorEnabled(): boolean {
    return this.logLevel <= LogLevel.ERROR;
  }

  public debug(loggingSource: string, message: string, ...optionalParams: any[]) {
    if (this.isDebugEnabled()) {
      // eslint-disable-next-line no-console
      console.debug(`[${loggingSource}] - ${message}`, optionalParams);
    }
  }

  public info(loggingSource: string, message: string, ...optionalParams: any[]) {
    if (this.isInfoEnabled()) {
      // eslint-disable-next-line no-console
      console.info(`[${loggingSource}] - ${message}`, optionalParams);
    }
  }

  public warn(loggingSource: string, message: string, ...optionalParams: any[]) {
    if (this.isWarningEnabled()) {
      // eslint-disable-next-line no-console
      console.warn(`[${loggingSource}] - ${message}`, optionalParams);
    }
  }

  public error(loggingSource: string, message: string, ...optionalParams: any[]) {
    if (this.isErrorEnabled()) {
      // eslint-disable-next-line no-console
      console.error(`[${loggingSource}] - ${message}`, optionalParams);
    }
  }

  private getLogOptions(optionalPartialOptions?: Partial<LogOptions>): LogOptions {
    if (!optionalPartialOptions) {
      return DEFAULT_LOG_OPTIONS;
    }
    return {...DEFAULT_LOG_OPTIONS, ...optionalPartialOptions};
  }

  public debugWithEvent(loggingSource: string, method: string, messageOrFunction: MessageOrFunction, optionalPartialOptions?: Partial<LogOptions>) {
    const options = this.getLogOptions(optionalPartialOptions);
    if (this.isDebugEnabled()) {
      // eslint-disable-next-line no-console
      console.debug(`[${loggingSource}] - ${method} - ${this.messageOrFunctionToMessage(messageOrFunction)}`);
    }
    if (this.isDebugEnabled() || (options.isEventLogHigherPriority && this.isInfoEnabled())) {
      this.addSystemEvent(loggingSource, method, this.messageOrFunctionToMessage(messageOrFunction), options);
    }
  }

  public infoWithEvent(loggingSource: string, method: string, messageOrFunction: MessageOrFunction, optionalPartialOptions?: Partial<LogOptions>) {
    const options = this.getLogOptions(optionalPartialOptions);
    if (this.isInfoEnabled()) {
      // eslint-disable-next-line no-console
      console.info(`[${loggingSource}] - ${method} - ${this.messageOrFunctionToMessage(messageOrFunction)}`);
    }
    if (this.isInfoEnabled() || (options.isEventLogHigherPriority && this.isWarningEnabled())) {
      this.addSystemEvent(loggingSource, method, this.messageOrFunctionToMessage(messageOrFunction), options);
    }
  }

  public warnWithEvent(loggingSource: string, method: string, messageOrFunction: MessageOrFunction, optionalPartialOptions?: Partial<LogOptions>) {
    const options = this.getLogOptions(optionalPartialOptions);
    if (this.isWarningEnabled()) {
      // eslint-disable-next-line no-console
      console.warn(`[${loggingSource}] - ${method} - ${this.messageOrFunctionToMessage(messageOrFunction)}`);
    }
    if (this.isWarningEnabled() || (options.isEventLogHigherPriority && this.isErrorEnabled())) {
      this.addSystemEvent(loggingSource, method, this.messageOrFunctionToMessage(messageOrFunction), options);
    }
  }

  public errorObjectWithEvent(loggingSource: string, method: string, error: any, optionalPartialOptions?: Partial<LogOptions>) {
    if (this.isErrorEnabled()) {
      this.errorWithEvent(loggingSource, method, convertErrorToMessage(error), optionalPartialOptions);
    }
  }

  public errorWithEvent(loggingSource: string, method: string, messageOrFunction: MessageOrFunction, optionalPartialOptions?: Partial<LogOptions>) {
    const options = this.getLogOptions(optionalPartialOptions);
    if (this.isErrorEnabled()) {
      // eslint-disable-next-line no-console
      console.error(`[${loggingSource}] - ${method} - ${this.messageOrFunctionToMessage(messageOrFunction)}`);
      this.addSystemEvent(loggingSource, method, this.messageOrFunctionToMessage(messageOrFunction), options, SystemEventCategoryEnum.ERROR);
    }
  }

  private addSystemEvent(loggingSource: string, method: string, message: string, options: LogOptions, category: SystemEventCategoryEnum = SystemEventCategoryEnum.EVENT): SystemEvent {
    const now = new Date();
    const systemEvent: SystemEvent = {
      category,
      page: loggingSource,
      source: method,
      message,
      timestamp: now.getTime(),
      timestampIsoDate: now.toISOString(),
    };
    if (options.logEvenWheNotAuthenticated) {
      systemEvent.logEvenWhenNotAuthenticated = true;
    }
    this.systemEventQueue.push(systemEvent);

    if (this.systemEventQueue.length > SYSTEM_EVENTS_MAX_LIMIT) {
      this.systemEventQueue.splice(0, SYSTEM_EVENTS_MAX_LIMIT - this.systemEventQueue.length - 1);
    }

    return systemEvent;
  }

  public popAllSystemEvents(): SystemEvent[] {
    return this.systemEventQueue.splice(0);
  }

  private messageOrFunctionToMessage(messageOrFunction: MessageOrFunction): string {
    if (typeof messageOrFunction === 'string') {
      return messageOrFunction;
    }
    if (typeof messageOrFunction === 'function') {
      try {
        return messageOrFunction();
      } catch (error) {
        return 'Error in function that generates log message. ' + convertErrorToMessage(error);
      }
    }
    return 'Unsupported argument messageOrFunction. ' + messageOrFunction;
  }
}
