<form class="omg-form" [formGroup]="form" action="#">
  <ion-grid class="ion-no-padding omg-form" *ngIf="workflowType === WorkflowTypeEnum.Protocol">
    <ion-accordion-group [multiple]="true" [value]="['individual-protocol', 'next-meetings', 'signatures']">
      <ion-accordion toggleIconSlot="start" [value]="'individual-protocol'">
        <ion-item slot="header" lines="none">
          <ion-label>{{ 'sendProtocol.protocolsPerRecipientSection.title' | translate }}</ion-label>
        </ion-item>
        <ion-row slot="content" class="hide-overflow-x">
          <ion-col size="12" class="ml-2">
            <ion-item class="omg-form-checkbox" lines="none">
              <ion-checkbox class="auto-width pr-2" label-placement="end" justify="start" formControlName="individualProtocol" (ionChange)="individualProtocolChanged($event)">
                {{ "sendProtocol.addIndividualProtocol" | translate }}
              </ion-checkbox>
              <fa-icon
                class="grey-text"
                [icon]="['fal', 'info-circle']"
                appInfoPopoverClick
                [infoTitle]="'sendProtocol.protocolsPerRecipientSection.infoTitle'"
                [infoText]="'sendProtocol.protocolsPerRecipientSection.infoText'"
                [infoHintText]="'sendProtocol.protocolsPerRecipientSection.infoHintText'"
              ></fa-icon>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-accordion>
      <ion-accordion *ngIf="!showOnlyTemplateOptions" toggleIconSlot="start" class="mt-3" [value]="'next-meetings'">
        <ion-item slot="header" lines="none">
          <ion-label>{{ 'sendProtocol.nextMeetingsSection.title' | translate }}</ion-label>
        </ion-item>
        <ion-row slot="content" class="hide-overflow-x">
          <ion-col class="ml-2 mr-2 mb-2">
            <app-next-meeting
              [participants]="participants"
              [nextMeeting]="nextMeeting"
              (nextMeetingChange)="nextMeetingChange.emit($event)"
              [individualNextMeetings]="individualNextMeetings"
              (individualNextMeetingsChange)="individualNextMeetingsChange.emit($event)">
            </app-next-meeting>
          </ion-col>
        </ion-row>
      </ion-accordion>
      <ion-accordion *ngIf="!showOnlyTemplateOptions && protocol && !protocol.closedAt && pdfProtocolSignatures" toggleIconSlot="start" class="mt-3" [value]="'signatures'">
        <ion-item slot="header" lines="none">
          <ion-label>{{ 'sendProtocol.signaturesSection.title' | translate }}</ion-label>
        </ion-item>
        <ion-row slot="content">
          <ion-col class="ml-2 mr-2 mb-2">
            <app-pdf-protocol-signatures
              [showSignatures]="showSignatures"
              [mailingListUnitContactProfileIds]="mailingListUnitContactProfileIds"
              (showSignaturesChange)="showSignaturesChange.emit($event)"
              [pdfProtocolSignatures]="pdfProtocolSignatures"
              (pdfProtocolSignaturesChange)="pdfProtocolSignaturesChange.emit($event)">
            </app-pdf-protocol-signatures>
          </ion-col>
        </ion-row>
      </ion-accordion>
    </ion-accordion-group>
  </ion-grid>
</form>
<ion-grid class="ion-no-padding omg-form">
  <ion-accordion-group *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report" [multiple]="true" [value]="['general', 'layout']" class="mt-2">
    <ion-accordion toggleIconSlot="start" [value]="'general'">
      <ion-item slot="header" lines="none">
        <ion-label>{{ "sendProtocol.protocolConfig.sections.content_general" | translate }}</ion-label>
      </ion-item>
      <ion-row slot="content" class="hide-overflow-x">
        <ion-col size="12" class="omg-form-checkbox-grid row-gap-3 ml-2">
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report && !report.closedAt">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="showReportSignatures"
                (ionChange)="showReportSignaturesChange.emit($event.detail.checked)"
                [disabled]="!(isSignatureEnabled$ | async)"
            >
              <div>{{ "sendProtocol.advancedSettings.sign" | translate }}</div>
              <div class="signatureNoPermission" *ngIf="!(isSignatureEnabled$ | async)">
                {{ "sendProtocol.protocolConfig.signature.noPermission" | translate }}
              </div>
            </ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report && (isUsingTimeBlocks$ | async)">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="showStaffTimeBlocks"
                (ionChange)="showStaffTimeBlocksChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.showTimeBlocks" | translate }}</ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-accordion>
    <ion-accordion toggleIconSlot="start" [value]="'layout'" class="mt-3">
      <ion-item slot="header" lines="none">
        <ion-label>{{ 'sendProtocol.advancedSettings.layout' | translate }}</ion-label>
      </ion-item>
      <ion-row slot="content" class="hide-overflow-x">
        <ion-col size="12" class="omg-form-checkbox-grid row-gap-3 ml-2">
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="appendActivityAttachments"
                (ionChange)="appendActivityAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.activity" | translate }}</ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="appendOtherAttachments"
                (ionChange)="appendOtherAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.other" | translate }}</ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
            <ion-checkbox 
              label-placement="end" justify="start"
              [(ngModel)]="appendMaterialAttachments"
              (ionChange)="appendMaterialAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.material" | translate }}</ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report && report">
            <ion-checkbox
              label-placement="end" justify="start"
              [(ngModel)]="appendEquipmentAttachments"
              (ionChange)="appendEquipmentAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.equipment" | translate }}</ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-accordion>
  </ion-accordion-group>
  <ion-accordion-group *ngIf="showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Report" [multiple]="true" [value]="['general', 'layout']" class="mt-2">
    <ion-accordion *ngIf="reportTypeName && reportTypeName !== reportTypeCode.REPORT_TYPE_DIRECTED_REPORT" toggleIconSlot="start" [value]="'general'">
      <ion-item slot="header" lines="none">
        <ion-label>{{ "sendProtocol.protocolConfig.sections.content_general" | translate }}</ion-label>
      </ion-item>
      <ion-row slot="content" class="hide-overflow-x">
        <ion-col size="12" class="omg-form-checkbox-grid row-gap-3 ml-2">
          <ion-item class="omg-form-checkbox" lines="none">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="showStaffTimeBlocks"
                (ionChange)="showStaffTimeBlocksChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.showTimeBlocks" | translate }}</ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-accordion>
    <ion-accordion toggleIconSlot="start" [value]="'layout'" class="mt-3">
      <ion-item slot="header" lines="none">
        <ion-label>{{ 'sendProtocol.advancedSettings.layout' | translate }}</ion-label>
      </ion-item>
      <ion-row slot="content" class="hide-overflow-x">
        <ion-col size="12" class="omg-form-checkbox-grid row-gap-3 ml-2">
          <ion-item class="omg-form-checkbox" lines="none">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="appendActivityAttachments"
                (ionChange)="appendActivityAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.activity" | translate }}</ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none">
            <ion-checkbox
                label-placement="end" justify="start"
                [(ngModel)]="appendOtherAttachments"
                (ionChange)="appendOtherAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.other" | translate }}</ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none">
            <ion-checkbox 
              label-placement="end" justify="start"
              [(ngModel)]="appendMaterialAttachments"
              (ionChange)="appendMaterialAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.material" | translate }}</ion-checkbox>
          </ion-item>
          <ion-item class="omg-form-checkbox" lines="none">
            <ion-checkbox
              label-placement="end" justify="start"
              [(ngModel)]="appendEquipmentAttachments"
              (ionChange)="appendEquipmentAttachmentsChange.emit($event.detail.checked)"
            >{{ "sendProtocol.advancedSettings.equipment" | translate }}</ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-accordion>
  </ion-accordion-group>
</ion-grid>
