import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AlertController, NavParams} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ProjectRoomAttachmentsSelectionService} from 'src/app/services/project-room/project-room-attachments-selection.service';
import {ProjectRoomAttachmentsService} from 'src/app/services/project-room/project-room-attachments.service';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {IdType} from 'submodules/baumaster-v2-common';
import {LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY} from '../../../shared/constants';

@Component({
  selector: 'app-project-room-attachments-selector',
  templateUrl: './project-room-attachments-selector.component.html',
  styleUrls: ['./project-room-attachments-selector.component.scss'],
  providers: [ProjectRoomAttachmentsService, ProjectRoomAttachmentsSelectionService],
})
export class ProjectRoomAttachmentsSelectorComponent implements OnInit {
  private modal: HTMLIonModalElement;
  public viewportWidth: number | undefined;
  public maxSelectionLimit: number;
  public projectId: IdType | null = null;
  protected readonly selectedAttachments$ = this.projectRoomAttachmentsSelectionService.selected$;

  @ViewChild('attachmentContainer', {static: false}) attachmentContainer: ElementRef<HTMLDivElement>;

  constructor(
    private navParams: NavParams,
    private alertController: AlertController,
    private translateService: TranslateService,
    private projectRoomAttachmentsSelectionService: ProjectRoomAttachmentsSelectionService,
    private projectRoomAttachmentsService: ProjectRoomAttachmentsService
  ) {}

  async ngOnInit() {
    this.maxSelectionLimit = this.navParams.data.maxSelectionLimit || LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY;
    this.setCanDismiss();
    this.projectRoomAttachmentsService.projectId = this.projectId ?? undefined;
  }

  async ionViewDidEnter() {
    this.viewportWidth = this.attachmentContainer?.nativeElement?.clientWidth;
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'select') {
      return true;
    }

    const selectedAttachments = await observableToPromise(this.selectedAttachments$);

    if (!selectedAttachments.length) {
      return true;
    }

    const alert = await this.alertController.create({
      message: this.translateService.instant('modal.cancel.confirm'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'no',
          cssClass: 'secondary',
        },
        {
          text: this.translateService.instant('yes'),
          role: 'yes',
        },
      ],
    });
    await alert.present();

    return (await alert.onWillDismiss()).role === 'yes';
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  public async select() {
    const selectedAttachments = await observableToPromise(this.projectRoomAttachmentsSelectionService.selected$);
    await this.modal.dismiss(
      {
        attachments: selectedAttachments,
      },
      'select'
    );
  }

  public cancel() {
    return this.modal.dismiss();
  }
}
