<ion-row [class.even]="even" [class.odd]="!even" class="ion-align-items-center">
  <ion-col size-lg="4" size-xs="12" *ngIf="profileCompanyAddress?.company?.name">
    <ion-item lines="none">
      <strong class="text-wrap-one-line" *ngIf="profileCompanyAddress?.profile?.type === unitContactProfileType">{{'unitContacts.unitContacts' | translate}}</strong>
      <strong class="text-wrap-one-line" *ngIf="profileCompanyAddress?.profile?.type !== unitContactProfileType">{{profileCompanyAddress?.company?.name}}</strong>
      <ion-button slot="end" class="omg-btn-text ion-hide-lg-up" (click)="deleteSignature()" [disabled]="readonly">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-item>
  </ion-col>
  <ion-col size-lg="4" size-xs="12" *ngIf="profileCompanyAddress?.address">
    <span class="text-wrap-one-line">{{profileCompanyAddress?.address | userNameString}}</span>
  </ion-col>
  <ion-col size="4" class="ion-hide-lg-down" *ngIf="!profileCompanyAddress?.address"></ion-col>
  <ion-col size-lg="4" size-xs="12" *ngIf="!profileCompanyAddress?.address">
    <ion-item lines="none">
      <span class="text-wrap-one-line">{{pdfProtocolSignature.name}}</span>
      <ion-button slot="end" class="omg-btn-text ion-hide-lg-up" (click)="deleteSignature()" [disabled]="readonly">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-item>
  </ion-col>
  <ion-col size-lg="4" size-xs="12" *ngIf="!pdfProtocolSignature.signature">
    <ion-item lines="none" class="item-small ion-no-margin ion-no-padding">
      <ion-button class="omg-btn-secondary" (click)="sign()" [disabled]="readonly">+ {{'pdfReport.signature.signature' | translate}}</ion-button>
      <ion-buttons slot="end">
        <ion-button class="omg-btn-text ion-hide-lg-down" (click)="deleteSignature()" [disabled]="readonly || pdfProtocolSignature.signature">
          <fa-icon [icon]="['fal', 'times']"></fa-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
  </ion-col>
  <ion-col size-lg="4" size-xs="12" *ngIf="pdfProtocolSignature.signature">
    <ion-item lines="none" class="item-small">
      <app-attachment-viewer *ngIf="!showCheckInsteadOfImage" size="signature" [attachment]="pdfProtocolSignature.signature" class="ion-no-padding signature"></app-attachment-viewer>
      <fa-icon *ngIf="showCheckInsteadOfImage" [icon]="['fas', 'check']" class="checkSigned"></fa-icon>
      <ion-buttons slot="end">
        <ion-button class="omg-btn-text ion-hide-lg-down" (click)="deleteSignature()" [disabled]="readonly">
          <fa-icon [icon]="['fal', 'times']"></fa-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
  </ion-col>
</ion-row>
