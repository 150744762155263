import {Injectable, inject} from '@angular/core';
import {AbstractSelectionService} from './abstract-selection.service';
import {Attachment} from 'submodules/baumaster-v2-common';
import {Observable, filter, map, switchMap} from 'rxjs';
import {ProjectRoomAttachmentsService} from './project-room-attachments.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectRoomAttachmentsSelectionService extends AbstractSelectionService<Attachment> {
  private projectRoomAttachmentsService = inject(ProjectRoomAttachmentsService);
  selected$: Observable<Attachment[]> = this.projectRoomAttachmentsService.attachments$.pipe(
    switchMap((attachments) =>
      this.selectedSet$.pipe(
        filter((selectedSet) => selectedSet.size === 0 || attachments.length > 0),
        map((selectedIds) => attachments.filter((attachment) => selectedIds.has(attachment.id)))
      )
    )
  );

  constructor() {
    super();
  }

  toggleMultiple(objects: Attachment[], amountTillMaxReached?: number) {
    if (!objects.length) {
      return;
    }

    const allSelected = objects.every((obj) => this.isSelected(obj));
    const notSelected = objects.filter((obj) => !this.isSelected(obj));
    if (allSelected || (amountTillMaxReached <= 0 && objects.some((obj) => this.isSelected(obj)))) {
      this.unselect(objects);
    } else {
      this.select(amountTillMaxReached || amountTillMaxReached === 0 ? notSelected.slice(0, amountTillMaxReached) : objects, false);
    }
  }
}
