import {ColumnConfig} from '../ColumnConfig';

export const ProtocolEntryDefaultValueColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'protocol_id', prop: 'protocolId'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'company_id', prop: 'companyId', def: null},
  {name: 'internal_assignment_id', prop: 'internalAssignmentId', def: null},
  {name: 'craft_id', prop: 'craftId', def: null},
  {name: 'location_id', prop: 'locationId', def: null},
  {name: 'type_id', prop: 'typeId', def: null},
  {name: 'nameable_dropdown_id', prop: 'nameableDropdownId', def: null},
  {name: 'start_date', prop: 'startDate', cast: 'Date', def: null},
  {name: 'todo_until', prop: 'todoUntil', cast: 'Date', def: null},
  {name: 'priority', def: null},
  {name: 'observer_company_ids', prop: 'observerCompanyIds', def: []},
  {name: 'all_companies', prop: 'allCompanies', def: null},
];
