<ion-content>
  <div class="ion-padding content-wrapper">
    <img *ngIf="!hideIcon" class="icon" src="/assets/images/brian-hello-login.png"/>
    <div class="title ion-padding-top">{{title | translate}}</div>
    <div class="subtitle">{{subtitle | translate}}</div>
    <div class="infoMessage" *ngIf="infoMessage">{{infoMessage | translate}}</div>
    <div class="warningMessage" *ngIf="warningMessage">{{warningMessage | translate}}</div>
    <div class="errorMessage" *ngIf="errorMessage">{{errorMessage | translate:{amount: filesExceededAmount} }}</div>
    <div class="infoErrorPlaceholder" *ngIf="!infoMessage && !warningMessage && !errorMessage"></div>
  </div>
</ion-content>
