import {Injectable, OnDestroy} from '@angular/core';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {Camera, CameraDirection, CameraResultType, CameraSource} from '@capacitor/camera';
import {AuthenticationService} from '../auth/authentication.service';
import {Observable, Subscription} from 'rxjs';
import {Attachment, AttachmentWithContent, SUPERUSER_DEVICE_UUID} from 'submodules/baumaster-v2-common';
import {
  abortControllerOrAbortSignalToSignal,
  AbstractFileAccessUtil,
  AttachmentPathProperty,
  convertBase64ToBlob,
  convertBlobToBase64,
  downloadAttachment,
  ensureMimeTypeSet,
  getAttachmentFromCache,
  getAttachmentPath,
  isAttachmentBlob,
} from '../../utils/attachment-utils';

import {AttachmentBlob, ImageOffline, OfflineAttachmentType, ScaleImageFixedOption, ScaleImageFixedResponse} from '../../model/attachments';
import {EMPTY_IMAGE_BACKGROUND, EMPTY_IMAGE_HEIGHT, EMPTY_IMAGE_WIDTH, ToastDurationInMs} from '../../shared/constants';
import {TranslateService} from '@ngx-translate/core';
import {SketchComponent} from '../../components/common/sketch/sketch.component';
import {loadImageEnsureWidthHeight} from '../../../../submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import {fabric} from 'fabric';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {observableToPromise} from '../../utils/async-utils';
import {v4 as uuidv4} from 'uuid';
import {Nullish} from 'src/app/model/nullish';
import {ToastService} from '../common/toast.service';
import {DownloadService} from '../download/download.service';
import {LoggingService} from '../common/logging.service';
import {SystemEventService} from '../event/system-event.service';
import {AttachmentKind, convertErrorToMessage, OfflineError, UnprocessableFileForPdfGenerationError} from '../../shared/errors';
import _ from 'lodash';
import {PictureQualityService} from './picture-quality.service';
import {LoadingService} from '../common/loading.service';
import {ensureContentIsJpegOrPng, isJpeg} from 'src/app/utils/image-utils';
import {Device} from '@capacitor/device';
import {AuthDetails} from 'src/app/model/auth';
import {TokenManagerService} from '../auth/token-manager.service';
import {replyOnUnauthorized} from 'src/app/utils/unauthorized-reply-utils';
import {distinctUntilChanged} from 'rxjs/operators';
import mime from 'mime';
import {NetworkStatusService} from '../common/network-status.service';
import {OfflineInfoService} from '../common/offline-info.service';
import {PosthogService} from '../posthog/posthog.service';
import piexif from 'piexifjs';

const IMAGE_QUALITY = 0.7;
const IMAGE_QUALITY_HIGH = Math.round(1 * 100);
const IMAGE_QUALITY_STANDARD = Math.round(IMAGE_QUALITY * 100);
const MAX_IMAGE_SIZE = 2000;
const DOT = '.';
const LOG_SOURCE = 'PhotoService';

@Injectable({
  providedIn: 'root',
})
export class PhotoService implements OnDestroy {
  public photos: Photo[] = [];
  private authSubscription: Subscription;
  private onlineSubscription: Subscription;
  private auth: Pick<AuthDetails, 'userId' | 'impersonated'> | null | undefined;
  private deviceUuid: string | undefined;
  private readonly fileAccessUtil$: Observable<AbstractFileAccessUtil>;
  private online: boolean | undefined;

  constructor(
    private platform: Platform,
    authenticationService: AuthenticationService,
    private modalController: ModalController,
    private translateService: TranslateService,
    private toastService: ToastService,
    private alertCtrl: AlertController,
    private attachmentSettingService: AttachmentSettingService,
    private downloader: DownloadService,
    private loggingService: LoggingService,
    private systemEventService: SystemEventService,
    private pictureQualityService: PictureQualityService,
    private loadingService: LoadingService,
    private tokenManagerService: TokenManagerService,
    private networkStatusService: NetworkStatusService,
    private offlineInfoService: OfflineInfoService,
    private posthogService: PosthogService
  ) {
    this.fileAccessUtil$ = this.attachmentSettingService.fileAccessUtil$;
    this.authSubscription = authenticationService.data
      .pipe(
        distinctUntilChanged((a, b) => {
          if (a === b || (!a && !b)) {
            return true;
          }

          if ((!a && b) || (a && !b)) {
            return false;
          }

          return a.userId === b.userId && a.impersonated === b.impersonated;
        })
      )
      .subscribe((auth) => {
        this.auth = auth;
        if (auth?.impersonated) {
          this.deviceUuid = SUPERUSER_DEVICE_UUID;
        } else {
          this.getDeviceUuid().then((deviceUuid) => (this.deviceUuid = deviceUuid));
        }
      });
    this.onlineSubscription = this.networkStatusService.online$.subscribe((online) => (this.online = online));
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
    this.onlineSubscription.unsubscribe();
  }

  private isDataUrl(uri: string): boolean {
    return uri.startsWith('data:');
  }

  async getBlob(attachment: Nullish<Attachment | AttachmentBlob>, attachmentType: OfflineAttachmentType, abortSignal?: AbortSignal): Promise<Blob | null> {
    if (abortSignal?.aborted) {
      return null;
    }
    if (!attachment) {
      return null;
    }
    if (isAttachmentBlob(attachment)) {
      return (attachment as AttachmentBlob).blob;
    }
    return await this.downloadAttachment(attachment, attachmentType, false, abortSignal);
  }

  /**
   * NOTE: Caller of this method is responsible for revoking the URL via `URL.revokeObjectURL`
   */
  async getObjectUrl(
    imageUri: Nullish<string | Attachment | AttachmentBlob>,
    attachmentType: OfflineAttachmentType,
    objectUrlCreated?: (objectUrl: string) => void,
    abortSignal?: AbortSignal
  ): Promise<string | null> {
    if (abortSignal?.aborted) {
      return null;
    }
    if (!imageUri) {
      return null;
    }
    if (typeof imageUri === 'string') {
      if (this.isDataUrl(imageUri)) {
        return imageUri;
      }
      throw new Error('ImageUriPipe does not support URLs..');
    }
    const blob = await this.getBlob(imageUri, attachmentType, abortSignal);
    if (abortSignal?.aborted) {
      return null;
    }
    if (blob === null || blob === undefined) {
      return null;
    }
    const objectUrl = URL.createObjectURL(blob);
    if (objectUrlCreated) {
      objectUrlCreated(objectUrl);
    }
    return objectUrl;
  }

  async getImageSize(attachment: Nullish<Attachment | AttachmentBlob>): Promise<{width: number; height: number}> {
    const url = await this.getObjectUrl(attachment, 'image');

    const image = new Image();
    image.src = url;

    return new Promise<{width: number; height: number}>((res, rej) => {
      image.onerror = (reason) => {
        rej(reason);
      };
      image.onload = () => {
        res({width: image.width, height: image.height});
      };
    }).then(
      (v) => {
        URL.revokeObjectURL(url);
        return v;
      },
      (error) => {
        URL.revokeObjectURL(url);
        throw error;
      }
    );
  }

  private async takePictureInternal(): Promise<Blob> {
    const qualitySetting = this.pictureQualityService.qualitySetting;
    let image;
    if (qualitySetting === 'HIGH') {
      image = await Camera.getPhoto({
        quality: IMAGE_QUALITY_HIGH,
        saveToGallery: true,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera,
        direction: CameraDirection.Rear,
      });
      let scaledImage;
      await this.loadingService.withLoading(
        async () => {
          scaledImage = this.scaleImage(convertBase64ToBlob(image.base64String));
        },
        {message: this.translateService.instant('attachment.compressing')}
      );
      return scaledImage;
    } else {
      image = await Camera.getPhoto({
        quality: IMAGE_QUALITY_STANDARD,
        width: MAX_IMAGE_SIZE,
        height: MAX_IMAGE_SIZE,
        saveToGallery: true,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera,
        direction: CameraDirection.Rear,
      });
      return convertBase64ToBlob(image.base64String);
    }
  }

  async getAttachmentFromCamera(
    source: string,
    existingAttachments: number,
    theLimit: Nullish<number>,
    onAttachmentAdded: (attachment: AttachmentBlob) => Promise<Attachment>,
    onAttachmentUpdated: (attachment: AttachmentBlob) => void,
    attachmentsLimitReachedLabel: string = 'alert.reachedAttachmentMaxNumberLimit.message_entry'
  ) {
    if (typeof theLimit === 'number') {
      const theLimitOrOneMore = Math.min(theLimit, existingAttachments + 1);
      return this.getAttachmentsFromCamera(source, existingAttachments, theLimitOrOneMore, onAttachmentAdded, onAttachmentUpdated, attachmentsLimitReachedLabel);
    }

    return this.getAttachmentsFromCamera(source, existingAttachments, existingAttachments + 1, onAttachmentAdded, onAttachmentUpdated, attachmentsLimitReachedLabel);
  }

  async getAttachmentsFromCamera(
    source: string,
    existingAttachments: number,
    theLimit: Nullish<number>,
    onAttachmentAdded: (attachment: AttachmentBlob) => Promise<Attachment>,
    onAttachmentUpdated: (attachment: AttachmentBlob) => void,
    attachmentsLimitReachedLabel: string = 'alert.reachedAttachmentMaxNumberLimit.message_entry'
  ) {
    let currentLimit: number | undefined;
    if (typeof theLimit === 'number') {
      currentLimit = theLimit - existingAttachments;
      if (currentLimit <= 0) {
        await this.showAlertAttachmentNumberLimitReached(theLimit, attachmentsLimitReachedLabel);
        return;
      }
    }

    let lastBlob: Blob = null;
    let lastAttachment: Attachment = null;

    const addedPicturesCount = await this.takePictures(
      source,
      async (blob) => {
        const addedAttachment = this.createAttachmentBlob(blob, uuidv4() + '.jpg');
        lastAttachment = await onAttachmentAdded(addedAttachment);
        lastBlob = blob;
      },
      currentLimit
    );

    if (addedPicturesCount === 1 && lastBlob && lastAttachment) {
      await this.showToastMessageImageTakenEditMarkings(
        lastAttachment,
        async (attachment: AttachmentBlob, markings) => {
          attachment.markings = markings;
          onAttachmentUpdated(attachment);
        },
        lastBlob
      );
    } else {
      await this.showToastPicturesSuccessful(addedPicturesCount);
    }
  }

  public async takePicture(source: string): Promise<Blob> {
    this.posthogService.captureEvent('[PhotoService] takePicture', {source});
    return this.takePictureInternal();
  }

  public async takePictures(source: string, onPhotoTaken: (blob: Blob) => Promise<void>, limit?: number): Promise<number> {
    this.posthogService.captureEvent('[PhotoService] takePictures', {source});
    let count = 0;
    let condition = limit ?? true;

    while (condition) {
      try {
        await onPhotoTaken(await this.takePictureInternal());
        count++;
        if (condition !== true) {
          condition--;
        }
      } catch (e) {
        if (e?.message === 'User cancelled photos app') {
          break;
        }
        throw e;
      }
    }

    return count;
  }

  public async showToastPicturesSuccessful(count: number) {
    if (count <= 0) {
      return;
    }

    await this.toastService.toastWithTranslateParams('attachment.multiplePhotosSuccess', {count}, ToastDurationInMs.INFO);
  }

  public async showToastMessageImageTakenEditMarkings(
    takenImageAttachment: AttachmentBlob | Attachment,
    onMarkingsChanged: (attachment: AttachmentBlob | Attachment, markings: Nullish<string>) => Promise<void>,
    blob?: Blob
  ): Promise<void> {
    const imageUrl = isAttachmentBlob(takenImageAttachment) && !blob ? URL.createObjectURL(takenImageAttachment.blob) : URL.createObjectURL(blob);
    await this.toastService.infoWithMessageAndButtons('attachment.photoSuccess', [
      {
        side: 'end',
        text: this.translateService.instant('edit'),
        handler: async () => {
          await this.openImageInSketchTool(takenImageAttachment, imageUrl, onMarkingsChanged);
        },
      },
    ]);
  }

  private async getDeviceUuid(): Promise<string> {
    const deviceId = await Device.getId();
    return deviceId.identifier;
  }

  async downloadAttachment(attachment: Attachment, attachmentType: OfflineAttachmentType, storeInCacheStorage = false, abortControllerOrSignal?: AbortController | AbortSignal): Promise<Blob | null> {
    if (abortControllerOrAbortSignalToSignal(abortControllerOrSignal)?.aborted) {
      return null;
    }
    const fileAccessUtil = await observableToPromise(this.fileAccessUtil$);
    const path = getAttachmentPath(attachment, attachmentType);
    if (!path) {
      return null;
    }
    if (this.online === false) {
      const abortSignal = abortControllerOrAbortSignalToSignal(abortControllerOrSignal);
      const blob = (await fileAccessUtil.readAttachmentByType(attachment, attachmentType, 'media', abortSignal))?.blob;
      if (blob) {
        return blob;
      }
      throw new OfflineError(`Unable to load attachment ${attachment?.id} from the server as the device is offline and the attachment is not stored locally.`);
    } else {
      const response = await this.tokenManagerService.runWithTokenGetter(async (tokenGetter) => {
        const blobDownloaded = await replyOnUnauthorized(async () => {
          return await downloadAttachment(attachment, attachmentType, tokenGetter, this.deviceUuid, fileAccessUtil, storeInCacheStorage, false, abortControllerOrSignal);
        });
        return blobDownloaded ? blobDownloaded.blob : null;
      });
      return response;
    }
  }

  async toAttachmentWithContent<T extends Attachment>(
    attachments: Array<T>,
    attachmentType: OfflineAttachmentType,
    attachmentsMissingContent: Array<Attachment>,
    {
      orderByDate = false,
      shouldPopulateContentBase64 = () => true,
      validateContentBase64 = () => {},
    }: {
      orderByDate?: boolean;
      shouldPopulateContentBase64?: (attachment: T) => boolean;
      validateContentBase64?: (attachment: T, contentBase64: string | undefined) => void;
    } = {}
  ): Promise<Array<AttachmentWithContent<T>>> {
    if (!attachments?.length) {
      return [];
    }
    let attachmentsWithContent = new Array<AttachmentWithContent<T>>();

    for (const attachment of attachments) {
      let contentBase64: string | undefined;
      if (shouldPopulateContentBase64(attachment)) {
        contentBase64 = await this.downloadAttachmentWithMarkings(attachment, attachmentType);
        if (!contentBase64 && attachmentType === 'thumbnail') {
          contentBase64 = await this.downloadAttachmentWithMarkings(attachment, 'image');
        }
        validateContentBase64(attachment, contentBase64);
        if (!contentBase64) {
          attachmentsMissingContent.push(attachment);
        }
      }
      attachmentsWithContent.push({
        attachment,
        contentBase64: contentBase64 || undefined,
      });
    }

    if (orderByDate) {
      attachmentsWithContent = _.orderBy(attachmentsWithContent, 'attachment.createdAt');
    }

    return attachmentsWithContent;
  }

  async downloadAttachmentWithMarkings(attachment: Attachment, attachmentType: OfflineAttachmentType, storeInCacheStorage = false): Promise<string | null> {
    const blob = await this.downloadAttachment(attachment, attachmentType, storeInCacheStorage);
    if (!blob || blob.size === 0) {
      return null;
    }
    if (!attachment.markings || attachment.markings === '' || (attachmentType === 'thumbnail' && (attachment.thumbnailPath || attachment.bigThumbnailPath || attachment.mediumThumbnailPath))) {
      return await convertBlobToBase64(blob);
    }
    return await this.applyMarkings(blob, attachment);
  }

  private async applyMarkings(blob: Blob, attachment: Attachment): Promise<string> {
    let dataUrl: string | undefined;
    let canvas: fabric.Canvas | undefined;
    try {
      dataUrl = URL.createObjectURL(blob);
      const {image, imageScaled} = await loadImageEnsureWidthHeight(dataUrl, attachment.width, attachment.height);
      if (imageScaled) {
        this.loggingService.warn(LOG_SOURCE, `applyMarkings - Image needed to be scaled`);
      }
      canvas = new fabric.Canvas(null, {width: image.width, height: image.height});
      let data = typeof attachment.markings === 'string' ? JSON.parse(attachment.markings) : attachment.markings;
      if (typeof data === 'string') {
        // markings are being stored as string in the database, even though is a jsonb datatype. This allows to work with both types of data.
        data = JSON.parse(data);
      }
      data.fabricData.backgroundImage = null;
      canvas.loadFromJSON(data.fabricData, canvas.renderAll.bind(canvas));
      canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas));
      canvas.renderAll();
      return canvas.toDataURL();
    } finally {
      if (dataUrl) {
        URL.revokeObjectURL(dataUrl);
      }
      canvas?.setDimensions({width: 0, height: 0});
      canvas?.dispose();
    }
  }

  public isCameraSupported(): boolean {
    return this.platform.is('android') || this.platform.is('ios') || this.platform.is('pwa');
  }

  private async openImageInSketchTool(
    imageAttachment: AttachmentBlob | Attachment,
    imageUrl: string,
    markingsChangedCb: (attachment: AttachmentBlob | Attachment, markings: Nullish<string>) => Promise<void>
  ) {
    const modal = await this.modalController.create({
      component: SketchComponent,
      cssClass: 'full-screen-sketch',
      backdropDismiss: false,
      componentProps: {
        attachmentUrl: imageUrl,
        attachmentWidth: imageAttachment && 'width' in imageAttachment ? imageAttachment?.width : undefined,
        attachmentHeight: imageAttachment && 'height' in imageAttachment ? imageAttachment?.height : undefined,
        revokeObjectUrlOnDestroy: true,
        markings: imageAttachment.markings,
        onMarkingsChanged: (markings: Nullish<string>) => {
          markingsChangedCb(imageAttachment, markings);
        },
      },
    });
    return await modal.present();
  }

  public async getAttachmentFromCache(attachment: Attachment, attachmentType: OfflineAttachmentType): Promise<Blob | null> {
    const fileAccessUtil = await observableToPromise(this.fileAccessUtil$);
    return getAttachmentFromCache(attachment, attachmentType, fileAccessUtil);
  }

  public async ensureContentIsJpegOrPng(file: File, kind?: AttachmentKind | undefined) {
    try {
      await ensureContentIsJpegOrPng(file, kind);
      return true;
    } catch (e) {
      if (e instanceof UnprocessableFileForPdfGenerationError) {
        this.showAlertIncorrectImage(e);
        return false;
      }

      throw e;
    }
  }

  public async showAlertIncorrectImage(error: UnprocessableFileForPdfGenerationError) {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('errors.unprocessableImage.headerErrorUploadingImage'),
      message: this.translateService.instant('errors.unprocessableImage.unsupportedImage', error.translationParams),
      buttons: [this.translateService.instant('okay')],
    });
    await alert.present();
  }

  public scaleImage(blob: Blob, maxImageSize = MAX_IMAGE_SIZE, quality = IMAGE_QUALITY): Promise<Blob> {
    return new Promise<Blob>(async (resolve, reject) => {
      const objectUrl = URL.createObjectURL(blob);
      const img = new Image();
      let exifString = '';
      const base64Img = await convertBlobToBase64(blob);
      if (isJpeg(base64Img)) {
        const exifObj = piexif.load(base64Img);
        if ('0th' in exifObj && piexif.ImageIFD.Orientation in exifObj['0th']) {
          delete exifObj['0th'][piexif.ImageIFD.Orientation];
        }
        if ('1st' in exifObj && piexif.ImageIFD.Orientation in exifObj['1st']) {
          delete exifObj['1st'][piexif.ImageIFD.Orientation];
        }
        exifString = piexif.dump(exifObj);
      }
      img.src = objectUrl;
      img.onerror = (reason) => {
        URL.revokeObjectURL(objectUrl);
        reject(reason);
      };
      img.onload = () => {
        const imageSize = Math.max(img.width, img.height);
        if (imageSize <= maxImageSize) {
          resolve(blob); // no need to scale down
          return;
        }
        const elem = document.createElement('canvas');
        try {
          const scaleFactor = maxImageSize / imageSize;
          elem.width = img.width * scaleFactor;
          elem.height = img.height * scaleFactor;

          const ctx = elem.getContext('2d') as CanvasRenderingContext2D;
          ctx.drawImage(img, 0, 0, elem.width, elem.height);
          ctx.canvas.toBlob(
            async (convertedBlob) => {
              URL.revokeObjectURL(objectUrl);
              if (!exifString) {
                resolve(convertedBlob);
              } else {
                let base64 = await convertBlobToBase64(convertedBlob);
                let base64WithExif = piexif.insert(exifString, base64);
                const index = base64WithExif.indexOf('data:image/jpeg;base64,');
                if (index >= 0) {
                  base64WithExif = base64WithExif.substring('data:image/jpeg;base64,'.length);
                }
                const convertedBlobWithExif = convertBase64ToBlob(base64WithExif);
                resolve(convertedBlobWithExif);
              }
            },
            blob.type,
            quality
          );
        } catch (error) {
          reject(error);
        } finally {
          URL.revokeObjectURL(objectUrl);
          elem.remove();
        }
      };
    });
  }

  public scaleImageToFixed(blob: Blob, scaleImageFixedOption: ScaleImageFixedOption): Promise<ScaleImageFixedResponse> {
    return new Promise<{blob: Blob; imageWidth: number; imageHeight: number; poorQuality: boolean}>(async (resolve, reject) => {
      const objectUrl = URL.createObjectURL(blob);
      const option = {quality: IMAGE_QUALITY, imageConversion: 'crop', ...scaleImageFixedOption};
      const img = new Image();
      let exifString = '';
      const base64Img = await convertBlobToBase64(blob);
      if (isJpeg(base64Img)) {
        const exifObj = piexif.load(base64Img);
        exifString = piexif.dump(exifObj);
      }
      img.src = objectUrl;
      img.onerror = (reason) => {
        URL.revokeObjectURL(objectUrl);
        reject(reason);
      };
      img.onload = () => {
        const poorQuality = img.width < option.fixedWidth || img.height < option.fixedHeight;
        const elem = document.createElement('canvas');
        try {
          if (img.width < scaleImageFixedOption.fixedWidth && img.height < scaleImageFixedOption.fixedHeight) {
            resolve({
              blob,
              imageWidth: img.width,
              imageHeight: img.height,
              poorQuality: true,
            });
            return;
          }

          let imageWidth: number;
          let imageHeight: number;
          if (option.imageConversion === 'crop') {
            const factor = Math.max(option.fixedWidth / img.width, option.fixedHeight / img.height);
            elem.width = option.fixedWidth;
            elem.height = option.fixedHeight;
            imageWidth = Math.ceil(img.width * factor);
            imageHeight = Math.ceil(img.height * factor);
          } else {
            imageWidth = Math.ceil((option.fixedHeight * img.width) / img.height);
            elem.width = imageWidth;
            imageHeight = option.fixedHeight;
            elem.height = option.fixedHeight;
          }
          const ctx = elem.getContext('2d') as CanvasRenderingContext2D;
          ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
          ctx.canvas.toBlob(
            async (convertedBlob) => {
              URL.revokeObjectURL(objectUrl);
              if (!exifString) {
                resolve({
                  blob: convertedBlob,
                  imageWidth: img.width,
                  imageHeight: img.height,
                  poorQuality,
                });
              } else {
                let base64 = await convertBlobToBase64(convertedBlob);
                let base64WithExif = piexif.insert(exifString, base64);
                const index = base64WithExif.indexOf('data:image/jpeg;base64,');
                if (index >= 0) {
                  base64WithExif = base64WithExif.substring('data:image/jpeg;base64,'.length);
                }
                const convertedBlobWithExif = convertBase64ToBlob(base64WithExif);
                resolve({
                  blob: convertedBlobWithExif,
                  imageWidth: img.width,
                  imageHeight: img.height,
                  poorQuality,
                });
              }
            },
            blob.type,
            option.quality
          );
        } catch (error) {
          reject(error);
        } finally {
          URL.revokeObjectURL(objectUrl);
          elem.remove();
        }
      };
    });
  }

  public fileNameSplitToNameAndExt(filename: string): {fileNameWithoutExt: string; fileExt: string} {
    const index = filename.lastIndexOf(DOT);
    if (index === -1) {
      throw new Error(`Unable to parse filename "${filename} as it does not have an extension.`);
    }
    return {
      fileNameWithoutExt: filename.substr(0, index),
      fileExt: filename.substr(index + 1).toLowerCase(),
    };
  }

  public truncateAndRemoveSpecialCharOnFilename(filename: string, startSliceChar: number): string {
    const {fileNameWithoutExt, fileExt} = this.fileNameSplitToNameAndExt(filename);
    const newFileName = fileNameWithoutExt.replace(/[^\w\s\.]/gi, '');
    return newFileName.slice(0, startSliceChar - fileExt.length - DOT.length) + DOT + fileExt;
  }

  public async showAlertAttachmentNumberLimitReached(limit: number, attachmentsLimitReachedLabel: string) {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('alert.reachedAttachmentMaxNumberLimit.header'),
      message: this.translateService.instant(attachmentsLimitReachedLabel, {limit}),
      buttons: [this.translateService.instant('okay')],
    });
    await alert.present();
  }

  public async isAttachmentImageInCache(attachment: Attachment, abortSignal?: AbortSignal): Promise<boolean | null> {
    if (abortSignal?.aborted) {
      return null;
    }
    const fileAccessUtil = await observableToPromise(this.fileAccessUtil$);
    return await fileAccessUtil.attachmentExists(attachment, 'image', false, 'media', abortSignal);
  }

  public async isAttachmentThumbnailInCache(attachment: Attachment, abortSignal?: AbortSignal): Promise<boolean | null> {
    if (abortSignal?.aborted) {
      return null;
    }
    const fileAccessUtil = await observableToPromise(this.fileAccessUtil$);
    return await fileAccessUtil.attachmentExists(attachment, 'thumbnail', false, 'media', abortSignal);
  }

  public async toAttachmentWithOffline<T extends Attachment>(attachment: T, abortSignal?: AbortSignal): Promise<T & ImageOffline> {
    if (abortSignal?.aborted) {
      return undefined;
    }
    const imageOffline = await this.isAttachmentImageInCache(attachment, abortSignal);
    return {...attachment, ...{imageOffline}};
  }

  public async toAttachmentsWithOffline<T extends Attachment>(attachments: Array<T>): Promise<Array<T & ImageOffline>> {
    const attachmentsWithImageOffline = new Array<T & ImageOffline>();
    for (const attachment of attachments) {
      attachmentsWithImageOffline.push(await this.toAttachmentWithOffline(attachment));
    }
    return attachmentsWithImageOffline;
  }

  public createEmptyImage(width: number = EMPTY_IMAGE_WIDTH, height: number = EMPTY_IMAGE_HEIGHT): Blob {
    const canvas = new fabric.Canvas(null, {width, height, backgroundColor: EMPTY_IMAGE_BACKGROUND});
    canvas.renderAll();
    let base64 = canvas.toDataURL({quality: 6, format: 'jpeg'});
    const index = base64.indexOf('data:image/jpeg;base64,');
    if (index >= 0) {
      base64 = base64.substring('data:image/jpeg;base64,'.length);
    }
    return convertBase64ToBlob(base64);
  }

  public createAttachment(fileName: string, markings?: string | null, mimeType?: string): Attachment {
    const fileExt = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
    if (!mimeType) {
      mimeType = mime.getType(fileName);
    }
    const attachment: Attachment = {
      id: uuidv4(),
      hash: uuidv4(),
      mimeType,
      fileName,
      fileExt,
      changedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      markings,
      createdById: this.auth?.userId,
    };
    return attachment;
  }

  public createAttachmentBlob(blob: Blob, fileName: string, markings?: string | null): AttachmentBlob {
    blob = ensureMimeTypeSet(blob, fileName);
    const attachment: AttachmentBlob = {
      ...this.createAttachment(fileName, markings, blob.type),
      blob,
    };
    return attachment;
  }

  public async downloadAttachmentToDevice(attachment: AttachmentBlob | Attachment, attachmentType: OfflineAttachmentType = 'image') {
    let objectUrlCreated: string | undefined;
    const onObjectUrlCreated = (objectUrl: string): void => {
      objectUrlCreated = objectUrl;
    };
    try {
      if (this.downloader.isDownloadNativePlatform()) {
        const blob = await this.getBlob(attachment, attachmentType);
        await this.downloader.downloadBlob(blob, attachment.fileName);
      } else {
        const objectUrl = await this.getObjectUrl(attachment, attachmentType, onObjectUrlCreated);
        const linkElement = document.createElement('a');
        linkElement.href = objectUrl;
        linkElement.setAttribute('download', attachment.fileName);
        linkElement.click();
      }
    } catch (error) {
      if (error instanceof OfflineError) {
        const retry = await this.offlineInfoService.showOfflineAlert();
        if (retry) {
          this.downloadAttachmentToDevice(attachment, attachmentType);
        }
      } else {
        this.loggingService.error(LOG_SOURCE, `downloadAttachmentToDevice failed with error: ${convertErrorToMessage(error)}`);
        await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - downloadAttachmentToDevice', convertErrorToMessage(error));
        await this.toastService.error('attachment.download.failed');
      }
    } finally {
      if (objectUrlCreated) {
        URL.revokeObjectURL(objectUrlCreated);
      }
    }
  }

  public async writeAttachment(attachment: Attachment, content: Blob, attachmentType: AttachmentPathProperty = 'filePath', overwrite = false) {
    if (!attachment.filePath) {
      throw new Error(`PhotoService.writeAttachment - attachment with id ${attachment.id} does not have a filePath.`);
    }
    const fileAccessUtil = await observableToPromise(this.fileAccessUtil$);
    if (!overwrite && (await fileAccessUtil.attachmentExists(attachment, attachmentType, true))) {
      throw new Error(`writeAttachment - Attachment with id ${attachment.id} and path "${attachment.filePath}" already exists. Do not overwrite.`);
    }
    await fileAccessUtil.writeAttachment(attachment, attachmentType, content);
  }
}

interface Photo {
  filepath: string;
  webviewPath: string;
  base64?: string;
}
