<app-modal-header>
  <ion-title>
    {{ 'project_room.pdf_plan_folder_columns_modal.title' | translate }}
  </ion-title>
</app-modal-header>

<ion-content class="with-padding" [formGroup]="form">
  <div class="omg-attention omg-attention-warning ion-hide-xxl-up">
    {{ 'project_room.pdf_plan_folder_columns_modal.some_columns_hidden' | translate }}
  </div>
  <ion-grid class="grid-gap-3" formGroupName="showColumns">
    <ion-row>
      <ion-col size="12" class="omg-t-header-3-bold">
        {{ 'project_room.pdf_plan_folder_columns_modal.show_columns' | translate }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="4">
        <button appBauCheckbox formControlName="date" class="column-date clear-button d-flex ion-align-items-center gap-2">
          <span class="bau-checkbox"></span>
          <span>{{'project_room.pdf_plan_holders.list_header.date' | translate}}</span>
        </button>
      </ion-col>
      <ion-col size="4">
        <button appBauCheckbox formControlName="index" class="column-index clear-button d-flex ion-align-items-center gap-2">
          <span class="bau-checkbox"></span>
          <span>{{'project_room.pdf_plan_holders.list_header.index' | translate}}</span>
        </button>
      </ion-col>
      <ion-col size="4">
        <button appBauCheckbox formControlName="locationId" class="column-locationId clear-button d-flex ion-align-items-center gap-2">
          <span class="bau-checkbox"></span>
          <span>{{'Location' | translate}}</span>
        </button>
      </ion-col>
      <ion-col size="4">
        <button appBauCheckbox formControlName="scale" class="column-scale clear-button d-flex ion-align-items-center gap-2">
          <span class="bau-checkbox"></span>
          <span>{{'project_room.pdf_plan_version_form.scale' | translate}}</span>
        </button>
      </ion-col>
      <ion-col size="4">
        <button appBauCheckbox formControlName="tags" class="column-tags clear-button d-flex ion-align-items-center gap-2">
          <span class="bau-checkbox"></span>
          <span>{{'project_room.pdf_plan_holders.list_header.tags' | translate}}</span>
        </button>
      </ion-col>
      <ion-col size="4">
        <button appBauCheckbox formControlName="unitId" class="column-unitId clear-button d-flex ion-align-items-center gap-2">
          <span class="bau-checkbox"></span>
          <span>{{'protocol.unit.unit' | translate}}</span>
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="close()">{{ 'cancel' | translate }}</ion-button>
    <ion-button class="omg-btn-primary" (click)="save()">{{ 'button.save' | translate }}</ion-button>
  </ion-buttons>
</app-modal-footer>