import {Directive, HostBinding, HostListener, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: '[appBauCheckbox]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BauCheckboxDirective),
      multi: true,
    },
  ],
})
export class BauCheckboxDirective implements ControlValueAccessor {
  @HostBinding('class.bau-checkbox--checked')
  checked = false;

  onChange = (val: boolean) => {};
  onTouched = () => {};
  isDisabled = false;

  constructor() {}
  writeValue(val: boolean): void {
    this.checked = val;
  }
  registerOnChange(fn: (val: boolean) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  @HostListener('click')
  toggle() {
    this.checked = !this.checked;
    this.onChange(this.checked);
    this.onTouched();
  }
}
