import {Column, Content, ContentTable, Table, TableCell, TableLayout} from 'pdfmake/interfaces';
import {SvgIcons} from '../../pdfSvgIcons';
import {AbstractPdfContent, formatDate} from '../abstractPdf.content';
import _ from 'lodash';
import {Address, Client, Company, Craft, IdType, IndividualNextMeetingRequest, Participant, PdfPreview, Profile} from '../../../../models';
import {PdfColor, PdfProtocolGenerateData} from '../../pdfProtocol.model';
import {convertToRichText, formatOneLineAddress, isRichText, PdfHelperFunctions} from '../../../common-report-utils';
import {PdfProtocolSendReq} from '../../../../requestResponse';
import {protocolIndexNumberToString} from '../../pdfutils';
import {formatProjectNumberOptional} from '../../../../commonUtils';
import {UnitProfileAddress} from '../abstractProject.content';
import {HIDE_PROTOCOL_DATE_ON_PDF_CLIENT_IDS, UNIT_OWNER_TENANT_COMPANY_ID} from '../../../../constants';

export interface ParticipantsProfile extends Participant {
  profile: Profile | undefined;
  client: Client | undefined;
  address: Address | undefined;
  craft: Craft | undefined;
}

export interface CompanyWithParticipants {
  companyName: string;
  company: Company;
  participants: ParticipantsProfile[];
  companyOrder?: number | null;
}

const lowerCaseCompanyNameIdentity = (value: {companyName: string}) => value.companyName.toLocaleLowerCase();

export abstract class AbstractProjectContentNew extends AbstractPdfContent {
  constructor(
    lng: string,
    config: PdfProtocolSendReq,
    data: PdfProtocolGenerateData,
    protected pdfHelperFunctions: PdfHelperFunctions,
    pdfPreview?: PdfPreview
  ) {
    super(lng, config, data, pdfPreview);
  }

  protected tableLayoutMeeting: TableLayout = {
    hLineWidth() {
      return 0.5;
    },
    hLineColor: function (i: number, node: ContentTable) {
      return i === 0 || i === 1 || i === 2 || i === node.table.body.length ? '#4F5964' : '#B0BAC5';
    },
    vLineWidth: function (i: number, node: ContentTable) {
      return i === 0 || i === node.table.widths?.length ? 0.5 : 0;
    },
    vLineColor() {
      return '#4F5964';
    },
    paddingLeft() {
      return 4;
    },
    paddingRight() {
      return 4;
    },
    paddingTop() {
      return 4;
    },
    paddingBottom() {
      return 4;
    },
  };

  protected tableLayout: TableLayout = {
    hLineWidth: function (i: number, node: ContentTable) {
      return i === 0 || i === node.table.body.length ? 0.5 : 0;
    },
    hLineColor() {
      return '#4F5964';
    },
    vLineWidth: function (i: number, node: ContentTable) {
      return i === 0 || i === node.table.widths?.length ? 0.5 : 0;
    },
    vLineColor() {
      return '#4F5964';
    },
    paddingLeft() {
      return 4;
    },
    paddingRight() {
      return 4;
    },
    paddingTop() {
      return 4;
    },
    paddingBottom: function (i: number, node: ContentTable) {
      return i === 0 ? 0 : 4;
    },
  };

  protected tableLayoutInnerForHeading: TableLayout = {
    hLineWidth() {
      return 0;
    },
    vLineWidth() {
      return 0;
    },
    paddingLeft() {
      return 0;
    },
    paddingRight() {
      return 0;
    },
    paddingTop() {
      return 0;
    },
    paddingBottom() {
      return 0;
    },
  };

  protected writeProjectPhoto(content: Content[]) {
    const bannerContentBase64 = this.data.pdfProjectBanners?.get('pdf_start_banner')?.contentBase64 ?? this.data.attachmentClients?.get('pdfStartBanner')?.contentBase64;
    if (!_.isEmpty(bannerContentBase64) && !this.config.pdfProtocolSetting?.showHeaderBannerOnEveryPage) {
      content.push({image: 'pdfStartBanner', fit: [515, 105]});
    }
  }

  protected writeCompanyInfo(content: Content[]) {
    const client = this.getValueNotNull(this.data.client, 'client');
    if (!this.data.client.addressId || !this.config.pdfProtocolSetting?.clientShowAddress) {
      return;
    }
    const addressId = this.getValueNotNull(this.data.client.addressId, 'client.addressId');
    const address = this.getValueNotNull(this.data.lookup.addresses.get(addressId), 'client address');

    let companyAddress = client.name + '';
    if (address.street1) {
      companyAddress += ' - ' + address.street1;
    }
    if (address.street2) {
      companyAddress += ' - ' + address.street2;
    }
    if (address.zipCode && address.city) {
      companyAddress += ' ' + address.zipCode + ' ' + address.city;
    }
    content.push({
      text: companyAddress,
      style: ['companyInfo', 'font8'],
    });
  }

  protected writeProjectSection(content: Content[], indexNumber: number | undefined) {
    const protocolType = this.data.lookup?.protocolTypes.get(this.data.protocol.typeId)?.code;
    const protocolShortId = `${protocolType ?? ''}${this.data.protocol.number.toString().padStart(2, '0')}`;
    const indexString = protocolIndexNumberToString(indexNumber);
    content.push({
      columns: [
        [
          {
            text: `${protocolShortId} ${indexString !== undefined ? '| ' + indexString : ''} - ${this.data.protocol.name}`,
            style: ['font18', 'marginTop10'],
          },
          {
            text: `${formatProjectNumberOptional(this.data.project.number)} - ${this.data.project.name}`,
            style: ['font14', 'fontColorGray', 'textBold', 'marginTop2'],
          },
        ],
      ],
    });

    content.push({
      columns: [this.writeProjectDetails()],
    });
  }

  protected writeProjectDetails(): Column[] {
    const columns: Column[] = [];
    const projectColumms: Column[] = [];
    const isProjectImageExisting = !_.isEmpty(this.data.attachmentProjectImage);

    if (isProjectImageExisting) {
      projectColumms.push({
        columns: [{image: 'projectPhoto', fit: [140, 140]}],
        width: 'auto',
      });
    }

    projectColumms.push({
      columns: [[this.getProjectDetailInformation()]],
      width: '*',
      style: [!isProjectImageExisting ? '' : 'marginLeft10'],
    });

    columns.push({
      columns: [
        {
          style: 'marginTop10',
          columns: projectColumms,
        },
      ],
    });

    if (!_.isEmpty(this.config.pdfProtocolSetting?.additionalText)) {
      columns.push({
        style: 'marginTop10',
        columns: [
          {
            columns: [
              {
                svg: this.changeSvgIconFill(SvgIcons.paper, this.getProtocolColor()),
                fit: [12, 12],
              },
            ],
            width: 'auto',
          },
          {
            width: '*',
            text: `${this.config.pdfProtocolSetting?.additionalText}`,
            style: ['font9', 'marginLeft10'],
          },
        ],
      });
    }
    return columns;
  }

  protected getProjectDetailInformation(): Column[] {
    const columns: Column[] = [];
    const isProjectImageExisting = !_.isEmpty(this.data.attachmentProjectImage);
    const dateTimeColumn: Column[] = [];

    let protocolTime;
    if (!this.isEmptyDate(this.data.protocol.timeFrom) || !this.isEmptyDate(this.data.protocol.timeUntil)) {
      protocolTime = `${this.getTime(this.data.protocol.timeFrom)} - ${this.getTime(this.data.protocol.timeUntil)}`;
      if (this.isEmptyDate(this.data.protocol.timeUntil) && !this.isEmptyDate(this.data.protocol.timeFrom)) {
        protocolTime = `${this.i18n?.get('start')} ${this.getTime(this.data.protocol.timeFrom)}`;
      } else if (this.isEmptyDate(this.data.protocol.timeFrom) && !this.isEmptyDate(this.data.protocol.timeUntil)) {
        protocolTime = `${this.i18n?.get('finished')} ${this.getTime(this.data.protocol.timeUntil)}`;
      }
    }

    let projectAddress = '';
    if (this.data.project.addressSite && !this.data.project.addressId) {
      projectAddress = this.data.project.addressSite;
    } else {
      projectAddress = formatOneLineAddress(this.data.projectAddress);
    }

    dateTimeColumn.push({
      columns: [
        [
          !HIDE_PROTOCOL_DATE_ON_PDF_CLIENT_IDS.includes(this.data.client.id) ? {text: `${this.i18n?.get('date')}`, style: ['greyHeading']} : {text: ''},
          !HIDE_PROTOCOL_DATE_ON_PDF_CLIENT_IDS.includes(this.data.client.id)
            ? {text: `${this.getDateValueNotNull(this.data.protocol.date)}${protocolTime ? ' | ' + protocolTime : ''}`, style: ['font9', 'marginTop2']}
            : {text: ''},
          {text: `${this.i18n?.get('sent_date')}`, style: ['greyHeading', 'marginTop8']},
          {text: `${this.getDateValueNotNull(new Date().toISOString())}`, style: ['font9', 'marginTop2']},
          {text: this.data.protocol.location ? `${this.i18n?.get('place')}` : '', style: ['greyHeading', 'marginTop8']},
          {text: `${this.sanitizeValue(this.data.protocol.location)}`, style: ['font9', 'marginTop2']},
        ],
      ],
    });

    const unitName = this.data.protocol.unitId ? this.data.unitForBreadcrumbs?.find((unit) => unit.id === this.data.protocol.unitId)?.breadcrumbsName : undefined;
    columns.push({
      columns: [
        {
          columns: [
            _.compact([
              projectAddress ? {text: projectAddress ? `${this.i18n?.get('projectAddress')}` : '', style: ['greyHeading', !isProjectImageExisting ? '' : 'marginLeft10']} : undefined,
              projectAddress ? {text: `${this.sanitizeValue(projectAddress)}`, style: ['font9', !isProjectImageExisting ? 'marginTop2' : 'marginLeft10Top2']} : undefined,
              this.data.project.contractee || this.data.project.addressContractee
                ? {
                    text: `${this.i18n?.get('client')}`,
                    style: ['greyHeading', !isProjectImageExisting ? 'marginTop8' : 'marginLeft10Top8'],
                  }
                : undefined,
              this.data.project.contractee || this.data.project.addressContractee
                ? {text: `${this.sanitizeValue(this.data.project.contractee)}`, style: ['font9', !isProjectImageExisting ? 'marginTop2' : 'marginLeft10Top2']}
                : undefined,
              {text: `${this.sanitizeValue(this.data.project.addressContractee)}`, style: ['font9', !isProjectImageExisting ? '' : 'marginLeft10']},
              unitName ? {text: `${this.i18n?.get('unit')}`, style: ['greyHeading', !isProjectImageExisting ? 'marginTop8' : 'marginLeft10Top8']} : undefined,
              unitName ? {text: `${this.sanitizeValue(unitName)}`, style: ['font9', !isProjectImageExisting ? 'marginTop2' : 'marginLeft10Top2']} : undefined,
            ]),
          ],
          width: isProjectImageExisting ? 215 : '*',
        },
        {
          columns: dateTimeColumn,
          width: 150,
        },
      ],
    });

    if (!_.isEmpty(this.config.sendPdfProtocolAdditionalInfo.customText)) {
      columns.push({
        columns: [
          {
            columns: [
              [
                {text: '', style: ['font9', !isProjectImageExisting ? '' : 'marginLeft10Top5']},
                {text: '', style: ['font9', !isProjectImageExisting ? '' : 'marginLeft10']},
              ],
            ],
            width: '*',
          },
          {
            columns: [
              [
                {
                  style: 'marginTop10',
                  columns: [
                    {
                      columns: [
                        {
                          svg: this.changeSvgIconFill(SvgIcons.info, PdfColor.RED),
                          fit: [12, 12],
                        },
                      ],
                      width: 'auto',
                    },
                    {
                      text: `${this.config.sendPdfProtocolAdditionalInfo.customText}`,
                      style: ['font9', 'fontColorRed', 'marginLeft10'],
                    },
                  ],
                },
              ],
            ],
            width: 150,
          },
        ],
      });
    }

    return columns;
  }

  private getProfileDetails(profileId: IdType): {
    companyName: string;
    name: string;
    email: string;
    phone: string;
  } {
    const profile = this.data.lookup.profiles.get(profileId);
    const company = this.data.lookup.companies.get(profile?.companyId + '');
    const companyName = company?.name ?? '';

    const address = this.data.lookup.addresses.get(profile?.addressId + '');
    const name = `${address?.firstName} ${address?.lastName}`;
    const email = this.getParticipantEmail({profile, address});
    const phone = this.getParticipantPhone({profile, address});

    return {
      companyName,
      name,
      email,
      phone,
    };
  }

  private getCompactIndividualNextMeetingCells(content: Content[], meeting: IndividualNextMeetingRequest): TableCell[] {
    const details = this.getProfileDetails(meeting.profileId);

    return [
      {text: `${this.sanitizeValue(details.companyName)}`, style: ['font9', 'textBold']},
      {text: `${this.sanitizeValue(details.name)}`, style: ['font9']},
      {text: `${this.sanitizeValue(details.phone)}`, style: ['font9']},
      {text: `${this.sanitizeValue(details.email)}`, link: `mailto: ${this.sanitizeValue(details.email)}`, style: ['font9']},
      {
        style: ['font9'],
        text: this.sanitizeValue(`${meeting.timeStart} - ${meeting.timeEnd}`),
      },
    ];
  }

  private writeCompactIndividualNextMeetings(content: Content[]) {
    let nextMeetingDate = this.getDateValueNotNull(this.config.nextMeeting?.date) + ' ';
    nextMeetingDate += '| ' + this.i18n?.get('from') + ' ';
    nextMeetingDate += this.sanitizeValue(this.config.nextMeeting?.timeStart) + ' ';
    nextMeetingDate += this.i18n?.get('to') + ' ';
    nextMeetingDate += this.sanitizeValue(this.config.nextMeeting?.timeEnd) + ' ';

    const cells: TableCell[][] = [];

    for (const meeting of _.sortBy(this.config.individualNextMeetings, ['timeStart', 'timeEnd'])) {
      cells.push(this.getCompactIndividualNextMeetingCells(content, meeting));
    }
    const innerTable: Table = {
      headerRows: 0,
      widths: ['auto', 'auto'],
      body: [
        [
          {
            svg: this.changeSvgIconFill(SvgIcons.calendar, this.config.pdfProtocolSetting?.protocolColor + ''),
            fit: [12, 12],
          },
          {
            text: `${this.i18n?.get('next_meeting')}`,
            style: ['font9', 'protocolFontColor', 'marginLeft3', 'textBold'],
          },
        ],
      ],
    };

    const tblMeeting: Table = {
      headerRows: 1,
      widths: [110, 100, 86, 118, 60],
      keepWithHeaderRows: 2,
      body: [
        [
          {
            colSpan: 3,
            table: innerTable,
            layout: this.tableLayoutInnerForHeading,
          },
          {},
          {},
          {
            colSpan: 2,
            text: nextMeetingDate,
            style: ['font9'],
            alignment: 'right',
            noWrap: true,
          },
          {},
        ],
        [
          {text: `${this.i18n?.get('company')}`, style: ['greyHeading']},
          {text: `${this.i18n?.get('name_without_degree')}`, style: ['greyHeading']},
          {text: `${this.i18n?.get('phone')}`, style: ['greyHeading']},
          {text: `${this.i18n?.get('email')}`, style: ['greyHeading']},
          {text: `${this.i18n?.get('time_range')}`, style: ['greyHeading']},
        ],
        ...cells,
      ],
    };

    content.push({
      table: tblMeeting,
      layout: this.tableLayoutMeeting,
      style: ['marginTop10'],
    });
  }

  protected writeIndividualNextMeetings(content: Content[]) {
    if (_.isEmpty(this.config.individualNextMeetings)) {
      return;
    }
    this.writeCompactIndividualNextMeetings(content);
  }

  protected writeNextMeeting(content: Content[]) {
    if (_.isEmpty(this.config.nextMeeting?.date)) {
      return;
    }

    const additionalText = !_.isEmpty(this.config.individualNextMeetings) ? this.i18n?.get('individual_next_meetings_details_last_page') : undefined;

    this.writeNextMeetingHeading(content, additionalText);
  }

  protected getWeather(weatherKey: number | undefined | null): string {
    const weatherOption = this.i18n?.get('weatherOption_' + weatherKey);
    return _.isEmpty(weatherOption) ? '' : weatherOption + '';
  }

  protected writeWeather(content: Content[]): void {
    let weather = '';
    let temp = '';
    let humidity = '';
    let windspeed = '';
    if (this.getWeather(this.data.protocol.weather)) {
      weather += this.getWeather(this.data.protocol.weather);
    }

    if ((this.data.protocol.minTemp !== null && this.data.protocol.minTemp !== undefined) || (this.data.protocol.maxTemp !== null && this.data.protocol.maxTemp !== undefined)) {
      if (this.data.protocol.minTemp !== null && this.data.protocol.minTemp !== undefined && this.data.protocol.maxTemp !== null && this.data.protocol.maxTemp !== undefined) {
        temp += this.data.protocol.minTemp;
        temp += '° ' + this.i18n?.get('to') + ' ';
        temp += this.data.protocol.maxTemp;
      } else {
        temp += this.data.protocol.minTemp ? this.data.protocol.minTemp : this.data.protocol.maxTemp;
      }
      temp += '°';
    }
    if (this.data.protocol.humidity !== null && this.data.protocol.humidity !== undefined) {
      humidity += this.data.protocol.humidity;
      humidity += ' %';
    }
    if (this.data.protocol.windspeed !== null && this.data.protocol.windspeed !== undefined) {
      windspeed += this.data.protocol.windspeed;
      windspeed += ' km/h';
    }

    const protocolDate = formatDate(this.data.protocol.date);

    const innerTable: Table = {
      headerRows: 0,
      widths: ['auto', 'auto', 'auto'],
      body: [
        [
          {
            svg: this.changeSvgIconFill(SvgIcons.weather, this.getProtocolColor()),
            fit: [12, 12],
          },
          {
            text: `${this.i18n?.get('weather')}`,
            style: ['font9', 'protocolFontColor', 'textBold', 'marginLeft3'],
          },
          {
            text: `(${this.i18n?.get('forDate')} ${protocolDate})`,
            style: ['font9', 'marginLeft3'],
          },
        ],
      ],
    };

    const tblWeather: Table = {
      headerRows: 1,
      widths: ['*', '*', '*', '*'],
      body: [
        [
          {
            colSpan: 4,
            table: innerTable,
            layout: this.tableLayoutInnerForHeading,
          },
          {},
          {},
          {},
        ],
        [
          {
            text: [
              {text: `${this.i18n?.get('weather')}`, style: ['greyHeading']},
              {text: '\n', style: ['font9']},
              {text: weather, style: ['font9']},
            ],
          },
          {
            text: [
              {text: `${this.i18n?.get('temperature')}`, style: ['greyHeading']},
              {text: '\n', style: ['font9']},
              {text: temp, style: ['font9']},
            ],
          },
          {
            text: [
              {text: `${this.i18n?.get('humidity')}`, style: ['greyHeading']},
              {text: '\n', style: ['font9']},
              {text: humidity, style: ['font9']},
            ],
          },
          {
            text: [
              {text: `${this.i18n?.get('windspeed')}`, style: ['greyHeading']},
              {text: '\n', style: ['font9']},
              {text: windspeed, style: ['font9']},
            ],
          },
        ],
      ],
    };

    content.push({
      layout: this.tableLayout,
      table: tblWeather,
      margin: [0, 10, 0, 0],
    });
  }

  private writeNextMeetingHeading(content: Content[], additionalText?: string) {
    let nextMeetingDate = this.getDateValueNotNull(this.config.nextMeeting?.date) + ' ';
    nextMeetingDate += '| ' + this.i18n?.get('from') + ' ';
    nextMeetingDate += this.sanitizeValue(this.config.nextMeeting?.timeStart) + ' ';
    nextMeetingDate += this.i18n?.get('to') + ' ';
    nextMeetingDate += this.sanitizeValue(this.config.nextMeeting?.timeEnd) + ' ';

    const innerTable: Table = {
      headerRows: 0,
      widths: ['auto', 'auto'],
      body: [
        [
          {
            svg: this.changeSvgIconFill(SvgIcons.calendar, this.config.pdfProtocolSetting?.protocolColor + ''),
            fit: [12, 12],
          },
          {
            text: `${this.i18n?.get('next_meeting')}`,
            style: ['font9', 'protocolFontColor', 'marginLeft3', 'textBold'],
          },
        ],
      ],
    };

    const tblMeeting: Table = {
      headerRows: 1,
      widths: ['*'],
      body: [
        [
          {
            table: innerTable,
            layout: this.tableLayoutInnerForHeading,
          },
        ],
        [
          {
            text: `${nextMeetingDate} ${additionalText ? '(' + additionalText + ')' : ''}`,
            style: ['font9'],
          },
        ],
      ],
    };

    content.push({
      layout: this.tableLayout,
      table: tblMeeting,
      margin: [0, 10, 0, 0],
    });
  }

  protected writeNoProtocolEntries(content: Content[]) {
    const profile = this.data.lookup.profiles.get(this.config.participant?.profileId + '');
    const company = this.data.lookup.companies.get(profile?.companyId + '');

    content.push({
      columns: [
        {
          text: `${this.i18n?.get('no_entries')?.replace('{{ company_name }}', this.sanitizeValue(company?.name))}`,
          style: ['participantCompany', 'textBold', 'font12', 'alignCenter', 'marginTop20'],
        },
      ],
      style: ['marginTop20'],
    });
  }

  protected writeCompactParticipantList(content: Content[], showParticipantCol: boolean | null | undefined, companyWithParticipants?: CompanyWithParticipants[]) {
    if (!companyWithParticipants) {
      companyWithParticipants = this.getCompanyWithSelectedParticipants();
    }
    const containsUnitCompany = companyWithParticipants.some((c) => c.company.id === UNIT_OWNER_TENANT_COMPANY_ID);

    const innerTable: Table = {
      headerRows: 0,
      widths: ['auto', 'auto'],
      body: [
        [
          {
            svg: this.changeSvgIconFill(containsUnitCompany ? SvgIcons.clipboardUser : SvgIcons.participants, this.config.pdfProtocolSetting?.protocolColor + ''),
            fit: [12, 12],
          },
          {
            text: `${this.i18n?.get(containsUnitCompany ? 'ownerTenant' : 'participants')}`,
            noWrap: true,
            style: ['font9', 'marginLeft3', 'protocolFontColor', 'textBold'],
          },
        ],
      ],
    };

    const tblParticipant: Table = {
      headerRows: 0,
      widths: [137, 100, 86, 119, 12, 12],
      body: [
        [
          {
            colSpan: 3,
            table: innerTable,
            layout: this.tableLayoutInnerForHeading,
          },
          {},
          {},
          {
            colSpan: 3,
            text: `${this.i18n?.get('recAndPart')}`,
            style: ['greyText', 'font9', 'italic'],
            alignment: 'right',
            noWrap: true,
          },
          {},
          {},
        ],
        [
          {text: `${this.i18n?.get(containsUnitCompany ? 'unit' : 'company')}`, width: 100, style: ['greyHeading']},
          {text: `${this.i18n?.get('name_without_degree')}`, width: 137, style: ['greyHeading']},
          {text: `${this.i18n?.get('phone')}`, width: 86, style: ['greyHeading']},
          {text: `${this.i18n?.get('email')}`, width: 119, style: ['greyHeading']},
          {text: `${this.i18n?.get('recNew')}`, width: 12, style: ['greyHeading']},
          {text: showParticipantCol !== false ? `${this.i18n?.get('partNew')}` : '', width: 12, style: ['greyHeading']},
        ],
      ],
    };

    let index = 2;
    const indexesWithHLine: number[] = [];
    for (const companyParticipants of companyWithParticipants) {
      for (const participantProfile of this.sortParticipantsProfiles(companyParticipants.participants)) {
        const participantColumn: Column[] = [];
        if (showParticipantCol !== false) {
          participantColumn.push([
            {
              columns: [
                {
                  svg: participantProfile.present ? SvgIcons.blackCheckboxCheck : SvgIcons.blackCheckboxUncheck,
                  fit: [12, 12],
                },
              ],
            },
          ]);
        }
        const unitForBreadcrumb = containsUnitCompany && this.data.unitForBreadcrumbs ? this.data.unitForBreadcrumbs.find((v) => v.id === this.data.protocol?.unitId) : undefined;
        tblParticipant.body.push([
          {text: `${this.sanitizeValue(unitForBreadcrumb?.name ?? companyParticipants.companyName)}`, style: ['font9']},
          {text: `${this.sanitizeValue(participantProfile.address?.firstName)} ${this.sanitizeValue(participantProfile.address?.lastName)} `, style: ['font9']},
          {text: `${this.getParticipantPhone(participantProfile)}`, style: ['font9']},
          {text: `${this.getParticipantEmail(participantProfile)}`, link: `mailto: ${this.sanitizeValue(participantProfile.address?.email)}`, style: ['font9']},
          {columns: [{svg: participantProfile.mailingList ? SvgIcons.blackCheckboxCheck : SvgIcons.blackCheckboxUncheck, fit: [12, 12]}]},
          participantColumn,
        ]);
        index++;
        indexesWithHLine.push(index);
      }
    }

    const tableLayoutParticipant: TableLayout = {
      hLineWidth: function (i: number, node: ContentTable) {
        if (indexesWithHLine.includes(i)) {
          return 0.5;
        }
        return i <= 2 || i === node.table.body.length ? 0.5 : 0;
      },
      hLineColor: function (i: number, node: ContentTable) {
        if (i === node.table.body.length) {
          return '#4F5964';
        }
        if (indexesWithHLine.includes(i)) {
          return '#B0BAC5';
        }
        return '#4F5964';
      },
      vLineWidth: function (i: number, node: ContentTable) {
        return i === 0 || i === node.table.widths?.length ? 0.5 : 0;
      },
      vLineColor() {
        return '#4F5964';
      },
      paddingLeft() {
        return 4;
      },
      paddingRight() {
        return 4;
      },
      paddingTop() {
        return 4;
      },
      paddingBottom() {
        return 4;
      },
    };
    content.push({
      layout: tableLayoutParticipant,
      table: tblParticipant,
      margin: [0, 10, 0, 0],
    });
  }

  protected writeParticipantList(content: Content[], showParticipantCol: boolean | null | undefined, companyWithParticipants?: CompanyWithParticipants[]) {
    if (!companyWithParticipants) {
      companyWithParticipants = this.getCompanyWithSelectedParticipants();
    }
    const containsUnitCompany = companyWithParticipants.some((c) => c.company.id === UNIT_OWNER_TENANT_COMPANY_ID);

    const innerTable: Table = {
      headerRows: 0,
      widths: ['auto', 'auto'],
      body: [
        [
          {
            svg: this.changeSvgIconFill(containsUnitCompany ? SvgIcons.clipboardUser : SvgIcons.participants, this.config.pdfProtocolSetting?.protocolColor + ''),
            fit: [12, 12],
          },
          {
            text: `${this.i18n?.get(containsUnitCompany ? 'ownerTenant' : 'participants')}`,
            noWrap: true,
            style: ['font9', 'marginLeft3', 'protocolFontColor', 'textBold'],
          },
        ],
      ],
    };

    const tblParticipant: Table = {
      headerRows: 1,
      widths: [137, 100, 86, 119, 12, 12],
      body: [
        [
          {
            colSpan: 3,
            table: innerTable,
            layout: this.tableLayoutInnerForHeading,
          },
          {},
          {},
          {
            colSpan: 3,
            text: `${this.i18n?.get('recAndPart')}`,
            style: ['greyText', 'font9', 'italic'],
            alignment: 'right',
            noWrap: true,
          },
          {},
          {},
        ],
        [
          {text: `${this.i18n?.get('name_without_degree')}`, width: 137, style: ['greyHeading']},
          {text: `${containsUnitCompany ? '' : this.i18n?.get('craft')}`, width: 100, style: ['greyHeading']},
          {text: `${this.i18n?.get('phone')}`, width: 86, style: ['greyHeading']},
          {text: `${this.i18n?.get('email')}`, width: 119, style: ['greyHeading']},
          {text: `${this.i18n?.get('recNew')}`, width: 12, style: ['greyHeading']},
          {text: showParticipantCol !== false ? `${this.i18n?.get('partNew')}` : '', width: 12, style: ['greyHeading']},
        ],
      ],
    };

    let index = 2;
    const indexesWithHLine: number[] = [];
    for (const companyParticipants of companyWithParticipants) {
      if (!containsUnitCompany) {
        // no company name for unitCompany
        tblParticipant.body.push([
          {
            colSpan: 6,
            text: companyParticipants.companyName,
            style: ['font9', 'textBold', 'marginTop2'],
            alignment: 'left',
            noWrap: true,
          },
          {},
          {},
          {},
          {},
          {},
        ]);
        index++;
        indexesWithHLine.push(index);
      }

      for (const participantProfile of this.sortParticipantsProfiles(companyParticipants.participants)) {
        const participantColumn: Column[] = [];
        if (showParticipantCol !== false) {
          participantColumn.push([
            {
              columns: [
                {
                  svg: participantProfile.present ? SvgIcons.blackCheckboxCheck : SvgIcons.blackCheckboxUncheck,
                  fit: [12, 12],
                },
              ],
            },
          ]);
        }
        tblParticipant.body.push([
          {text: `${this.sanitizeValue(participantProfile.address?.firstName)} ${this.sanitizeValue(participantProfile.address?.lastName)} `, style: ['font9']},
          {text: `${this.sanitizeValue(containsUnitCompany ? '' : (participantProfile.craft?.name ?? '-'))}`, style: ['font9']},
          {text: `${this.getParticipantPhone(participantProfile)}`, style: ['font9']},
          {text: `${this.getParticipantEmail(participantProfile)}`, link: `mailto: ${this.sanitizeValue(participantProfile.address?.email)}`, style: ['font9']},
          {columns: [{svg: participantProfile.mailingList ? SvgIcons.blackCheckboxCheck : SvgIcons.blackCheckboxUncheck, fit: [12, 12]}]},
          participantColumn,
        ]);
        index++;
        indexesWithHLine.push(index);
      }
    }

    const tableLayoutParticipant: TableLayout = {
      hLineWidth: function (i: number, node: ContentTable) {
        if (indexesWithHLine.includes(i)) {
          return 0.5;
        }
        return i <= 2 || i === node.table.body.length ? 0.5 : 0;
      },
      hLineColor: function (i: number, node: ContentTable) {
        if (i === node.table.body.length) {
          return '#4F5964';
        }
        if (indexesWithHLine.includes(i)) {
          return '#B0BAC5';
        }
        return '#4F5964';
      },
      vLineWidth: function (i: number, node: ContentTable) {
        return i === 0 || i === node.table.widths?.length ? 0.5 : 0;
      },
      vLineColor() {
        return '#4F5964';
      },
      paddingLeft() {
        return 4;
      },
      paddingRight() {
        return 4;
      },
      paddingTop() {
        return 4;
      },
      paddingBottom() {
        return 4;
      },
    };
    content.push({
      layout: tableLayoutParticipant,
      table: tblParticipant,
      margin: [0, 10, 0, 0],
    });
  }

  protected writeUnitContactList(content: Content[], unitProfileAddresses: Array<UnitProfileAddress>) {
    const innerTable: Table = {
      headerRows: 0,
      widths: ['auto', 'auto'],
      body: [
        [
          {
            svg: this.changeSvgIconFill(SvgIcons.clipboardUser, this.config.pdfProtocolSetting?.protocolColor + ''),
            fit: [12, 12],
          },
          {
            text: `${this.i18n?.get('unitContacts')}`,
            noWrap: true,
            style: ['font9', 'marginLeft3', 'protocolFontColor', 'textBold'],
          },
        ],
      ],
    };

    const tblParticipant: Table = {
      headerRows: 1,
      widths: [157, 120, 86, 119],
      body: [
        [
          {
            colSpan: 3,
            table: innerTable,
            layout: this.tableLayoutInnerForHeading,
          },
          {},
          {},
          {},
        ],
        [
          {text: `${this.i18n?.get('name_without_degree')}`, width: 157, style: ['greyHeading']},
          {text: `${this.i18n?.get('unit')}`, width: 120, style: ['greyHeading']},
          {text: `${this.i18n?.get('phone')}`, width: 86, style: ['greyHeading']},
          {text: `${this.i18n?.get('email')}`, width: 119, style: ['greyHeading']},
        ],
      ],
    };

    let index = 2;
    const indexesWithHLine: number[] = [];
    for (const unitProfileAddress of unitProfileAddresses) {
      const profileAddress = {
        profile: unitProfileAddress.profile,
        address: unitProfileAddress.address,
      };
      tblParticipant.body.push([
        {text: `${this.sanitizeValue(unitProfileAddress.address?.firstName)} ${this.sanitizeValue(unitProfileAddress.address?.lastName)} `, style: ['font9']},
        {text: `${this.sanitizeValue(unitProfileAddress.unit.breadcrumbsName)}`, style: ['font9']},
        {text: `${this.getParticipantPhone(profileAddress)}`, style: ['font9']},
        {text: `${this.getParticipantEmail(profileAddress)}`, link: `mailto: ${this.getParticipantEmail(profileAddress)}`, style: ['font9']},
      ]);
      index++;
      indexesWithHLine.push(index);
    }

    const tableLayoutParticipant: TableLayout = {
      hLineWidth: function (i: number, node: ContentTable) {
        if (indexesWithHLine.includes(i)) {
          return 0.5;
        }
        return i <= 2 || i === node.table.body.length ? 0.5 : 0;
      },
      hLineColor: function (i: number, node: ContentTable) {
        if (i === node.table.body.length) {
          return '#4F5964';
        }
        if (indexesWithHLine.includes(i)) {
          return '#B0BAC5';
        }
        return '#4F5964';
      },
      vLineWidth: function (i: number, node: ContentTable) {
        return i === 0 || i === node.table.widths?.length ? 0.5 : 0;
      },
      vLineColor() {
        return '#4F5964';
      },
      paddingLeft() {
        return 4;
      },
      paddingRight() {
        return 4;
      },
      paddingTop() {
        return 4;
      },
      paddingBottom() {
        return 4;
      },
    };
    content.push({
      layout: tableLayoutParticipant,
      table: tblParticipant,
      margin: [0, 10, 0, 0],
    });
  }

  protected getParticipantPhone(participantProfile: Pick<ParticipantsProfile, 'profile' | 'address'>): string {
    if (participantProfile?.profile?.dsgvoShowTel && !_.isEmpty(participantProfile?.address?.mobile)) {
      return participantProfile?.address?.mobile + '';
    } else if (participantProfile?.profile?.dsgvoShowTel && !_.isEmpty(participantProfile?.address?.phone)) {
      return participantProfile?.address?.phone + '';
    }
    return '-';
  }

  protected getParticipantEmail(participantProfile: Pick<ParticipantsProfile, 'profile' | 'address'>): string {
    if (participantProfile?.profile?.dsgvoShowEmail && !_.isEmpty(participantProfile?.address?.email)) {
      return participantProfile?.address?.email + '';
    }
    return '-';
  }

  protected writeParticipantsHeader(content: Content[]) {
    content.push({
      style: ['marginTop10'],
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.participants, this.config.pdfProtocolSetting?.protocolColor + ''),
              fit: [12, 12],
            },
          ],
          width: 'auto',
        },
        {
          text: `${this.i18n?.get('participants')}`,
          width: '*',
          style: ['font9', 'marginLeft10', 'protocolFontColor'],
        },
      ],
    });
    this.writeLine(content);
  }

  protected sortParticipantsProfiles(participantsProfiles: ParticipantsProfile[]): ParticipantsProfile[] {
    const sortAddressName = (participantProfile: ParticipantsProfile) => `${participantProfile.address?.firstName} ${participantProfile.address?.lastName}`;
    return _.orderBy(participantsProfiles, sortAddressName, ['asc']);
  }

  protected getCompanyWithSelectedParticipants(): CompanyWithParticipants[] {
    const companyWithParticipants = this.config.pdfProtocolSetting?.showAllContactsInProject ? this.getAllParticipants() : this.getSelectedParticipants();

    return _.orderBy(companyWithParticipants, ['companyOrder', lowerCaseCompanyNameIdentity], ['asc', 'asc']);
  }

  private getAllParticipants(): CompanyWithParticipants[] {
    const companyWithParticipants: CompanyWithParticipants[] = [];

    for (const [, projectCompany] of this.data.lookup.projectCompanies.entries()) {
      if (this.data.lookup.companies.has(projectCompany.companyId)) {
        const company = this.data.lookup.companies.get(projectCompany.companyId);
        if (company !== undefined) {
          if (company.isActive === false) {
            continue;
          }
          const profiles = this.getProjectProfilesByCompanyId(company.id);
          for (const currentProfile of profiles) {
            let companyForProfile = company;
            let profile = currentProfile;
            if (profile.isActive === false) {
              continue;
            }
            const participant = this.data.participants.find((record) => record.profileId === profile.id);
            const participantsProfile = this.getParticipantsProfile(profile, participant);
            const isUnitContact = profile.type === 'UNIT_CONTACT';
            if (isUnitContact) {
              if (!participant?.present && !participant?.mailingList) {
                continue;
              }
              companyForProfile = this.getOrCreateUnitCompanyFromLookupCompanies();
              profile = {
                ...currentProfile,
                companyId: companyForProfile.id,
              };
            }
            const companyWithParticipantsIndex = _.findIndex(companyWithParticipants, (companyWithParticipant) => companyWithParticipant.company.id === companyForProfile?.id);
            if (companyWithParticipantsIndex !== -1) {
              companyWithParticipants[companyWithParticipantsIndex].participants.push(participantsProfile);
            } else if (companyWithParticipantsIndex === -1) {
              companyWithParticipants.push({
                companyName: companyForProfile.name,
                company: companyForProfile,
                companyOrder: projectCompany.sortOrder,
                participants: [participantsProfile],
              });
            }
          }
        }
      }
    }
    return companyWithParticipants;
  }

  protected getProjectProfilesByCompanyId(companyId: IdType): Profile[] {
    const profiles: Profile[] = [];
    for (const [profileId, profile] of this.data.lookup.profiles.entries()) {
      if (profile.isActive !== false && profile.companyId === companyId && this.data.lookup.projectProfiles.some((projectProfile) => projectProfile.profileId === profileId)) {
        profiles.push(profile);
      }
    }
    return profiles;
  }

  private getSelectedParticipants(): CompanyWithParticipants[] {
    const companyWithParticipants: CompanyWithParticipants[] = [];
    const projectCompanyByCompanyId = _.keyBy(Array.from(this.data.lookup.projectCompanies.values()), 'companyId');
    for (const participant of this.data.participants) {
      let profile = this.data.lookup.profiles.get(participant.profileId);
      if (profile?.isActive === false) {
        continue;
      }
      if (profile && (participant.mailingList || participant.present)) {
        let company = this.data.lookup.companies.get(profile.companyId);
        if (company?.isActive === false) {
          continue;
        }
        const isUnitContact = profile?.type === 'UNIT_CONTACT';
        if (isUnitContact) {
          profile = {...profile, companyId: UNIT_OWNER_TENANT_COMPANY_ID};
          company = this.getOrCreateUnitCompanyFromLookupCompanies();
        }
        const projectCompany = company ? projectCompanyByCompanyId[company.id] : undefined;
        const participantsProfile = this.getParticipantsProfile(profile, participant);
        const companyWithParticipantsIndex = _.findIndex(companyWithParticipants, (companyWithParticipant) => companyWithParticipant.company.id === company?.id);
        if (companyWithParticipantsIndex !== -1 && !this.isParticipantInList(participant, companyWithParticipants[companyWithParticipantsIndex].participants)) {
          companyWithParticipants[companyWithParticipantsIndex].participants.push(participantsProfile);
        } else if (company && companyWithParticipantsIndex === -1) {
          companyWithParticipants.push({
            companyName: company.name,
            company,
            companyOrder: projectCompany?.sortOrder,
            participants: [participantsProfile],
          });
        }
      }
    }
    return companyWithParticipants;
  }

  protected isParticipantInList(participant: Participant, participantList: ParticipantsProfile[]): boolean {
    return participantList.find((record) => record.profileId === participant.profileId) !== undefined;
  }

  protected getParticipantsProfile(profile: Profile, participant?: Participant): ParticipantsProfile {
    const client = this.data.lookup.clients.get(profile.clientId);
    const address = this.data.lookup.addresses.get(profile?.addressId || '');
    const clientProfileCraft = this.data.lookup.profileCrafts.find((profileCraft) => profileCraft.profileId === profile.id);
    const clientCraft = this.data.lookup.crafts.get(clientProfileCraft?.craftId || '');

    let participantsProfile: ParticipantsProfile;
    if (participant !== undefined) {
      participantsProfile = _.clone(participant) as ParticipantsProfile;
    } else {
      participantsProfile = {
        invited: false,
        mailingList: false,
        profileId: profile.id,
      } as ParticipantsProfile;
    }

    participantsProfile.profile = profile;
    participantsProfile.client = client;
    participantsProfile.address = address;
    participantsProfile.craft = clientCraft;

    return participantsProfile;
  }

  protected writeStartText(content: Content[]) {
    if (!_.isEmpty(this.config.pdfProtocolSetting?.pdfStartText)) {
      const pdfStartText = this.config.pdfProtocolSetting?.pdfStartText;
      if (isRichText(pdfStartText)) {
        content.push({
          text: '',
          style: ['font9', 'marginTop10'],
        });
        content.push(this.pdfHelperFunctions.convertHtmlToPdfmake(convertToRichText(pdfStartText), {fontSize: 9}));
      } else {
        content.push({
          text: `${this.config.pdfProtocolSetting?.pdfStartText}`,
          style: ['font9', 'marginTop10'],
        });
      }
    }
  }

  protected writeStartTextHeader(content: Content[]) {
    content.push({
      style: ['marginTop10'],
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.paper, this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
          width: 'auto',
        },
        {
          text: `${this.i18n?.get('start_text')}`,
          width: 'auto',
          style: ['font9', 'protocolFontColor', 'marginLeft10'],
        },
      ],
    });
    this.writeLine(content);
  }
}
