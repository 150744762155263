import {Injectable} from '@angular/core';
import {TasksPageService} from '../tasks/tasks-page.service';
import {Router} from '@angular/router';
import {EntryCardModel} from 'src/app/model/entry-card-model';
import {findPreviousTask} from 'src/app/utils/entry-utils';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {getProtocolEntryPagePath, getTaskPagePath} from 'src/app/utils/router-utils';
import {PosthogService} from '../posthog/posthog.service';
import {PopoverService, isPopoverDismissed} from '../ui/popover.service';
import {MoveEntryService} from './move-entry.service';
import {RemoveEntryService} from './remove-entry.service';
import {CreateEntryService} from '../protocol/create-entry.service';
import {CopyProtocolEntryComponent} from 'src/app/components/copy/copy-protocol-entry/copy-protocol-entry.component';
import {ProtocolEntryCreateComponent} from 'src/app/components/protocol/protocol-entry-create/protocol-entry-create.component';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {dismissOverlayOnBackButtonOrNavigation} from 'src/app/utils/overlay-utils';
import {LicenseType, IdAware, ProtocolEntry, Project, Protocol, ProtocolLayout, IdType, MAX_TASKS_SENDING, MAX_TASK_IMAGES_SENDING} from 'submodules/baumaster-v2-common';
import {FeatureEnabledService} from '../feature/feature-enabled.service';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolEntrySelectionService} from '../protocol/protocol-entry-selection.service';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDataService} from '../data/project-data.service';
import {SystemEventService} from '../event/system-event.service';
import {ToastService} from '../common/toast.service';
import {LoggingService} from '../common/logging.service';
import {ProtocolNavigationService} from '../protocol-navigation.service';
import {ProtocolEntryService} from '../protocol/protocol-entry.service';
import {NetworkStatusService} from '../common/network-status.service';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {EntryMailSendModalComponent} from 'src/app/components/entry/entry-mail-send-modal/entry-mail-send-modal.component';
import {ConvertEntryService} from './convert-entry.service';
import {compact} from 'lodash';
import {EntryEditNumberModalComponent} from 'src/app/components/entry/entry-edit-number-modal/entry-edit-number-modal/entry-edit-number-modal.component';

const LOG_SOURCE = 'EntryThreeDotsActionsService';

@Injectable({
  providedIn: 'root',
})
export class EntryThreeDotsActionsService {
  private isEditNumberModalOpened = false;
  constructor(
    private createEntryService: CreateEntryService,
    private popoverService: PopoverService,
    private posthogService: PosthogService,
    private moveEntryService: MoveEntryService,
    private removeEntryService: RemoveEntryService,
    private router: Router,
    private featureEnabledService: FeatureEnabledService,
    private modalController: ModalController,
    private protocolEntryDataService: ProtocolEntryDataService,
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private projectDataService: ProjectDataService,
    private systemEventService: SystemEventService,
    private toastService: ToastService,
    private platform: Platform,
    private loggingService: LoggingService,
    private protocolNavigationService: ProtocolNavigationService,
    private protocolEntryService: ProtocolEntryService,
    private networkStatusService: NetworkStatusService,
    private attachmentEntryDataService: AttachmentEntryDataService,
    private convertEntryService: ConvertEntryService
  ) {}

  async openSendTasksModal(entryIds: Array<IdType>) {
    if (!entryIds.length) {
      return;
    }
    if (entryIds.length > MAX_TASKS_SENDING) {
      await this.toastService.error(this.translateService.instant(`entryMailSend.maxTasksExceeded`, {tasks: entryIds.length, maxTasks: MAX_TASKS_SENDING}));
    }
    const attachments = await observableToPromise(this.attachmentEntryDataService.getByProtocolEntries(entryIds));
    if (attachments.length > MAX_TASK_IMAGES_SENDING) {
      await this.toastService.error(this.translateService.instant(`entryMailSend.maxTaskAttachmentsExceeded`, {attachments: attachments.length, maxAttachments: MAX_TASK_IMAGES_SENDING}));
      return;
    }
    const modal = await this.modalController.create({
      component: EntryMailSendModalComponent,
      keyboardClose: false,
      backdropDismiss: false,
      cssClass: 'pdf-workflow-modal',
      componentProps: {
        entryIds,
      },
    });
    await modal.present();
  }

  async entryActions(
    event: MouseEvent,
    entry: EntryCardModel,
    {
      protocol,
      protocolEntrySelectionService,
      protocolLayout,
    }: {
      protocol: Protocol;
      protocolEntrySelectionService: ProtocolEntrySelectionService;
      protocolLayout: ProtocolLayout;
    }
  ) {
    let isParentCarriedOver = false;
    if (entry.parentId) {
      const parentEntry = await observableToPromise(this.protocolEntryDataService.getProtocolEntryOrOpenById(entry.parentId));
      isParentCarriedOver = parentEntry.originalProtocolId && parentEntry.originalProtocolId !== parentEntry.protocolId;
    }
    const result = await this.popoverService.openActions(
      event,
      compact([
        {
          role: 'copy',
          label: 'copy',
          icon: ['fal', 'copy'],
        },
        entry.layout !== 'SHORT' && !entry.parentId
          ? {
              role: 'new_sub',
              label: 'Subentry',
              icon: ['fal', 'plus'],
              disabled: !!protocol.closedAt,
            }
          : undefined,
        {
          role: 'change_entry_number',
          label: 'changeEntryNumber.title',
          icon: ['fal', 'list-ol'],
          disabled: !!protocol.closedAt || (entry.layout === 'CONTINUOUS' ? entry.isCarriedOver : entry.protocolId !== protocol.id) || isParentCarriedOver,
        },
        !entry.parentId
          ? {
              role: 'move',
              label: 'move_to',
              disabled: !!protocol.closedAt || (entry.layout === 'CONTINUOUS' ? entry.isCarriedOver : entry.protocolId !== protocol.id),
              icon: ['fal', 'arrow-from-left'],
            }
          : undefined,
        !entry.parentId && entry.layout !== 'SHORT'
          ? {
              role: 'convert_to_sub',
              label: 'convertToMainOrSubEntry.toSub.buttonLabel',
              icon: ['fal', 'arrow-from-top'],
              disabled: !!protocol.closedAt || (entry.layout === 'CONTINUOUS' ? entry.isCarriedOver : entry.protocolId !== protocol.id),
            }
          : undefined,
        entry.parentId
          ? {
              role: 'convert_to_main',
              label: 'convertToMainOrSubEntry.toMain.buttonLabel',
              icon: ['fal', 'arrow-from-bottom'],
              disabled: !!protocol.closedAt || (entry.layout === 'CONTINUOUS' ? entry.isCarriedOver : entry.protocolId !== protocol.id),
            }
          : undefined,
        {
          role: 'delete',
          label: 'delete',
          itemClass: 'danger',
          icon: ['fal', 'trash-alt'],
          disabled: !!protocol.closedAt || (entry.layout === 'CONTINUOUS' ? entry.isCarriedOver : entry.protocolId !== protocol.id),
        },
      ])
    );

    if (!isPopoverDismissed(result)) {
      switch (result) {
        case 'copy':
          await this.copyEntry(entry, protocol, protocolLayout);
          break;
        case 'move':
          await this.moveEntry(entry, protocol, protocolEntrySelectionService);
          break;
        case 'new_sub':
          await this.newSubEntry(entry, protocol);
          break;
        case 'change_entry_number':
          await this.openEntryEditModal(!!protocol.closedAt, entry.id, entry.isCarriedOver);
          break;
        case 'delete':
          await this.deleteEntry(entry, protocol, protocolEntrySelectionService);
          break;
        case 'convert_to_sub':
          await this.convertEntryService.convertToSubEntryWithConfirm(protocol.id, entry.id);
          break;
        case 'convert_to_main':
          await this.convertEntryService.convertToMainEntryWithConfirm(protocol.id, entry.id);
          break;
      }
    }
  }

  private async openEntryEditModal(isClosed: boolean, protocolEntryId: string, isCarriedEntry: boolean) {
    if (!(await this.featureEnabledService.isFeatureEnabledOrToast(false, true, [LicenseType.VIEWER]))) {
      return;
    }
    if (this.isEditNumberModalOpened) {
      return;
    }
    try {
      this.isEditNumberModalOpened = true;
      const modal = await this.modalController.create({
        component: EntryEditNumberModalComponent,
        canDismiss: true,
        backdropDismiss: false,
        componentProps: {
          isProtocolClosed: isClosed,
          entryId: protocolEntryId,
          isCarriedOver: isCarriedEntry,
          clickedFrom: 'three-dots',
        },
      });
      await modal.present();
      await modal.onDidDismiss();
    } finally {
      this.isEditNumberModalOpened = false;
    }
  }

  private async newSubEntry(entry: EntryCardModel, protocol: Protocol) {
    if (!(await this.featureEnabledService.isFeatureEnabled(false, true, [LicenseType.VIEWER]))) {
      return;
    }
    const modal = await this.modalController.create({
      component: ProtocolEntryCreateComponent,
      keyboardClose: false,
      backdropDismiss: false,
      componentProps: {
        protocolId: entry.originalProtocolId ?? entry.protocolId,
        createdInProtocolId: entry.isCarriedOver ? protocol.id : undefined,
        expressView: true,
        parentEntryId: entry.id,
        navigateOnSuccess: true, // TODO: ?
        onlyActionableEntryTypes: false,
        typeRequired: false,
        isTask: false,
        parentIsCarriedOver: entry.isCarriedOver,
      },
    });
    await modal.present();
    this.posthogService.captureEvent('[Protocols][Entry] Add Subentry', {
      button: 'threeDotsMenu',
    });
  }

  private async moveEntry(entry: EntryCardModel, protocol: Protocol, protocolEntrySelectionService: ProtocolEntrySelectionService) {
    await this.moveEntries([entry], protocol, protocolEntrySelectionService);
  }

  private getRedirectionPath(containsActive: boolean, protocol: Protocol, protocolEntrySelectionService: ProtocolEntrySelectionService): string[] | null {
    const firstEntryPath = getProtocolEntryPagePath(protocol.id, 'first');

    if (protocolEntrySelectionService.hasAllSelected) {
      return firstEntryPath;
    }

    if (containsActive) {
      return firstEntryPath;
    }

    // Stay on the same page
    return null;
  }

  private async moveEntries(entries: IdAware[], protocol: Protocol, protocolEntrySelectionService: ProtocolEntrySelectionService) {
    const currentEntry = await observableToPromise(this.protocolEntryDataService.currentProtocolEntryObservable);
    const isCurrentEntryInSelected = entries.some((entry) => currentEntry?.protocolEntry?.id === entry.id);
    const redirectPath = this.getRedirectionPath(isCurrentEntryInSelected, protocol, protocolEntrySelectionService);

    const moved = await this.moveEntryService.moveProtocolEntries(
      entries.map((entry) => entry.id),
      protocol
    );

    if (moved) {
      if (redirectPath) {
        await this.router.navigate(redirectPath, {
          state: {
            protocolListShowActive: true,
          },
        });
      }
    }

    return moved;
  }

  private async deleteEntry(entry: EntryCardModel, protocol: Protocol, protocolEntrySelectionService: ProtocolEntrySelectionService) {
    const project = await observableToPromise(this.projectDataService.getByIdAcrossClients(protocol.projectId));
    if (!project) {
      throw new Error(`Project ${protocol.projectId} not found`);
    }
    const protocolEntry = await observableToPromise(this.protocolEntryDataService.getByIdAcrossProjects(entry.id));
    if (!protocolEntry) {
      throw new Error(`Entry ${entry.id} not found`);
    }

    const alert = await this.alertCtrl.create({
      header: entry.parentId ? this.translateService.instant('alert.deleteProtocolSubEntry.header') : this.translateService.instant('alert.deleteProtocolEntry.header'),
      message: entry.parentId ? this.translateService.instant('alert.deleteProtocolSubEntry.message') : this.translateService.instant('alert.deleteProtocolEntry.message'),
      buttons: [
        {
          text: this.translateService.instant('cancel'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('delete_permanently'),
          cssClass: ['ion-color-danger', 'alert-button-solid'],
          handler: async () => {
            const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Remove protocol entry (id=${entry?.id}, type=${entry?.typeId || '_empty_'})`);
            try {
              await this.performEntryDeletion(entry, protocolEntry, project, protocol, protocolEntrySelectionService);
              logInstance.success();
            } catch (e) {
              logInstance.failure(e);
              await this.toastService.errorWithMessageAndHeader('error_deleting_message', convertErrorToMessage(e));
            }
          },
        },
      ],
    });
    dismissOverlayOnBackButtonOrNavigation(alert, this.router, this.platform);
    await alert.present();
  }

  private async performEntryDeletion(entry: EntryCardModel, protocolEntry: ProtocolEntry, project: Project, protocol: Protocol, protocolEntrySelectionService: ProtocolEntrySelectionService) {
    this.loggingService.debug(LOG_SOURCE, 'Removing protocolEntry');

    const currentEntry = await observableToPromise(this.protocolEntryDataService.currentProtocolEntryObservable);
    const isCurrentEntryInSelected = currentEntry?.protocolEntry?.id === entry.id;
    const redirectPath = this.getRedirectionPath(isCurrentEntryInSelected, protocol, protocolEntrySelectionService);

    if (isCurrentEntryInSelected) {
      this.protocolNavigationService.cancelWatchProtocolEntryDeleted(currentEntry?.protocolEntry.id);
    }
    await this.protocolEntryService.deleteProtocolEntry(protocolEntry, project.id);
    this.loggingService.info(LOG_SOURCE, 'Removing of protocolEntry was successful');
    await this.protocolEntryService.checkAndHandleGapsBetweenProtocolEntries('fillGapsEntries.actions.delete', protocolEntry.protocolId);
    try {
      const isNetworkConnected = this.networkStatusService.onlineOrUnknown;
      this.posthogService.captureEvent('[Protocols][Entry] Delete', {
        type: entry.taskNumber !== undefined ? 'task' : 'entry',
        editedOffline: !isNetworkConnected,
      });
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error capturing posthog event "${error?.userMessage}" - "${error?.message}"`);
    }

    if (redirectPath) {
      this.router.navigate(redirectPath, {
        state: {
          protocolListShowActive: true,
        },
      });
    }
  }

  private async copyEntry(entry: EntryCardModel, protocol: Protocol, protocolLayout: ProtocolLayout) {
    const modal = await this.modalController.create({
      component: CopyProtocolEntryComponent,
      keyboardClose: false,
      backdropDismiss: true,
      componentProps: {
        protocolId: protocol.id,
        protocolEntry: await observableToPromise(this.protocolEntryDataService.getById(entry.id)),
        currentProtocolLayout: protocolLayout,
      },
    });
    await modal.present();
  }

  async taskActions(event: MouseEvent, entry: EntryCardModel, tasksPageService: TasksPageService) {
    const result = await this.popoverService.openActions(
      event,
      compact([
        {
          role: 'move',
          label: 'tasks.toolbar.actions.assignToProtocol',
          icon: ['fal', 'arrow-from-left'],
        },
        !entry.parentId
          ? {
              role: 'new_sub',
              label: 'tasks.subtask',
              icon: ['fal', 'plus'],
            }
          : undefined,
        !entry.parentId
          ? {
              role: 'convert_to_sub',
              label: 'convertToMainOrSubTask.toSub.buttonLabel',
              icon: ['fal', 'arrow-from-top'],
            }
          : undefined,
        entry.parentId
          ? {
              role: 'convert_to_main',
              label: 'convertToMainOrSubTask.toMain.buttonLabel',
              icon: ['fal', 'arrow-from-bottom'],
            }
          : undefined,
        {
          role: 'send_email',
          label: 'send',
          icon: ['fal', 'paper-plane'],
        },
        {
          role: 'delete',
          label: 'delete',
          itemClass: 'danger',
          icon: ['fal', 'trash-alt'],
        },
      ])
    );

    if (!isPopoverDismissed(result)) {
      const getTaskProtocolId = async () => {
        const protocolId = await observableToPromise(tasksPageService.taskProtocolId$);
        if (!protocolId) {
          throw new Error('Task protocol ID not found');
        }

        return protocolId;
      };
      switch (result) {
        case 'move':
          await this.moveTask(entry);
          break;
        case 'new_sub':
          await this.newSubTask(entry);
          break;
        case 'delete':
          await this.deleteTask(entry, tasksPageService);
          break;
        case 'send_email':
          await this.openSendTasksModal([entry.id]);
          break;
        case 'convert_to_sub':
          await this.convertEntryService.convertToSubEntryWithConfirm(await getTaskProtocolId(), entry.id);
          break;
        case 'convert_to_main':
          await this.convertEntryService.convertToMainEntryWithConfirm(await getTaskProtocolId(), entry.id);
          break;
      }
    }
  }

  private async newSubTask(entry: EntryCardModel) {
    await this.createEntryService.createTask({entryCreateComponentProps: {parentEntryId: entry.id}});
    this.posthogService.captureEvent('[Tasks] Add Subentry', {
      button: 'threeDotsMenu',
    });
  }

  private async moveTask(entry: EntryCardModel) {
    await this.moveEntryService.moveTasks([entry.id]);
  }

  private async deleteTask(entry: EntryCardModel, tasksPageService: TasksPageService) {
    const currentTaskIdBeforeDeleting = await observableToPromise(tasksPageService.currentTaskId$);
    const tasksBeforeDeleting = await observableToPromise(tasksPageService.entriesFiltered$);
    await this.removeEntryService.deleteEntries([entry.id]);
    const tasks = await observableToPromise(tasksPageService.entriesFiltered$);
    if (!tasks.length) {
      this.router.navigate(['/tasks', 'card']);
      return;
    }
    if (tasks.length) {
      if (tasks.some((task) => task.id === currentTaskIdBeforeDeleting)) {
        return; // current task was not deleted. No need to navigate
      }
      const taskToNavigateTo = findPreviousTask(tasks, currentTaskIdBeforeDeleting, tasksBeforeDeleting);
      if (taskToNavigateTo) {
        this.router.navigate(getTaskPagePath('', taskToNavigateTo.id));
      } else {
        this.router.navigate(['/tasks', 'card']);
      }
    }
  }
}
