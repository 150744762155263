import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectorRef, Component, HostBinding, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AlertController, IonSearchbar, LoadingController, ModalController, NavParams, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {from, Observable, of, Subscription} from 'rxjs';
import {map, switchMap, takeWhile} from 'rxjs/operators';
import {EmptyUserEmailSignature} from 'src/app/model/email-signature-form-model';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {ProfileDataService} from 'src/app/services/data/profile-data.service';
import {UserEmailSignatureDataService} from 'src/app/services/data/user-email-signature-data.service';
import {PdfProtocolSettingService} from 'src/app/services/pdf/pdf-protocol-setting.service';
import {ProtocolEntrySearchFilterService} from 'src/app/services/search/protocol-entry-search-filter.service';
import {ProtocolEntrySearchFilteredDataService} from 'src/app/services/search/protocol-entry-search-filtered-data.service';
import {UserService} from 'src/app/services/user/user.service';
import {AttachmentKind, convertErrorToMessage, UnprocessableImageForPdfGenerationError} from 'src/app/shared/errors';
import {protocolShortIdWithDeps} from 'src/app/utils/protocol-entry-utils';
import {
  Address,
  Attachment,
  AttachmentReportSignature,
  convertOptionalProjectNumberToString,
  convertRichTextToPlainText,
  ErrorCodeAuthenticationType,
  formatProjectNumberOptional,
  IdType,
  isRichText,
  Participant,
  PdfProtocolSetting,
  PdfReportSendReq,
  PdfReportSetting,
  Profile,
  Protocol,
  ProtocolEntry,
  Report,
  ReportTypeCode,
  SendPdfProtocolOption,
  UserEmailSignature,
} from 'submodules/baumaster-v2-common';
import {v4 as uuid4} from 'uuid';
import {ProtocolEntrySearchFilter} from '../../../model/protocol-entry-search-filter';
import {ReportMonthGroup, ReportWeekGroup} from '../../../model/report-group';
import {PdfMailingListCompany, PdfMailingListEmployee, PdfWorkflowStepConfig, SendPdfWorkflowStep, Signer, SignerWithSignature, WorkflowType} from '../../../model/send-protocol';
import {LoggingService} from '../../../services/common/logging.service';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {ToastService} from '../../../services/common/toast.service';
import {AttachmentReportSignatureDataService} from '../../../services/data/attachment-report-signature-data.service';
import {ParticipantDataService} from '../../../services/data/participant-data.service';
import {PdfProtocolSettingDataService} from '../../../services/data/pdf-protocol-setting-data.service';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {ReportDataService} from '../../../services/data/report-data.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {PdfGlobalSearchService} from '../../../services/pdf/pdf-global-search.service';
import {NextMeetingDetails, PdfProtocolService} from '../../../services/pdf/pdf-protocol.service';
import {PdfReportService} from '../../../services/pdf/pdf-report.service';
import {PdfSettingsCache, PdfSettingsCacheService} from '../../../services/pdf/pdf-settings-cache.service';
import {PdfWorkflowService} from '../../../services/pdf/pdf-workflow.service';
import {ProtocolSignatureService} from '../../../services/protocol/protocol-signature.service';
import {ProtocolService} from '../../../services/protocol/protocol.service';
import {ReportService} from '../../../services/report/report.service';
import {SyncStatusService} from '../../../services/sync/sync-status.service';
import {SyncStrategy} from '../../../services/sync/sync-utils';
import {DataSyncResultStatus, SyncService} from '../../../services/sync/sync.service';
import {UserEmailSignatureService} from '../../../services/user/user-email-signature.service';
import {REG_SPECIAL} from '../../../shared/constants';
import {combineLatestAsync, observableToPromise, resolveWithMinimumTimeout} from '../../../utils/async-utils';
import {ManageCompanyOrderComponent} from '../../company/manage-company-order/manage-company-order.component';
import {ProjectProtocolAndEntry} from '../../search/search-results/search-results.component';
import {MissingAttachmentsComponent} from '../missing-attachments/missing-attachments.component';
import {NextMeetingComponent} from '../next-meeting/next-meeting.component';
import {IndividualNextMeetingForm, NextMeetingForm} from '../next-meeting/next-meeting.interface';
import {PdfAdvancedSettingsComponent} from '../pdf-advanced-settings/pdf-advanced-settings.component';
import {PdfConfigurationComponent} from '../pdf-configuration/pdf-configuration.component';
import {PdfMailingListComponent} from '../pdf-mailing-list/pdf-mailing-list.component';
import {PdfProtocolSignatureForm} from '../pdf-protocol-signatures/pdf-protocol-signatures.interface';
import {PdfViewerComponent} from '../pdf-viewer/pdf-viewer.component';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {AlertService} from 'src/app/services/ui/alert.service';
import {Translatable} from 'src/app/model/translatable';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {WithParamsTranslateService} from 'src/app/services/common/with-params-translate.service';
import {PdfReportSettingDataService} from 'src/app/services/data/pdf-report-setting-data.service';
import {ReportTypeDataService} from 'src/app/services/data/report-type-data.service';

const LOG_SOURCE = 'PdfWorkflowComponent';

@Component({
  selector: 'app-pdf-workflow',
  templateUrl: './pdf-workflow.component.html',
  styleUrls: ['./pdf-workflow.component.scss'],
})
export class PdfWorkflowComponent implements OnInit, OnDestroy {
  @HostBinding('class.omg-boundary') readonly omg = true;
  @ViewChild('appPdfMailingList') appPdfMailingList: PdfMailingListComponent;
  @ViewChild(PdfAdvancedSettingsComponent, {
    static: true,
  })
  pdfAdvancedSettingsComponent: PdfAdvancedSettingsComponent;
  @ViewChild(PdfConfigurationComponent, {
    static: false,
  })
  pdfConfigurationComponent: PdfConfigurationComponent;
  @ViewChild('autofocus', {static: false}) searchbar: IonSearchbar;
  private participants: Array<Participant> | undefined;
  private profilesById: Record<IdType, Profile> | undefined;
  private mailingListCompanies: Array<PdfMailingListCompany> | undefined;
  get nextMeetingComponent(): NextMeetingComponent | undefined {
    return this.pdfAdvancedSettingsComponent?.nextMeetingComponent;
  }
  public readonly signersPdfReport: Array<Signer> = [
    {code: 'client', name: this.translateService.instant('pdfReport.signature.signers.client')},
    {code: 'contractor', name: this.translateService.instant('pdfReport.signature.signers.contractor')},
  ];
  public protocol?: Protocol;
  public report?: Report;
  public reportGroup?: ReportWeekGroup | ReportMonthGroup;
  public reportGroupYear?: number;
  public reportGroupMonth?: number;
  public reportGroupWeekNumber?: number;
  public isContinuousProtocol = false;
  public hasCarriedOverEntries = false;
  public currentStep: SendPdfWorkflowStep;
  public currentWorkflowConfig: PdfWorkflowStepConfig;
  public pdfWorkflowStepConfigs: Array<PdfWorkflowStepConfig>;
  get currentStepIndex() {
    return Math.max(this.pdfWorkflowStepConfigs?.indexOf(this.currentWorkflowConfig) ?? 0, 0);
  }
  public syncInProgress = false;
  public loading = false;
  private changes = new Map<string, Participant>();
  public protocolConfigFormDirty = false;
  public emailSettingsFormDirty = false;
  public networkConnected$: Observable<boolean>;
  public allParticipants: Array<Participant>;

  private protocolConfigurationFormDefaultSetting: any;

  public protocolConfigurationForm: UntypedFormGroup;

  public emailSettingsForm: UntypedFormGroup;
  public closeProtocolAfterSend = false;
  private spinnerSyncInProgress: HTMLIonLoadingElement | undefined;
  public searchTextInput: string | undefined;
  public isMailingListNotEmpty: boolean;
  public hasMailingListUnitContacts: boolean | undefined;
  public mailingListUnitContactProfileIds: Array<IdType> | undefined;

  private localChanges: Set<IdType | undefined>;
  private localChangesSubscription: Subscription | undefined;
  private beforeSendPdfDataSyncStatusSubscription: Subscription | undefined;
  private syncedLocalChangesBeforeSend = false;
  private modal: HTMLIonModalElement;
  private modalDismissed: boolean | undefined;
  public pdfProtocolSettings: PdfProtocolSetting | undefined;
  private pdfProtocolSettingsSubscription: Subscription | undefined;
  private printFilteredEntriesOnlySubscription: Subscription | undefined;
  private userEmailSignatureSubscription: Subscription | undefined;
  private profilesSubscription: Subscription | undefined;

  public nextMeeting: NextMeetingForm | undefined;
  public individualNextMeetings: IndividualNextMeetingForm[] = [];
  public showProtocolSignaturesChecked: boolean | undefined;
  public showReportSignatureChecked = false;
  public showStaffTimeBlocksChecked = true;
  public appendActivityAttachments = true;
  public appendOtherAttachments = true;
  public appendMaterialAttachments = true;
  public appendEquipmentAttachments = true;
  public pdfProtocolSignatures: PdfProtocolSignatureForm[] | undefined;
  public pdfPreviewObjectUrl: string | undefined;
  private pdfSettingsCache: PdfSettingsCache | undefined;

  public tempFilteredProtocolEntries: ProtocolEntry[] = [];
  public filteredProtocolEntries: ProtocolEntry[] = [];

  public workflowType: WorkflowType;
  public WorkflowTypeEnum = WorkflowType;
  private globalSearchProtocolEntries: Array<ProjectProtocolAndEntry> | undefined;
  private globalSearchFilters: ProtocolEntrySearchFilter | undefined;
  private globalSearchProtocolEntriesSubscription: Subscription | undefined;
  private globalSearchFiltersSubscription: Subscription | undefined;
  private protocolSubscription: Subscription | undefined;
  private reportSubscription: Subscription | undefined;
  private signersWithSignature = new Array<SignerWithSignature>();
  public reportType$: Observable<ReportTypeCode> | undefined;
  private reportTypeSubscription: Subscription | undefined;
  private reportType: ReportTypeCode | undefined;
  private protocolConfigurationFormSubscription: Subscription | undefined;

  private pdfReportSetting: PdfReportSetting | undefined;
  private pdfReportSettingSubscription: Subscription | undefined;

  protocolTypeName?: string;

  recipientEmails$: Observable<string[]>;
  userEmailSignature?: UserEmailSignature;

  pendingSettingsSaving = false;

  workflowName$: Observable<string> | undefined;
  canCloseProtocol$: Observable<boolean> | undefined;

  private address$ = this.userProfileService.currentUserProfile$.pipe(switchMap((profile) => this.addressDataService.getById(profile.addressId)));

  currentProjectLanguage$ = this.projectDataService.currentProjectObservable.pipe(map((project) => project?.language));

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private projectDataService: ProjectDataService,
    private participantDataService: ParticipantDataService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private modalController: ModalController,
    private systemEventService: SystemEventService,
    private loggingService: LoggingService,
    private navParams: NavParams,
    private syncService: SyncService,
    private reportService: ReportService,
    private networkStatusService: NetworkStatusService,
    private syncStatusService: SyncStatusService,
    private loadingController: LoadingController,
    private pdfProtocolService: PdfProtocolService,
    private pdfProtocolSettingDataService: PdfProtocolSettingDataService,
    private pdfProtocolSettingService: PdfProtocolSettingService,
    private pdfSettingsCacheService: PdfSettingsCacheService,
    private protocolService: ProtocolService,
    private pdfGlobalSearchService: PdfGlobalSearchService,
    private protocolEntrySearchFilterService: ProtocolEntrySearchFilterService,
    private protocolEntrySearchFilteredDataService: ProtocolEntrySearchFilteredDataService,
    private pdfWorkflowService: PdfWorkflowService,
    private pdfReportService: PdfReportService,
    private reportDataService: ReportDataService,
    private attachmentReportSignatureDataService: AttachmentReportSignatureDataService,
    private toastService: ToastService,
    private protocolSignatureService: ProtocolSignatureService,
    private userEmailSignatureDataService: UserEmailSignatureDataService,
    private platform: Platform,
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private userEmailSignatureService: UserEmailSignatureService,
    private posthogService: PosthogService,
    private alertService: AlertService,
    private userProfileService: UserProfileService,
    private withParamsTranslateService: WithParamsTranslateService,
    private pdfReportSettingDataService: PdfReportSettingDataService,
    private reportTypeDataService: ReportTypeDataService
  ) {}

  ionViewDidEnter() {
    if (this.platform.is('desktop')) {
      this.searchbar?.setFocus();
    }
  }

  ngOnDestroy(): void {
    this.localChangesSubscription?.unsubscribe();
    this.localChangesSubscription = undefined;
    this.beforeSendPdfDataSyncStatusSubscription?.unsubscribe();
    this.beforeSendPdfDataSyncStatusSubscription = undefined;
    this.pdfProtocolSettingsSubscription?.unsubscribe();
    this.pdfProtocolSettingsSubscription = undefined;
    this.printFilteredEntriesOnlySubscription?.unsubscribe();
    this.printFilteredEntriesOnlySubscription = undefined;
    this.globalSearchProtocolEntriesSubscription?.unsubscribe();
    this.globalSearchProtocolEntriesSubscription = undefined;
    this.globalSearchFiltersSubscription?.unsubscribe();
    this.globalSearchFiltersSubscription = undefined;
    this.protocolSubscription?.unsubscribe();
    this.protocolSubscription = undefined;
    this.reportSubscription?.unsubscribe();
    this.reportSubscription = undefined;
    this.reportTypeSubscription?.unsubscribe();
    this.reportTypeSubscription = undefined;
    this.protocolConfigurationFormSubscription?.unsubscribe();
    this.protocolConfigurationFormSubscription = undefined;
    this.userEmailSignatureSubscription?.unsubscribe();
    this.userEmailSignatureSubscription = undefined;
    this.pdfReportSettingSubscription?.unsubscribe();
    this.pdfReportSettingSubscription = undefined;
    this.profilesSubscription?.unsubscribe();
    this.profilesSubscription = undefined;
  }

  async ngOnInit() {
    this.networkConnected$ = this.networkStatusService.onlineOrUnknown$;
    this.protocol = this.navParams.data.protocol;
    this.report = this.navParams.data.report;
    this.reportGroup = this.navParams.data.reportGroup;
    if (this.reportGroup?.reports?.length && !this.report) {
      // In case multiple reports should be sent, we still populate the report variable with the first report so everything works as expected.
      this.report = this.reportGroup?.reports[0];
      if (this.reportGroup?.reports.length === 1) {
        this.reportGroup = undefined; // if only one report is selected, we use the same workflow as we use for one report
      }
    }
    if (this.reportGroup) {
      this.reportGroupYear = this.reportGroup.year;
      this.reportGroupMonth = 'month' in this.reportGroup ? this.reportGroup.month : undefined;
      this.reportGroupWeekNumber = 'weekNumber' in this.reportGroup ? this.reportGroup.weekNumber : undefined;
    }
    this.workflowType = this.navParams.data.workflow === undefined ? WorkflowType.Protocol : this.navParams.data.workflow;
    this.profilesSubscription = this.profileDataService.dataGroupedById.subscribe((profilesById) => (this.profilesById = profilesById));
    this.recipientEmails$ = this.getParticipantsObservable().pipe(
      map((participants) => Array.from(new Map(participants.map((part) => [part.id, part])).values())),
      switchMap((participants) =>
        combineLatestAsync([this.profileDataService.data, this.addressDataService.dataGroupedById]).pipe(
          map(([profiles, addressesById]) => {
            const addressMap = new Map<IdType, Address>();

            profiles.forEach((profile) => addressMap.set(profile.id, addressesById[profile.addressId]));

            return addressMap;
          }),
          map((addressMap) =>
            participants
              .filter((participant) => participant.mailingList)
              .map((participant) => addressMap.get(participant.profileId)?.email)
              .filter((email) => email)
          )
        )
      )
    );
    if (this.protocol) {
      this.protocolSubscription = this.protocolService.getProtocolById$(this.protocol.id).subscribe((protocol) => {
        if (protocol?.changedAt !== this.protocol?.changedAt) {
          this.protocol = protocol;
        }
      });
    }
    if (this.protocol && this.workflowType === WorkflowType.Protocol) {
      this.canCloseProtocol$ = this.pdfProtocolService.canCloseProtocol$(this.protocol.id);
    } else {
      this.canCloseProtocol$ = of(true);
    }
    if (this.report) {
      this.reportType$ = this.reportService.getReportTypeCode(this.report);
      this.reportTypeSubscription?.unsubscribe();
      this.reportTypeSubscription = this.reportType$.subscribe((reportType) => {
        this.reportType = reportType;
        this.pdfWorkflowStepConfigs = this.pdfWorkflowService.getWorkflowConfigs(this.workflowType, this.reportType);
      });
      this.reportSubscription = this.reportDataService.getById(this.report.id).subscribe((report) => {
        if (report?.changedAt !== this.report?.changedAt) {
          this.report = report;
        }
      });
      if (this.reportGroup) {
        for (const report of this.reportGroup.reports) {
          this.deleteOldAttachmentReportSignatures(report.id); // no await here. Otherwise, the component will not be initialized.
        }
      } else {
        this.deleteOldAttachmentReportSignatures(this.report.id); // no await here. Otherwise, the component will not be initialized.
      }
    }
    this.pdfWorkflowStepConfigs = this.pdfWorkflowService.getWorkflowConfigs(this.workflowType, this.reportType);
    this.protocolConfigurationFormDefaultSetting =
      this.workflowType === WorkflowType.Protocol
        ? this.pdfProtocolSettingService.protocolConfigurationFormDefaultSetting
        : this.pdfProtocolSettingService.protocolConfigurationFormDefaultSettingGlobalSearch;
    this.protocolConfigurationForm = this.pdfProtocolSettingService.getEmptyConfigurationFormGroup(this.workflowType);
    this.protocolConfigurationFormSubscription = this.protocolConfigurationForm.valueChanges.subscribe((values) => {
      this.removeProtocolSignaturesIfAny();
    });
    this.emailSettingsForm = this.pdfProtocolSettingService.getEmptyEmailSettingsFormGroup();
    this.userEmailSignatureSubscription = this.userEmailSignatureDataService.dataReally.subscribe(([signature]) => {
      this.userEmailSignature = signature;
      if (signature && !this.emailSettingsForm.dirty) {
        const {id, changedAt, ...signatureValues} = signature;
        this.emailSettingsForm.get('emailSignature').setValue(signatureValues);
      }
    });
    this.filteredProtocolEntries = !_.isEmpty(this.navParams.data.filteredProtocolEntries) ? this.navParams.data.filteredProtocolEntries : [];
    if (!this.currentStep) {
      this.currentStep = SendPdfWorkflowStep.MAILING_LIST;
      this.currentWorkflowConfig = this.getCurrentWorkflowConfig();
    }
    if (this.workflowType === WorkflowType.GlobalSearch) {
      this.globalSearchProtocolEntriesSubscription = this.protocolEntrySearchFilteredDataService.protocolEntries$.subscribe((protocolEntries) => (this.globalSearchProtocolEntries = protocolEntries));
      this.globalSearchFiltersSubscription = this.protocolEntrySearchFilterService.filters$.subscribe((filters) => (this.globalSearchFilters = filters));
      await this.syncCurrentProject();
      await this.participantDataService.deleteGlobalSearchForCurrentProject();
    }
    this.localChangesSubscription = this.syncStatusService.clientOrProjectsWithLocalChanges$.subscribe((data) => (this.localChanges = data));

    if (this.report && this.reportType) {
      this.pdfReportSettingSubscription = this.reportTypeDataService
        .getByName(this.reportType)
        .pipe(switchMap((reportType) => this.pdfReportSettingDataService.getByReportTypeId(reportType?.id)))
        .subscribe(async (data) => {
          this.pdfReportSetting = data;
          this.applyDataToAdvancedSettingsReportSettings();
          await this.applyDataToEmailSettings();
        });
    }

    if (this.protocol) {
      this.protocolTypeName = (await this.protocolService.getProtocolTypeById(this.protocol.typeId))?.name;
      this.pdfSettingsCache = this.pdfSettingsCacheService.get(this.protocol.id);
      this.nextMeeting = this.pdfSettingsCache?.nextMeeting || undefined;
      this.individualNextMeetings = this.pdfSettingsCache?.individualNextMeetings || [];
      this.pdfProtocolSettingsSubscription = this.pdfProtocolSettingDataService.getByProtocolTypeId(this.protocol.typeId).subscribe(async (data) => {
        this.pdfProtocolSettings = data;
        if (this.pdfSettingsCache?.protocolConfigForm) {
          this.protocolConfigurationForm = this.pdfSettingsCache.protocolConfigForm;
        } else {
          this.applyDataToProtocolConfiguration();
        }
        if (this.pdfSettingsCache?.emailSettingsForm) {
          this.emailSettingsForm = this.pdfSettingsCache.emailSettingsForm;
        } else {
          await this.applyDataToEmailSettings(true);
        }
      });
      this.isContinuousProtocol = await this.protocolService.isContinuousProtocol(this.protocol);
      this.hasCarriedOverEntries = await this.protocolService.hasCarriedOverEntries(this.protocol);
    } else {
      this.isContinuousProtocol = false;
      this.hasCarriedOverEntries = false;
    }
    this.setCanDismiss();
    this.printFilteredEntriesOnlySubscription = this.protocolConfigurationForm.get('printFilteredEntriesOnly').valueChanges.subscribe((filteredEntries) => {
      if (!filteredEntries) {
        this.filteredProtocolEntries = this.tempFilteredProtocolEntries;
      } else {
        this.tempFilteredProtocolEntries = this.filteredProtocolEntries;
        this.filteredProtocolEntries = [];
      }
    });

    if (this.workflowType === WorkflowType.Protocol && this.protocol) {
      await this.protocolSignatureService.cleanupAttachmentProtocolSignaturesForProtocol(this.protocol.id);
    }

    if (this.workflowType === WorkflowType.GlobalSearch || this.workflowType === WorkflowType.Protocol) {
      if (this.workflowType === WorkflowType.Protocol && this.protocol) {
        this.workflowName$ = this.translateService
          .get('sendProtocol.title')
          .pipe(
            switchMap((title) => from(this.protocolService.getProtocolTypeById(this.protocol.typeId)).pipe(map((type) => `${title} "${protocolShortIdWithDeps(this.locale, type, this.protocol)}"`)))
          );
      } else {
        this.workflowName$ = this.translateService.get('sendProtocol.title');
      }
    }

    if (this.workflowType === WorkflowType.Report && this.reportType$) {
      this.workflowName$ = this.reportType$.pipe(switchMap((type) => this.translateService.get(`pdfReport.${type}.title`)));
    }
  }

  private getParticipantsObservable(): Observable<Array<Participant>> {
    switch (this.workflowType) {
      case WorkflowType.GlobalSearch:
        return this.participantDataService.getForGlobalSearchCurrentProject();
      case WorkflowType.Report:
        return this.participantDataService.getByReportId(this.report.id);
      case WorkflowType.Protocol:
        return this.participantDataService.getByProtocolId(this.protocol.id);
      default:
        throw new Error(`Unsupported workflowType ${this.workflowType}`);
    }
  }

  applyDataToProtocolConfiguration() {
    if (this.pdfProtocolSettings) {
      this.pdfProtocolSettingService.fillConfigurationFormGroupFromProtocolSetting(this.workflowType, this.pdfProtocolSettings, this.protocolConfigurationForm);
    } else {
      this.protocolConfigurationForm.reset(this.protocolConfigurationFormDefaultSetting);
    }
  }

  private async applyFallbackTextsToEmailSettings() {
    if (this.workflowType !== WorkflowType.Protocol) {
      return;
    }
    const currentProject = await this.projectDataService.getCurrentProject();
    this.emailSettingsForm.get('subject').setValue(
      await observableToPromise(
        this.withParamsTranslateService.translateInLanguage(
          {
            key: 'sendProtocol.emailSettings.pdfProtocolFallbackTexts.subject',
            params: {projectName: `${formatProjectNumberOptional(currentProject?.number)} ${currentProject?.name}`},
          },
          currentProject.language
        )
      ),
      {onlySelf: true}
    );
    this.emailSettingsForm.get('text').setValue(
      await observableToPromise(
        this.withParamsTranslateService.translateInLanguage(
          {
            key: 'sendProtocol.emailSettings.pdfProtocolFallbackTexts.text',
            params: {projectName: `${formatProjectNumberOptional(currentProject?.number)} ${currentProject?.name}`, protocolName: this.protocol?.name},
          },
          currentProject.language
        )
      ),
      {onlySelf: true}
    );
    const address = await observableToPromise(this.address$);
    this.emailSettingsForm.get('mailTextUnderDownloadLink').setValue(
      this.pdfProtocolSettings?.mailTextUnderDownloadLink ??
        (await observableToPromise(
          this.withParamsTranslateService.translateInLanguage(
            {
              key: 'sendProtocol.emailSettings.pdfProtocolFallbackTexts.mailTextUnderDownloadLink',
              params: {userName: `${address?.firstName} ${address?.lastName}`},
            },
            currentProject.language
          )
        )),
      {onlySelf: true}
    );
  }

  async applyDataToEmailSettings(withIndividualProtocol = false) {
    if (this.pdfProtocolSettings) {
      this.emailSettingsForm.get('subject').setValue(this.pdfProtocolSettings.mailSubject, {onlySelf: true});
      this.emailSettingsForm
        .get('text')
        .setValue(isRichText(this.pdfProtocolSettings.mailText) ? convertRichTextToPlainText(this.pdfProtocolSettings.mailText) : this.pdfProtocolSettings.mailText, {onlySelf: true});
      this.emailSettingsForm.get('mailTextUnderDownloadLink').setValue(this.pdfProtocolSettings.mailTextUnderDownloadLink ?? '', {onlySelf: true});
      if (withIndividualProtocol) {
        this.emailSettingsForm.get('individualProtocol').setValue(this.pdfProtocolSettings.individualProtocol, {onlySelf: true});
      }
      this.emailSettingsForm.get('addSignature').setValue(this.pdfProtocolSettings?.addSignature ?? false, {onlySelf: true});
    } else if (this.pdfReportSetting) {
      this.emailSettingsForm.get('subject').setValue(this.pdfReportSetting.mailSubject, {onlySelf: true});
      this.emailSettingsForm
        .get('text')
        .setValue(isRichText(this.pdfReportSetting.mailText) ? convertRichTextToPlainText(this.pdfReportSetting.mailText) : this.pdfReportSetting.mailText, {onlySelf: true});
      this.emailSettingsForm.get('mailTextUnderDownloadLink').setValue(this.pdfReportSetting.mailTextUnderDownloadLink ?? '', {onlySelf: true});
      this.emailSettingsForm.get('addSignature').setValue(this.pdfReportSetting?.addSignature ?? false, {onlySelf: true});
    } else {
      await this.applyFallbackTextsToEmailSettings();
    }

    if (this.userEmailSignature) {
      const {id, changedAt, ...signatureValues} = this.userEmailSignature;
      this.emailSettingsForm.get('emailSignature').setValue(signatureValues, {onlySelf: true});
    } else {
      this.emailSettingsForm.get('emailSignature').setValue({...EmptyUserEmailSignature}, {onlySelf: true});
    }
  }

  public applyDataToAdvancedSettingsReportSettings() {
    this.showStaffTimeBlocksChecked = this.pdfReportSetting?.showStaffTimeBlocksChecked ?? true;
    this.appendActivityAttachments = this.pdfReportSetting?.appendActivityAttachments ?? true;
    this.appendOtherAttachments = this.pdfReportSetting?.appendOtherAttachments ?? true;
    this.appendMaterialAttachments = this.pdfReportSetting?.appendMaterialAttachments ?? true;
    this.appendEquipmentAttachments = this.pdfReportSetting?.appendEquipmentAttachments ?? true;
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'sent') {
      return true;
    }

    if (
      !(
        this.changes.size ||
        this.protocolConfigFormDirty ||
        this.emailSettingsFormDirty ||
        this.nextMeeting ||
        this.individualNextMeetings.length > 0 ||
        this.showProtocolSignaturesChecked ||
        this.emailSettingsForm.get('individualProtocol').dirty
      )
    ) {
      return true;
    }

    return this.confirmationBeforeLeave();
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
    this.modal.onDidDismiss().then(() => (this.modalDismissed = true));
  }

  async saveMailingList() {
    try {
      const changesAsArray: Array<Participant> = Array.from(this.changes.values());
      if (!changesAsArray.length) {
        return;
      }
      const currentProject = await this.projectDataService.getMandatoryCurrentProject();
      await this.participantDataService.insertOrUpdate(changesAsArray, currentProject.id);
      this.changes.clear();
      await this.toastService.savingSuccess();
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - save mailing list ', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error save mailing list. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.savingError();
      throw error;
    }
  }

  onRecipientChanged(employee: PdfMailingListEmployee) {
    this.changes.set(employee.participant.profileId, employee.participant);
    this.updateCanNavigateNext();
  }

  onPresentChanged(employee: PdfMailingListEmployee) {
    this.changes.set(employee.participant.profileId, employee.participant);
  }

  onSearchTextChanged() {
    if (this.appPdfMailingList) {
      this.appPdfMailingList.searchTextInput = this.searchTextInput;
      this.appPdfMailingList.searchEmployee();
    }
  }

  async onParticipantsLoaded(participants: Array<Participant>) {
    this.allParticipants = participants;
    if (this.workflowType === WorkflowType.Protocol && this.protocol) {
      if (!this.pdfProtocolSignatures) {
        this.pdfProtocolSignatures = await this.protocolSignatureService.createPdfProtocolSignaturesForParticipantsPresent(participants);
      } else {
        const {changed, filteredPdfProtocolSignatures} = this.protocolSignatureService.filterProtocolSignaturesNotParticipants(participants, this.pdfProtocolSignatures);
        if (changed) {
          this.pdfProtocolSignatures = filteredPdfProtocolSignatures;
        }
      }
    }
    this.updateCanNavigateNext();
  }

  onMailingLIstCompaniesChanged(mailingListCompanies: Array<PdfMailingListCompany>) {
    this.mailingListCompanies = mailingListCompanies;
    this.updateCanNavigateNext();
  }

  updateCanNavigateNext() {
    this.isMailingListNotEmpty = !!this.allParticipants.find((participant) => participant.mailingList) || !!Array.from(this.changes.values()).find((participant) => participant.mailingList);
    this.hasMailingListUnitContacts = !this.profilesById
      ? undefined
      : this.allParticipants.some((participant) => this.profilesById[participant.profileId]?.type === 'UNIT_CONTACT' && (participant.mailingList || participant.present));
    this.mailingListUnitContactProfileIds = !this.mailingListCompanies
      ? undefined
      : _.compact(_.flatten(this.mailingListCompanies.map((mailingListCompany) => mailingListCompany.unitContacts))).map((unitContact) => unitContact.profile?.id);
  }

  get protocolConfigurationFormDirty(): boolean {
    return this.protocolConfigFormDirty;
  }

  set protocolConfigurationFormDirty(newValue: boolean) {
    this.protocolConfigFormDirty = newValue;
  }

  get emailFormDirty(): boolean {
    return this.emailSettingsFormDirty;
  }

  set emailFormDirty(newValue: boolean) {
    this.emailSettingsFormDirty = newValue;
  }

  getCurrentWorkflowConfig(): PdfWorkflowStepConfig {
    const workflowConfig = this.pdfWorkflowStepConfigs.find((config) => config.currentStep === this.currentStep);
    if (!workflowConfig) {
      throw new Error('WorkflowConfig not found for the current workflow step ' + this.currentStep);
    }
    return workflowConfig;
  }

  async confirmationBeforeLeave() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('sendProtocol.cancelWorkflow.header'),
      message: this.translateService.instant('sendProtocol.cancelWorkflow.message'),
      buttons: [
        {
          text: this.translateService.instant('back'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('cancel'),
          role: 'dismiss',
          handler: () => {
            this.addToCache();
          },
        },
      ],
    });
    await alert.present();
    return (await alert.onWillDismiss()).role === 'dismiss';
  }

  async cancel() {
    this.modal.dismiss();
  }

  async next() {
    if (this.currentStep === SendPdfWorkflowStep.MAILING_LIST) {
      this.loading = true;
      try {
        await this.saveMailingList();
        if (this.protocol) {
          this.participants = await observableToPromise(this.participantDataService.getByProtocolId(this.protocol.id));
        } else if (this.report) {
          this.participants = await observableToPromise(this.participantDataService.getByReportId(this.report.id));
        }
      } finally {
        this.loading = false;
      }
      this.syncLocalChangesAndEnsureSameParticipants();
    }
    if (this.currentStep === SendPdfWorkflowStep.EMAIL_SETTINGS) {
      this.syncedLocalChangesBeforeSend = false;
    }
    const workflowConfig = this.getCurrentWorkflowConfig();
    this.currentStep = workflowConfig.nextStep;
    this.currentWorkflowConfig = this.getCurrentWorkflowConfig();
  }

  private async syncLocalChangesAndEnsureSameParticipants() {
    await this.syncLocalChanges();
    await this.ensureSameParticipants(this.participants);
  }

  private async ensureSameParticipants(participants: Array<Participant> | undefined): Promise<boolean> {
    if (!participants) {
      return true;
    }
    let participantsAfterSync: Participant[];
    if (this.protocol) {
      participantsAfterSync = await observableToPromise(this.participantDataService.getByProtocolId(this.protocol.id));
    } else if (this.report) {
      participantsAfterSync = await observableToPromise(this.participantDataService.getByReportId(this.report.id));
    } else {
      return true;
    }
    const filterByMailingListPresent = (values: Array<Participant>): Array<Participant> => values.filter((v) => v.mailingList || v.present);
    const participantsForCompare = filterByMailingListPresent(participants);
    const participantsAfterSyncForCompare = filterByMailingListPresent(participantsAfterSync);
    const compareByIdMailingListPresent = (p1: Participant, p2: Participant): boolean => p1.id === p2.id && p1.mailingList === p2.mailingList && p1.present === p2.present;
    const diff = _.xorWith(participantsForCompare, participantsAfterSyncForCompare, compareByIdMailingListPresent);
    if (!diff.length) {
      return true;
    }
    this.loggingService.warn(LOG_SOURCE, `PdfWorkflowComponent.syncLocalChangesAndEnsureSameParticipants - Participants have changed for protocol ${this.protocol?.id}`);
    this.systemEventService.logEvent('PdfWorkflowComponent.syncLocalChangesAndEnsureSameParticipants', () => `Participants have changed for protocol ${this.protocol?.id}`);
    if (!this.modal || this.modalDismissed) {
      return false;
    }
    const toMailingList = await this.alertService.confirm({
      message: 'sendProtocol.mailingList.alertMailingListChanged.message',
      confirmLabel: 'sendProtocol.mailingList.alertMailingListChanged.toMailingList',
      confirmButton: {
        fill: 'solid',
      },
      cancelLabel: 'sendProtocol.mailingList.alertMailingListChanged.ignore',
      cancelButton: {
        color: 'danger',
        fill: 'outline',
      },
    });
    if (toMailingList) {
      if (this.modal && !this.modalDismissed) {
        this.goToFirstStep();
      }
      return false;
    }
    this.participants = undefined; // User clicked cancel. Make sure error does not pop up again.
    return true;
  }

  async syncLocalChanges() {
    if (this.localChanges.size > 0 && !this.syncStatusService.dataSyncInProgress.inProgress) {
      await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    }
  }

  async syncLocalChangesBeforeSending() {
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    await this.spinnerSyncInProgress?.dismiss();
  }

  async syncCurrentProject() {
    const spinnerSyncInProgress = await this.loadingController.create({
      message: this.translateService.instant('sendProtocol.syncInProgress'),
    });
    try {
      await spinnerSyncInProgress.present();
      await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    } finally {
      await spinnerSyncInProgress.dismiss();
    }
  }

  back() {
    const workflowConfig = this.getCurrentWorkflowConfig();
    this.currentStep = workflowConfig.previousStep;
    if (this.currentStep === 'MAILING_LIST') {
      this.participants = undefined;
    }
    this.currentWorkflowConfig = this.getCurrentWorkflowConfig();
  }

  goToFirstStep() {
    this.currentStep = this.pdfWorkflowStepConfigs[0].currentStep;
    if (this.currentStep === 'MAILING_LIST') {
      this.participants = undefined;
    }
    this.currentWorkflowConfig = this.getCurrentWorkflowConfig();
  }

  async triggerSendPdf(beforeCallingSend?: () => Promise<void>) {
    if (this.syncStatusService.dataSyncInProgress.inProgress) {
      this.spinnerSyncInProgress = await this.loadingController.create({
        message: this.translateService.instant('sendProtocol.syncInProgress'),
      });
      await this.spinnerSyncInProgress.present();
      this.beforeSendPdfDataSyncStatusSubscription?.unsubscribe();
      this.beforeSendPdfDataSyncStatusSubscription = this.syncStatusService.dataSyncInProgressObservable
        .pipe(takeWhile((syncStatus) => !this.syncedLocalChangesBeforeSend))
        .subscribe(async (dataSyncStatus) => {
          if (!dataSyncStatus.inProgress && this.localChanges.size > 0) {
            this.syncedLocalChangesBeforeSend = true;
            await this.syncLocalChangesBeforeSending();
            if (await this.ensureSameParticipants(this.participants)) {
              await this.confirmIfAttachmentsNotUploadedAndSendPdf(beforeCallingSend);
            }
          } else if (!dataSyncStatus.inProgress && this.localChanges.size === 0) {
            this.syncedLocalChangesBeforeSend = true;
            await this.spinnerSyncInProgress.dismiss();
            if (await this.ensureSameParticipants(this.participants)) {
              await this.confirmIfAttachmentsNotUploadedAndSendPdf(beforeCallingSend);
            }
          }
        });
    } else {
      if (this.localChanges.size > 0) {
        this.spinnerSyncInProgress = await this.loadingController.create({
          message: this.translateService.instant('sendProtocol.syncInProgress'),
        });
        await this.spinnerSyncInProgress.present();
        await this.syncLocalChangesBeforeSending();
        if (await this.ensureSameParticipants(this.participants)) {
          await this.confirmIfAttachmentsNotUploadedAndSendPdf(beforeCallingSend);
        }
        return;
      }
      if (await this.ensureSameParticipants(this.participants)) {
        await this.sendPdf(beforeCallingSend);
      }
    }
  }

  checkCloseProtocol() {
    this.closeProtocolAfterSend = !this.closeProtocolAfterSend;
  }

  async sendPdfFromPdfViewer(modal: HTMLIonModalElement, closeProtocolAfterSend = false) {
    this.closeProtocolAfterSend = closeProtocolAfterSend;
    const beforeCallingSend = async (): Promise<void> => {
      await modal.dismiss();
    };
    await this.triggerSendPdf(beforeCallingSend);
  }

  async sendPdf(beforeCallingSend?: () => Promise<void>) {
    if (this.workflowType === WorkflowType.Protocol) {
      const protocolValidation = await observableToPromise(this.protocolService.isValidForSendPdfProtocol(this.protocol.id, this.filteredProtocolEntries));
      if (!protocolValidation.valid) {
        await this.showErrorMessage(protocolValidation);
        return;
      }
      if (!!this.protocol.closedAt) {
        await this.sendClosedProtocol(beforeCallingSend);
      } else if (this.closeProtocolAfterSend) {
        await this.closeAndSendPdf(beforeCallingSend);
      } else {
        await this.sendOnlyPdf(beforeCallingSend);
      }
    } else if (this.workflowType === WorkflowType.GlobalSearch) {
      await this.sendGlobalSearchPdf(beforeCallingSend);
    } else if (this.workflowType === WorkflowType.Report) {
      if (this.closeProtocolAfterSend) {
        await this.closeAndSendReportPdf(beforeCallingSend);
      } else {
        await this.sendReportPdf(beforeCallingSend);
      }
    } else {
      throw new Error(`WorkflowType ${this.workflowType} not supported.`);
    }
  }

  async sendClosedProtocol(beforeCallingSend?: () => Promise<void>) {
    await this.doSend(this.translateService.instant('pdfProtocol.alertMessage.sendClosedProtocol'), SendPdfProtocolOption.SEND_ONLY, beforeCallingSend);
  }

  async sendOnlyPdf(beforeCallingSend?: () => Promise<void>) {
    await this.doSend(this.translateService.instant('pdfProtocol.alertMessage.sendOnly'), SendPdfProtocolOption.SEND_ONLY, beforeCallingSend);
  }

  async closeAndSendPdf(beforeCallingSend?: () => Promise<void>) {
    await this.doSend(this.translateService.instant('pdfProtocol.alertMessage.closeAndSend'), SendPdfProtocolOption.CLOSE_AND_SEND, beforeCallingSend);
  }

  async sendGlobalSearchPdf(beforeCallingSend?: () => Promise<void>) {
    const filterStatus = {
      status: !_.isEmpty(this.globalSearchFilters.entry.status.in),
      craft: !_.isEmpty(this.globalSearchFilters.entry.craftId.in),
      company: !_.isEmpty(this.globalSearchFilters.entry.companyId.in),
      allCompanies: !_.isEmpty(this.globalSearchFilters.entry.allCompanies.in),
      observer: !_.isEmpty(this.globalSearchFilters.entry.observerCompanies.in),
      responsible: !_.isEmpty(this.globalSearchFilters.entry.internalAssignmentId.in),
      type: !_.isEmpty(this.globalSearchFilters.entry.typeId.in),
      location: !_.isEmpty(this.globalSearchFilters.entry.locationId.in),
      customField: !_.isEmpty(this.globalSearchFilters.entry.nameableDropdownId.in),
      priority: !_.isEmpty(this.globalSearchFilters.entry.priority.in),
      fromDateCreated: !_.isNull(this.globalSearchFilters.entry.createdAt.gte),
      toDateCreated: !_.isNull(this.globalSearchFilters.entry.createdAt.lte),
      project: !_.isEmpty(this.globalSearchFilters.project.in),
      protocolType: !_.isEmpty(this.globalSearchFilters.protocol.typeId.in),
      protocols: !_.isEmpty(this.globalSearchFilters.protocol.id.in),
      fromStartDate: !_.isNull(this.globalSearchFilters.entry.startDate.gte),
      toStartDate: !_.isNull(this.globalSearchFilters.entry.startDate.lte),
      fromTodoUntil: !_.isNull(this.globalSearchFilters.entry.todoUntil.gte),
      toTodoUntil: !_.isNull(this.globalSearchFilters.entry.todoUntil.lte),
      unit: !_.isEmpty(this.globalSearchFilters.entry.unitId.in),
    };
    this.posthogService.captureEvent('[Detail Search] Sent detail search PDF', filterStatus);
    await this.doSend(this.translateService.instant('pdfGlobalSearch.alertMessage.send'), SendPdfProtocolOption.SEND_ONLY, beforeCallingSend);
  }

  private async getAlertMessageSendReport(): Promise<string> {
    const messagePrefix = await this.getMessagePrefix();
    if (this.workflowType === WorkflowType.Report && this.reportGroup && this.reportGroup.reports.length > 1) {
      return this.translateService.instant(messagePrefix + '.alertMessage.sendMultiple', {count: this.reportGroup?.reports?.length});
    }
    return this.translateService.instant(messagePrefix + '.alertMessage.send');
  }

  async closeAndSendReportPdf(beforeCallingSend?: () => Promise<void>) {
    const message = await this.getAlertMessageSendReport();
    await this.doSend(message, SendPdfProtocolOption.CLOSE_AND_SEND, beforeCallingSend);
  }

  async sendReportPdf(beforeCallingSend?: () => Promise<void>) {
    const message = await this.getAlertMessageSendReport();
    await this.doSend(message, SendPdfProtocolOption.SEND_ONLY, beforeCallingSend);
  }

  private async alertMessage(message: string) {
    const alert = await this.alertController.create({
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  private async toastMessage(message: string) {
    await this.toastService.info(message);
  }

  private async getMessagePrefix(): Promise<string> {
    if (this.workflowType === WorkflowType.Report) {
      const reportType = await observableToPromise(this.reportType$);
      return `pdfReport.${reportType}`;
    }
    return 'pdfProtocol';
  }

  private async callSendPdf(sendPdfProtocolOption: SendPdfProtocolOption, customText: string, ignoreMissingAttachments?: boolean) {
    const messagePrefix = await this.getMessagePrefix();
    const message = this.translateService.instant(messagePrefix + '.sending');
    const loading = await this.loadingController.create({
      message,
    });

    try {
      await loading.present();
      const pdfProtocolSetting: PdfProtocolSetting = await this.createPdfProtocolSetting();
      if (ignoreMissingAttachments) {
        pdfProtocolSetting.ignoreMissingAttachments = true;
      }
      const signature = this.emailSettingsForm.get('addSignature').value ? this.emailSettingsForm.get('emailSignature').value : undefined;
      const emailSettings = {signature};
      if (this.workflowType === WorkflowType.Protocol) {
        const sendNewMeeting = this.nextMeeting && this.nextMeeting.show && this.nextMeeting.date && this.nextMeeting.timeStart && this.nextMeeting.timeEnd;
        const logInstance = this.systemEventService.logAction(
          LOG_SOURCE,
          () =>
            `Send PDF protocol (id=${this.protocol?.id}, closedAt=${
              this.protocol?.closedAt || 'still_open'
            }, sendType=${SendPdfProtocolOption[sendPdfProtocolOption]}, filteredEntries=${this.filteredProtocolEntries
              ?.map((entry) => entry.id)
              .join(',')}, sendNewMeeting=${sendNewMeeting}, groupMeeting=${this.nextMeeting?.groupMeeting})`
        );
        logInstance.logCheckpoint(() => `(settings=${JSON.stringify(pdfProtocolSetting)})`);
        const nextMeetingDetails: NextMeetingDetails | undefined = sendNewMeeting
          ? {
              nextMeeting: this.nextMeeting,
              individualNextMeetings: this.individualNextMeetings,
            }
          : undefined;
        try {
          await this.pdfProtocolService.sendPdf(
            this.protocol.id,
            sendPdfProtocolOption,
            {customText},
            {
              pdfProtocolSetting,
              meetingDetails: nextMeetingDetails,
              filteredProtocolEntries: this.filteredProtocolEntries,
              emailSettings,
            }
          );
          logInstance.success();
          this.posthogService.captureEvent('[Protocols] Sent PDF', {
            individualProtocol: pdfProtocolSetting.individualProtocol ?? false,
            signedProtocol: this.showProtocolSignaturesChecked ?? false,
            newDate: this.nextMeeting?.show ?? false,
            signaturesCount: this.showProtocolSignaturesChecked ? this.pdfProtocolSignatures?.length : 0,
            ...Object.entries(pdfProtocolSetting)
              .filter(([, value]) => typeof value === 'boolean')
              .reduce((acc, [key, val]) => ({...acc, [key]: val}), {}),
          });
        } catch (error) {
          logInstance.failure(error);
          throw error;
        }
      } else if (this.workflowType === WorkflowType.GlobalSearch) {
        await this.pdfGlobalSearchService.sendPdf(
          this.globalSearchProtocolEntries,
          this.globalSearchFilters,
          {customText},
          {
            pdfProtocolSetting,
            emailSettings,
          }
        );
      } else if (this.workflowType === WorkflowType.Report) {
        const disableAppend = this.reportType === ReportTypeCode.REPORT_TYPE_CONSTRUCTION_DIARY;
        const pdfReportSendReq: PdfReportSendReq = {
          pdfProtocolSetting,
          closeReport: sendPdfProtocolOption === SendPdfProtocolOption.CLOSE_AND_SEND,
          emailSettings,
          participants: await this.getParticipantsForPdfReport(this.report.id),
          showTimeBlocks: this.showStaffTimeBlocksChecked,
          appendActivityAttachments: disableAppend ? false : this.appendActivityAttachments,
          appendOtherAttachments: disableAppend ? false : this.appendOtherAttachments,
          appendMaterialAttachments: disableAppend ? false : this.appendMaterialAttachments,
          appendEquipmentAttachments: disableAppend ? false : this.appendEquipmentAttachments,
        };
        let reportIds: Array<IdType>;
        if (this.reportGroup) {
          reportIds = this.reportGroup.reports.map((report) => report.id);
          pdfReportSendReq.reportGroup = {
            reportIds,
            year: this.reportGroup.year,
            month: 'month' in this.reportGroup ? this.reportGroup.month : undefined,
            weekNumber: 'weekNumber' in this.reportGroup ? this.reportGroup.weekNumber : undefined,
          };
        } else {
          reportIds = [this.report?.id];
        }
        await this.pdfReportService.sendPdf(reportIds, pdfReportSendReq);
      } else {
        throw new Error(`WorkflowType ${this.workflowType} not supported.`);
      }
      await this.toastService.info(sendPdfProtocolOption === SendPdfProtocolOption.CLOSE_AND_SEND ? messagePrefix + '.sendAndCloseSuccessful' : messagePrefix + '.sendOnlySuccessful');
      this.clearCache();
      await this.modal.dismiss(undefined, 'sent');
    } catch (error) {
      const syncPromise = this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
      let errorMessage = error.message;
      if (error instanceof HttpErrorResponse && error.status === 400) {
        const errorCode = error.error.errorCode as ErrorCodeAuthenticationType;
        if (errorCode === 'VERSION_CHANGED') {
          this.loggingService.error('PdfWorkflowComponent - versionChanged error in callSendPdf', errorCode);
          this.showVersionChangedAlert(syncPromise);
          return;
        } else if (errorCode === 'MISSING_ATTACHMENTS') {
          this.loggingService.warn('PdfWorkflowComponent - missing attachments in callSendPdf', errorCode);
          const attachmentsMissingContent: Array<Attachment> = error.error.errorData.attachmentsMissingContent;
          if (attachmentsMissingContent?.length) {
            this.alertMissingAttachments(attachmentsMissingContent, sendPdfProtocolOption, customText); // no "await" here
            return;
          }
        } else if (errorCode === 'PARTICIPANT_ADDRESS_DELETED_OR_EMAIL_MISSING') {
          this.alertMissingAddress(); // no "await" here
          return;
        } else if (errorCode === 'UNPROCESSABLE_IMAGE_FOR_PDF_GENERATION') {
          const attachmentKind: AttachmentKind = error.error.errorData.attachmentKind;
          const attachmentFileName: string = error.error.errorData.attachmentFileName;
          this.showAlertUnprocessableImage({
            key: `errors.unprocessableImage.${attachmentKind}.message`,
            params: {fileName: attachmentFileName},
          });
          return;
        } else {
          errorMessage = this.translateService.instant(`errorCodeMessages.${errorCode}`);
        }
      }
      await this.alertMessage(this.translateService.instant(messagePrefix + '.errorSending', {message: errorMessage}));
      this.loggingService.error('PdfWorkflowComponent - error in callSendPdf', error);
    } finally {
      await loading.dismiss();
    }
  }

  private async getParticipantsForPdfReport(reportId: IdType): Promise<Participant[]> {
    const participants = await observableToPromise(this.participantDataService.getByReportId(reportId));
    return participants.filter((participant) => participant.mailingList);
  }

  private async alertMissingAddress() {
    const alert = await this.alertController.create({
      message: this.translateService.instant('pdfWorkflow.errors.participantMissingAddress.error'),
      buttons: [
        {
          text: this.translateService.instant('pdfWorkflow.errors.participantMissingAddress.goToParticipantList'),
        },
      ],
    });
    await alert.present();
    await alert.onDidDismiss();
    this.goToFirstStep();
  }

  private async alertMissingAttachments(missingAttachments: Array<Attachment>, sendPdfProtocolOption: SendPdfProtocolOption, customText: string) {
    const detailsText = this.translateService.instant('details');
    const closeText = this.translateService.instant('close');
    const sendAnywayText = this.translateService.instant('sendAnyway');
    const messagePrefix = await this.getMessagePrefix();
    const alert = await this.alertController.create({
      message: this.translateService.instant(messagePrefix + '.errorSendingMissingAttachments'),
      buttons: [
        {
          text: detailsText,
          role: 'details',
        },
        {
          text: closeText,
          role: 'close',
        },
        {
          text: sendAnywayText,
          role: 'ignore',
        },
      ],
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    if (result.role === 'details') {
      const modal = await this.modalController.create({
        component: MissingAttachmentsComponent,
        componentProps: {attachments: missingAttachments, attachmentsOrigin: 'server'},
      });
      await modal.present();
    } else if (result.role === 'ignore') {
      this.callSendPdf(sendPdfProtocolOption, customText, true);
    }
  }

  private async showVersionChangedAlert(syncPromise?: Promise<DataSyncResultStatus>) {
    const errorMessage = this.translateService.instant(`errorCodeMessages.VERSION_CHANGED_SEND_PDF`);
    if (!syncPromise) {
      syncPromise = this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    }
    const alert = await this.alertController.create({
      message: errorMessage,
      buttons: [
        {
          text: this.translateService.instant('close'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('showPreview'),
          handler: async () => {
            const syncSpinner = await this.loadingController.create({
              message: this.translateService.instant('sendProtocol.syncInProgress'),
              backdropDismiss: true,
              keyboardClose: true,
              showBackdrop: true,
            });
            await syncSpinner.present();
            try {
              await syncPromise;
            } finally {
              await syncSpinner.dismiss();
            }
            await this.generatePreview();
          },
        },
      ],
    });
    await alert.present();
  }

  private async createPdfProtocolSetting(): Promise<PdfProtocolSetting> {
    return this.pdfProtocolSettingService.formGroupsToPdfProtocolSetting(this.protocolConfigurationForm, this.emailSettingsForm, (await this.projectDataService.getMandatoryCurrentProject()).id);
  }

  private async confirmIfAttachmentsNotUploadedAndSendPdf(beforeCallingSend?: () => Promise<void>) {
    if (this.syncStatusService.numberOfAttachmentsInUploadQueue > 0) {
      const alert = await this.alertController.create({
        message: this.translateService.instant('sendProtocol.confirmNotUploadedAttachments'),
        buttons: [
          {
            text: this.translateService.instant('cancel'),
            role: 'cancel',
          },
          {
            text: this.translateService.instant('send'),
            handler: () => this.sendPdf(beforeCallingSend),
          },
        ],
      });
      await alert.present();
    } else {
      await this.sendPdf(beforeCallingSend);
    }
  }

  private async doSend(message: string, sendPdfProtocolOption: SendPdfProtocolOption, beforeCallingSend?: () => Promise<void>) {
    const alert = await this.alertController.create({
      message,
      buttons: [
        {
          text: this.translateService.instant('cancel'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('send'),
          handler: async (inputsHandler) => {
            if (beforeCallingSend) {
              await beforeCallingSend();
            }
            await this.callSendPdf(sendPdfProtocolOption, inputsHandler && ((_.isArray(inputsHandler) && inputsHandler?.length) || inputsHandler[0]) ? inputsHandler[0] : undefined);
          },
        },
      ],
    });
    await alert.present();
  }

  showPreviewButton(): boolean {
    if (this.workflowType === WorkflowType.Report) {
      if (this.reportType === ReportTypeCode.REPORT_TYPE_CONSTRUCTION_REPORT || this.reportType === ReportTypeCode.REPORT_TYPE_DIRECTED_REPORT) {
        return this.currentStep === SendPdfWorkflowStep.ADVANCED_SETTINGS;
      }

      return this.currentStep === SendPdfWorkflowStep.EMAIL_SETTINGS;
    }
    return this.currentStep === SendPdfWorkflowStep.PROTOCOL_CONFIGURATION || this.currentStep === SendPdfWorkflowStep.ADVANCED_SETTINGS;
  }

  hasInvalidReportName(): boolean {
    return this.workflowType === WorkflowType.GlobalSearch && this.currentStep === 'PROTOCOL_CONFIGURATION' && !this.protocolConfigurationForm?.valid;
  }

  private async generatePreviewProtocol(abortSignal?: AbortSignal): Promise<{blob: Blob; attachmentsMissingContent: Array<Attachment>}> {
    const protocolValidation = await observableToPromise(this.protocolService.isValidForSendPdfProtocol(this.protocol.id, this.filteredProtocolEntries));
    if (abortSignal?.aborted) {
      return;
    }
    if (!protocolValidation.valid) {
      await this.showErrorMessage(protocolValidation);
      return;
    }
    const pdfProtocolSetting: PdfProtocolSetting = await this.createPdfProtocolSetting();
    pdfProtocolSetting.ignoreMissingAttachments = true;
    if (abortSignal?.aborted) {
      return;
    }

    const logInstance = this.systemEventService.logAction(
      LOG_SOURCE,
      () => `Generating PDF preview (id=${this.protocol?.id}, closedAt=${this.protocol?.closedAt || 'still_open'}, filteredEntries=${this.filteredProtocolEntries?.map((entry) => entry.id).join(',')})`
    );
    logInstance.logCheckpoint(() => `(settings=${JSON.stringify(pdfProtocolSetting)})`);
    try {
      const result = await this.pdfProtocolService.generatePdfPreview(
        this.protocol.id,
        SendPdfProtocolOption.SEND_ONLY,
        {},
        pdfProtocolSetting,
        {
          nextMeeting: this.nextMeeting?.show ? this.nextMeeting : undefined,
          individualNextMeetings: this.nextMeeting?.show ? (this.individualNextMeetings ?? []) : undefined,
        },
        this.filteredProtocolEntries
      );

      logInstance.success();

      return result;
    } catch (error) {
      logInstance.failure(error);
      if (error instanceof UnprocessableImageForPdfGenerationError) {
        this.showAlertUnprocessableImage(error.translatable);
      }
      throw error;
    }
  }

  private async generatePreviewGlobalSearch(abortSignal?: AbortSignal): Promise<{blob: Blob; attachmentsMissingContent: Array<Attachment>}> {
    const pdfProtocolSetting: PdfProtocolSetting = await this.createPdfProtocolSetting();
    pdfProtocolSetting.ignoreMissingAttachments = true;
    return await this.pdfGlobalSearchService.generatePdfPreview(this.globalSearchProtocolEntries, this.globalSearchFilters, {}, pdfProtocolSetting, abortSignal);
  }

  private async generatePreviewWithAbort(abortSignal?: AbortSignal) {
    let attachmentsMissingContent: Array<Attachment> | undefined;
    try {
      let renderFunction: (abortSignal?: AbortSignal) => Promise<{blob?: Blob; blobs?: Blob[]; attachmentsMissingContent: Array<Attachment>}>;

      switch (this.workflowType) {
        case WorkflowType.Protocol:
          renderFunction = async (argAbortSignal?: AbortSignal) => await this.generatePreviewProtocol(argAbortSignal);
          break;
        case WorkflowType.GlobalSearch:
          renderFunction = (argAbortSignal?: AbortSignal) => this.generatePreviewGlobalSearch(argAbortSignal);
          break;
        case WorkflowType.Report:
          const reportIds = this.reportGroup ? this.reportGroup.reports.map((report) => report.id) : [this.report.id];
          const disableAppend = this.reportType === ReportTypeCode.REPORT_TYPE_CONSTRUCTION_DIARY;
          renderFunction = (argAbortSignal?: AbortSignal) =>
            this.pdfReportService.generatePdfPreviews(
              reportIds,
              {
                participants: Array.from(this.changes.values()),
                pdfProtocolSetting: {ignoreMissingAttachments: true},
                showTimeBlocks: this.showStaffTimeBlocksChecked,
                appendActivityAttachments: disableAppend ? false : this.appendActivityAttachments,
                appendOtherAttachments: disableAppend ? false : this.appendOtherAttachments,
                appendMaterialAttachments: disableAppend ? false : this.appendMaterialAttachments,
                appendEquipmentAttachments: disableAppend ? false : this.appendEquipmentAttachments,
              } as PdfReportSendReq,
              argAbortSignal
            );
          break;
        default:
          throw new Error(`WorkflowType ${this.workflowType} not supported.`);
      }
      const pdfPreviewResult = await renderFunction(abortSignal);
      if (abortSignal?.aborted) {
        return;
      }
      attachmentsMissingContent = pdfPreviewResult.attachmentsMissingContent;
      const pdfObjectUrls = (pdfPreviewResult.blob ? [pdfPreviewResult.blob] : pdfPreviewResult.blobs).map((blob) => URL.createObjectURL(blob));
      await this.openPreviewDialog(pdfObjectUrls, pdfPreviewResult.blob ?? pdfPreviewResult.blobs, renderFunction);
      if (abortSignal?.aborted) {
        return;
      }
    } catch (error) {
      await this.toastService.error('pdfProtocol.errorRenderingPreview');
      this.systemEventService.logErrorEvent('PdfWorkflowComponent.generatePreview', error);
      this.loggingService.error(LOG_SOURCE, `Error generating PDF-Preview. ${error?.message}`);
    } finally {
      if (attachmentsMissingContent?.length && !abortSignal?.aborted) {
        await this.showAlertMissingAttachmentsPreview(attachmentsMissingContent, true);
      }
    }
  }

  async generatePreview() {
    const loading = await this.loadingController.create({
      message: this.translateService.instant('pdfProtocol.creatingPreview'),
      backdropDismiss: true,
      keyboardClose: true,
      showBackdrop: true,
    });
    try {
      const abortController = new AbortController();
      await loading.present();
      loading.onDidDismiss().then((result) => {
        if (result?.role === 'backdrop') {
          abortController.abort();
          this.toastMessage('pdfProtocol.aborted');
        }
      });
      await this.generatePreviewWithAbort(abortController.signal);
    } finally {
      await loading.dismiss();
    }
  }

  private async showAlertMissingAttachmentsPreview(attachmentsMissingContent: Array<Attachment>, preview: boolean) {
    const alert = await this.alertController.create({
      message: this.translateService.instant('pdfProtocol.missingAttachmentsMessage', {count: attachmentsMissingContent.length}),
      buttons: [
        {text: this.translateService.instant('details'), role: 'details'},
        {text: this.translateService.instant('close'), role: 'close'},
      ],
      cssClass: 'z-index-99999',
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    if (result.role === 'details') {
      const modal = await this.modalController.create({
        component: MissingAttachmentsComponent,
        componentProps: {attachments: attachmentsMissingContent, attachmentsOrigin: preview ? 'client' : 'server'},
      });
      await modal.present();
    }
  }

  private async showAlertUnprocessableImage(message: Translatable) {
    await this.alertService.ok({
      message,
      header: 'errors.unprocessableImage.headerErrorGeneratingPdf',
    });
  }

  async openPreviewDialog(
    pdfObjectUrl: string | Array<string>,
    pdfBlob: Blob | Array<Blob>,
    renderFunction: (abortSignal?: AbortSignal) => Promise<{blob?: Blob; blobs?: Array<Blob>; attachmentsMissingContent: Array<Attachment>}>
  ) {
    const showReportSignature = this.showReportSignature();
    const showProtocolSignature = this.showProtocolSignature();
    const showPreviewSend = this.showPreviewSendInsteadOfSend();
    let projectId: IdType;
    let shortId: string | undefined;
    if (this.protocol) {
      projectId = this.protocol.projectId;
      const protocolType = await this.protocolService.getProtocolTypeById(this.protocol.typeId);
      shortId = protocolShortIdWithDeps(this.locale, protocolType, this.protocol);
    } else if (this.report) {
      projectId = (await observableToPromise(this.reportService.getReportWeek(this.report)))?.projectId ?? (await this.projectDataService.getMandatoryCurrentProject()).id;
      shortId =
        this.translateService.instant('reportTypeCodeAbbreviation.' + (await observableToPromise(this.reportService.getReportTypeCode(this.report)))) +
        this.report.reportNumber.toString().padStart(2, '0');
    } else {
      projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    }
    const projectNumber = (await observableToPromise(this.projectDataService.getById(projectId)))?.number;
    const modal = await this.modalController.create({
      component: PdfViewerComponent,
      cssClass: 'pdf-workflow-modal',
      componentProps: {
        pdfObjectUrl,
        pdfBlob,
        filename: this.getPdfFileName(this.workflowType, convertOptionalProjectNumberToString(projectNumber), shortId),
        projectId,
        protocolId: this.protocol?.id ?? undefined,
        reportId: this.report?.id ?? undefined,
        signers: showReportSignature ? this.signersPdfReport : undefined,
        signersWithSignature: showReportSignature ? this.signersWithSignature : undefined,
        signersWithSignatureChanged: showReportSignature ? (signersWithSignature) => this.signersWithSignatureChanged(signersWithSignature) : undefined,
        pdfProtocolSignatures: showProtocolSignature ? this.pdfProtocolSignatures : undefined,
        pdfProtocolSignaturesChanged: showProtocolSignature ? (pdfProtocolSignatures) => this.pdfProtocolSignaturesChange(pdfProtocolSignatures) : undefined,
        showSendButtons: showReportSignature || showPreviewSend ? this.showCloseButtonInPreview() : undefined,
        showCloseCheckbox: showReportSignature || showPreviewSend ? this.showCloseCheckbox() : undefined,
        requestSend: showReportSignature || showPreviewSend ? (argument: {closeProtocolAfterSend?: boolean}) => this.sendPdfFromPdfViewer(modal, argument.closeProtocolAfterSend) : undefined,
        requestClose:
          showReportSignature || showPreviewSend
            ? () => {
                modal.dismiss();
                this.next();
              }
            : undefined,
        requestRender: renderFunction,
      },
    });
    await modal.present();
    modal.onDidDismiss().then(() => {
      if (_.isArray(pdfObjectUrl)) {
        (pdfObjectUrl as Array<string>).forEach((v) => URL.revokeObjectURL(v));
      } else {
        URL.revokeObjectURL(pdfObjectUrl as string);
      }
      pdfBlob = null;
    });
  }

  private getPdfFileName(workflowType: WorkflowType, projectNumber: string, shortId?: string): string {
    switch (workflowType) {
      case WorkflowType.Protocol:
        return `${formatProjectNumberOptional(projectNumber).replace(REG_SPECIAL, '_')}-${shortId}-${this.protocol.name}-${this.translateService.instant('preview')}.pdf`;
      case WorkflowType.Report:
        return `${formatProjectNumberOptional(projectNumber).replace(REG_SPECIAL, '_')}-${shortId}-${this.report.title}-${this.translateService.instant('preview')}.pdf`;
      case WorkflowType.GlobalSearch:
        return 'search_preview.pdf';
    }
  }

  private async signersWithSignatureChanged(signersWithSignature: Array<SignerWithSignature>) {
    await this.syncSignersWithSignatureWithAttachmentSignatures(signersWithSignature);
  }

  private async syncSignersWithSignatureWithAttachmentSignatures(signersWithSignature: Array<SignerWithSignature>) {
    try {
      const reports = this.reportGroup ? this.reportGroup.reports : [this.report];
      for (const report of reports) {
        const reportId = report.id;
        const project = await this.projectDataService.getMandatoryCurrentProject();
        const projectId = project.id;
        const attachmentReportSignatures = await observableToPromise(this.attachmentReportSignatureDataService.getByReportId(reportId));

        const attachmentReportSignaturesToDelete = attachmentReportSignatures.filter(
          (attachmentReportSignature) => !signersWithSignature.some((signerWithSignature) => signerWithSignature.code === attachmentReportSignature.code)
        );
        const newSignersWithSignature = signersWithSignature.filter(
          (signerWithSignature) => !attachmentReportSignatures.some((attachmentReportSignature) => signerWithSignature.code === attachmentReportSignature.code)
        );
        const attachmentReportSignaturesToInsert = new Array<AttachmentReportSignature>();
        const blobs = new Array<Blob>();
        for (const signerWithSignature of newSignersWithSignature) {
          const attachmentBlob = signerWithSignature.signature;
          const attachmentReportSignature = this.createAttachmentSignatureFromSignerWithSignature(signerWithSignature, reportId, projectId);
          attachmentReportSignaturesToInsert.push(attachmentReportSignature);
          blobs.push(attachmentBlob.blob);
        }
        if (attachmentReportSignaturesToInsert.length) {
          await this.attachmentReportSignatureDataService.insert(attachmentReportSignaturesToInsert, projectId, {}, blobs);
        }

        if (attachmentReportSignaturesToDelete.length) {
          await this.attachmentReportSignatureDataService.delete(attachmentReportSignaturesToDelete, projectId);
        }
      }
    } catch (error) {
      const errorMessage = error?.message || error;
      this.loggingService.error(LOG_SOURCE, `error in syncSignersWithSignatureWithAttachmentSignatures. ${errorMessage}`);
      this.systemEventService.logErrorEvent(LOG_SOURCE, `error in syncSignersWithSignatureWithAttachmentSignatures. ${errorMessage}`);
      await this.toastService.error(this.translateService.instant('pdfReport.signature.errorSaving', {message: errorMessage}));
    }
  }

  private createAttachmentSignatureFromSignerWithSignature(signerWithSignature: SignerWithSignature, reportId: IdType, projectId: IdType): AttachmentReportSignature {
    const attachmentBlob = signerWithSignature.signature;
    return {
      id: uuid4(),
      hash: attachmentBlob.hash,
      mimeType: attachmentBlob.mimeType,
      fileExt: attachmentBlob.fileExt,
      changedAt: attachmentBlob.changedAt,
      createdAt: attachmentBlob.createdAt,
      createdById: attachmentBlob.createdById,
      markings: attachmentBlob.markings,
      reportId,
      projectId,
      code: signerWithSignature.code,
      name: signerWithSignature.name,
      signerName: signerWithSignature.signerName,
      forClosed: false,
    } as AttachmentReportSignature;
  }

  private async deleteOldAttachmentReportSignatures(reportId: IdType) {
    const attachmentReportSignatures = await observableToPromise(this.attachmentReportSignatureDataService.getByReportId(reportId));
    if (attachmentReportSignatures.length) {
      const projectId = attachmentReportSignatures[0].projectId;
      await this.attachmentReportSignatureDataService.delete(attachmentReportSignatures, projectId);
    }
  }

  private addToCache(): void {
    this.pdfSettingsCacheService.add(this.protocol?.id, {
      emailSettingsForm: this.emailSettingsForm,
      protocolConfigForm: this.protocolConfigurationForm,
      nextMeeting: this.nextMeeting,
      individualNextMeetings: this.individualNextMeetings,
    });
  }

  private clearCache(): void {
    if (this.pdfSettingsCache) {
      this.pdfSettingsCacheService.clear(this.protocol.id);
    }
  }

  private async showErrorMessage(protocolValidation: any) {
    const message = !protocolValidation.message ? `${this.translateService.instant('pdfProtocol.errorMissingInformation')}` : protocolValidation.message;
    const alert = await this.alertController.create({
      message,
      buttons: [
        {
          text: 'OK',
          handler: async () => {
            await alert.dismiss();
          },
        },
      ],
    });
    await alert.present();
  }

  public showReportSignature(): boolean {
    return (
      this.workflowType === WorkflowType.Report &&
      this.reportType &&
      (this.reportType === ReportTypeCode.REPORT_TYPE_CONSTRUCTION_REPORT || this.reportType === ReportTypeCode.REPORT_TYPE_DIRECTED_REPORT) &&
      this.report &&
      !this.report.closedAt &&
      this.showReportSignatureChecked
    );
  }

  public showProtocolSignature(): boolean {
    return this.workflowType === WorkflowType.Protocol && this.protocol && !this.protocol.closedAt && this.showProtocolSignaturesChecked && !!this.pdfProtocolSignatures?.length;
  }

  public showPreviewSendInsteadOfSend(): boolean {
    if (this.workflowType === WorkflowType.Report) {
      return this.currentWorkflowConfig.currentStep === SendPdfWorkflowStep.ADVANCED_SETTINGS && this.showReportSignature();
    }
    return this.currentWorkflowConfig.currentStep === SendPdfWorkflowStep.ADVANCED_SETTINGS && this.showProtocolSignature();
  }

  public showSendButton(): boolean {
    return this.currentWorkflowConfig.nextStep === null;
  }

  public showCloseButtonInPreview(): boolean {
    return this.currentWorkflowConfig.currentStep === SendPdfWorkflowStep.ADVANCED_SETTINGS;
  }

  public showCloseCheckbox(): boolean {
    return (
      this.currentWorkflowConfig.nextStep === null &&
      ((this.workflowType === this.WorkflowTypeEnum.Protocol && !this.protocol?.closedAt) ||
        (this.workflowType === this.WorkflowTypeEnum.Report && !this.reportGroup && !this.report?.closedAt) ||
        (this.workflowType === this.WorkflowTypeEnum.Report && this.reportGroup && !!this.reportGroup.reports.find((report) => !report.closedAt)))
    );
  }

  public async pdfProtocolSignaturesChange(pdfProtocolSignatures: Array<PdfProtocolSignatureForm>) {
    this.pdfProtocolSignatures = pdfProtocolSignatures;
    await this.protocolSignatureService.persistAttachmentProtocolSignaturesFromFormValues(this.protocol.projectId, this.protocol.id, pdfProtocolSignatures);
  }

  public async showProtocolSignaturesCheckedChange(show: boolean) {
    this.showProtocolSignaturesChecked = show;
    if (!this.protocol) {
      return;
    }
    if (show) {
      this.pdfProtocolSignatures = await this.protocolSignatureService.createPdfProtocolSignaturesForParticipantsPresent(this.allParticipants);
      await this.protocolSignatureService.persistAttachmentProtocolSignaturesFromFormValues(this.protocol.projectId, this.protocol.id, this.pdfProtocolSignatures);
    } else if (!show && this.pdfProtocolSignatures) {
      this.protocolSignatureService.removeSignatures(this.pdfProtocolSignatures);
      await this.protocolSignatureService.cleanupAttachmentProtocolSignaturesForProtocol(this.protocol.id);
    }
  }

  private async removeProtocolSignaturesIfAny(): Promise<boolean> {
    if (this.pdfProtocolSignatures?.length && this.protocol) {
      const changes = this.protocolSignatureService.removeSignatures(this.pdfProtocolSignatures);
      if (changes) {
        await this.protocolSignatureService.persistAttachmentProtocolSignaturesFromFormValues(this.protocol.projectId, this.protocol.id, this.pdfProtocolSignatures);

        await this.toastService.info('sendProtocol.protocolConfig.signature.signaturesRemoved');
        return true;
      }
    }
    return false;
  }

  private setPdfSettingsCache() {
    this.pdfSettingsCache = {
      emailSettingsForm: this.emailSettingsForm,
      individualNextMeetings: this.individualNextMeetings,
      nextMeeting: this.nextMeeting,
      protocolConfigForm: this.protocolConfigurationForm,
    };
  }

  private isNotProtocolWorkflowType() {
    return this.workflowType !== WorkflowType.Protocol || !this.protocol;
  }

  private async performPdfProtocolSettingSaving(fn: () => PdfProtocolSetting, confirmTitle: string, confirmMessage: string) {
    if (this.isNotProtocolWorkflowType()) {
      return;
    }

    if (!(await this.confirmSaveAsDefault(confirmTitle, confirmMessage))) {
      return;
    }

    this.pendingSettingsSaving = true;
    try {
      this.setPdfSettingsCache();
      const saveFn = (pdfProtocolSetting: PdfProtocolSetting) =>
        this.pdfProtocolSettings
          ? this.pdfProtocolSettingDataService.update(pdfProtocolSetting, pdfProtocolSetting.projectId)
          : this.pdfProtocolSettingDataService.insert(pdfProtocolSetting, pdfProtocolSetting.projectId);
      const pdfProtocolSetting = fn();
      pdfProtocolSetting.protocolTypeId = this.protocol.typeId;
      if (!pdfProtocolSetting.projectId) {
        pdfProtocolSetting.projectId = this.protocol.projectId;
      }
      await resolveWithMinimumTimeout(saveFn(pdfProtocolSetting), 300);
    } catch (e) {
      this.toastService.savingError();
      const message = convertErrorToMessage(e);
      this.loggingService.error(LOG_SOURCE, ` - performPdfProtocolSettingSaving failed; reason: ${message}`);
      this.systemEventService.logErrorEvent(LOG_SOURCE, ` - performPdfProtocolSettingSaving failed; reason: ${message}`);
    } finally {
      this.pendingSettingsSaving = false;
    }
  }

  private async confirmSaveAsDefault(title: string, message: string): Promise<boolean> {
    const alert = await this.alertController.create({
      header: this.translateService.instant(title),
      message: this.translateService.instant(message),
      buttons: [
        {
          text: this.translateService.instant('cancel'),
        },
        {
          text: this.translateService.instant('button.save'),
          role: 'confirm',
        },
      ],
    });

    await alert.present();

    return (await alert.onWillDismiss()).role === 'confirm';
  }

  async saveSignature() {
    if (
      !(await this.confirmSaveAsDefault(
        'sendProtocol.emailSettings.saveSignatureAsTemplate.title',
        `sendProtocol.emailSettings.saveSignatureAsTemplate.${this.userEmailSignature ? 'messageWhenSignature' : 'messageWhenNoSignature'}`
      ))
    ) {
      return;
    }

    if (this.userEmailSignature) {
      const signature: UserEmailSignature = {
        ...this.userEmailSignature,
        ...this.emailSettingsForm.get('emailSignature').value,
      };

      await this.userEmailSignatureDataService.update(signature);
    } else {
      const user = await observableToPromise(this.userService.currentUser$);
      if (!user) {
        throw new Error(`User is not defined!`);
      }
      const signature: UserEmailSignature = {
        ...this.emailSettingsForm.get('emailSignature').value,
        id: user.id,
        changedAt: new Date().toISOString(),
      };

      await this.userEmailSignatureDataService.insert(signature);
    }
  }

  async saveLayoutProtocolSettings() {
    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    await this.performPdfProtocolSettingSaving(
      () =>
        this.pdfProtocolSettingService.formGroupsToPdfProtocolSetting(
          this.protocolConfigurationForm,
          this.pdfProtocolSettings ? this.pdfProtocolSettingService.emailSettingsFormGroupFromSetting(this.pdfProtocolSettings) : this.pdfProtocolSettingService.getEmptyEmailSettingsFormGroup(),
          projectId,
          this.pdfProtocolSettings
        ),
      'sendProtocol.protocolConfig.confirmTemplate.title',
      'sendProtocol.protocolConfig.confirmTemplate.message'
    );
  }

  async saveAdvancedSettingsProtocolSettings() {
    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    await this.performPdfProtocolSettingSaving(
      () => {
        const emailSettingsHelperForm = this.pdfProtocolSettings
          ? this.pdfProtocolSettingService.emailSettingsFormGroupFromSetting(this.pdfProtocolSettings)
          : this.pdfProtocolSettingService.getEmptyEmailSettingsFormGroup();

        emailSettingsHelperForm.get('individualProtocol').setValue(this.emailSettingsForm.get('individualProtocol').value);

        return this.pdfProtocolSettingService.formGroupsToPdfProtocolSetting(
          this.pdfProtocolSettings
            ? this.pdfProtocolSettingService.configurationFormGroupFromSetting(this.pdfProtocolSettings)
            : this.pdfProtocolSettingService.getEmptyConfigurationFormGroup(this.workflowType),
          emailSettingsHelperForm,
          projectId,
          this.pdfProtocolSettings
        );
      },
      'sendProtocol.advancedSettings.confirmTemplate.title',
      'sendProtocol.advancedSettings.confirmTemplate.message'
    );
  }

  async saveEmailSettingsProtocolSettings() {
    const projectId = (await this.projectDataService.getMandatoryCurrentProject()).id;
    await this.performPdfProtocolSettingSaving(
      () => {
        const emailSettingsHelperForm = this.pdfProtocolSettings
          ? this.pdfProtocolSettingService.emailSettingsFormGroupFromSetting(this.pdfProtocolSettings)
          : this.pdfProtocolSettingService.getEmptyEmailSettingsFormGroup();
        const helperPdfSetting = this.pdfProtocolSettingService.formGroupsToPdfProtocolSetting(this.protocolConfigurationForm, this.emailSettingsForm, projectId, this.pdfProtocolSettings);
        const copyEmailSettingsForm = this.pdfProtocolSettingService.emailSettingsFormGroupFromSetting(helperPdfSetting);

        copyEmailSettingsForm.get('individualProtocol').setValue(emailSettingsHelperForm.get('individualProtocol').value);

        return this.pdfProtocolSettingService.formGroupsToPdfProtocolSetting(
          this.pdfProtocolSettings
            ? this.pdfProtocolSettingService.configurationFormGroupFromSetting(this.pdfProtocolSettings)
            : this.pdfProtocolSettingService.getEmptyConfigurationFormGroup(this.workflowType),
          copyEmailSettingsForm,
          projectId,
          this.pdfProtocolSettings
        );
      },
      'sendProtocol.emailSettings.confirmTemplate.title',
      'sendProtocol.emailSettings.confirmTemplate.message'
    );
  }

  resetAdvancedSettingsProtocolSettings() {
    this.emailSettingsForm.get('individualProtocol').setValue(this.pdfProtocolSettings?.individualProtocol ?? false);
    this.emailSettingsForm.get('individualProtocol').markAsPristine();
    this.showProtocolSignaturesCheckedChange(false);
    if (this.nextMeeting) {
      this.nextMeetingComponent?.onShowChange(false);
      this.individualNextMeetings = [];
    }
  }

  resetLayoutProtocolSettings() {
    this.protocolConfigurationForm.markAsPristine();
    this.protocolConfigurationFormDirty = false;
    this.applyDataToProtocolConfiguration();
  }

  detectChanges() {
    this.cd.detectChanges();
  }

  handleAttachmentUserEmailSignatureCreate({blob, filename}: {blob: Blob; filename: string}) {
    return this.userEmailSignatureService.uploadAttachmentUserEmailSignature(blob, filename);
  }

  async openCompanyOrderModal() {
    const modal = await this.modalController.create({
      component: ManageCompanyOrderComponent,
      cssClass: 'omg-modal omg-boundary',
    });

    await modal.present();
  }
}
