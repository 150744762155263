<app-pdf-plan-holder-list-item
  *ngFor="let holder of pdfPlanHolders; trackBy: trackById"
  [pdfPlanHolder]="holder"
  [disabled]="true"
  [viewMode]="'edit'"
>
  <ng-template appHolderItemNameTemplate let-holder="holder">
    <ng-container [formGroup]="getHolderFormGroup(holder.id)">
      <input type="text" formControlName="name" maxlength="60" appInlineInput />
    </ng-container>
  </ng-template>
  <ng-template appHolderItemIndexTemplate let-holder="holder">
    <ng-container [formGroup]="getHolderFormGroup(holder.id)">
      <input type="text" formControlName="index" maxlength="4" appInlineInput />
    </ng-container>
  </ng-template>
  <ng-template appHolderItemDateTemplate let-holder="holder">
    <ng-container [formGroup]="getHolderFormGroup(holder.id)">
      <mbsc-datepicker [controls]="['calendar']" #calendarDate hidden
                       display="center"
                       [dateFormat]="MBSC_DATE_FORMAT"
                       [showWeekNumbers]="true"
                       [firstSelectDay]="1"
                       [firstDay]="1"
                       [setText]="'button.apply' | translate"
                       [cancelText]="'cancel' | translate"
                       [buttons]="[ 'cancel', 'set' ]"
                       [locale]="mbscLocale$ | async"
                       [themeVariant]="mbscThemeVariant$ | async"
                       theme="windows"
                       formControlName="date" [returnFormat]="'jsdate'">
      </mbsc-datepicker>
      <button type="button" appInlineInput (click)="calendarDate.open()">
        {{ getHolderFormGroup(holder.id)?.get('date')?.value | date:DATE_FORMAT }}
      </button>
    </ng-container>
  </ng-template>
  <ng-template appHolderItemScaleTemplate let-holder="holder">
    <ng-container [formGroup]="getHolderFormGroup(holder.id)">
      <input type="text" formControlName="scale" maxlength="10" appInlineInput />
    </ng-container>
  </ng-template>
  <ng-template appHolderItemLocationTemplate let-holder="holder">
    <ng-container [formGroup]="getHolderFormGroup(holder.id)">
      <button type="button" appInlineInput (click)="handleEditLocation($event, holder.id)">
        {{ ((locationById$ | async) || {})[getHolderFormGroup(holder.id)?.get('locationId')?.value]?.location || '&nbsp;' }}
      </button>
    </ng-container>
  </ng-template>
  <ng-template appHolderItemUnitTemplate let-holder="holder">
    <ng-container [formGroup]="getHolderFormGroup(holder.id)">
      <div class="unit-field-item">
        <button
          class="unit-field-label omg-inline-input"
          formControlName="unitId"
          appUnitSelector
          #us="appUnitSelector"
          [selectorUnits]="units$ | async"
          [selectorUnitLevels]="unitLevels$ | async"
          [idOnly]="true"
          (click)="us.chooseUnit()"
          appEllipsisTooltip
        >
          <bdi>{{us.value()?.breadcrumbsName || '&nbsp;'}}</bdi>
        </button>
      </div>
    </ng-container>
  </ng-template>
  <ng-template appHolderItemTagsTemplate let-holder="holder">
    <ng-container [formGroup]="getHolderFormGroup(holder.id)">
      <app-tag-list-edit
        (click)="analytics.planTagsClick(holder.latestPdfPlanVersion?.id, 'edit')"
        [tags]="tagsById[holder.latestPdfPlanVersion?.id]"
        [objectId]="holder.latestPdfPlanVersion?.id"
        [objectType]="'pdfPlanVersions'"
        (tagsChange)="handleTagsChange($event, holder.latestPdfPlanVersion?.id)"
      ></app-tag-list-edit>
    </ng-container>
  </ng-template>
  <ng-template appHolderItemCommentTemplate let-holder="holder">
    <ion-text color="text-dark">
      <button type="button" appInlineInput (click)="handleEditComment(holder, getHolderFormGroup(holder.id))">
        {{ 'project_room.pdf_plan_holders.edit.note' | translate }}
      </button>
    </ion-text>
  </ng-template>
</app-pdf-plan-holder-list-item>

<div class="omg-boundary actions-row" appLayout appLayoutJustifyContent="flex-end" appLayoutAlignItems="flex-end">
  <ion-button class="omg-btn-text" (click)="handleCancel()">
    {{'cancel' | translate}}
  </ion-button>
  <ion-button [disabled]="holdersForm.invalid" class="omg-btn-secondary" (click)="handleSubmitAndShare()">
    <fa-icon slot="start" [icon]="['fal', 'paper-plane']"></fa-icon>
    <span>{{ 'project_room.pdf_plan_holders.edit.save_and_share' | translate }}</span>
  </ion-button>
  <ion-button [disabled]="holdersForm.invalid" class="omg-btn-primary" (click)="handleSubmit()">
    <fa-icon slot="start" [icon]="['fal', 'save']"></fa-icon>
    <span>{{ 'project_room.pdf_plan_holders.edit.save' | translate }}</span>
  </ion-button>
</div>