import {LoggingService} from './../common/logging.service';
import {Injectable} from '@angular/core';
import {CaptureError, CaptureVideoOptions, MediaCapture, MediaFile} from '@awesome-cordova-plugins/media-capture/ngx';
import {File, FileEntry} from '@awesome-cordova-plugins/file/ngx';
import {Capacitor} from '@capacitor/core';
import {Media, MEDIA_STATUS, MediaObject} from '@awesome-cordova-plugins/media/ngx';
import {Platform} from '@ionic/angular';
import _ from 'lodash';
import {first} from 'rxjs/operators';
import {SystemEventService} from '../event/system-event.service';

const LOG_SOURCE = 'MediaService';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private readonly videoOptions: CaptureVideoOptions;
  private audioFile: FileEntry | undefined;
  private audioMediaObject: MediaObject | undefined;

  constructor(
    private platform: Platform,
    private mediaCapture: MediaCapture,
    private media: Media,
    private file: File,
    private loggingService: LoggingService,
    private systemEventService: SystemEventService
  ) {
    this.videoOptions = {
      duration: 60,
      limit: 1, // For now just one video per recording
    };
  }

  isRecordingSupported(): boolean {
    return this.platform.is('cordova') && (this.platform.is('android') || this.platform.is('ios'));
  }

  public async startAudioRecording(): Promise<void> {
    const audioMediaSrc = await this.createPathFromAudioFile();
    this.audioMediaObject = this.media.create(audioMediaSrc);
    this.audioMediaObject.startRecord();
  }

  public async createPathFromAudioFile(blobFile?: Blob, existingName?: string): Promise<string> {
    const path = this.platform.is('android') ? this.file.externalApplicationStorageDirectory : this.file.documentsDirectory;
    let name;
    if (!existingName) {
      name = this.platform.is('android') ? `${new Date().getTime().toString()}.aac` : `${new Date().getTime().toString()}.m4a`;
    } else {
      name = existingName;
    }
    try {
      if (blobFile) {
        this.audioFile = await this.file.writeFile(path, name, blobFile, {replace: true});
      } else {
        this.audioFile = await this.file.createFile(path, name, true);
      }
    } catch (e) {
      this.loggingService.error(LOG_SOURCE, e.message);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ` createPathFromAudioFile`, e);
    }
    if (path) {
      // for ios the file prefix needs to be removed otherwise no audio is recorded
      return this.platform.is('android') ? `${path}${name}` : `${path.replace(/file:[\/]{2}/, '')}${name}`;
    } else {
      return null;
    }
  }

  public async stopAudioRecording(): Promise<FileEntry> {
    if (this.audioFile) {
      this.audioMediaObject.stopRecord();
      this.audioMediaObject.release();
      this.audioMediaObject = undefined;
      const audioFile = this.audioFile;
      this.audioFile = undefined;
      return audioFile;
    }
  }

  public async playAudio(path: string): Promise<MEDIA_STATUS> {
    this.audioMediaObject = this.media.create(path);
    this.audioMediaObject.setVolume(1);
    this.audioMediaObject.play();
    // Emit when it is finished
    return this.audioMediaObject.onStatusUpdate.pipe(first((status) => status === 4)).toPromise();
  }

  public async stopAudio(): Promise<void> {
    if (this.audioMediaObject) {
      this.audioMediaObject.pause();
      this.audioMediaObject.stop();
      this.audioMediaObject.release();
      this.audioMediaObject = undefined;
    }
    return null;
  }

  public releaseAudio() {
    if (this.audioMediaObject) {
      this.audioMediaObject.release();
      this.audioMediaObject = undefined;
    }
  }

  public async takeVideo(): Promise<MediaFile> {
    const videoResult = await this.mediaCapture.captureVideo(this.videoOptions);
    if (_.isArray(videoResult)) {
      return _.head(videoResult);
    }
    const captureError = videoResult as CaptureError;
    throw new Error(`Error capturing video. Error code ${captureError.code}`);
  }

  public async getBlob(pathFile: string): Promise<Blob> {
    return await fetch(Capacitor.convertFileSrc(pathFile)).then((r) => r.blob());
  }
}
