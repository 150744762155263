<form class="omg-form" [formGroup]="form">
  <ion-grid class="grid-gap-4">
    <ng-template #projectTeamOrEmptyIcon let-item="item">
      <fa-icon [icon]="['fal', 'bullhorn']" *ngIf="item.id === PROJECT_TEAM_PSEUDO_ID" class="pr-4"></fa-icon>
      <fa-icon [icon]="['fal', 'empty-set']" *ngIf="item.id === EMPTY_FILTER_ID" class="pr-4"></fa-icon>
    </ng-template>
    <ion-row>
      <ion-col size="12">
        <app-entry-filter-multi-buttons
          [buttons]="statuses"
          [formControl]="$any(form.get('entry.status.in'))"
          [forceOneSelected]="false"
          [storeOnlyValue]="true"
        ></app-entry-filter-multi-buttons>
      </ion-col>

      <ion-col size="12">
        <app-entry-filter-selectable
          [itemLabelIconTemplate]="projectTeamOrEmptyIcon"
          [recentlyUsedKey]="showRecentlyUsedInCurrentProject ? 'craft' : undefined"
          [label]="'Category' | translate"
          [modalTitle]="'Category' | translate"
          [items]="crafts"
          itemTextField="name"
          itemGreyedOutField="isDeletedOrNotInProject"
          [formControl]="$any(form.get('entry.craftId.in'))"
        ></app-entry-filter-selectable>
      </ion-col>
  
      <ion-col size="12">
        <app-entry-filter-selectable
          [itemLabelIconTemplate]="projectTeamOrEmptyIcon"
          [recentlyUsedKey]="showRecentlyUsedInCurrentProject ? 'company' : undefined"
          [label]="'Company' | translate"
          [modalTitle]="'Company' | translate"
          [items]="companiesWithProjectTeam"
          itemTextField="name"
          itemGreyedOutField="isDeletedOrNotInProject"
          [formControl]="$any(form.get('entry.companyId.in'))"
        ></app-entry-filter-selectable>
      </ion-col>

      <ion-col size="12">
        <app-entry-filter-selectable
          [itemLabelIconTemplate]="projectTeamOrEmptyIcon"
          [recentlyUsedKey]="showRecentlyUsedInCurrentProject ? 'company' : undefined"
          [label]="'observerCompanies' | translate"
          [modalTitle]="'observerCompanies' | translate"
          [items]="companies"
          itemTextField="name"
          itemGreyedOutField="isDeletedOrNotInProject"
          [formControl]="$any(form.get('entry.observerCompanies.in'))"
        ></app-entry-filter-selectable>
      </ion-col>
      
      <ion-col size="12">
        <app-entry-filter-selectable
          [itemLabelIconTemplate]="projectTeamOrEmptyIcon"
          [recentlyUsedKey]="showRecentlyUsedInCurrentProject ? 'user' : undefined"
          [label]="'internalAssignment' | translate"
          [modalTitle]="'internalAssignment' | translate"
          [items]="profiles"
          itemTextField="name"
          itemGreyedOutField="isDeletedOrNotInProject"
          [formControl]="$any(form.get('entry.internalAssignmentId.in'))"
        ></app-entry-filter-selectable>
      </ion-col>
     
      <ion-col size="12">
        <app-entry-filter-selectable
          [itemLabelIconTemplate]="projectTeamOrEmptyIcon"
          [recentlyUsedKey]="showRecentlyUsedInCurrentProject ? 'protocolEntryType' : undefined"
          [label]="'EntryType' | translate"
          [modalTitle]="'EntryType' | translate"
          [items]="entryTypes"
          itemTextField="name"
          itemGreyedOutField="isDeletedOrNotInProject"
          [formControl]="$any(form.get('entry.typeId.in'))"
        ></app-entry-filter-selectable>
      </ion-col>

      <ion-col size="12">
        <app-entry-filter-selectable
          [itemLabelIconTemplate]="projectTeamOrEmptyIcon"
          [recentlyUsedKey]="showRecentlyUsedInCurrentProject ? 'location' : undefined"
          [label]="'Location' | translate"
          [modalTitle]="'Location' | translate"
          [items]="locations"
          itemTextField="location"
          itemGreyedOutField="isDeletedOrNotInProject"
          [formControl]="$any(form.get('entry.locationId.in'))"
        ></app-entry-filter-selectable>
      </ion-col>

      <ion-col size="12">
        <app-entry-filter-selectable
          [itemLabelIconTemplate]="projectTeamOrEmptyIcon"
          [recentlyUsedKey]="showRecentlyUsedInCurrentProject ? 'additionalField' : undefined"
          [label]="nameableDropdownName"
          [modalTitle]="nameableDropdownName"
          [items]="customFields"
          itemTextField="name"
          itemGreyedOutField="isDeletedOrNotInProject"
          [formControl]="$any(form.get('entry.nameableDropdownId.in'))"
        ></app-entry-filter-selectable>
      </ion-col>

      <ion-col size="12">
        <app-entry-filter-selectable
          [itemLabelIconTemplate]="projectTeamOrEmptyIcon"
          [recentlyUsedKey]="showRecentlyUsedInCurrentProject ? 'unit' : undefined"
          [label]="'unit' | translate"
          [modalTitle]="'unit' | translate"
          [items]="units"
          [(ngModel)]="selectedUnit"
          [ngModelOptions]="{standalone: true}"
          (ngModelChange)="unitFilterChanged($event)"
          [isMultiple]="false"
          [isUnitFilter]="true"
          itemTextField="breadcrumbsName"
          itemGreyedOutField="isDeletedOrNotInProject"
          [unitLevels]="unitLevels"
        ></app-entry-filter-selectable>
      </ion-col>

      <ion-col size="12" class="ion-no-padding">
        <ion-item lines="none" class="small-item">
          <ion-toggle
            [(ngModel)]="shouldIncludeSubUnits"
            (ngModelChange)="shouldIncludeSubUnitsChanged()"
            [ngModelOptions]="{standalone: true}"
            appSlimToggle
          ></ion-toggle>
          <ion-label class="slim-toggle-label">{{ ('unitFilter.includeSub' | translate) }}</ion-label>
        </ion-item>
      </ion-col>

      <ng-template #datePicker let-formControl="formControl" let-placeholder="placeholder" let-mode="mode" let-formFieldName="formFieldName">
        <ion-col size="12" size-sm="6">
          <mbsc-datepicker hidden
            [controls]="['calendar']"
            [options]="datePickerSettings | async"
            (ngModelChange)="formControl.setValue(updateDateValue($event, mode))"
            [ngModel]="formControl.value"
            [ngModelOptions]="{ standalone: true }"
            display="center"
            [dateFormat]="MBSC_DATE_FORMAT"
            [showWeekNumbers]="true"
            [firstSelectDay]="1"
            [firstDay]="1"
            [setText]="'button.apply' | translate"
            [cancelText]="'cancel' | translate"
            [buttons]="[ 'cancel', {text: 'reset_short' | translate, handler: resetDatePickerFunctions[formFieldName]}, 'set' ]"
            [locale]="mbscLocale$ | async"
            [themeVariant]="mbscThemeVariant$ | async"
            theme="windows"
            #dateStartPicker
          ></mbsc-datepicker>
          <ion-button [disabled]="emptyDateFilter" [style.opacity]="formControl.value ? undefined : '0.6'" [class.omg-btn-color-selected]="!!formControl.value" class="omg-btn-secondary date-button" (click)="dateStartPicker.open()">
            <div class="d-flex ion-align-items-center ion-justify-content-between date-button-content">
              <span *ngIf="formControl.value && formControl.value !== 'empty'">{{formControl.value | date:'dd.MM.yy'}}</span>
              <span *ngIf="formControl.value && formControl.value === 'empty'">{{formControl.value | translate}}</span>
              <span *ngIf="!formControl.value">
                {{ placeholder }}
              </span>
              <fa-icon
                *ngIf="formControl.value && !emptyDateFilter"
                class="cursor-pointer fake-button"
                (click)="$event.stopPropagation(); formControl.setValue(null)"
                [icon]="['fal', 'times']"
              ></fa-icon>
            </div>
          </ion-button>
        </ion-col>
      </ng-template>

      <ion-col size="12">
        <div class="d-flex ion-align-items-center gap-2 text-secondary omg-t-medium-body">
          <fa-icon [icon]="['fal', 'calendar-alt']"></fa-icon>
          <span>{{'dashboard.settings.tasks.sortFields.todoUntil' | translate}}</span>
        </div>
      </ion-col>

      <ng-container *ngTemplateOutlet="datePicker; context: {mode: 'start', formControl: $any(form.get('entry.todoUntil.gte')), placeholder: 'protocolEntry.timeFrom' | translate, formFieldName: 'entry.todoUntil.gte'}"></ng-container>
      <ng-container *ngTemplateOutlet="datePicker; context: {mode: 'end', formControl: $any(form.get('entry.todoUntil.lte')), placeholder: 'protocolEntry.timeUntil' | translate, formFieldName: 'entry.todoUntil.lte'}"></ng-container>

      <ion-col size="12" class="ion-no-padding">
        <ion-item lines="none" class="small-item">
          <ion-toggle
            (ionChange)="onEmptyDateFilterChange()"
            appSlimToggle
          ></ion-toggle>
          <ion-label class="slim-toggle-label">{{ ('emptyDateFilterToggle' | translate) }}</ion-label>
        </ion-item>
      </ion-col>

      <ion-col size="12">
        <div class="d-flex ion-align-items-center gap-2 text-secondary omg-t-medium-body">
          <fa-icon [icon]="['fal', 'calendar-alt']"></fa-icon>
          <span>{{'protocolEntry.createdAt' | translate}}</span>
        </div>
      </ion-col>

      <ng-container *ngTemplateOutlet="datePicker; context: {mode: 'start', formControl: $any(form.get('entry.createdAt.gte')), placeholder: 'protocolEntry.timeFrom' | translate, formFieldName: 'entry.createdAt.gte'}"></ng-container>
      <ng-container *ngTemplateOutlet="datePicker; context: {mode: 'end', formControl: $any(form.get('entry.createdAt.lte')), placeholder: 'protocolEntry.timeUntil' | translate, formFieldName: 'entry.createdAt.lte'}"></ng-container>

      <ion-col size="12">
        <app-entry-filter-multi-buttons
          [buttons]="priorities"
          [formControl]="$any(form.get('entry.priority.in'))"
          [forceOneSelected]="false"
          [storeOnlyValue]="true"
        ></app-entry-filter-multi-buttons>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
