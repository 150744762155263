<ion-card class="omg" [button]="!disabled" [class.selected]="selected">
  <app-pdf-plan-holder-list-item-row
    [viewMode]="viewMode"
    [inset]="false"
    [disabled]="disabled"
    [activeToggleDisabled]="activeToggleDisabled"
    [pdfPlanHolder]="pdfPlanHolder"
    [pdfPlanVersion]="pdfPlanHolder.latestPdfPlanVersion"
    [expanded]="expanded"
    [selected]="selected"
    [hideCheckbox]="false"
    (holderClick)="holderClick.emit($event)"
    (actionsClick)="actionsClick.emit($event)"
    (expandClick)="expandClick.emit($event)"
    (activeChange)="activeChange.emit($event)"
    (selectedChange)="selectedChange.emit($event)"
    [nameTemplate]="nameTemplate"
    [indexTemplate]="indexTemplate"
    [dateTemplate]="dateTemplate"
    [scaleTemplate]="scaleTemplate"
    [tagsTemplate]="tagsTemplate"
    [locationTemplate]="locationTemplate"
    [unitTemplate]="unitTemplate"
    [commentTemplate]="commentTemplate"
  ></app-pdf-plan-holder-list-item-row>
  <ng-content></ng-content>
</ion-card>
