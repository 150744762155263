import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule, IonInput, ViewDidEnter} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import _ from 'lodash';
import {distinctUntilChanged, map, Observable, startWith, switchMap} from 'rxjs';
import {ActionWithLoadingDirective} from 'src/app/directives/ui/action-with-loading.directive';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {UnitDataService} from 'src/app/services/data/unit-data.service';
import {UnitProfileDataService} from 'src/app/services/data/unit-profile-data.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {AlertService} from 'src/app/services/ui/alert.service';
import {isPopoverDismissed, PopoverService} from 'src/app/services/ui/popover.service';
import {UnitService} from 'src/app/services/unit/unit.service';
import {UnitContact, UnitContactsService} from 'src/app/services/units/unit-contacts.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {isAddressFilled} from 'src/app/utils/address-utils';
import {getAddressFormConfig} from 'src/app/utils/form-utils';
import {haveObjectsEqualProperties} from 'src/app/utils/object-utils';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {Address, IdType, LicenseType, Unit, UnitLevel, UnitProfile} from 'submodules/baumaster-v2-common';
import {v4} from 'uuid';
import {AddressFormComponent} from '../../common/address-form/address-form.component';
import {CreateUnitInLevelEvent} from '../unit-levels-grid/unit-levels.grid.model';
import {ContactMenuClickEvent, SimplifiedUnitProfile, UnitContactWithSimplifiedUnitProfile, UnitProfilesInUnitComponent} from '../unit-profiles-in-unit/unit-profiles-in-unit.component';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {DeviceService} from 'src/app/services/ui/device.service';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {compareUnsortedArraysByObjectKeys} from 'src/app/utils/compare-utils';
import {RxLet} from '@rx-angular/template/let';

const LOG_SOURCE = 'UnitModalComponent';
const MINIMUM_SERIES_MODE_CREATE_AMOUNT = 1;
const MAXIMUM_SERIES_MODE_CREATE_AMOUNT = 50;
const MINIMUM_START_NUMBER = 0;
const MAXIMUM_START_NUMBER = 100000;

interface UnitNameWithHighlight {
  id: string;
  name: string;
  highlight: boolean;
}

@Component({
  selector: 'app-unit-modal',
  templateUrl: './unit-modal.component.html',
  styleUrls: ['./unit-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, UiModule, ReactiveFormsModule, TranslateModule, FontAwesomeModule, AsyncPipe, AddressFormComponent, UnitProfilesInUnitComponent, FormsModule, RxLet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitModalComponent implements OnInit, ViewDidEnter {
  protected readonly nameMaxLength = 255;
  protected readonly notesMaxLength = 255;
  modal: HTMLIonModalElement;
  form = this.fb.group({
    name: ['', [Validators.maxLength(this.nameMaxLength), Validators.required]],
    notes: ['', [Validators.maxLength(this.notesMaxLength)]],
    address: this.fb.group(getAddressFormConfig()),
    simplifiedUnitProfiles: this.fb.array<SimplifiedUnitProfile>([]),
    startNumber: ['', this.startingNumberValidator()],
    amountUnits: ['', this.amountValidator()],
  });
  @Input()
  unit: Unit | undefined;
  @Input()
  unitLevel: UnitLevel;
  @Input()
  insertPosition: CreateUnitInLevelEvent['insertPosition'] | undefined;
  @Input()
  parentUnitId: IdType;

  currentAddress: Address | undefined;
  currentUnitProfiles: UnitProfile[] = [];
  currentUnitContacts: UnitContact[] = [];
  seriesMode: boolean = false;
  protected hasInputAnyDuplicates$: Observable<boolean>;
  protected hasPreviewAnyDuplicates$: Observable<boolean>;
  protected unitsForPreview$: Observable<UnitNameWithHighlight[] | undefined>;
  protected addressAccordionGroup: [] | ['address'] = [];

  @ViewChild('autofocus', {static: false}) input: IonInput;

  protected allUnitContacts$: Observable<UnitContact[]> = this.unitContactsService.allUnitContacts$;
  protected selectedUnitContacts$: Observable<UnitContactWithSimplifiedUnitProfile[]> = this.form.controls.simplifiedUnitProfiles.valueChanges.pipe(
    startWith(null),
    map(() => this.form.controls.simplifiedUnitProfiles.getRawValue()),
    switchMap((simplifiedUnitProfiles) =>
      this.allUnitContacts$.pipe(
        map((allUnitContacts) => {
          const contacts: UnitContactWithSimplifiedUnitProfile[] = [];
          for (const contact of allUnitContacts) {
            const unitProfile = simplifiedUnitProfiles.find(({profileId}) => contact.id === profileId);
            if (!unitProfile) {
              continue;
            }
            contacts.push({...contact, unitProfile});
          }
          return _.orderBy(
            contacts,
            ['unitProfile.isActive', (contact) => contact?.address.firstName.toLocaleLowerCase(), (contact) => contact?.address.lastName.toLocaleLowerCase()],
            ['desc', 'asc', 'asc']
          );
        })
      )
    )
  );
  protected availableUnitContacts$ = this.form.controls.simplifiedUnitProfiles.valueChanges.pipe(
    startWith(null),
    map(() => this.form.controls.simplifiedUnitProfiles.getRawValue()),
    switchMap((simplifiedUnitProfiles) =>
      this.allUnitContacts$.pipe(
        map((allUnitContacts) => {
          const contacts: UnitContact[] = [];
          for (const contact of allUnitContacts) {
            const isSelected = simplifiedUnitProfiles.some(({profileId}) => contact.id === profileId);
            if (isSelected) {
              continue;
            }
            contacts.push(contact);
          }
          return contacts;
        })
      )
    )
  );
  protected isReadonly$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]).pipe(map((enabled) => !enabled));
  protected isUnitMaintenanceFeatureEnabled$ = this.unitService.isUnitMaintenanceFeatureEnabled$;

  constructor(
    private fb: FormBuilder,
    private unitDataService: UnitDataService,
    private systemEventService: SystemEventService,
    private loggingService: LoggingService,
    private toastService: ToastService,
    private alertService: AlertService,
    private unitContactsService: UnitContactsService,
    private popoverService: PopoverService,
    private projectDataService: ProjectDataService,
    private addressDataService: AddressDataService,
    private unitProfileDataService: UnitProfileDataService,
    private router: Router,
    private unitService: UnitService,
    private featureEnabledService: FeatureEnabledService,
    private deviceService: DeviceService
  ) {}

  protected canDismiss = async (data?: any, role?: any) => {
    const isUnitProfilesDirty = _.reduce(
      this.getUnitProfileMutationSet(this.unit?.id ?? 'stub', this.form.get('simplifiedUnitProfiles').getRawValue()),
      (prev, curr) => prev || curr.length > 0,
      false
    );

    if ((role !== 'save' && role !== 'remove' && isUnitProfilesDirty) || this.form.dirty) {
      return await this.confirmationBeforeLeave();
    }

    return true;
  };

  private async confirmationBeforeLeave(): Promise<boolean> {
    return await this.alertService.confirm({header: 'protocolCreation.data_loss_header', message: 'protocolCreation.data_loss_message'});
  }

  async ngOnInit() {
    this.modal.canDismiss = this.canDismiss;

    if (this.unit) {
      this.form.patchValue(this.unit);
      if (this.unit.addressId) {
        this.currentAddress = await observableToPromise(this.addressDataService.getById(this.unit.addressId));
        if (this.currentAddress) {
          this.form.controls.address.patchValue(this.currentAddress);
          if (isAddressFilled(this.currentAddress)) {
            this.addressAccordionGroup = ['address'];
          }
        }
      }
      const unitContacts = (await observableToPromise(this.unitContactsService.unitContactsByUnitId$))[this.unit.id] ?? [];
      this.currentUnitContacts = unitContacts;
      this.currentUnitProfiles = _.flatten(unitContacts.map(({unitProfiles}) => unitProfiles));
      const simplifiedUnitProfilesControl = this.form.controls.simplifiedUnitProfiles;
      simplifiedUnitProfilesControl.clear();
      for (let i = 0; i < unitContacts.length; i++) {
        const contact = unitContacts[i];
        simplifiedUnitProfilesControl.push(new FormControl({isActive: contact.unitProfiles[0].isActive, isDefault: contact.unitProfiles[0].isDefault, profileId: contact.id}));
      }
    }
    this.unitsForPreview$ = combineLatestAsync([
      this.unitDataService.getByUnitLevel(this.unitLevel.id).pipe(distinctUntilChanged(compareUnsortedArraysByObjectKeys(['name']))),
      this.form.valueChanges.pipe(startWith(this.form.getRawValue())),
    ]).pipe(map(([unitsOfLevel]) => this.getUnitsForPreview(unitsOfLevel)));

    this.hasPreviewAnyDuplicates$ = this.unitsForPreview$.pipe(map((units) => units.some((unit) => unit.highlight)));

    this.hasInputAnyDuplicates$ = combineLatestAsync([
      this.unitDataService.getByUnitLevel(this.unitLevel.id).pipe(distinctUntilChanged(compareUnsortedArraysByObjectKeys(['name']))),
      this.form.valueChanges.pipe(startWith(this.form.getRawValue())),
    ]).pipe(map(([unitsOfLevel, valueChange]) => this.form.controls.name.dirty && unitsOfLevel.some((unit) => unit.name === valueChange.name)));
  }

  async ionViewDidEnter() {
    if (this.deviceService.isDesktop() && (await observableToPromise(this.isUnitMaintenanceFeatureEnabled$))) {
      setTimeout(() => this.input?.setFocus(), 50);
    }
  }

  protected cancel() {
    this.modal.dismiss();
  }

  private getUnitProfileMutationSet(unitId: IdType, simplifiedUnitProfiles: SimplifiedUnitProfile[]): Record<'inserts' | 'updates' | 'deletes', UnitProfile[]> {
    const profileIds = simplifiedUnitProfiles.map(({profileId}) => profileId);
    const existingProfiles = new Set(this.currentUnitProfiles.map(({profileId}) => profileId));
    const newUnitProfiles: UnitProfile[] = profileIds
      .filter((profileId) => !existingProfiles.has(profileId))
      .map((profileId) => ({
        id: v4(),
        changedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        isActive: true,
        isDefault: false,
        profileId,
        unitId,
      }));
    const unitProfilesToDelete = this.currentUnitProfiles.filter(({profileId}) => !profileIds.includes(profileId));
    const unitProfilesToUpdate = _.compact(
      this.currentUnitProfiles.map((cUnitProfile) => {
        const simplifiedUnitProfile = simplifiedUnitProfiles.find((unitProfile) => unitProfile.profileId === cUnitProfile.profileId);
        if (!simplifiedUnitProfile) {
          return null;
        }

        if (haveObjectsEqualProperties(simplifiedUnitProfile, cUnitProfile, ['isDefault', 'isActive'])) {
          return null;
        }

        return {...cUnitProfile, ...simplifiedUnitProfile};
      })
    );

    return {
      inserts: newUnitProfiles,
      updates: unitProfilesToUpdate,
      deletes: unitProfilesToDelete,
    };
  }

  private async persistUnitProfiles(unitId: IdType, simplifiedUnitProfiles: SimplifiedUnitProfile[], projectId: IdType) {
    await this.unitProfileDataService.insertUpdateDelete(this.getUnitProfileMutationSet(unitId, simplifiedUnitProfiles), projectId);
  }

  protected save = async () => {
    try {
      const {simplifiedUnitProfiles, address, name, startNumber, amountUnits, ...data} = this.form.getRawValue();
      let unitNumberStart = _.isNaN(Number(startNumber)) ? undefined : Number(startNumber);
      let unitNumberAmount = _.isNaN(Number(amountUnits)) ? undefined : Number(amountUnits);

      const project = await observableToPromise(this.projectDataService.getById(this.unitLevel.projectId));

      if (!project) {
        throw new Error(`Project ${this.unitLevel.projectId} not found`);
      }

      const createAddressFromForm = async () => {
        const theAddress: Address = {
          ...address,
          id: v4(),
          changedAt: new Date().toISOString(),
          clientId: project.clientId,
        };

        await this.addressDataService.insert(theAddress, theAddress.clientId);

        return theAddress.id;
      };

      let unit: Unit;
      const units: Unit[] = [];
      if (!this.unit) {
        if (!this.seriesMode) {
          unitNumberAmount = 1;
        }
        for (let i = 0; i < unitNumberAmount; i++) {
          let addressId: IdType | undefined;

          if (isAddressFilled(address)) {
            addressId = await createAddressFromForm();
          }

          unit = {
            ...data,
            name: this.seriesMode ? name + ' ' + (unitNumberStart + i) : name,
            addressId,
            id: v4(),
            changedAt: new Date().toISOString(),
            createdAt: new Date().toISOString(),
            index: 0, // Will be updated by `updates`
            parentId: this.parentUnitId,
            unitLevelId: this.unitLevel.id,
          };

          units.push(unit);
        }
        const updates = this.unitService.getEnsureIndexUpdatesForInsertsWithPosition(units, this.insertPosition);

        await this.unitDataService.insertUpdateDelete(
          {
            inserts: units,
            updates,
          },
          this.unitLevel.projectId
        );

        if (!this.seriesMode) {
          await this.persistUnitProfiles(unit.id, simplifiedUnitProfiles, this.unitLevel.projectId);
        }
      } else {
        let addressId = this.unit.addressId;
        if ((!addressId || !this.currentAddress) && isAddressFilled(address)) {
          addressId = await createAddressFromForm();
        }
        if (this.unit.addressId && this.currentAddress && !haveObjectsEqualProperties<Partial<typeof address>>(this.currentAddress, address, ['street1', 'street2', 'zipCode', 'country', 'city'])) {
          await this.addressDataService.update({...this.currentAddress, ...address}, this.currentAddress.clientId);
        }
        unit = {...this.unit, addressId, name, ...data};
        await this.unitDataService.update(unit, this.unitLevel.projectId);

        await this.persistUnitProfiles(this.unit.id, simplifiedUnitProfiles, this.unitLevel.projectId);
      }
      this.form.markAsPristine();
      this.modal.dismiss(unit, 'save');
    } catch (e) {
      const message = `Failed to ${this.unit ? 'update' : 'create'} unit; error: ${convertErrorToMessage(e)}`;
      this.systemEventService.logErrorEvent(LOG_SOURCE, message);
      this.loggingService.error(LOG_SOURCE, message);
      this.toastService.savingError();
    }
  };

  private performRemove = async () => {
    try {
      await this.unitService.deleteUnitsRecursive([this.unit], {deleteEmptyUnitLevels: false});

      this.form.markAsPristine();
      this.modal.dismiss(undefined, 'remove');
    } catch (e) {
      const message = `Failed to delete unit ${this.unit.id}; error: ${convertErrorToMessage(e)}`;
      this.systemEventService.logErrorEvent(LOG_SOURCE, message);
      this.loggingService.error(LOG_SOURCE, message);
      this.toastService.savingError();
    }
  };

  protected async remove(actionWithLoading: ActionWithLoadingDirective) {
    if (!(await observableToPromise(this.isUnitMaintenanceFeatureEnabled$))) {
      this.toastService.infoWithMessageAndHeader('toast.licenseDisabled.header', 'toast.licenseDisabled.message');
      return;
    }
    if (
      !(await this.alertService.confirm({
        header: 'units_settings.remove_unit.header',
        message: 'units_settings.remove_unit.message',
        confirmLabel: 'delete',
        confirmButton: {
          color: 'danger',
          fill: 'solid',
        },
      }))
    ) {
      return;
    }

    actionWithLoading.performAction(this.performRemove);
  }

  protected async handleContactAdded(unitContact: UnitContact) {
    if (await observableToPromise(this.isReadonly$)) {
      return;
    }

    if (!this.form.controls.simplifiedUnitProfiles.getRawValue().some((profile) => profile.profileId === unitContact.id)) {
      this.form.controls.simplifiedUnitProfiles.push(new FormControl({profileId: unitContact.id, isActive: true, isDefault: false}));
    }
  }

  protected async handleContactMenuClick(event: ContactMenuClickEvent) {
    const isDisabledForViewer = await observableToPromise(this.isReadonly$);
    const isUnitMaintenanceFeatureEnabled = await observableToPromise(this.isUnitMaintenanceFeatureEnabled$);
    const result = await this.popoverService.openActions(
      event.event,
      _.compact([
        {
          label: 'units_settings.unit_contacts.go_to_unit_contact',
          role: 'go_to_unit_contact',
          icon: ['fal', 'pen'],
        },
        !isDisabledForViewer
          ? {
              label: 'units_settings.unit_contacts.delete_connection',
              role: 'delete',
              icon: ['fal', 'trash-alt'],
              lookDisabled: !isUnitMaintenanceFeatureEnabled,
            }
          : undefined,
        this.unit && !isDisabledForViewer
          ? {
              label: event.unitContact.unitProfile.isActive ? 'units_settings.unit_contacts.archive_connection' : 'units_settings.unit_contacts.restore_connection',
              role: event.unitContact.unitProfile.isActive ? ('archive' as const) : ('restore' as const),
              icon: ['fal', event.unitContact.unitProfile.isActive ? 'archive' : 'redo-alt'] as [string, string],
              lookDisabled: !isUnitMaintenanceFeatureEnabled,
            }
          : undefined,
      ])
    );

    if (isPopoverDismissed(result)) {
      return;
    }

    const processSimplifiedUnitProfiles = (processFn: (control: FormControl<SimplifiedUnitProfile>, index: number) => unknown): void => {
      for (let i = 0; i < this.form.controls.simplifiedUnitProfiles.length; i++) {
        const control = this.form.controls.simplifiedUnitProfiles.at(i);
        if (control.value.profileId === event.unitContact.profile.id) {
          processFn(control, i);
          break;
        }
      }
    };

    switch (result) {
      case 'go_to_unit_contact':
        const addressId = event.unitContact.address.id;
        this.modal.dismiss(undefined, 'go_to_unit_contact').then((dismissed) => {
          if (dismissed) {
            this.router.navigate([`/contact-detail/unit-contact/${addressId}`]);
          }
        });
        return;
      case 'delete':
        if (!isUnitMaintenanceFeatureEnabled) {
          this.toastService.infoWithMessageAndHeader('toast.licenseDisabled.header', 'toast.licenseDisabled.message');
          return;
        }
        processSimplifiedUnitProfiles((_control, i) => this.form.controls.simplifiedUnitProfiles.removeAt(i));
        return;
      case 'archive':
        if (!isUnitMaintenanceFeatureEnabled) {
          this.toastService.infoWithMessageAndHeader('toast.licenseDisabled.header', 'toast.licenseDisabled.message');
          return;
        }
        processSimplifiedUnitProfiles((control) => control.patchValue({...control.value, isActive: false}));
        return;
      case 'restore':
        if (!isUnitMaintenanceFeatureEnabled) {
          this.toastService.infoWithMessageAndHeader('toast.licenseDisabled.header', 'toast.licenseDisabled.message');
          return;
        }
        processSimplifiedUnitProfiles((control) => control.patchValue({...control.value, isActive: true}));
        return;
    }
  }

  private getUnitsForPreview(unitsOfLevel: Unit[]) {
    const {name} = this.form.getRawValue();
    const startNumber = _.isNaN(Number(this.form.value?.startNumber)) || this.form.value?.startNumber === '' ? -1 : Number(this.form.value?.startNumber);
    const amountUnits = Number(this.form.value?.amountUnits);
    const showPreview = !!name && startNumber >= MINIMUM_START_NUMBER && amountUnits >= MINIMUM_SERIES_MODE_CREATE_AMOUNT && amountUnits <= MAXIMUM_SERIES_MODE_CREATE_AMOUNT;
    if (!showPreview) {
      return [];
    }
    return this.createPreviewArray(unitsOfLevel, name, startNumber, amountUnits);
  }

  private createPreviewArray(unitsOfLevel: Unit[], name: string, startingNumber: number, amountUnits: number) {
    const unitsForPreview = [];
    for (let i = 0; i < amountUnits; i++) {
      const unitName = name + ' ' + (i + startingNumber);
      const highlight = unitsOfLevel.some((unit) => unit.name === unitName);
      unitsForPreview.push({id: v4(), name: unitName, highlight});
    }
    return unitsForPreview;
  }

  toggleSeriesMode() {
    this.seriesMode = !this.seriesMode;
    if (this.seriesMode) {
      this.form.controls.simplifiedUnitProfiles.clear();
    } else {
      this.form.controls.startNumber.updateValueAndValidity();
      this.form.controls.amountUnits.updateValueAndValidity();
    }
  }

  private startingNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startingNumber: string | null = control.value;
      try {
        if (!this.seriesMode) {
          return null;
        }

        if (startingNumber === '') {
          return {empty: true};
        }

        if (_.isNaN(Number(startingNumber))) {
          return {invalid: true};
        }

        if (Number(startingNumber) < MINIMUM_START_NUMBER || Number(startingNumber) > MAXIMUM_START_NUMBER) {
          return {invalid: true};
        }

        return null;
      } catch (error) {
        return null;
      }
    };
  }

  private amountValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const amount: string | null = control.value;
      try {
        if (!this.seriesMode) {
          return null;
        }

        if (amount === '') {
          return {empty: true};
        }

        if (_.isNaN(Number(amount))) {
          return {invalid: true};
        }

        if (Number(amount) < MINIMUM_SERIES_MODE_CREATE_AMOUNT || Number(amount) > MAXIMUM_SERIES_MODE_CREATE_AMOUNT) {
          return {invalid: true};
        }

        return null;
      } catch (error) {
        return null;
      }
    };
  }
}
