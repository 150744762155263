<form class="email-frame" [formGroup]="emailSettingsForm" action="#">
  <div (click)="collapsedRecipients = !collapsedRecipients" class="email-frame__row cursor-pointer" [ngClass]="{'email-frame__row--no-wrap email-frame__row--overflow-gradient': collapsedRecipients}">
    <div class="email-frame__row__label">
      {{ 'sendProtocol.emailSettings.receipents' | translate }}:
    </div>
    <div *ngFor="let email of recipientEmails" class="email-frame__row__chip">
      {{ email }}
    </div>
  </div>
  <div class="email-frame__row">
    <div class="email-frame__row__label">
      {{ 'sendProtocol.emailSettings.sender' | translate }}:
    </div>
    <span class="email-frame__row__text" *ngIf="(address$ | async) as address">
      {{ address.firstName }} {{ address.lastName }}
      <span class="email-frame__row__text--small">({{ senderEmailAddress }})</span>
    </span>
  </div>
  <div class="email-frame__row">
    <ion-input label="{{ 'sendProtocol.emailSettings.subject' | translate }}:" class="email-frame__row__text no-padding legacy" fill="outline" formControlName="subject" type="text" spellcheck="true"></ion-input>
  </div>
  <div class="email-frame__body" [ngClass]="{'no-gap': workflowType === WorkflowTypeEnum.EntryMail}">
    <ion-textarea [placeholder]="'sendProtocol.emailSettings.text' | translate" auto-grow="true" color="no-padding" formControlName="text" type="text" class="legacy" fill="outline" spellcheck="true"></ion-textarea>

    <ng-template #defaultBetweenTemplate>
      <ion-button id="send-pdf-workflow-download-button" class="ion-align-self-start omg-btn-primary protocol-button">{{ pdfDownloadButtonLabel | translateInLanguage:language | async }}</ion-button>
      <app-tooltip target="send-pdf-workflow-download-button" placement="right">
        {{ 'sendProtocol.emailSettings.downloadButtonTooltip' | translate }}
      </app-tooltip>

      <ng-container *ngIf="form.get('individualProtocol').value">
        <div>
          <p>{{ 'sendProtocol.emailSettings.pdfIndividualText' | translateInLanguage:language | async }}</p>
          <ion-button id="send-pdf-workflow-individual-download-button" class="ion-align-self-start omg-btn-primary protocol-button">{{ 'sendProtocol.emailSettings.pdfIndividualDownloadText' | translateInLanguage:language | async }}</ion-button>
        </div>
        <app-tooltip target="send-pdf-workflow-individual-download-button" placement="right">
          {{ 'sendProtocol.emailSettings.downloadButtonTooltip' | translate }}
        </app-tooltip>
      </ng-container>
    </ng-template>
    <ng-container *ngTemplateOutlet="betweenTemplate || defaultBetweenTemplate"></ng-container>

    <ion-textarea [placeholder]="'sendProtocol.emailSettings.mailTextUnderDownloadLink' | translate" auto-grow="true" color="no-padding" formControlName="mailTextUnderDownloadLink" type="text" class="legacy" fill="outline" spellcheck="true"></ion-textarea>

    <ng-container *ngIf="showOnlyTemplateOptions; else signatureTemplate">
      <app-email-signature-raw-preview *ngIf="form.get('addSignature').value && userEmailSignature" [signature]="userEmailSignature"></app-email-signature-raw-preview>
      <ion-text color="text-secondary" *ngIf="form.get('addSignature').value && !userEmailSignature">
        {{ 'sendProtocol.emailSettings.noSignatureDefinedPlaceholder' | translate }}
      </ion-text>
    </ng-container>
    <ng-template #signatureTemplate>
      <app-email-signature-inline-form [ngClass]="{'pt-3': workflowType === WorkflowTypeEnum.EntryMail}" *ngIf="form.get('addSignature').value" formControlName="emailSignature" (attachmentCreate)="attachmentUserEmailSignatureCreate.emit($event)"></app-email-signature-inline-form>
    </ng-template>

    <ng-container *ngIf="showPostScriptum$ | async">
      <p [ngClass]="{'pt-3': workflowType === WorkflowTypeEnum.EntryMail}" id="pdf-send-closing-text" [innerHTML]="closingText | translateInLanguage:language | async"></p>
      <app-tooltip target="pdf-send-closing-text" [placement]="'bottom'">
        {{ 'sendProtocol.emailSettings.closingTextTooltip' | translate }}
      </app-tooltip>
    </ng-container>
  </div>
  <div class="email-frame__row ion-align-items-center">
    <div class="ion-align-items-center email-frame__gapped email-frame__gapped--full-width">
      <ion-item class="add-signature-toggle-item no-padding cursor-pointer" lines="none" (click)="form.get('addSignature').setValue(!form.get('addSignature').value)">
        <ion-toggle formControlName="addSignature" slot="start" class="ion-no-padding" appSlimToggle (click)="$event.stopPropagation()"></ion-toggle>
        <ion-label class="ion-no-margin">{{ 'sendProtocol.addSignature' | translate }}</ion-label>
      </ion-item>
      <ion-button class="omg-btn-secondary" *ngIf="!showOnlyTemplateOptions && workflowType === WorkflowTypeEnum.Protocol && form.get('addSignature').value && !compareSignatures(form.get('emailSignature').value, userEmailSignature)" (click)="saveSignatureAsDefaultSettings.emit()" [disabled]="pendingSettingsSaving">
        <ion-spinner slot="start" class="spinner-center-button" *ngIf="pendingSettingsSaving"></ion-spinner>
        <fa-icon slot="start" [icon]="['fal', 'star']"></fa-icon>
        <span class="ion-hide-sm-down ion-hide-lg-up">{{ 'sendProtocol.emailSettings.saveAsDefaultSignatureShort' | translate }}</span>
        <span class="ion-hide-lg-down">{{ 'sendProtocol.emailSettings.saveAsDefaultSignature' | translate }}</span>
      </ion-button>
    </div>
    <div class="ion-align-items-center email-frame__gapped email-frame__gapped--full-width" *ngIf="!showOnlyTemplateOptions">
      <ion-button *ngIf="showSaveTemplate || workflowType === WorkflowTypeEnum.Protocol" class="omg-btn-secondary" (click)="saveAsDefaultSettings.emit()" [disabled]="pendingSettingsSaving">
        <ion-spinner class="spinner-center-button" *ngIf="pendingSettingsSaving"></ion-spinner>
        <fa-icon slot="start" [icon]="['fal', 'star']"></fa-icon>
        <span class="ion-hide-sm-down ion-hide-lg-up">{{ 'sendProtocol.emailSettings.saveAsDefaultEmailSettingsShort' | translate }}</span>
        <span class="ion-hide-lg-down">{{ 'sendProtocol.emailSettings.saveAsDefaultEmailSettings' | translate }}</span>
      </ion-button>
      <ion-button *ngIf="!hideResetButton" class="omg-btn-secondary" (click)="restoreDefaultValues()">
        <fa-icon slot="start" [icon]="['fal', 'undo']"></fa-icon>
        <span class="ion-hide-sm-down">{{'sendProtocol.resetValues' | translate }}</span>
      </ion-button>
    </div>
  </div>
</form>
