<app-modal-header>
  <ion-title>
    @if (unit) {
      {{'units_settings.edit_unit' | translate}}
    } @else {
      {{'units_settings.new_unit' | translate}}
    }
  </ion-title>
  @if (!(isUnitMaintenanceFeatureEnabled$ | async)) {
    <div class="ion-text-center non-admin-text-container">
      <div class="omg-attention omg-attention-warning ion-text-center flex-column">
        {{'units_settings.non_expert_license' | translate}}
      </div>
    </div>
  }
</app-modal-header>

<ion-content>
  <form [formGroup]="form" class="omg-form app-split-pane gap-4">
    <ion-content class="app-split-pane-side d-flex flex-column ion-hide-lg-down with-padding">
      <app-unit-profiles-in-unit
        class="flex-grow-1"
        [unitContacts]="selectedUnitContacts$ | async"
        [unselectedUnitContacts]="availableUnitContacts$ | async"
        (contactMenuClick)="handleContactMenuClick($event)"
        (contactAdded)="handleContactAdded($event)"
        [readonly]="!(isUnitMaintenanceFeatureEnabled$ | async)"
        [disabledInSeriesMode]="seriesMode"
      ></app-unit-profiles-in-unit>
    </ion-content>
    <ion-content class="app-split-pane-main with-padding">
      <ion-grid class="grid-gap-4">
        <ion-row>
          <ion-col size="12">
            <ion-item class="omg-can-error-text omg-form-invalid-after-touched" [class.warning-duplicate]="(hasInputAnyDuplicates$ | async) && !seriesMode" [disabled]="!(isUnitMaintenanceFeatureEnabled$ | async)">
              <ion-input #autofocus [class.warning-duplicate]="(hasInputAnyDuplicates$ | async) && !seriesMode" [label]="'units_settings.unit_form.name' | translate" label-placement="floating" type="text"
                formControlName="name" [maxlength]="nameMaxLength" class="omg-required" [errorText]="form.controls.name.errors && form.controls.name.dirty ? ((form.controls.name.errors.maxlength ? 'formValidation.fieldTooLong' : 'formValidation.emptyField') | translate:{field: 'units_settings.unit_form.name' | translate, maxLength: nameMaxLength}) : ''"></ion-input>
              </ion-item>
              @if ((hasInputAnyDuplicates$ | async) && !seriesMode) {
                <span class="omg-t-xs-body grey-text pl-1">{{ 'units_settings.series_mode.duplicateInput' | translate }}</span>
              }
          </ion-col>
          @if (seriesMode && !unit) {
            <ion-col size="6">
              <ion-item class="omg-can-error-text omg-form-invalid-after-touched" [disabled]="!(isUnitMaintenanceFeatureEnabled$ | async)">
                <ion-input [label]="'units_settings.series_mode.start_number' | translate" label-placement="floating" type="text"
                  formControlName="startNumber" class="omg-required" [errorText]="form.controls.startNumber?.errors && form.controls.startNumber?.dirty ? ((form.controls.startNumber.errors.invalid ? 'units_settings.series_mode.invalid' : 'units_settings.series_mode.empty') | translate) : ''"></ion-input>
              </ion-item>
            </ion-col>
            <ion-col size="6">
              <ion-item class="omg-can-error-text omg-form-invalid-after-touched" [disabled]="!(isUnitMaintenanceFeatureEnabled$ | async)">
                <ion-input [label]="'units_settings.series_mode.amount' | translate" label-placement="floating" type="text"
                  formControlName="amountUnits" class="omg-required" [errorText]="form.controls.amountUnits?.errors && form.controls.amountUnits?.dirty ? ((form.controls.amountUnits.errors.invalid ? 'units_settings.series_mode.invalidAmount' : 'units_settings.series_mode.empty') | translate) : ''"></ion-input>
              </ion-item>
            </ion-col>
          }
        </ion-row>

        @if (unit?.externalId) {
        <ion-row>
          <ion-col>
            <ion-item [disabled]="true">
              <ion-input [label]="'units_settings.unit_form.external_id' | translate" label-placement="floating" type="text"
                [ngModel]="unit.externalId" [ngModelOptions]="{standalone: true}" [readonly]="true"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        }

        @if (!unit) {
          <ion-row class="pl-1">
            <ion-col>
              <ion-item class="omg omg-form-checkbox" (click)="toggleSeriesMode()" [disabled]="!(isUnitMaintenanceFeatureEnabled$ | async)">
                <ion-checkbox label-placement="end" justify="start" [ngModel]="seriesMode" [ngModelOptions]="{standalone: true}">
                  {{ 'units_settings.series_mode.multiple' | translate }}
                </ion-checkbox>
               </ion-item>
            </ion-col>
          </ion-row>
        }

        @if (seriesMode) {
          <div *rxLet="unitsForPreview$; let unitsForPreview" class="preview-container mr-3 ml-3">
              <div class="omg-modal-header fix-padding">
                {{ "units_settings.series_mode.preview_heading" | translate:{count: unitsForPreview?.length ?? 0} }}
              </div>
              @if (!unitsForPreview?.length) {
                <div class="text-grey omg-t-medium-body">
                  {{ "units_settings.series_mode.preview_info" | translate }}
                </div>
              }

              @if (hasPreviewAnyDuplicates$ | async) {
                <div class="mt-2 omg-attention omg-attention-warning">
                  <span class="omg-t-medium-body">{{ 'units_settings.series_mode.duplicate' | translate }}</span>
                </div>
              }
              <div class="pt-2">
                @for (unit of unitsForPreview; track unit.id) {
                  <div class="preview-item">
                    @if (unit.highlight) {
                      <fa-layers class="created-in-another-protocol-icon">
                        <fa-icon [fixedWidth]="true" [icon]="['fas', 'triangle']" class="tri-bg"></fa-icon>
                        <fa-icon [fixedWidth]="true" [icon]="['fal', 'exclamation-triangle']"></fa-icon>
                      </fa-layers>
                    }
                    <span [class.pl-2]="unit.highlight">{{unit.name}}</span>
                  </div>
                }
              </div>
            </div>
        }

        <ion-row class="ion-hide-lg-up">
          <ion-col>
            <app-unit-profiles-in-unit
              class="flex-grow-1"
              [unitContacts]="selectedUnitContacts$ | async"
              [unselectedUnitContacts]="availableUnitContacts$ | async"
              (contactMenuClick)="handleContactMenuClick($event)"
              (contactAdded)="handleContactAdded($event)"
              [disabledInSeriesMode]="seriesMode"
            ></app-unit-profiles-in-unit>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <div class="omg-modal-header">
              {{ 'units_settings.unit_form.other' | translate }}
            </div>
            <ion-item class="omg-can-error-text omg-form-invalid-after-touched" [disabled]="!(isUnitMaintenanceFeatureEnabled$ | async)">
              <ion-input #autofocus [label]="'units_settings.unit_form.notes' | translate" label-placement="floating" type="text"
              formControlName="notes" [maxlength]="notesMaxLength" [errorText]="form.controls.name.errors && form.controls.name.dirty ? ((form.controls.name.errors.maxlength ? 'formValidation.fieldTooLong' : 'formValidation.emptyField') | translate:{field: 'units_settings.unit_form.notes' | translate, maxLength: 500}) : ''"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-accordion-group [multiple]="true" [value]="addressAccordionGroup" class="mt-2 pr-3 pl-3">
          <ion-accordion toggleIconSlot="start" [value]="'address'">
            <ion-item slot="header" lines="none">
              <ion-label>{{ "companyForm.address_divider" | translate }}</ion-label>
            </ion-item>
            <div slot="content">    
              <app-address-form [readonly]="!(isUnitMaintenanceFeatureEnabled$ | async)" [withHeader]="false" formGroupName="address"></app-address-form>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-grid>
    </ion-content>
  </form>
</ion-content>

<app-modal-footer appActionWithLoading #actionWithLoading="appActionWithLoading">
  @if (unit && !(isReadonly$ | async)) {
  <ion-buttons slot="start">
    <ion-button [class.look-disabled]="!(isUnitMaintenanceFeatureEnabled$ | async)" class="omg-btn-secondary omg-btn-color-danger" [disabled]="form.invalid || (actionWithLoading.loading$ | async)" (click)="remove(actionWithLoading)">
      @if (actionWithLoading.loading$ | async) {
      <ion-spinner class="spinner-center-button"></ion-spinner>
      }
      <fa-icon slot="start" [icon]="['fal', 'trash-alt']"></fa-icon>
      <span>{{'delete' | translate}}</span>
    </ion-button>
  </ion-buttons>
  }
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">{{'cancel' | translate}}</ion-button>
    @if (isUnitMaintenanceFeatureEnabled$ | async) {
    <ion-button class="omg-btn-primary" [disabled]="form.invalid || (actionWithLoading.loading$ | async)" (click)="actionWithLoading.performAction(save)">
      @if (actionWithLoading.loading$ | async) {
      <ion-spinner class="spinner-center-button"></ion-spinner>
      }
      <span>{{'button.save' | translate}}</span>
    </ion-button>
    }
  </ion-buttons>
</app-modal-footer>
