<ion-text class="omg" [appLayout]="0.75" appLayoutAlignItems="center">
  <div class="cell cell__auto" [appLayout]="0.5">
    <div class="checkbox" [class.ion-activatable]="!hideCheckbox" (click)="$event.stopPropagation(); selectedChange.emit(!selected)">
      <ion-checkbox class="omg-checkbox" disabled [indeterminate]="indeterminate" [checked]="selected" [hidden]="hideCheckbox"></ion-checkbox>
    </div>
    <div class="name-box" (click)="analytics.planHeaderClicked('name'); activeSortChange.emit('name')" [class.active]="activeSort === 'name'">
      {{'project_room.pdf_plan_holders.list_header.plan_name' | translate}}
      <fa-icon [icon]="['fal', 'sort-alt']"></fa-icon>
    </div>
  </div>
  <div class="cell cell__index" (click)="analytics.planHeaderClicked('index'); activeSortChange.emit('index')" [class.active]="activeSort === 'index'">
    {{'project_room.pdf_plan_holders.list_header.index' | translate}}
    <fa-icon [icon]="['fal', 'sort-alt']"></fa-icon>
  </div>
  <div class="cell cell__scale">
    {{'project_room.pdf_plan_version_form.scale' | translate}}
  </div>
  <div class="cell cell__date" (click)="analytics.planHeaderClicked('date'); activeSortChange.emit('date')" [class.active]="activeSort === 'date'">
    {{'project_room.pdf_plan_holders.list_header.date' | translate}}
    <fa-icon [icon]="['fal', 'sort-alt']"></fa-icon>
  </div>
  <div class="cell cell__tags" (click)="analytics.planHeaderClicked('tags')">
    {{'project_room.pdf_plan_holders.list_header.tags' | translate}}
  </div>
  <div class="cell cell__locationId" (click)="analytics.planHeaderClicked('location')">
    {{'Location' | translate}}
  </div>
  <div class="cell cell__unitId" (click)="analytics.planHeaderClicked('unit')">
    <bdi>{{'protocol.unit.unit' | translate}}</bdi>
  </div>
  <div class="cell cell__icon cell__comment"></div>
  <div class="cell cell__icon cell__markings"></div>
  <div class="cell cell__marker" (click)="analytics.planHeaderClicked('marker')">
    {{'project_room.pdf_plan_holders.list_header.marker' | translate}}
  </div>
  <div class="cell cell__active_and_actions" (click)="analytics.planHeaderClicked('actions')">
    {{'project_room.pdf_plan_holders.list_header.actions' | translate}}
  </div>
</ion-text>
