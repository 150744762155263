import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {Subject, take, takeUntil} from 'rxjs';
import {BauCheckboxDirective} from 'src/app/directive/common/bau-checkbox.directive';
import {PdfPlanFolderColumnSettings} from 'src/app/model/pdf-plan-folder-column-settings';
import {LoggingService} from 'src/app/services/common/logging.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {ProjectRoomPdfPlanFolderColumnsService} from 'src/app/services/project-room/project-room-pdf-plan-folder-columns.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {UiModule} from 'src/app/shared/module/ui/ui.module';

const LOG_SOURCE = 'ProjectRoomPdfPlanFolderColumnsModalComponent';

@Component({
  selector: 'app-project-room-pdf-plan-folder-columns-modal',
  templateUrl: './project-room-pdf-plan-folder-columns-modal.component.html',
  styleUrls: ['./project-room-pdf-plan-folder-columns-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, UiModule, IonicModule, TranslateModule, BauCheckboxDirective],
})
export class ProjectRoomPdfPlanFolderColumnsModalComponent implements OnInit, OnDestroy {
  private readonly modal: HTMLIonModalElement;
  private readonly destroy$ = new Subject<void>();

  protected readonly form = this.fb.group({
    showColumns: this.fb.group<PdfPlanFolderColumnSettings['showColumns']>({date: true, index: true, locationId: true, scale: true, tags: true, unitId: true}),
  });

  constructor(
    private projectRoomPdfPlanFolderColumnsService: ProjectRoomPdfPlanFolderColumnsService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private systemEventService: SystemEventService,
    private loggingService: LoggingService
  ) {}

  ngOnInit() {
    this.projectRoomPdfPlanFolderColumnsService.data.pipe(take(1), takeUntil(this.destroy$)).subscribe((data) => {
      if (this.form.pristine) {
        this.form.patchValue(data);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected close() {
    this.modal.dismiss();
  }

  protected async save() {
    try {
      const settings = this.form.getRawValue();
      await this.projectRoomPdfPlanFolderColumnsService.upsert(settings);
      this.modal.dismiss();
    } catch (e) {
      const message = `Failed to save PDF plan columns settings; error: ${convertErrorToMessage(e)}`;
      this.loggingService.error(LOG_SOURCE, message);
      this.systemEventService.logErrorEvent(LOG_SOURCE, message);
      this.toastService.savingError();
    }
  }
}
