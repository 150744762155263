import {AttachmentKind, UnprocessableFileForPdfGenerationError} from '../shared/errors';

const BASE64_DATA_PREFIX = 'data:';
const BASE64_PART = ';base64,';
const JPEG_PNG_HEADER_BYTE_LENGTHS = 8;

const simpleBase64ToUint8Array = (base64: string): Uint8Array => {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const isUint8ArrayJpegOrPng = (headerBytes: Uint8Array): boolean => {
  // Logic taken from pdfmake
  if (headerBytes[0] === 0xff && headerBytes[1] === 0xd8) {
    // JP(E)G
    return true;
  } else if (headerBytes[0] === 0x89 && headerBytes[1] === 'P'.charCodeAt(0) && headerBytes[2] === 'N'.charCodeAt(0) && headerBytes[3] === 'G'.charCodeAt(0)) {
    // PNG
    return true;
  } else {
    return false;
  }
};

export const isUint8ArrayJpeg = (headerBytes: Uint8Array): boolean => {
  if (headerBytes[0] === 0xff && headerBytes[1] === 0xd8) {
    return true;
  } else {
    return false;
  }
};

function headerBytesFromBase64(base64: string): string | undefined {
  if (!base64.startsWith(BASE64_DATA_PREFIX)) {
    return undefined;
  }
  const indexOfData = base64.indexOf(BASE64_PART);
  if (indexOfData === -1) {
    return undefined;
  }
  return base64.substring(indexOfData + BASE64_PART.length, indexOfData + BASE64_PART.length + JPEG_PNG_HEADER_BYTE_LENGTHS);
}

export const isJpegOrPng = (base64: string): boolean => {
  // We need 4 bytes to determine JPEG and PNG header.
  // 6 bytes as base64 is 8 characters
  const headerBase64 = headerBytesFromBase64(base64);
  if (!headerBase64) {
    return false;
  }
  const headerBytes = simpleBase64ToUint8Array(headerBase64);
  return isUint8ArrayJpegOrPng(headerBytes);
};

export const isJpeg = (base64: string): boolean => {
  const headerBase64 = headerBytesFromBase64(base64);
  if (!headerBase64) {
    return false;
  }
  const headerBytes = simpleBase64ToUint8Array(headerBase64);
  return isUint8ArrayJpeg(headerBytes);
};

export const ensureContentIsJpegOrPng = async (file: File, kind: AttachmentKind): Promise<void> => {
  if (['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {
    const header = file.slice(0, 4);
    const arrayHeader = await header.arrayBuffer();
    const array = new Uint8Array(arrayHeader);
    if (!isUint8ArrayJpegOrPng(array)) {
      throw new UnprocessableFileForPdfGenerationError(file, kind);
    }
  }
};

export function getImageNameSuffix(lang: string, isDark: boolean) {
  return `${isDark ? 'dm' : 'lm'}-${lang === 'de' || lang === 'en' ? lang : 'en'}`;
}
