<app-modal-header>
  <ion-title>{{'pdfPlanMarker.selectPlan' | translate}}</ion-title>
  <ion-searchbar #autofocus debounce="200" [(ngModel)]="searchText" (ngModelChange)="filterPlans()"
                  placeholder="{{'search' | translate}}" class="slim-searchbar mt-4"></ion-searchbar>
</app-modal-header>

<ion-content>
  <div class="ion-padding no-files-found" *ngIf="filteredFolderFilesTree.length === 0">
    <span class="omg-t-header-3-bold">
      {{ 'pdfPlanMarker.empty.header' | translate }}
    </span>
    <div class="omg-t-body">
      {{ 'pdfPlanMarker.empty.body' | translate }}
    </div>
  </div>
  <ion-list *ngIf="filteredFolderFilesTree.length > 0" lines="none" class="omg-boundary project-list">
    <ion-item-divider class="with-line" *ngIf="!searchText && recentlyUsedPlanVersions?.length > 0">
      {{'recently_used' | translate}}
    </ion-item-divider>
    <div *ngIf="!searchText">
      <ion-radio-group
      (ngModelChange)="openPdfPlanMarker($event)"
      [ngModel]="selectedVersion">
        <ion-item lines="none" *ngFor="let recentFile of recentlyUsedPlanVersions">
          <ion-radio label-placement="end" justify="start" [value]="recentFile">{{ recentFile?.name }}</ion-radio>
        </ion-item>
      </ion-radio-group>
      <div class="with-line"></div>
    </div>
    <ion-item-divider class="with-line">
      {{'all' | translate}}
    </ion-item-divider>
    <div *ngFor="let folderFiles of filteredFolderFilesTree">
      <div *ngIf="folderFiles.files.length > 0">
        <ion-item-divider>
          <fa-icon [icon]="folderFiles.folder.icon ? [removeSuffixForIcon(folderFiles.folder.icon), removePrefixForIcon(folderFiles.folder.icon)] : ['fal', 'folder-open']" class="tree-icons">
          </fa-icon>  
          {{ folderFiles.folder?.name }}
        </ion-item-divider>
        <div class="with-line"></div>
        <ion-radio-group (ngModelChange)="openPdfPlanMarker($event)" [ngModel]="selectedVersion">
          <ion-item lines="none" *ngFor="let file of this.folderFiles.files">
            <ion-radio label-placement="end" justify="start" [value]="file">{{ file?.name }}</ion-radio>
          </ion-item>
        </ion-radio-group>
      </div>
    </div>
  </ion-list>
</ion-content>
