<app-modal-header>
  <ion-title>{{'project_room.attachments' | translate}}</ion-title>
  <div *ngIf="(selectedAttachments$ | async)?.length === maxSelectionLimit" class="ion-text-center attention-text-container pt-3 pb-3">
    <ion-label class="omg-attention omg-attention-warning omg-t-body">
      {{'project_room.attachmentLimitWarning' | translate: {limit: (selectedAttachments$ | async)?.length} }}
    </ion-label>
  </div>
</app-modal-header>

<ion-content class="with-padding">
  <div #attachmentContainer class="attachmentContainer">
    <app-project-room-attachments-list appProjectRoomAttachmentsSelectMode [selectionLimit]="maxSelectionLimit" [projectId]="projectId" *ngIf="viewportWidth" [viewportWidth]="viewportWidth" [openFullscreenOnClick]="false" [maxSelectionLimit]="maxSelectionLimit"></app-project-room-attachments-list>
  </div>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">
      {{ 'cancel' | translate }}
    </ion-button>
    <ion-button *rxLet="selectedAttachments$; let selectedAttachments" class="omg-btn-primary" (click)="select()" [disabled]="!selectedAttachments?.length">
      {{ 'button.add' | translate }}
      <span *ngIf="selectedAttachments?.length">({{selectedAttachments.length}})</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
