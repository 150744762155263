import {AfterViewInit, Component, EventEmitter, Input, Output, QueryList, ViewChildren} from '@angular/core';
import _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {CompanyDataService} from 'src/app/services/data/company-data.service';
import {ProfileDataService} from 'src/app/services/data/profile-data.service';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {Address, Company, Participant, Profile} from 'submodules/baumaster-v2-common';
import {IndividualNextMeetingsComponent} from '../individual-next-meetings/individual-next-meetings.component';
import {IndividualNextMeetingForm, IndividualNextMeetingsGroup, NextMeetingForm} from '../next-meeting.interface';
import {IonicSelectableComponent} from 'ionic-selectable';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../../services/common/selectable-util.service';

type ProfileWithAddressCompany = Profile & {address: Address; company: Company; searchText: string};

const sortByCompanyAndAddress = <T extends {company: Company; address: Address}>(objects: T[]): T[] =>
  _.sortBy(objects, [(p) => p.company?.name?.toLocaleLowerCase(), (p) => p.address?.firstName?.toLocaleLowerCase(), (p) => p.address?.lastName?.toLocaleLowerCase()]);

@Component({
  selector: 'app-individual-next-meetings-groups',
  templateUrl: './individual-next-meetings-groups.component.html',
  styleUrls: ['./individual-next-meetings-groups.component.scss'],
})
export class IndividualNextMeetingsGroupsComponent implements AfterViewInit {
  private meetingsSubject = new BehaviorSubject<IndividualNextMeetingForm[]>([]);
  private participantsSubject = new BehaviorSubject<Participant[]>([]);

  @Input()
  set meetings(meetings: IndividualNextMeetingForm[]) {
    this.meetingsSubject.next(meetings);
  }
  @Input()
  set participants(participants: Participant[]) {
    this.participantsSubject.next(participants ?? []);
  }
  @Input()
  nextMeeting: NextMeetingForm;

  @Output()
  individualNextMeetingsChange = new EventEmitter<IndividualNextMeetingForm[]>();
  @Output()
  nextMeetingChange = new EventEmitter<NextMeetingForm>();
  @Output()
  afterViewInit = new EventEmitter<void>(true);

  @ViewChildren(IndividualNextMeetingsComponent) meetingLists: QueryList<IndividualNextMeetingsComponent>;

  selectProfiles$: Observable<Profile[]> = this.meetingsSubject.pipe(map((meetings) => meetings.map((meeting) => meeting.profile)));

  meetings$: Observable<IndividualNextMeetingForm[]> = this.meetingsSubject.pipe(map((theMeetings) => _.sortBy(theMeetings, ['timeStart'])));

  profiles$: Observable<ProfileWithAddressCompany[]> = combineLatestAsync([
    this.participantsSubject,
    this.profileDataService.data,
    this.addressDataService.dataGroupedById,
    this.companyDataService.dataGroupedById,
  ]).pipe(
    map<[Participant[], Profile[], Record<string, Address>, Record<string, Company>], [Profile[], Record<string, Address>, Record<string, Company>]>(([participants, profiles, ...rest]) => [
      profiles.filter((profile) => participants.some((participant) => participant.mailingList && participant.profileId === profile.id)),
      ...rest,
    ]),
    map(([profiles, addresses, companies]) =>
      sortByCompanyAndAddress(
        profiles
          .filter((profile) => profile.isActive)
          .map((profile) => ({
            ...profile,
            company: companies[profile.companyId],
            address: addresses[profile.addressId],
            searchText: `${addresses[profile.addressId]?.firstName ?? ''} ${addresses[profile.addressId]?.lastName ?? ''} ${
              addresses[profile.addressId]?.email ?? ''
            } ${companies[profile.companyId]?.name ?? ''}`,
          }))
      )
    )
  );

  readonly trackByCompany = (group: IndividualNextMeetingsGroup) => group.company?.id ?? group.meetings?.[0]?.profile.companyId;
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  constructor(
    private companyDataService: CompanyDataService,
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private selectableUtilService: SelectableUtilService
  ) {}

  ngAfterViewInit() {
    this.afterViewInit.emit();
  }

  handleMeetingsChange(meetings: IndividualNextMeetingForm[]) {
    if (meetings.some((individual) => individual.timeStart !== this.nextMeeting.timeStart || individual.timeEnd !== this.nextMeeting.timeEnd)) {
      this.nextMeeting = {...this.nextMeeting, groupMeeting: false};
      this.nextMeetingChange.emit(this.nextMeeting);
    }
    this.individualNextMeetingsChange.emit(meetings);
  }

  updateMeetingsByProfiles(profiles: Profile[]) {
    const meetings: IndividualNextMeetingForm[] = [
      ...this.meetingsSubject.value.filter((meeting) => profiles.some((p) => p.id === meeting.profile.id)),
      ...profiles
        .filter((profile) => this.meetingsSubject.value.every((meeting) => meeting.profile.id !== profile.id))
        .map((profile) => ({
          profile,
          timeStart: this.nextMeeting.timeStart,
          timeEnd: this.nextMeeting.timeEnd,
        })),
    ];

    this.individualNextMeetingsChange.emit(meetings);
  }

  handleProfilesChange(profiles: Profile[]) {
    this.updateMeetingsByProfiles(profiles);
  }

  isValid() {
    if (!this.meetingLists?.length) {
      return true;
    }
    return !this.meetingLists.some((list) => !list.isValid());
  }

  async onOpen($event: {component: IonicSelectableComponent}) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: {component: IonicSelectableComponent}) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
