import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {DOCUMENT} from '@angular/common';
import {Directive, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TextTooltipComponent} from 'src/app/components/common/text-tooltip/text-tooltip.component';

const DEFAULT_DELAY: number | undefined = 500;

@Directive({
  selector: '[appEllipsisTooltip]',
  standalone: true,
})
export class EllipsisTooltipDirective implements OnInit, OnChanges, OnDestroy {
  @Input()
  bindToClosest?: string;

  @Input()
  delay?: number = DEFAULT_DELAY;

  private _overlayRef: OverlayRef | undefined;
  private portal: ComponentPortal<TextTooltipComponent> | undefined;

  private get overlayRef(): OverlayRef {
    return this._overlayRef ?? this.createOverlayRef();
  }

  private target: HTMLElement = this.getTarget();

  private timeoutId: number | undefined;

  constructor(
    private elRef: ElementRef<HTMLElement>,
    private overlay: Overlay,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.refreshTargetAndListeners();
  }

  ngOnDestroy() {
    this.removeListeners();
    this.detachIfAttachedAndClearTimeout();
    this._overlayRef?.dispose();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bindToClosest) {
      this.refreshTargetAndListeners();
    }
  }

  private refreshTargetAndListeners() {
    this.removeListeners();
    this.detachIfAttachedAndClearTimeout();
    this.target = this.getTarget();
    this.setupListeners();
  }

  private getTarget(): HTMLElement {
    return this.bindToClosest ? (this.elRef.nativeElement.closest(this.bindToClosest) ?? this.elRef.nativeElement) : this.elRef.nativeElement;
  }

  get window() {
    return this.document.defaultView;
  }

  private createOverlayRef() {
    return (this._overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elRef)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
          },
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
          },
        ])
        .withPush(false),
      panelClass: ['no-pointer-events', 'no-user-events'],
    }));
  }

  private mouseEnterListener = () => {
    if (!this.doesWidthOverflow()) {
      return;
    }

    if (typeof this.delay === 'number' && !isNaN(this.delay)) {
      this.timeoutId = this.window.setTimeout(() => {
        this.attachTooltipIfWidthOverflows();
      }, this.delay);

      return;
    }

    this.attachTooltipIfWidthOverflows();
  };

  private mouseLeaveListener = () => {
    this.detachIfAttachedAndClearTimeout();
  };

  private touchStartListener = () => {
    this.removeListeners();
    this.detachIfAttachedAndClearTimeout();
  };

  private attachTooltipIfWidthOverflows() {
    if (!this.doesWidthOverflow()) {
      return;
    }

    if (!this.portal) {
      this.portal = new ComponentPortal(TextTooltipComponent);
    }

    const ref = this.overlayRef.attach(this.portal);
    ref.setInput('text', this.elRef.nativeElement.innerText);
  }

  private doesWidthOverflow() {
    return this.elRef.nativeElement.offsetWidth !== this.elRef.nativeElement.scrollWidth;
  }

  private detachIfAttachedAndClearTimeout() {
    this.removeTimeoutIfExists();
    if (this._overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  private removeTimeoutIfExists() {
    if (this.timeoutId) {
      this.window.clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }

  private setupListeners() {
    this.target.addEventListener('touchstart', this.touchStartListener, {passive: true});
    this.target.addEventListener('mouseenter', this.mouseEnterListener, {passive: true});
    this.target.addEventListener('mouseleave', this.mouseLeaveListener, {passive: true});
  }

  private removeListeners() {
    this.target.removeEventListener('touchstart', this.touchStartListener);
    this.target.removeEventListener('mouseenter', this.mouseEnterListener);
    this.target.removeEventListener('mouseleave', this.mouseLeaveListener);
  }
}
