import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {SplashScreen} from '@capacitor/splash-screen';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {AlertController, IonMenu, ModalController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Subject, Subscription} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {v4 as uuidv4} from 'uuid';
import {SendStatusReportComponent} from './components/advanced/send-status-report/send-status-report.component';
import {SyncMissingAttachmentsComponent} from './components/common/sync-missing-attachments/sync-missing-attachments.component';
import {TermsAndConditionsComponent} from './components/common/terms-and-conditions/terms-and-conditions.component';
import {AuthenticationService} from './services/auth/authentication.service';
import {CanduService} from './services/candu.service';
import {LoadingService} from './services/common/loading.service';
import {LoggingService} from './services/common/logging.service';
import {Menu, MenuService} from './services/common/menu.service';
import {NetworkStatusService} from './services/common/network-status.service';
import {PwaService} from './services/common/pwa.service';
import {TermsAndConditionsService} from './services/common/terms-and-conditions.service';
import {ToastService} from './services/common/toast.service';
import {ProjectDataService} from './services/data/project-data.service';
import {SystemEventService} from './services/event/system-event.service';
import {LanguageService} from './services/i18n/language.service';
import {LogoutService} from './services/logout.service';
import {StorageService} from './services/storage.service';
import {SyncSchedulerService} from './services/sync/sync-scheduler.service';
import {SyncStatusService} from './services/sync/sync-status.service';
import {SyncStrategy} from './services/sync/sync-utils';
import {SyncService} from './services/sync/sync.service';
import {AlertService} from './services/ui/alert.service';
import {IonMenuService} from './services/ui/ion-menu.service';
import {ThemeService} from './services/ui/theme.service';
import {UserflowService} from './services/userflow/userflow.service';
import {LOCAL_STORAGE_ACTIVE_REPLY_PREFIX, LOCAL_STORAGE_REQUEST_ACTIVE, LOCAL_STORAGE_WINDOW_ID_STARTED, ToastDurationInMs} from './shared/constants';
import {convertErrorToMessage, NetworkTimeoutError} from './shared/errors';
import {observableToPromise} from './utils/async-utils';
import {TIMEOUT_ERROR} from './utils/fetch-utils';
import {UsageStatisticsService} from './services/auth/usage-statistics.service';
import {PosthogService} from './services/posthog/posthog.service';
import {ClientStatusWatcherService} from './services/client/client-status-watcher.service';
import {AppState, RestoredListenerEvent} from '@capacitor/app/dist/esm/definitions';
import {PictureQualityService} from './services/photo/picture-quality.service';
import {SystemInfoService} from './services/common/system-info.service';
import {DeviceService} from './services/ui/device.service';
import {TokenRefresherService} from './services/auth/token-refresher.service';
import {AppUpdateService} from './services/common/app-update.service';
import {register as registerSwiper} from 'swiper/element/bundle';
import {FcmPushTokenManagerService} from './services/notifications/fcm-push-token-manager.service';
import {NotificationManagerService} from './services/notifications/notification-manager.service';
import {SideBarSettingsService} from './services/sidebar/sidebar-settings.service';
import {FirefoxService} from './services/common/firefox.service';
import {SavedStateNavigationService} from './services/common/saved-state-navigation.service';

const LOG_SOURCE = 'AppComponent';

registerSwiper();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(IonMenu, {
    static: true,
  })
  ionMenu: IonMenu;
  private platformResumeSubscription: Subscription | undefined;
  private platformPauseSubscription: Subscription | undefined;
  private termsAndConditionsSubscription: Subscription | undefined;
  private languageSubscription: Subscription | undefined;
  public isAuthenticated: boolean;
  private authenticationSubscription: Subscription | undefined;
  private termsAndConditionsModalOpen = false;
  private readonly windowId = uuidv4();
  private readonly appOpenInMultipleWindowsPossible: boolean;
  private readonly otherAppsOpenSubject = new Subject<Date>();

  private destroy$ = new Subject<void>();

  showSidebarMenuStatically$ = this.menuService.showSidebarMenuStatically$.pipe(debounceTime(0));
  enableIonMenu$ = this.menuService.enableIonMenu$.pipe(debounceTime(0));
  allowExpandedMenu$ = this.menuService.allowExpandedMenu$.pipe(debounceTime(0));
  pageWithSidebarMenu$ = this.menuService.pageWithSidebarMenu$.pipe(debounceTime(0));
  isMenuBigOnScreen$ = this.menuService.isMenuBigOnScreen$;
  renderTooltips$ = combineLatest([this.menuService.isMenuBigOnScreen$, this.menuService.allowExpandedMenu$]).pipe(
    map(([isMenuBigOnScreen, allowExpandedMenu]) => !isMenuBigOnScreen || !allowExpandedMenu),
    debounceTime(0)
  );

  sideBarSetting$ = this.sideBarSettingsService.sideBarSetting$;
  isMenuExpandedOnScreen$ = this.menuService.isMenuExpandedOnScreen$.pipe(debounceTime(0));
  isDesktop = this.menuService.isDesktop;

  constructor(
    private storage: StorageService,
    private platform: Platform,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastService: ToastService,
    private languageService: LanguageService,
    private themeService: ThemeService,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private syncService: SyncService,
    private syncSchedulerService: SyncSchedulerService,
    public syncStatusService: SyncStatusService,
    private loggingService: LoggingService,
    private userflowService: UserflowService,
    private networkStatusService: NetworkStatusService,
    public menuService: MenuService,
    private logoutService: LogoutService,
    private termsAndConditionsService: TermsAndConditionsService,
    private modalController: ModalController,
    private pwaService: PwaService,
    private systemEventService: SystemEventService,
    private systemInfoService: SystemInfoService,
    private canduService: CanduService,
    private projectDataService: ProjectDataService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private ionMenuService: IonMenuService,
    private posthogService: PosthogService,
    private pictureQualityService: PictureQualityService,
    private usageStatisticsService: UsageStatisticsService, // Leave that service reference here even though it is not used. Referencing it is enough to start the service.
    private clientStatusWatcherService: ClientStatusWatcherService, // Leave that service reference here even though it is not used. Referencing it is enough to start the service.
    private tokenRefresherService: TokenRefresherService, // Leave that service reference here even though it is not used. Referencing it is enough to start the service.
    private fcmPushTokenManagerService: FcmPushTokenManagerService,
    private deviceService: DeviceService,
    private appUpdateService: AppUpdateService,
    private notificationManagerService: NotificationManagerService,
    private sideBarSettingsService: SideBarSettingsService,
    private firefoxService: FirefoxService,
    private savedStateNavigationService: SavedStateNavigationService, // Leave that service reference here even though it is not used. Referencing it is enough to start the service.
    @Inject(DOCUMENT) private document: Document
  ) {
    this.appOpenInMultipleWindowsPossible = this.platform.is('mobileweb') || this.platform.is('desktop');
    this.languageSubscription = this.languageService.selectedLanguage.subscribe((language) => (this.document.documentElement.lang = language));
    this.languageService.setTranslateLanguageOrDefault(this.translateService.getBrowserLang());
    this.loggingService.debug(LOG_SOURCE, 'constructor called');
    this.initializeApp();
    this.loggingService.debug(LOG_SOURCE, 'constructor - after initializeApp');
  }
  private async persistIfPendingChanges() {
    if (this.storage.hasPendingChanges()) {
      await this.storage.persistPendingChanges();
    }
  }

  initializeApp() {
    this.loggingService.debug(LOG_SOURCE, 'initializeApp called');
    if (environment.deepLinksDomains.length > 0) {
      const listener = async (event: URLOpenListenerEvent) => {
        this.systemEventService.logEvent(LOG_SOURCE, () => `appUrlOpen`);
        const [, rootPath] = event.url.split(new RegExp(environment.deepLinksDomains.map((domainRegex) => `(?:${domainRegex.source})`).join('|')));
        if (rootPath) {
          try {
            await this.languageService.setInitialAppLanguage();
          } finally {
          }
          await this.loadingService.withLoading(
            async () => {
              if (!(await observableToPromise(this.authenticationService.isAuthenticated$))) {
                this.loggingService.info(LOG_SOURCE, 'appUrlOpen - ignored deep link: not authenticated');
                return;
              }
              let pathToNavigate = rootPath;
              if (rootPath.includes('?')) {
                const [path, query] = rootPath.split('?');
                const params = new URLSearchParams(query);
                if (params.has('projectId')) {
                  pathToNavigate = path;
                  await observableToPromise(this.projectDataService.dataReally);
                  await this.projectDataService.setCurrentProjectId(params.get('projectId'));
                }
              }
              this.loggingService.info(LOG_SOURCE, `appUrlOpen - navigating to ${pathToNavigate}`);
              await this.router.navigateByUrl(pathToNavigate);
            },
            {
              message: this.translateService.instant('deep_links.loading_navigation'),
            }
          );
        }
      };
      App.addListener('appUrlOpen', listener);
    }
    App.addListener('appRestoredResult', (event: RestoredListenerEvent) => {
      this.systemEventService.logEvent(LOG_SOURCE, () => `appRestoredResult - event.methodName="${event?.methodName}"`);
      if (event.methodName === 'addListener') {
        this.loggingService.debug(LOG_SOURCE, `App.appRestoredResult - pluginId="${event?.pluginId}", methodName="${event?.methodName}", success="${event?.success}"`);
      } else {
        this.loggingService.info(LOG_SOURCE, `App.appRestoredResult - pluginId="${event?.pluginId}", methodName="${event?.methodName}", success="${event?.success}"`);
        if (!this.posthogService.posthogInitialized) {
          this.posthogService.initPosthog();
        }
        this.posthogService.captureEvent('[App] appRestoredResult', {pluginId: event?.pluginId, methodName: event?.methodName, success: event?.success});
      }
    });

    const resumeBackgroundProcesses = async () => {
      this.loggingService.debug(LOG_SOURCE, `Resume BackgroundProcesses.`);
      this.systemEventService.logEvent(LOG_SOURCE + '.resumeBackgroundProcesses', 'called');
      this.networkStatusService.startCallingPingAndNetworkStatus();
      this.fcmPushTokenManagerService.startTokenManagement();
      await this.syncSchedulerService.startSchedulerWithDelay();
      await this.pwaService.startCheckForUpdates();
      await this.appUpdateService.startCheckForUpdates();
      this.systemInfoService.startStorageQuotaLevelNotification();
    };

    const stopBackgroundProcesses = async () => {
      this.loggingService.debug(LOG_SOURCE, `Stop BackgroundProcesses.`);
      this.systemEventService.logEvent(LOG_SOURCE + '.stopBackgroundProcesses', 'called');
      this.networkStatusService.stopCallingPingAndNetworkStatus();
      this.fcmPushTokenManagerService.stopTokenManagement();
      await this.syncSchedulerService.stopScheduler();
      this.pwaService.stopCheckForUpdates();
      this.appUpdateService.stopCheckForUpdates();
      this.systemInfoService.stopStorageQuotaLevelNotification();
    };

    App.addListener('appStateChange', async (state: AppState) => {
      this.loggingService.info(LOG_SOURCE, `App.appStateChange - state="${state?.isActive}"`);
      this.systemEventService.logEvent(LOG_SOURCE, () => `appStateChange - state="${state?.isActive}"`);
      if (state?.isActive === true) {
        await resumeBackgroundProcesses();
      } else if (state?.isActive === false) {
        await this.persistIfPendingChanges();
        await stopBackgroundProcesses();
      }
    });
    if (this.appOpenInMultipleWindowsPossible) {
      window.addEventListener('unload', (event) => {
        this.systemEventService.logEvent(LOG_SOURCE, () => `unload`);
        this.removeLocalStorageWindowIdStarted();
      });
      window.addEventListener('storage', (event) => {
        this.loggingService.debug(LOG_SOURCE, `storage (key="${event.key}")`);
        this.systemEventService.logEvent(LOG_SOURCE, () => `storage (key="${event.key}")`);
        if (event.key === LOCAL_STORAGE_REQUEST_ACTIVE && event.newValue !== this.windowId) {
          this.loggingService.info(LOG_SOURCE, `Replying to WindowsActiveRequest.`);
          localStorage.setItem(LOCAL_STORAGE_ACTIVE_REPLY_PREFIX + this.windowId, new Date().toISOString());
        }
        if (event.key.startsWith(LOCAL_STORAGE_ACTIVE_REPLY_PREFIX) && event.key !== LOCAL_STORAGE_ACTIVE_REPLY_PREFIX + this.windowId) {
          const otherWindowsId = event.key.substring(0, LOCAL_STORAGE_ACTIVE_REPLY_PREFIX.length - 1);
          const date = new Date(event.newValue);
          this.loggingService.info(LOG_SOURCE, `Received feedback from WindowsActiveRequest. otherWindowsId=${otherWindowsId}, date=${date}`);
          this.otherAppsOpenSubject.next(date);
        }
      });
    }
    this.platform.ready().then(async () => {
      this.loggingService.info(LOG_SOURCE, 'platform.ready');
      this.systemEventService.logEvent(LOG_SOURCE, () => `platform.ready`);
      this.canduService.initialize();
      this.statusBar.styleDefault();
      await this.languageService.setInitialAppLanguage();
      await this.themeService.initializeAppTheme();
      await this.pictureQualityService.initializePictureQuality();
      const canContinue = await this.ensureAppOnlyOpenInOneWindow();
      if (canContinue) {
        await this.syncSchedulerService.startSchedulerWithDelay();
        this.systemInfoService.startStorageQuotaLevelNotification();
      }
      this.fcmPushTokenManagerService.startTokenManagement();
      this.notificationManagerService.addListener();
      await this.userflowService.startUserflow();
      if (!this.posthogService.posthogInitialized) {
        this.posthogService.initPosthog();
      }
      await this.firefoxService.startCheckForFirefox();
      await this.deviceService.lockScreenPortraitForSmallDevices();
      await this.appUpdateService.startCheckForUpdates();
      if (navigator?.storage?.persist) {
        const persistGranted = await navigator.storage.persist();
        this.loggingService.info(LOG_SOURCE, `storage persist granted = ` + persistGranted);
      }
      window.addEventListener('pagehide', () => {
        this.loggingService.info(LOG_SOURCE, `pagehide`);
        this.systemEventService.logEvent(LOG_SOURCE, () => 'pagehide');
        this.persistIfPendingChanges();
      });

      document.addEventListener('visibilitychange', () => {
        this.loggingService.info(LOG_SOURCE, `visibilitychange to "${document?.visibilityState}" - ${new Date().toISOString()}`);
        this.systemEventService.logEvent(LOG_SOURCE, () => `visibilitychange - visibilityState="${document.visibilityState}"`);
        if (document.visibilityState === 'hidden') {
          if (this.syncStatusService.attachmentSyncInProgress?.inProgress) {
            this.posthogService.captureEvent('[App] HideTabWhileAttSync', {syncStrategy: this.syncStatusService.attachmentSyncInProgress.syncStrategy});
          }
          if (this.syncStatusService.dataSyncInProgress?.inProgress) {
            this.posthogService.captureEvent('[App] HideTabWhileDataSync', {syncStrategy: this.syncStatusService.dataSyncInProgress.syncStrategy});
          }
          return this.persistIfPendingChanges();
        }
      });
      this.platform.pause.subscribe(() => {
        this.loggingService.info(LOG_SOURCE, `platform.pause`);
        this.systemEventService.logEvent(LOG_SOURCE, () => `platform.pause`);
        this.persistIfPendingChanges();
      });

      window.addEventListener('beforeunload', (event) => {
        this.loggingService.info(LOG_SOURCE, `beforeunload`);
        this.systemEventService.logEvent(LOG_SOURCE, () => 'beforeunload');
        if (this.storage.hasPendingChanges()) {
          this.loggingService.info(LOG_SOURCE, `beforeunload - pending changes`);
          this.systemEventService.logEvent(LOG_SOURCE, () => 'beforeunload - pending changes');
          event.preventDefault();
          event.returnValue = '';

          return true;
        } else {
          this.loggingService.info(LOG_SOURCE, `beforeunload - no pending`);
          this.systemEventService.logEvent(LOG_SOURCE, () => 'beforeunload - no pending changes');
        }
      });
    });

    this.platformResumeSubscription = this.platform.resume.subscribe(async () => {
      this.loggingService.info(LOG_SOURCE, `platform.resume - ${new Date().toISOString()}`);
      this.systemEventService.logEvent(LOG_SOURCE, () => 'platform.resume');
    });
    this.platformPauseSubscription = this.platform.pause.subscribe(async () => {
      this.loggingService.info(LOG_SOURCE, 'platform.pause');
      this.systemEventService.logEvent(LOG_SOURCE, () => 'platform.pause');
    });
    this.pwaService.startCheckForUpdates();
    this.pwaService.refreshIfNewVersion();

    if (this.platform.is('ios') && this.platform.is('cordova')) {
      window?.visualViewport?.addEventListener('resize', () => {
        const root = this.document.documentElement;
        root.style.setProperty('--viewport-height', window.visualViewport.height.toString() + 'px');
      });
    }
  }

  async ngOnInit() {
    this.authenticationSubscription = this.authenticationService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });
    this.termsAndConditionsSubscription = this.subscribeToTermsAndConditionsAndOpenModal();
  }

  ngAfterViewInit() {
    this.systemEventService.logEvent(LOG_SOURCE, () => 'AfterViewInit');
    this.ionMenuService.setIonMenu(this.ionMenu);
    SplashScreen.hide();
  }

  ngOnDestroy(): void {
    this.authenticationSubscription?.unsubscribe();
    if (this.platformResumeSubscription) {
      this.platformResumeSubscription.unsubscribe();
      this.platformResumeSubscription = undefined;
    }
    if (this.platformPauseSubscription) {
      this.platformPauseSubscription.unsubscribe();
      this.platformPauseSubscription = undefined;
    }
    this.termsAndConditionsSubscription?.unsubscribe();
    this.languageSubscription?.unsubscribe();
    this.languageSubscription = undefined;
    this.destroy$.next();
    this.destroy$.complete();
  }

  private removeLocalStorageWindowIdStarted() {
    if (this.appOpenInMultipleWindowsPossible) {
      if (localStorage.getItem(LOCAL_STORAGE_WINDOW_ID_STARTED) === this.windowId) {
        localStorage.setItem(LOCAL_STORAGE_WINDOW_ID_STARTED, '');
      }
      localStorage.removeItem(LOCAL_STORAGE_ACTIVE_REPLY_PREFIX + this.windowId);
    }
  }

  async syncDataFull() {
    await this.syncService.startSync(SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE, {showInfoToastMessages: true, syncWithoutSince: true, forceReadingAttachmentFileInfo: true});
  }

  async syncMissingAttachments() {
    const modal = await this.modalController.create({
      component: SyncMissingAttachmentsComponent,
      backdropDismiss: false,
    });

    await modal.present();
  }

  private async showPopupAppOpenInOtherBrowser(): Promise<boolean> {
    return await this.alertService.confirm({
      header: 'appOpenInOtherBrowser.header',
      message: 'appOpenInOtherBrowser.message',
      confirmButton: {
        color: 'danger',
        fill: 'solid',
      },
      confirmLabel: 'appOpenInOtherBrowser.buttonLabelOpenAnyway',
      cancelButton: {
        color: 'primary',
        fill: 'solid',
      },
      cancelLabel: 'appOpenInOtherBrowser.buttonLabelCheckAgain',
    });
  }

  private async isAppOpenInOtherWindow(): Promise<boolean> {
    this.loggingService.debug(LOG_SOURCE, `isAppOpenInOtherWindow started`);
    const windowIdInStorage = localStorage.getItem(LOCAL_STORAGE_WINDOW_ID_STARTED);
    const maybeOpenInOtherWindow = windowIdInStorage && windowIdInStorage !== this.windowId;
    if (!maybeOpenInOtherWindow) {
      this.loggingService.info(LOG_SOURCE, `isAppOpenInOtherWindow determined, no other window active.`);
      return false;
    }
    this.loggingService.info(LOG_SOURCE, `isAppOpenInOtherWindow determined, another window might be active. Need to send a request.`);
    return await this.areOtherInstancesAlive();
  }

  private async areOtherInstancesAlive(timeoutInMs = 2000): Promise<boolean> {
    this.loggingService.debug(LOG_SOURCE, `areOtherInstancesAlive called.`);
    const otherAppsOpenPromise = observableToPromise(this.otherAppsOpenSubject);
    localStorage.setItem(LOCAL_STORAGE_REQUEST_ACTIVE, this.windowId);
    const promises = Promise.race([otherAppsOpenPromise, new Promise<Response>((_, reject) => setTimeout(() => reject(new NetworkTimeoutError(TIMEOUT_ERROR)), timeoutInMs))]);
    try {
      await promises;
      this.loggingService.info(LOG_SOURCE, 'Another instance is active');
      return true;
    } catch (e) {
      this.loggingService.info(LOG_SOURCE, `No other instances active (non responded after ${timeoutInMs} ms.`);
      return false;
    }
  }

  private async ensureAppOnlyOpenInOneWindow(): Promise<boolean> {
    if (!this.appOpenInMultipleWindowsPossible) {
      return true;
    }
    if (!(await this.isAppOpenInOtherWindow())) {
      localStorage.setItem(LOCAL_STORAGE_WINDOW_ID_STARTED, this.windowId);
      return true;
    }
    if (await this.showPopupAppOpenInOtherBrowser()) {
      // "Load anyway" clicked. Clear the current windowId value and then reload
      this.loggingService.warn(LOG_SOURCE, `ensureAppOnlyOpenInOneWindow - App is open in another browser and user clicked "open anyway"`);
      try {
        // await is important since we reload the application further down. But let's ignore errors
        await this.systemEventService.logEvent(LOG_SOURCE + ' ensureAppOnlyOpenInOneWindow', `App is open in another browser and user clicked "open anyway"`);
        if (!this.posthogService.posthogInitialized) {
          this.posthogService.initPosthog();
        }
        this.posthogService.captureEvent('[App] AppOpenInMultipleWindows', {windowId: this.windowId});
      } catch (error) {
        // ignore
      }
      localStorage.setItem(LOCAL_STORAGE_WINDOW_ID_STARTED, '');
    }
    window.location.reload();
    return false;
  }

  async syncDataActive() {
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES, {showInfoToastMessages: true});
  }

  async uploadFilesFromErrorQueue() {
    const filesScheduled = await this.syncService.uploadFilesFromErrorQueue();
    if (filesScheduled === 0) {
      await this.toastMessage(this.translateService.instant('sync_no_attachments_in_error_queue'));
    } else {
      await this.toastMessage(this.translateService.instant('sync_attachments_moved_from_error_to_upload_queue', {count: filesScheduled}));
    }
  }

  async logout() {
    await this.logoutService.logout();
  }

  public async startUserflow() {
    if (this.networkStatusService.offline) {
      const alert = await this.alertCtrl.create({
        header: this.translateService.instant('userflow.error.cannotStartNoInternet'),
        buttons: [this.translateService.instant('okay')],
      });
      await alert.present();
      return;
    }
    if (!this.userflowService.isUserflowEnabledForScreenSize()) {
      const alert = await this.alertCtrl.create({
        header: this.translateService.instant('userflow.error.notSupportForScreenSize'),
        buttons: [this.translateService.instant('okay')],
      });
      await alert.present();
      return;
    }
    await this.userflowService.startUserflowWithContent();
  }

  async showNotImplementedDialog(actionName: string) {
    const alert = await this.alertCtrl.create({
      message: `${this.translateService.instant('underConstruction')}`,
      buttons: ['OK'],
    });
    await alert.present();
  }

  private async toastMessage(message: string, duration = 3000) {
    await this.toastService.toast(message, duration);
  }

  setCurrentMenu(menu: Menu) {
    this.menuService.setMenu(menu);
  }

  private async openTermsAndConditionsModalIfNotAlreadyOpen(): Promise<boolean> {
    if (this.termsAndConditionsModalOpen) {
      return false;
    }
    try {
      this.termsAndConditionsModalOpen = true;
      const modal = await this.modalController.create({
        component: TermsAndConditionsComponent,
        canDismiss: false,
        backdropDismiss: false,
      });

      await modal.present();
      await modal.onDidDismiss();
      return true;
    } finally {
      this.termsAndConditionsModalOpen = false;
    }
  }

  public subscribeToTermsAndConditionsAndOpenModal(): Subscription {
    return this.termsAndConditionsService.currentUserAccepted$.subscribe(async (currentUserAccepted) => {
      if (currentUserAccepted && !currentUserAccepted.accepted) {
        await this.openTermsAndConditionsModalIfNotAlreadyOpen();
      }
    });
  }

  public async advancedSendStatusReport() {
    this.termsAndConditionsModalOpen = true;
    const modal = await this.modalController.create({
      component: SendStatusReportComponent,
    });

    await modal.present();
  }

  public async deleteLocalChanges() {
    const projectsWithLocalChanges = await observableToPromise(this.syncStatusService.clientOrProjectsWithLocalChanges$);
    if (!projectsWithLocalChanges.size) {
      const noLocalChangesAlert = await this.alertCtrl.create({
        message: this.translateService.instant('localChanges.noLocalChanges'),
        buttons: ['OK'],
      });
      await noLocalChangesAlert.present();
      return;
    }
    if (this.networkStatusService.offline) {
      const noConnectionAlert = await this.alertCtrl.create({
        message: this.translateService.instant('localChanges.noInternetConnection'),
        buttons: ['OK'],
      });
      await noConnectionAlert.present();
      return;
    }
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('localChanges.confirmDelete.title'),
      message: this.translateService.instant('localChanges.confirmDelete.text'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            try {
              await this.syncService.startSync(SyncStrategy.PROJECTS_WITH_CHANGES, {syncWithoutSince: true, clearLocalChanges: true});
              await this.toastService.info('localChanges.localChangesDeleted');
            } catch (e) {
              await this.toastService.error('localChanges.errorDeletingLocalChanges');
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async regenerateUploadQueue() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('advancedActions.regenerateUploadQueue.header'),
      message: this.translateService.instant('advancedActions.regenerateUploadQueue.message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            this.loggingService.warn(LOG_SOURCE, 'regenerateUploadQueue - started');
            await this.systemEventService.logEvent(LOG_SOURCE, 'regenerateUploadQueue - started');
            try {
              const result = await this.syncService.regenerateFilesInMediaQueue();
              const message = `regenerateUploadQueue - completed ${JSON.stringify(result)}`;
              this.loggingService.warn(LOG_SOURCE, message);
              await this.systemEventService.logEvent(LOG_SOURCE, message);
              await this.toastService.toastWithTranslateParams('advancedActions.regenerateUploadQueue.regenerated', result, ToastDurationInMs.INFO);
            } catch (e) {
              const message = `regenerateUploadQueue - failed, error: '${convertErrorToMessage(e)}'`;
              this.loggingService.error(LOG_SOURCE, message);
              await this.systemEventService.logErrorEvent(LOG_SOURCE, message);
              await this.toastService.error('advancedActions.regenerateUploadQueue.failedToRegenerate');
            }
          },
        },
      ],
    });
    await alert.present();
  }
}
