import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {PdfPlansFilter} from 'src/app/model/pdf-plans-filter';
import {PdfPlansFilterService} from 'src/app/services/project-room/pdf-plans-filter.service';
import {PdfPlansFilteredDataService} from 'src/app/services/project-room/pdf-plans-filtered-data.service';
import {EMPTY_FILTER_ID} from 'src/app/utils/filter-utils';
import {IonicSelectableComponent} from 'ionic-selectable';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';
import {UnitService} from 'src/app/services/unit/unit.service';
import {UnitLevelDataService} from 'src/app/services/data/unit-level-data.service';
import {IdType} from 'submodules/baumaster-v2-common/dist';
import {Nullish} from 'src/app/model/nullish';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {UnitDataService} from 'src/app/services/data/unit-data.service';

@Component({
  selector: 'app-pdf-plan-filters',
  templateUrl: './pdf-plan-filters.component.html',
  styleUrls: ['./pdf-plan-filters.component.scss'],
})
export class PdfPlanFiltersComponent implements OnInit {
  form = this.fb.group({
    tags: [[]],
    locationIds: [[]],
    unitIds: [[]],
    includeSubUnits: [false],
    includeActive: [true],
    includeInactive: [false],
  });

  locations$ = this.pdfPlansFilteredDataService.activeLocationsAndUsedInPdfVersions$.pipe(
    map((locations) => [{id: EMPTY_FILTER_ID, location: this.translateService.instant('emptyFilter'), clientId: null, changedAt: new Date(), isActive: true}, ...locations])
  );

  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;
  protected readonly EMPTY_FILTER_ID = EMPTY_FILTER_ID;
  protected units$ = this.unitService.unitsForBreadcrumbs$;
  protected unitLevels$ = this.unitLevelDataService.data;

  protected selectedUnitId: Nullish<IdType>;

  constructor(
    private fb: UntypedFormBuilder,
    private pdfPlansFilterService: PdfPlansFilterService,
    private pdfPlansFilteredDataService: PdfPlansFilteredDataService,
    private translateService: TranslateService,
    private selectableUtilService: SelectableUtilService,
    private unitService: UnitService,
    private unitDataService: UnitDataService,
    private unitLevelDataService: UnitLevelDataService
  ) {}

  ngOnInit() {
    this.form.patchValue(this.pdfPlansFilterService.filter);
    this.selectedUnitId = this.pdfPlansFilterService.filter?.unitIds[0];
  }

  async unitFilterChanged(unitId: Nullish<IdType>) {
    if (!unitId) {
      this.form.get('unitIds').setValue([]);
      return;
    }
    if (this.form.get('includeSubUnits')?.value) {
      const allSubunits = await observableToPromise(this.unitDataService.getAllSubUnits(unitId));
      const unitIds = [unitId, ...allSubunits.map((unit) => unit.id)];
      this.form.get('unitIds').setValue(unitIds);
    } else {
      this.form.get('unitIds').setValue([unitId]);
    }
  }

  async shouldIncludeSubUnitsChanged() {
    await this.unitFilterChanged(this.selectedUnitId);
  }

  getValue(): PdfPlansFilter {
    return this.form.getRawValue();
  }

  async onOpen($event: {component: IonicSelectableComponent}) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: {component: IonicSelectableComponent}) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
