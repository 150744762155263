<app-modal-header (close)="cancel()">
  <app-modal-step-indicator
      [stepsCount]="workflowStepConfigs.length"
      [current]="currentStepIndex"
      [workflowName]="'entryMailSend.title' | translate"
      [workflowStep]="currentStep | entryMailStepTranslationKey | translate"
  ></app-modal-step-indicator>
  <div class="ion-text-center no-internet-text-container" *ngIf="!(networkConnected$ | async) || attachmentsMissingContent?.length">
    <ion-label class="omg-attention omg-attention-danger" *ngIf="!(networkConnected$ | async)">{{'entryMailSend.textNoInternet' | translate}}</ion-label>
    <ion-label class="omg-attention omg-attention-warning" *ngIf="attachmentsMissingContent?.length">{{'entryMailSend.warningMissingAttachments' | translate: {attachments: attachmentsMissingContent?.length} }}</ion-label>
  </div>
  <div class="d-flex gap-2" *ngIf="currentStep === 'MAILING_LIST'">
    <ion-searchbar class="slim-searchbar" #autofocus [(ngModel)]="searchTextInput" (ngModelChange)="onSearchTextChanged()" debounce="100"
                   placeholder="{{'sendProtocol.mailingList.searchName' | translate}}"></ion-searchbar>
  </div>
  <div *ngIf="currentStep === 'MAILING_LIST'" class="d-flex ion-justify-content-between omg-modal-list-header omg-modal-section-header-small">
    <div>{{'sendProtocol.mailingList.companiesAndContacts' | translate}}</div>
    <div>
      <ion-label>{{'sendProtocol.mailingList.recipient' | translate}}</ion-label>
      <ion-label>{{'sendProtocol.mailingList.participant' | translate}}</ion-label>
    </div>
  </div>

</app-modal-header>

<ion-content class="with-padding">
  <app-pdf-mailing-list #appPdfMailingList
                        *ngIf="currentStep === 'MAILING_LIST'"
                        [hideHeader]="true"
                        [workflowType]="workflowType"
                        [searchTextInput]="searchTextInput"
                        [skipInactiveContacts]="true"
                        [skipInactiveCompanies]="true"
                        (allParticipantLoaded)="onParticipantsLoaded($event.participants)"
                        (presentChanged)="onPresentChanged($event.employee)"
                        (recipientChanged)="onRecipientChanged($event.employee)"
                        [sortCompaniesByNameOnly]="true"
                        [showCompanyPin]="false"></app-pdf-mailing-list>

  <app-pdf-email-settings *ngIf="currentStep === 'EMAIL_SETTINGS'"
                          [language]="currentProject?.language"
                          [workflowType]="workflowType"
                          [(form)]="emailSettingsForm"
                          (resetForm)="applyDataToEmailSettings()"
                          [(formDirty)]="emailFormDirty"
                          [hideResetButton]="false"
                          [recipientEmails]="recipientEmails$ | async"
                          [userEmailSignature]="userEmailSignature"
                          (attachmentUserEmailSignatureCreate)="handleAttachmentUserEmailSignatureCreate($event)">
    <ng-template appPdfEmailSettingsBetweenTemplate>
      <div [innerHTML]="htmlContent | safeHtml"></div>
    </ng-template>
  </app-pdf-email-settings>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start">
    <ion-button class="omg-btn-secondary" (click)="back()" *ngIf="currentWorkflowConfig.previousStep !== null">{{'back' | translate}}</ion-button>
  </ion-buttons>

  <ion-buttons slot="end">
    <ion-button class="omg-btn-primary" (click)="next()" *ngIf="currentWorkflowConfig.nextStep !== null">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="loading"></ion-spinner>
      <span>{{'next' | translate}}</span>
    </ion-button>
    <ion-button class="omg-btn-primary" (click)="triggerSend()" [disabled]="!(networkConnected$ | async) || !isMailingListNotEmpty" *ngIf="currentWorkflowConfig.nextStep === null">
      <span class="ion-hide-sm-down">{{'send' | translate}}</span>
      <fa-icon class="ion-hide-sm-up" [icon]="['fal','paper-plane']"></fa-icon>
    </ion-button>
  </ion-buttons>
</app-modal-footer>