import {AbstractPdfContent, formatDate, MaybeMinimizeProtocolEntry, ProtocolEntryWithSubEntries} from '../abstractPdf.content';
import _ from 'lodash';
import {PdfProtocolSendReq} from '../../../../requestResponse';
import {
  AttachmentBimMarkerScreenshot,
  AttachmentProtocolEntry,
  BimMarker,
  IdType,
  PdfPreview,
  ProjectCurrencyEnum,
  PROTOCOL_LAYOUT_NAME_CONTINUOUS,
  PROTOCOL_LAYOUT_NAME_SHORT,
  ProtocolEntry,
  ProtocolEntryChat,
  ProtocolEntryIconStatus,
  ProtocolEntryPriorityLevel,
  ProtocolEntryPriorityType,
  ShowPicturesEnum,
  UnitForBreadcrumbs,
} from '../../../../models';
import {AttachmentWithContent, PdfPlanAttachmentWithContent, PdfProtocolGenerateData} from '../../pdfProtocol.model';
import {Column, ColumnProperties, Content, ContentColumns, ContentImage, ContentStack, ContentSvg, ContentTable, Table, TableCell, TableLayout, TableOfContent} from 'pdfmake/interfaces';
import {SvgIcons} from '../../pdfSvgIcons';
import {getProtocolEntryIconStatus, getProtocolEntryStatus} from '../../../../planMarker/planMarkerCanvasUtils';
import {createCanvas} from 'canvas';
import {PdfPrintEntryDetails} from '../../pdfProtocolEnums';
import {convertToRichText, isRichText, PdfHelperFunctions} from '../../../common-report-utils';
import {addZeroWidthSpaces} from '../../pdfutils';
import {
  PDF_NODE_ID_BIM_CONTENT,
  PDF_NODE_ID_BIM_HEADER,
  PDF_NODE_ID_DESC_SEARCH_STRING,
  PDF_NODE_ID_ENTRY_TITLE,
  PDF_NODE_ID_HEADER_SEARCH_STRING,
  PDF_NODE_ID_HEADER_TABLE_SEARCH_STRING,
  PDF_NODE_ID_IMAGE_HEADER,
  PDF_NODE_ID_PLAN_CONTENT,
  PDF_NODE_ID_PLAN_HEADER,
  PDF_NODE_ID_SUBENTRY_HEADER,
} from '../../../../constants';

const TABLE_WIDTH = 507;

export abstract class AbstractProtocolEntriesContentNew extends AbstractPdfContent {
  protected isGroupBy = false;

  protected tableLayoutInnerForHeading: TableLayout = {
    hLineWidth() {
      return 0;
    },
    vLineWidth() {
      return 0;
    },
    paddingLeft() {
      return 0;
    },
    paddingRight() {
      return 0;
    },
    paddingTop() {
      return 0;
    },
    paddingBottom() {
      return 0;
    },
  };

  protected constructor(
    language: string,
    config: PdfProtocolSendReq,
    data: PdfProtocolGenerateData,
    protected pdfHelperFunctions: PdfHelperFunctions,
    pdfPreview?: PdfPreview
  ) {
    super(language, config, data, pdfPreview);

    this.isGroupBy = this.isGroupByCriteriaMet(this.data.protocol.sortEntriesBy);
  }

  protected getProtocolEntries(): MaybeMinimizeProtocolEntry[] {
    if (this.data.filteredProtocolEntries !== undefined && this.data.filteredProtocolEntries?.length > 0) {
      // We only want to use a subset of the protocol entries
      return this.getFilteredProtocolEntries(this.data.filteredProtocolEntries);
    }
    return this.data.protocolEntries;
  }

  protected getOwnProtocolEntries(): MaybeMinimizeProtocolEntry[] {
    const protocolEntries = this.getProtocolEntries();
    const carriedSubEntries = protocolEntries.filter((entry) => entry.createdInProtocolId === this.data.protocol.id);
    const carriedSubEntryParentIds = new Set(carriedSubEntries.map(({parentId}) => parentId).filter((v): v is IdType => Boolean(v)));

    return this.getProtocolEntries().filter((entry) => carriedSubEntryParentIds.has(entry.id) || !this.data.protocolOpenEntries.some((openEntry) => openEntry.protocolEntryId === entry.id));
  }

  protected getCarriedOverProtocolEntriesNotGroup(): MaybeMinimizeProtocolEntry[] {
    const protocolEntries = this.getProtocolEntries();
    const carriedSubEntries = protocolEntries.filter((entry) => entry.createdInProtocolId === this.data.protocol.id);
    const carriedSubEntryParentIds = new Set(carriedSubEntries.map(({parentId}) => parentId).filter((v): v is IdType => Boolean(v)));

    return this.getProtocolEntries().filter((entry) => !carriedSubEntryParentIds.has(entry.id) && this.data.protocolOpenEntries.some((openEntry) => openEntry.protocolEntryId === entry.id));
  }

  protected getCarriedOverProtocolEntriesGroupBy(): MaybeMinimizeProtocolEntry[] {
    return this.getProtocolEntries().filter((entry) => this.data.protocolOpenEntries.some((openEntry) => openEntry.protocolEntryId === entry.id));
  }

  private getFilteredProtocolEntries(filteredProtocolEntries: ProtocolEntry[]): MaybeMinimizeProtocolEntry[] {
    const newProtocolEntries: MaybeMinimizeProtocolEntry[] = [];
    for (const filteredProtocolEntry of filteredProtocolEntries) {
      if (!_.isEmpty(filteredProtocolEntry.parentId)) {
        const parentEntry = this.data.protocolEntries.find((entry) => entry.id === filteredProtocolEntry.parentId);
        if (parentEntry && !newProtocolEntries.some((newEntry) => newEntry.id === parentEntry.id)) {
          newProtocolEntries.push({
            ...parentEntry,
            minimize: !filteredProtocolEntries.some((entry) => entry.id === parentEntry.id),
          });
        }
      }

      if (!newProtocolEntries.some((newEntry) => newEntry.id === filteredProtocolEntry.id)) {
        newProtocolEntries.push(filteredProtocolEntry);
      }
    }
    return newProtocolEntries;
  }

  protected writeEndText(content: Content[]) {
    if (!_.isEmpty(this.config.pdfProtocolSetting?.pdfEndText)) {
      const pdfEndText = this.config.pdfProtocolSetting?.pdfEndText;
      if (isRichText(pdfEndText)) {
        content.push({
          text: '',
          style: ['marginTop10'],
        });
        content.push(this.pdfHelperFunctions.convertHtmlToPdfmake(convertToRichText(pdfEndText), {fontSize: 9}));
      } else {
        content.push({
          text: `${pdfEndText}`,
          style: ['font9', 'marginTop10'],
        });
      }
    }
  }

  protected writeTextHeader(content: Content[], label: string, icon?: SvgIcons) {
    const columns: Column[] = [];
    if (icon) {
      columns.push({
        columns: [
          {
            svg: this.changeSvgIconFill(icon, this.getProtocolColor()),
            fit: [12, 12],
          },
        ],
        width: 'auto',
      });
    }
    columns.push({
      text: label,
      width: 'auto',
      style: ['font9', 'protocolFontColor', ...(icon ? ['marginLeft10'] : [])],
    });
    content.push({
      style: ['marginTop10'],
      columns,
    });
    this.writeLine(content);
  }

  protected writeGroupByProtocolEntries(content: Content[], protocolEntries: MaybeMinimizeProtocolEntry[], isTask: boolean, isOwnEntriesAndAppendCarried = false) {
    const groupProtocolEntries = this.groupProtocolEntries(protocolEntries, isOwnEntriesAndAppendCarried);
    for (const groupProtocolEntry of groupProtocolEntries) {
      const currentGroupName = groupProtocolEntry.groupName.replace(/.+__/, '');
      if (this.isForSpecificParticipantCompany(currentGroupName)) {
        continue;
      }
      content.push({
        text: currentGroupName,
        style: ['font12', 'groupName', 'textBold'],
      });
      const protocolNumberComparer = (protocolEntry: MaybeMinimizeProtocolEntry) => this.getProtocolNumber(protocolEntry);
      const protocolEntryNumberComparer = (protocolEntry: MaybeMinimizeProtocolEntry) => protocolEntry.number;
      const sortedProtocolEntries = _.orderBy(groupProtocolEntry.protocolEntries, [protocolNumberComparer, protocolEntryNumberComparer], ['asc', 'asc']);
      let count = sortedProtocolEntries.length;
      for (const protocolEntry of sortedProtocolEntries) {
        this.writeProtocolEntry(content, protocolEntry, isTask);
        if (this.config.pdfProtocolSetting?.everyEntryOnNewPage && count > 1) {
          content.push({
            text: '',
            pageBreak: 'after',
          });
        }
        count--;
      }
    }
  }

  protected isMinimizeDetails(protocolEntry: ProtocolEntry): boolean {
    if (_.isEmpty(protocolEntry.parentId)) {
      const protocolEntryWithSubEntries = protocolEntry as ProtocolEntryWithSubEntries;
      return protocolEntryWithSubEntries.minimize;
    }
    return false;
  }

  protected writeProtocolEntry(content: Content[], protocolEntry: ProtocolEntry, isTask: boolean, isFirstSubentry?: boolean) {
    const isHightLight = this.config.pdfProtocolSetting?.highlightEntryTitles;
    const isMinimizeMainEntry = this.isMinimizeDetails(protocolEntry);
    const showEntryDetails: PdfPrintEntryDetails[] = this.config.pdfProtocolSetting?.printEntryDetails || [];

    content.push({
      text: '\u200B',
      id: _.uniqueId(PDF_NODE_ID_HEADER_SEARCH_STRING),
      margin: [0, 0, 0, 0],
      fontSize: 0,
    });

    const protocolEntryInformation = this.getProtocolEntryInformation(protocolEntry);

    let descriptionColumn: Column = {text: ''};
    let detailColumn: Column = {text: ''};
    let isFirstOfDetails = true;
    const protocolEntryDetailColumns: Column[] = [];
    if ((!this.isEmptyDate(protocolEntry.startDate) || !this.isEmptyDate(protocolEntry.todoUntil)) && _.includes(showEntryDetails, PdfPrintEntryDetails.DATES)) {
      protocolEntryDetailColumns.push(...this.getProtocolStartEndDate(protocolEntry));
      isFirstOfDetails = false;
    }

    const protocolPriorityLevel = this.getProtocolEntryPriorityLevel(protocolEntry.priority);
    if (!_.isEmpty(protocolPriorityLevel) && _.includes(showEntryDetails, PdfPrintEntryDetails.PRIORITY)) {
      protocolEntryDetailColumns.push({
        columns: [
          {
            svg: `${this.getSvgPriorityLevel(protocolEntry.priority)}`,
            fit: [9, 9],
            width: 9,
            style: ['marginTop2'],
          },
          {
            text: `${protocolPriorityLevel}`,
            style: ['greyHeading'],
          },
        ],
        style: [isFirstOfDetails ? '' : 'marginTop4'],
      });
      isFirstOfDetails = false;
    }

    if (protocolEntry.text?.length || (protocolEntryInformation.protocolEntryDetailRows && protocolEntryInformation.protocolEntryDetailRows > 1)) {
      const descriptionContent = this.pdfHelperFunctions.convertHtmlToPdfmake(convertToRichText(protocolEntry.text), {fontSize: 9});
      if (!isMinimizeMainEntry && !this.config.pdfProtocolSetting?.hideDescription) {
        descriptionColumn = addZeroWidthSpaces(descriptionContent, this.lng);
        descriptionColumn.width = 329;
      }

      if (!isMinimizeMainEntry) {
        const protocolEntryDetails = this.getProtocolEntryDetails(protocolEntry, isFirstOfDetails);
        detailColumn = {
          width: 170,
          columns: [protocolEntryDetails.columns],
        };
      }
    }

    const innerTable = this.getProtocolEntryHeader(protocolEntry, isTask);
    const tblEntry: Table = {
      headerRows: 0,
      widths: [protocolEntry.parentId ? 318 : 329, 170],
      body: [
        [
          {
            colSpan: 2,
            table: innerTable,
            layout: this.tableLayoutInnerForHeading,
            fillColor: isHightLight ? '#F4F4F4' : '#FFFFFF',
            id: _.uniqueId(PDF_NODE_ID_HEADER_TABLE_SEARCH_STRING),
          },
          {},
        ],
        [
          {
            stack: [
              {
                text: `${this.sanitizeValue(protocolEntry.title)}`,
                style: ['textBold', 'font9', 'marginBottom4'],
                id: _.uniqueId(PDF_NODE_ID_ENTRY_TITLE),
              },
              descriptionColumn,
            ],
            id: _.uniqueId(PDF_NODE_ID_DESC_SEARCH_STRING),
          },
          {
            stack: [protocolEntryDetailColumns, detailColumn],
          },
        ],
      ],
    };

    const tableLayout: TableLayout = {
      hLineWidth: function (i: number, node: ContentTable) {
        return i <= 1 || i === node.table.body.length ? 0.5 : 0;
      },
      hLineColor() {
        return '#4F5964';
      },
      vLineWidth: function (i: number, node: ContentTable) {
        return i === 0 || (i === node.table.widths?.length && !protocolEntry.parentId) ? 0.5 : 0;
      },
      vLineColor() {
        return '#4F5964';
      },
      paddingLeft() {
        return 4;
      },
      paddingRight() {
        return 4;
      },
      paddingTop() {
        return 4;
      },
      paddingBottom() {
        return 4;
      },
    };

    content.push({
      layout: tableLayout,
      table: tblEntry,
      style: isFirstSubentry ? [] : ['marginTop10'],
    });

    this.writeProtocolEntryTableDetails(content, protocolEntry, isTask);
  }

  private writeProtocolEntryTableDetails(content: Content[], protocolEntry: ProtocolEntry, isTask: boolean) {
    const isMinimizeMainEntry = this.isMinimizeDetails(protocolEntry);
    let hasOtherInfo = false;
    let hasSubEntries = false;
    let indexSubentries = 0;

    const tableCell: TableCell[][] = [];
    if (!isMinimizeMainEntry) {
      const pdfPlanPage = this.getPdfPlanPage(protocolEntry.id);
      if (pdfPlanPage !== undefined) {
        hasOtherInfo = true;
        indexSubentries++;
        tableCell.push([
          {
            columns: [this.getPlanMarker(protocolEntry)],
            style: ['marginBottom6'],
          },
        ]);
      }

      const bimMarkers = this.getBimMarkers(protocolEntry.id);
      if (bimMarkers.length > 0) {
        hasOtherInfo = true;
        indexSubentries++;
        tableCell.push([
          {
            columns: [this.getBimMarker(protocolEntry, bimMarkers)],
            style: ['marginBottom6'],
          },
        ]);
      }

      const protocolEntryPhotos = this.getProtocolEntryAttachmentsData(protocolEntry.id);
      if (protocolEntryPhotos !== undefined) {
        hasOtherInfo = true;
        indexSubentries++;
        tableCell.push([
          {
            columns: [this.getProtocolEntryAttachments(protocolEntry)],
          },
        ]);
      }

      const comments = this.getProtocolEntryComments(protocolEntry.id);
      if (comments !== undefined) {
        hasOtherInfo = true;
        indexSubentries++;
        tableCell.push([
          {
            columns: [this.getComments(protocolEntry)],
          },
        ]);
      }
    }

    const sortedSubProtocolEntries = this.getProtocolEntrySubEntries(protocolEntry);
    if (sortedSubProtocolEntries.length > 0) {
      hasOtherInfo = true;
      hasSubEntries = true;
      tableCell.push([
        {
          columns: [this.getSubEntries(protocolEntry, isTask)],
        },
      ]);
    }

    const tableLayoutEntryDetails: TableLayout = {
      hLineWidth: function (i: number, node: ContentTable) {
        return (i > 0 && i <= 4) || i === node.table.body.length ? 0.5 : 0;
      },
      hLineColor() {
        return '#4F5964';
      },
      vLineWidth: function (i: number, node: ContentTable) {
        return i === 0 || (i === node.table.widths?.length && !protocolEntry.parentId) ? 0.5 : 0;
      },
      vLineColor() {
        return '#4F5964';
      },
      paddingLeft() {
        return 4;
      },
      paddingRight() {
        return 4;
      },
      paddingTop: function (i: number, node: ContentTable) {
        return i === indexSubentries && hasSubEntries ? 0 : 4;
      },
      paddingBottom: function (i: number, node: ContentTable) {
        return i === node.table.body.length - 1 && hasSubEntries ? 10 : 4;
      },
    };

    if (hasOtherInfo) {
      content.push({
        table: {
          widths: [this.getTableWidth(protocolEntry)],
          body: tableCell,
        },
        layout: tableLayoutEntryDetails,
      });
    }
  }

  getBimMarker(protocolEntry: ProtocolEntry, bimMarkers: BimMarker[]): Column[] {
    const columns: Column[] = [];
    const bimVersion = this.data.bimVersions?.find((bim) => bimMarkers.some((marker) => marker.bimVersionId === bim.id));
    const bimVersionInfo = bimVersion ? (bimVersion.name ? `${bimVersion.name} | ` : '') + formatDate(bimVersion.createdAt) : '';
    columns.push({
      text: '\u200B',
      id: _.uniqueId(PDF_NODE_ID_BIM_HEADER),
      margin: [0, 0, 0, 0],
      fontSize: 0,
    });
    columns.push({
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.marker, this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
          width: 12,
        },
        {
          // todo: translations
          text: `${this.i18n?.get('bimMarker')}`,
          width: 88,
          style: ['font9', 'protocolFontColor', 'marginLeft3', 'textBold'],
        },
        {
          text: bimVersion ? `${bimVersionInfo}` : '',
          width: 'auto',
          // todo: bim public link(?)
          link: '',
          // todo: bim public link color
          style: ['font9', 'greytext'],
        },
        {
          // todo: download info(?)
          text: '',
          width: 'auto',
          style: ['font9', 'greyText'],
        },
      ],
    });

    type RequiredBase64Screenshot = AttachmentWithContent<AttachmentBimMarkerScreenshot> & Required<Pick<AttachmentWithContent<AttachmentBimMarkerScreenshot>, 'contentBase64'>>;

    const markers = bimMarkers
      .map((marker) => (marker.id ? this.data.attachmentBimMarkerScreenshots?.get(marker.id) : undefined))
      .filter((marker: AttachmentWithContent<AttachmentBimMarkerScreenshot> | undefined): marker is RequiredBase64Screenshot => !!marker?.contentBase64);

    for (const chunk of _.chunk(markers, 2)) {
      columns.push({
        columns: chunk.map((marker) => ({
          image: this.sanitizeBase64Image(marker.contentBase64),
          fit: [161, 124],
          style: ['alignCenter', 'marginTop10'],
          // todo: bim marker public link(?)
          link: '',
          id: _.uniqueId(PDF_NODE_ID_BIM_CONTENT),
        })),
      });
    }

    return columns;
  }

  protected getTableWidth(protocolEntry: ProtocolEntry): number {
    return _.isEmpty(protocolEntry.parentId) ? TABLE_WIDTH : TABLE_WIDTH - 10;
  }

  protected getProtocolEntryInformation(protocolEntry: ProtocolEntry): {columns: Column[]; protocolEntryDetailRows: number | undefined} {
    const columns: Column[] = [];
    const isMinimizeMainEntry = this.isMinimizeDetails(protocolEntry);

    let protocolEntryDetailRows: number | undefined;
    if (!isMinimizeMainEntry) {
      const protocolEntryDetails = this.getProtocolEntryDetails(protocolEntry, false, {showEntriesUpTo: 0});
      protocolEntryDetailRows = protocolEntryDetails.protocolEntryDetailRows;
      columns.push({
        width: 180,
        columns: [protocolEntryDetails.columns],
        style: ['marginLeft10'],
      });
    }

    return {columns, protocolEntryDetailRows};
  }

  protected getProtocolEntryDetails(
    protocolEntry: ProtocolEntry,
    isFirstOfDetails: boolean,
    options?: {showEntriesUpTo?: number; showEntriesAfter?: number}
  ): {columns: Column[]; protocolEntryDetailRows: number} {
    const columns: Column[] = [];
    const showEntryDetails: PdfPrintEntryDetails[] = this.config.pdfProtocolSetting?.printEntryDetails || [];
    let rowNumber = -1;

    const showByOptions = (): boolean => {
      return (options?.showEntriesUpTo === undefined || rowNumber <= options.showEntriesUpTo) && (options?.showEntriesAfter === undefined || rowNumber > options.showEntriesAfter);
    };

    const protocolEntryCompany = this.getProtocolEntryCompany(protocolEntry);
    if (!_.isEmpty(protocolEntryCompany) && _.includes(showEntryDetails, PdfPrintEntryDetails.COMPANY)) {
      rowNumber++;
      if (showByOptions()) {
        columns.push({
          columns: [
            {
              text: `${this.i18n?.get('company')} - ${this.replaceSpecialCharToSpace(protocolEntryCompany)}`,
              style: ['greyHeading', isFirstOfDetails ? '' : 'marginTop4'],
            },
          ],
        });
        isFirstOfDetails = false;
      }
    }

    const companyProfile = this.data.lookup.profiles.get(protocolEntry?.internalAssignmentId + '');
    const companyAddresses = this.data.lookup.addresses.get(companyProfile?.addressId + '');
    if (!_.isEmpty(companyAddresses?.firstName) && !_.isEmpty(companyAddresses?.lastName) && _.includes(showEntryDetails, PdfPrintEntryDetails.RESPONSIBLE)) {
      rowNumber++;
      if (showByOptions()) {
        columns.push({
          columns: [
            {
              text: `${this.i18n?.get('responsible')} - ${companyAddresses?.firstName} ${companyAddresses?.lastName}`,
              style: ['greyHeading', isFirstOfDetails ? '' : 'marginTop4'],
            },
          ],
        });
        isFirstOfDetails = false;
      }
    }

    const protocolEntryCompanies = this.groupedObserverCompanies[protocolEntry.id] ?? [];
    if (!_.isEmpty(protocolEntryCompanies) && _.includes(showEntryDetails, PdfPrintEntryDetails.OBSERVER_COMPANIES)) {
      rowNumber++;
      if (showByOptions()) {
        columns.push({
          columns: [
            {
              text: `${this.i18n?.get('observerCompanies')} - ${protocolEntryCompanies
                .map(({companyId}) => this.data.lookup.companies.get(companyId)?.name)
                .filter(Boolean)
                .join(', ')}`,
              style: ['greyHeading', isFirstOfDetails ? '' : 'marginTop4'],
            },
          ],
        });
        isFirstOfDetails = false;
      }
    }

    const protocolEntryType = this.sanitizeValue(this.data.lookup.protocolEntryTypes.get(protocolEntry?.typeId + '')?.name);
    if (!_.isEmpty(protocolEntryType) && _.includes(showEntryDetails, PdfPrintEntryDetails.TYPE)) {
      rowNumber++;
      if (showByOptions()) {
        columns.push({
          columns: [
            {
              text: `${this.i18n?.get('type')} - ${this.replaceSpecialCharToSpace(protocolEntryType)}`,
              style: ['greyHeading', isFirstOfDetails ? '' : 'marginTop4'],
            },
          ],
        });
        isFirstOfDetails = false;
      }
    }

    const craft = this.sanitizeValue(this.data.lookup.crafts.get(protocolEntry?.craftId + '')?.name);
    if (!_.isEmpty(craft) && _.includes(showEntryDetails, PdfPrintEntryDetails.CRAFT)) {
      rowNumber++;
      if (showByOptions()) {
        columns.push({
          columns: [
            {
              text: `${this.i18n?.get('craft')} - ${this.replaceSpecialCharToSpace(craft)}`,
              style: ['greyHeading', isFirstOfDetails ? '' : 'marginTop4'],
            },
          ],
        });
        isFirstOfDetails = false;
      }
    }

    const location = this.sanitizeValue(this.data.lookup.protocolEntryLocations.get(protocolEntry?.locationId + '')?.location);
    if (!_.isEmpty(location) && _.includes(showEntryDetails, PdfPrintEntryDetails.LOCATION)) {
      rowNumber++;
      if (showByOptions()) {
        columns.push({
          columns: [
            {
              text: `${this.i18n?.get('location')} - ${this.replaceSpecialCharToSpace(location)}`,
              style: ['greyHeading', isFirstOfDetails ? '' : 'marginTop4'],
            },
          ],
        });
        isFirstOfDetails = false;
      }
    }
    const nameableItem = this.data.lookup.nameableDropdownItems.get(`${this.data.project.id}${protocolEntry?.nameableDropdownId}`);
    const nameableDropdown = this.data.lookup.nameableDropdowns.get(nameableItem?.nameabledropdownId + '')?.name;
    const additionalField = this.sanitizeValue(nameableDropdown);
    if (!_.isEmpty(additionalField) && _.includes(showEntryDetails, PdfPrintEntryDetails.ADDITIONAL_FIELD)) {
      rowNumber++;
      if (showByOptions()) {
        columns.push({
          columns: [
            {
              text: `${this.replaceSpecialCharToSpace(this.data.client.nameableDropdownName)} - ${this.replaceSpecialCharToSpace(additionalField)}`,
              style: ['greyHeading', isFirstOfDetails ? '' : 'marginTop4'],
            },
          ],
        });
        isFirstOfDetails = false;
      }
    }

    const cost = protocolEntry?.cost;
    const costParsed = parseFloat(`${cost}`);
    if (cost !== undefined && cost !== null && costParsed !== 0 && _.includes(showEntryDetails, PdfPrintEntryDetails.COSTS)) {
      rowNumber++;
      if (showByOptions()) {
        columns.push({
          columns: [
            {
              text: `${this.i18n?.get('costs')} - ${ProjectCurrencyEnum[this.data.project.currency]} ${this.getFormattedNumber(costParsed)}`,
              style: ['greyHeading', isFirstOfDetails ? '' : 'marginTop4'],
            },
          ],
        });
        isFirstOfDetails = false;
      }
    }

    if (_.includes(showEntryDetails, PdfPrintEntryDetails.UNITS) && protocolEntry.unitId) {
      const unitForBreadcrumb = this.getProtocolEntryUnitForBreadcrumbs(protocolEntry);
      if (!unitForBreadcrumb) {
        throw new Error(`getProtocolEntryUnitForBreadcrumbs did not find the unit ${protocolEntry.unitId} of protocolEntry ${protocolEntry.id}`);
      }
      rowNumber++;
      if (showByOptions()) {
        columns.push({
          columns: [
            {
              text: `${this.i18n?.get('unit')} - ${this.replaceSpecialCharToSpace(unitForBreadcrumb.breadcrumbsName)}`,
              style: ['greyHeading', isFirstOfDetails ? '' : 'marginTop4'],
            },
          ],
        });
        isFirstOfDetails = false;
      }
    }

    return {columns, protocolEntryDetailRows: rowNumber + 1};
  }

  protected getProtocolEntryCompany(protocolEntry: ProtocolEntry): string {
    const protocolType = this.data.lookup?.protocolTypes.get(this.data.protocol.typeId);
    const protocolLayout = this.data.lookup?.protocolLayouts.get(protocolType?.layoutId + '');
    if (protocolEntry.allCompanies) {
      return this.sanitizeValue(this.i18n?.get('project_team'));
    }
    let companyName = this.data.lookup?.companies.get(protocolEntry?.companyId + '')?.name;
    if (protocolType !== undefined && protocolLayout !== undefined && protocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT) {
      const userPublic = this.data.lookup.userPublicData.get(this.data.userId);
      const userProfile = this.data.lookup.profiles.get(userPublic?.profileId + '');
      const userCompany = this.data.lookup.companies.get(userProfile?.companyId + '');
      if (userCompany !== undefined) {
        companyName = userCompany.name;
      }
    }
    return this.sanitizeValue(companyName);
  }

  protected getProtocolEntryUnitForBreadcrumbs(protocolEntry: ProtocolEntry): UnitForBreadcrumbs | undefined {
    if (!protocolEntry.unitId) {
      return undefined;
    }
    return this.data.unitForBreadcrumbs?.find((v) => v.id === protocolEntry.unitId);
  }

  private isCarriedEntry(protocolEntry: ProtocolEntry): boolean {
    return this.data.protocolOpenEntries.some((entry) => entry.protocolEntryId === protocolEntry.id);
  }

  private isContinuousProtocol(): boolean {
    const layoutId = this.data.lookup.protocolTypes.get(this.data.protocol.typeId)?.layoutId;
    if (layoutId) {
      return this.data.lookup.protocolLayouts.get(layoutId)?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS;
    }
    return false;
  }

  protected getProtocolEntryHeader(protocolEntry: ProtocolEntry, isTask: boolean): Table {
    let addedLaterText = false;
    const displayNewFlag = (this.isContinuousProtocol() && !this.isCarriedEntry(protocolEntry)) || protocolEntry.createdInProtocolId === this.data.protocol.id;

    const protocolType = this.data.lookup?.protocolTypes.get(this.data.protocol.typeId);
    const protocolLayout = this.data.lookup?.protocolLayouts.get(protocolType?.layoutId + '');

    let protocolEntryStatus;

    if (protocolType !== undefined && protocolLayout !== undefined && protocolLayout.name === PROTOCOL_LAYOUT_NAME_SHORT) {
      protocolEntryStatus = getProtocolEntryStatus(protocolEntry);
    } else {
      protocolEntryStatus = getProtocolEntryIconStatus(protocolEntry, this.data.lookup.protocolEntryTypes.get(protocolEntry.typeId + ''));
    }

    let svgProtocolStatus = '',
      protocolStatusText = '';
    if (protocolEntryStatus === ProtocolEntryIconStatus.OPEN) {
      protocolStatusText = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? this.sanitizeValue(this.i18n?.get('checkbox_open_status')) : this.sanitizeValue(this.i18n?.get('open_status'));
      svgProtocolStatus = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? SvgIcons.blackCheckboxUncheck : SvgIcons.redProtocolStatus;
    } else if (protocolEntryStatus === ProtocolEntryIconStatus.ON_HOLD) {
      protocolStatusText = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? this.sanitizeValue(this.i18n?.get('checkbox_waiting_status')) : this.sanitizeValue(this.i18n?.get('waiting_status'));
      svgProtocolStatus = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? SvgIcons.blackCheckboxUncheck : SvgIcons.yellowProtocolStatus;
    } else if (protocolEntryStatus === ProtocolEntryIconStatus.DONE) {
      protocolStatusText = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? this.sanitizeValue(this.i18n?.get('checkbox_done_status')) : this.sanitizeValue(this.i18n?.get('done_status'));
      svgProtocolStatus = this.config.pdfProtocolSetting?.showStatusAsCheckbox ? SvgIcons.blackCheckboxCheck : SvgIcons.greenProtocolStatus;
    } else {
      protocolStatusText = this.sanitizeValue(this.i18n?.get('info'));
      svgProtocolStatus = SvgIcons.infoBlue;
    }
    if (protocolEntry.createdInProtocolId) {
      const createdInProtocol = this.data.lookup.protocols.get(protocolEntry.createdInProtocolId);
      if (createdInProtocol && this.data.protocol.number < createdInProtocol.number) {
        addedLaterText = true;
      }
    }

    const headerTable: Table = {
      headerRows: 0,
      widths: [12, protocolEntry.parentId ? 184 : 195, 30, 100, 100, 58, 12],
      body: [
        [
          {
            svg: isTask ? this.changeSvgIconFill(SvgIcons.recordCheck, this.getProtocolColor()) : this.changeSvgIconFill(SvgIcons.record, this.getProtocolColor()),
            fit: [12, 12],
            style: ['marginTop1'],
          },
          {
            text: `${isTask ? this.getTaskShortId(protocolEntry) : this.getProtocolEntryShortId(protocolEntry)}`,
            style: ['font9', 'protocolFontColor', 'textBold'],
          },
          {
            text: displayNewFlag ? `${this.i18n?.get('new_flag')}` : '',
            style: ['font9', 'fontColorRed'],
            relativePosition: {x: -237, y: 0},
          },
          addedLaterText
            ? {
                text: ` ${this.sanitizeValue(this.i18n?.get('entry_added_later'))} `,
                style: ['font9', 'fontColorRed'],
              }
            : {},
          {
            text: `${this.i18n?.get('created_at')} ${this.getDateValueNotNull(protocolEntry.createdAt)}`,
            style: ['font9', 'greyText', 'textBold'],
            noWrap: true,
          },
          !_.isEmpty(svgProtocolStatus) && !_.isEmpty(protocolStatusText)
            ? {
                text: protocolStatusText,
                style: ['font9', 'textBold', 'marginRight3'],
                alignment: 'right',
              }
            : {},
          !_.isEmpty(svgProtocolStatus) && !_.isEmpty(protocolStatusText)
            ? {
                svg: svgProtocolStatus,
                fit: [12, 12],
              }
            : {},
        ],
      ],
    };

    return headerTable;
  }

  protected getProtocolStartEndDate(protocolEntry: ProtocolEntry): Content[] {
    const content: Content[] = [];
    if (!this.isEmptyDate(protocolEntry.startDate)) {
      const startDateColumns: Column[] = [];
      startDateColumns.push({
        text: `${this.i18n?.get('startDate')}`,
        width: 'auto',
        style: ['font9', 'fontColorRed', 'textBold'],
      });

      startDateColumns.push({
        text: ` ${this.getDateValueNotNull(protocolEntry.startDate)}`,
        width: 'auto',
        style: ['font9', 'fontColorRed', 'textBold', 'marginLeft3'],
      });
      content.push({columns: startDateColumns});
    }
    if (!this.isEmptyDate(protocolEntry.todoUntil)) {
      const endDateColumns: Column[] = [];
      endDateColumns.push({
        text: `${this.i18n?.get('dueNew')}`,
        width: 'auto',
        style: ['font9', 'fontColorRed', 'textBold'],
      });

      endDateColumns.push({
        text: ` ${this.getDateValueNotNull(protocolEntry.todoUntil)}`,
        width: 'auto',
        style: ['font9', 'fontColorRed', 'textBold', 'marginLeft3'],
      });
      content.push({columns: endDateColumns});
    }
    return content;
  }

  protected getPdfPlanPage(protocolEntryId: IdType): PdfPlanAttachmentWithContent | undefined {
    const pdfPlanPageMarkerGeneral = this.data.pdfPlanPageMarkings.find((pdfPlanPageMarking) => !pdfPlanPageMarking.protocolEntryId && pdfPlanPageMarking);
    const pdfPlanPageMarker = this.data.pdfPlanPageMarkings.find((pdfPlanPageMarking) => pdfPlanPageMarking.protocolEntryId === protocolEntryId);
    const pdfPlanPage = this.data.pdfPlanPages?.find((pdfProtocolEntryPlanPage) => pdfProtocolEntryPlanPage.protocolEntryId === protocolEntryId);
    if (!this.config.pdfProtocolSetting?.showPdfPlanMarker || _.isEmpty(pdfPlanPage?.contentBase64)) {
      return undefined;
    }
    if (pdfPlanPage && (pdfPlanPageMarkerGeneral || pdfPlanPageMarker)) {
      pdfPlanPage.markings = [];
      if (pdfPlanPageMarkerGeneral) {
        pdfPlanPage.markings.push(pdfPlanPageMarkerGeneral.markings);
      }
      if (pdfPlanPageMarker) {
        pdfPlanPage.markings.push(pdfPlanPageMarker.markings);
      }
    }
    return pdfPlanPage;
  }

  protected getBimMarkers(protocolEntryId: IdType): Array<BimMarker> {
    if (!(this.config.pdfProtocolSetting?.showBimMarker ?? true)) {
      return [];
    }

    const bimMarkers = this.data.bimMarkers?.filter((marker) => marker.protocolEntryId === protocolEntryId) ?? [];

    return bimMarkers;
  }

  protected getPlanMarker(protocolEntry: ProtocolEntry): Column[] {
    const columns: Column[] = [];
    const pdfPlanPage = this.getPdfPlanPage(protocolEntry.id);
    const downloadInfo = pdfPlanPage?.publicLink && this.config.pdfProtocolSetting?.showAttachmentDlLink ? this.i18n?.get('downloadClick') : '';
    const planVersion = this.data.pdfPlanVersions ? this.data.pdfPlanVersions.find((plan) => plan.id === pdfPlanPage?.attachment.pdfPlanVersionId) : undefined;
    const planVersionInfo = planVersion ? planVersion.name + ' | ' + (planVersion.index ? planVersion.index + ' | ' : '') + formatDate(planVersion.date) : '';
    const planPublicLink = this.data.pdfPlanVersionsWithContent?.find((pdfPlan) => pdfPlan.attachment.pdfPlanVersionId === pdfPlanPage?.attachment.pdfPlanVersionId)?.publicLink;
    const fontColor = this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'fontColorBlue' : '';
    const linkUnderline = this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'linkUnderline' : '';
    columns.push({
      text: '\u200B',
      id: _.uniqueId(PDF_NODE_ID_PLAN_HEADER),
      margin: [0, 0, 0, 0],
      fontSize: 0,
    });
    columns.push({
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.marker, this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
          width: 12,
        },
        {
          text: `${this.i18n?.get('planMarker')}`,
          width: 88,
          style: ['font9', 'protocolFontColor', 'marginLeft3', 'textBold'],
        },
        {
          text: `${downloadInfo}`,
          width: 'auto',
          style: ['font9', 'greyText'],
        },
      ],
    });

    columns.push({
      columns: [
        {
          text: `${this.i18n?.get('plan')}`,
          width: 'auto',
          style: ['font9'],
        },
        {
          text: `${planVersionInfo}`,
          width: 'auto',
          link: `${planPublicLink ? planPublicLink : ''}`,
          style: ['font9', `${fontColor}`, `${linkUnderline}`, 'marginLeft3'],
        },
      ],
      style: ['marginTop4'],
    });

    if (pdfPlanPage?.contentBase64 !== undefined && pdfPlanPage?.secondContentBase64 !== undefined) {
      columns.push({
        columns: [
          {
            image: this.sanitizeBase64Image(pdfPlanPage?.contentBase64),
            fit: [161, 124],
            style: ['alignCenter', 'marginTop10'],
            link: pdfPlanPage.publicLink ? `${pdfPlanPage.publicLink}` : '',
          },
          {
            image: this.sanitizeBase64Image(pdfPlanPage?.secondContentBase64),
            fit: [161, 124],
            style: ['alignCenter', 'marginTop10'],
            link: pdfPlanPage.secondPublicLink ? `${pdfPlanPage.secondPublicLink}` : '',
          },
        ],
      });
    }

    columns.push({
      text: '\u200B',
      id: _.uniqueId(PDF_NODE_ID_PLAN_CONTENT),
      margin: [0, 0, 0, 0],
      fontSize: 0,
    });

    return columns;
  }

  protected getProtocolEntryAttachmentsData(protocolEntryId: IdType): AttachmentWithContent<AttachmentProtocolEntry>[] | undefined {
    const protocolEntryPhotos = this.data.attachmentProtocolEntries?.filter((protocolEntryAttachment) => protocolEntryAttachment.attachment.protocolEntryId === protocolEntryId);
    if (_.isEmpty(protocolEntryPhotos) || protocolEntryPhotos === undefined || this.config.pdfProtocolSetting?.showPictures === ShowPicturesEnum.NONE) {
      return undefined;
    }
    return _.orderBy(protocolEntryPhotos, [(v) => v.attachment.createdAt, (v) => v.attachment.id], ['asc', 'asc']);
  }

  protected getProtocolEntryAttachments(protocolEntry: ProtocolEntry): Column[] {
    const columns: Column[] = [];
    const protocolEntryPhotos = this.getProtocolEntryAttachmentsData(protocolEntry.id);
    const downloadInfo = protocolEntryPhotos?.some((photo) => photo.publicLink) && this.config.pdfProtocolSetting?.showAttachmentDlLink ? this.i18n?.get('downloadClick') : '';
    columns.push({
      text: '\u200B',
      id: _.uniqueId(PDF_NODE_ID_IMAGE_HEADER),
      margin: [0, 0, 0, 0],
      fontSize: 0,
    });
    columns.push({
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.attachment, this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
          width: 12,
        },
        {
          text: `${this.i18n?.get('photos_attachments')}`,
          width: 88,
          style: ['font9', 'protocolFontColor', 'marginLeft3', 'textBold'],
        },
        {
          text: `${downloadInfo}`,
          width: 'auto',
          style: ['font9', 'greyText'],
        },
      ],
    });
    const attachmentsOrderedByNewest = _.orderBy(protocolEntryPhotos, [(v) => v.attachment.createdAt, (v) => v.attachment.id], ['asc', 'asc']);
    if (this.config.pdfProtocolSetting?.showPictures === ShowPicturesEnum.SMALL) {
      this.addChunkPhotos(columns, attachmentsOrderedByNewest, [152, 100], 3);
    } else if (this.config.pdfProtocolSetting?.showPictures === ShowPicturesEnum.MEDIUM) {
      this.addChunkPhotos(columns, attachmentsOrderedByNewest, [232, 145], 2);
    } else if (this.config.pdfProtocolSetting?.showPictures === ShowPicturesEnum.LARGE) {
      this.addLargePhotosIntoColumns(columns, attachmentsOrderedByNewest);
    }
    return columns;
  }

  protected addChunkPhotos(columns: Column[], protocolEntryPhotos: AttachmentWithContent<AttachmentProtocolEntry>[], imageFit: [number, number], chunkImage: number) {
    const protocolEntryImages = protocolEntryPhotos.filter((protocolEntryPhoto) => this.isImage(protocolEntryPhoto.attachment.mimeType));
    const chunkProtocolEntryPhotos = _.chunk(protocolEntryImages, chunkImage);
    const chunkAttachmentsColumn = new Array<ContentStack & TableOfContent>();
    let index = 1;
    this.writeNonImageAttachment(columns, protocolEntryPhotos);

    for (const attachments of chunkProtocolEntryPhotos) {
      const attachmentColumns = new Array<ContentImage | ContentSvg | Content>();
      const indexColumns: Column[] = [];
      for (const attachment of attachments) {
        if (this.isImage(attachment.attachment.mimeType)) {
          if (attachment.contentBase64) {
            attachmentColumns.push({
              image: this.sanitizeBase64Image(attachment.contentBase64),
              fit: imageFit,
              style: ['alignCenter'],
              link: attachment.publicLink ? `${attachment.publicLink}` : '',
            });
          } else {
            attachmentColumns.push({
              svg: SvgIcons.noImagePlaceholder,
              fit: imageFit,
              style: ['alignCenter'],
            });
          }

          indexColumns.push({
            text: index.toString(),
            style: ['alignCenter', 'font9'],
          });
          index++;
        }
      }
      chunkAttachmentsColumn.push({
        stack: [
          {
            columns: indexColumns,
            style: ['marginTop10'],
          },
          {
            columns: attachmentColumns,
            style: ['marginTop2'],
          },
        ],
        id: 'imageRow',
      });
    }
    columns.push({
      columns: [chunkAttachmentsColumn],
      style: ['marginTop5Bottom6'],
    });
  }

  protected addLargePhotosIntoColumns(columns: Column[], protocolEntryPhotos: AttachmentWithContent<AttachmentProtocolEntry>[]) {
    const attachmentColumns = new Array<(ContentStack & TableOfContent & ColumnProperties) | ContentColumns>();
    const protocolEntryImages = protocolEntryPhotos.filter((protocolEntryPhoto) => this.isImage(protocolEntryPhoto.attachment.mimeType));
    let index = 1;
    this.writeNonImageAttachment(columns, protocolEntryPhotos);

    for (const protocolEntryPhoto of protocolEntryImages) {
      attachmentColumns.push({
        width: '*',
        style: ['alignCenter'],
        stack: [
          {
            text: index.toString(),
            style: ['alignCenter', 'font9', 'marginTop10'],
          },
          {
            columns: [
              protocolEntryPhoto.contentBase64
                ? {
                    image: this.sanitizeBase64Image(protocolEntryPhoto.contentBase64),
                    fit: [470, 270],
                    style: ['marginTop2'],
                    link: protocolEntryPhoto.publicLink ? `${protocolEntryPhoto.publicLink}` : '',
                  }
                : {
                    svg: SvgIcons.noImagePlaceholder,
                    fit: [470, 270],
                    style: ['marginTop2'],
                  },
            ],
          },
        ],
        id: 'imageRow',
      });
      index++;
    }

    columns.push({
      columns: [attachmentColumns],
      style: ['marginTop5'],
    });
  }

  protected getProtocolEntryComments(protocolEntryId: IdType): ProtocolEntryChat[] | undefined {
    const comments = _.sortBy(
      this.data.protocolEntryChats.filter((protocolEntryChat) => protocolEntryChat.protocolEntryId === protocolEntryId),
      ['createdAt']
    );
    if (!this.config.pdfProtocolSetting?.showEntryComments || comments.length === 0) {
      return undefined;
    }
    return comments;
  }

  protected getComments(protocolEntry: ProtocolEntry): Column[] {
    const columns: Column[] = [];
    const comments = this.getProtocolEntryComments(protocolEntry.id);
    const downloadInfo = this.data.attachmentChats?.some((attachmentChat) => attachmentChat.publicLink) && this.config.pdfProtocolSetting?.showAttachmentDlLink ? this.i18n?.get('downloadClick') : '';

    if (comments === undefined) {
      return columns;
    }

    columns.push({
      columns: [
        {
          columns: [
            {
              svg: this.changeSvgIconFill(SvgIcons.comments, this.getProtocolColor()),
              fit: [12, 12],
            },
          ],
          width: 12,
        },
        {
          text: `${this.i18n?.get('comments')}`,
          width: 88,
          style: ['font9', 'protocolFontColor', 'marginLeft3', 'textBold'],
        },
        {
          text: `${downloadInfo}`,
          width: 'auto',
          style: ['font9', 'greyText'],
        },
      ],
    });

    for (const comment of comments) {
      const commentsColumns: Column[] = [];
      const userData = this.data.lookup.userPublicData.get(comment?.createdById + '');
      const commenterProfile = this.data.lookup.profiles.get(userData?.profileId + '') ?? this.data.lookup.profilesByAttachedToUserId.get(comment?.createdById + '');
      const commenterAddress = this.data.lookup.addresses.get(commenterProfile?.addressId + '');
      const displayNewFlag = this.isContinuousProtocol() && this.isCarriedEntry(protocolEntry) && comment.changedAt > this.data.protocol.createdAt;

      commentsColumns.push({
        width: 100,
        columns: [
          [
            {
              style: ['font9', 'fontColorRed'],
              text: displayNewFlag ? `${this.i18n?.get('new_flag')}` : '',
              relativePosition: {x: -30, y: 0},
            },
            {
              style: ['greyHeading'],
              text: `${this.sanitizeValue(commenterAddress?.firstName)} ${this.sanitizeValue(commenterAddress?.lastName)}`,
            },
            {
              style: ['font9', 'greyText'],
              text: `${this.getDateValueNotNull(comment.changedAt, 'DD.MM.YYYY | HH:mm')}`,
            },
          ],
        ],
      });

      commentsColumns.push({
        width: '*',
        style: [],
        columns: [
          {
            style: ['font9'],
            text: `${this.sanitizeValue(comment?.message)}`,
          },
        ],
      });

      columns.push({
        columns: commentsColumns,
        width: '*',
        style: 'marginTop4',
      });

      if (this.config.pdfProtocolSetting?.showEntryCommentPictures) {
        const chatAttachments = this.data.attachmentChats?.filter((attachmentChat) => attachmentChat.attachment.chatId === comment.id);
        if (!_.isEmpty(chatAttachments) && chatAttachments !== undefined) {
          const sortedChatAttachments = _.orderBy(chatAttachments, [(v) => v.attachment.createdAt, (v) => v.attachment.id], ['asc', 'asc']);
          const nonImageAttachments = sortedChatAttachments.filter((attachment) => !this.isImage(attachment.attachment.mimeType));
          const imageAttachments = sortedChatAttachments.filter((attachment) => this.isImage(attachment.attachment.mimeType));
          const chunkChatImages = _.chunk(imageAttachments, 3);
          const chunkAttachmentsColumn = new Array<ContentStack & TableOfContent & ColumnProperties>();
          let index = 1;
          for (const attachment of nonImageAttachments) {
            const nonImageAttachmentColumn: Column[] = [];
            this.addToNonPhotosColumnComment(attachment, nonImageAttachmentColumn);
            columns.push({
              columns: [nonImageAttachmentColumn],
              style: ['marginTop4'],
            });
          }
          for (const attachments of chunkChatImages) {
            const attachmentColumns = new Array<ContentImage | ContentSvg>();
            const indexColumns: Column[] = [];
            for (const chatAttachment of attachments) {
              if (chatAttachment.contentBase64) {
                attachmentColumns.push({
                  image: this.sanitizeBase64Image(chatAttachment.contentBase64),
                  fit: [150, 150],
                  style: ['alignCenter'],
                  link: chatAttachment.publicLink ? `${chatAttachment.publicLink}` : '',
                });
              } else {
                attachmentColumns.push({
                  svg: SvgIcons.noImagePlaceholder,
                  fit: [150, 150],
                  style: ['alignCenter'],
                });
              }

              indexColumns.push({
                text: index.toString(),
                style: ['font9', 'alignCenter'],
              });
              index++;
            }
            chunkAttachmentsColumn.push({
              stack: [
                {
                  columns: indexColumns,
                  style: ['marginTop10'],
                },
                {
                  columns: attachmentColumns,
                  style: ['marginTop2'],
                },
              ],
              id: 'imageRow',
              width: 'auto',
            });
          }

          if (!_.isEmpty(chunkAttachmentsColumn)) {
            columns.push({
              width: '*',
              columns: [chunkAttachmentsColumn],
              style: ['marginBottom6'],
            });
          }
        }
      }
    }

    return columns;
  }

  protected writeNonImageAttachment(columns: Column[], protocolEntryPhotos: AttachmentWithContent<AttachmentProtocolEntry>[]) {
    const splitedNonPhotos: Column[] = [];
    for (const attachment of protocolEntryPhotos) {
      if (!this.isImage(attachment.attachment.mimeType)) {
        this.addToNonPhotosColumn(attachment, splitedNonPhotos);
      }
    }
    if (splitedNonPhotos.length > 0) {
      columns.push({
        stack: splitedNonPhotos,
        style: ['marginTop4'],
      });
    }
  }

  protected addToNonPhotosColumn(attachment: AttachmentWithContent<AttachmentProtocolEntry>, columns: Column[]) {
    const fontColorBlue = this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'fontColorBlue' : '';
    const linkUnderline = this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'linkUnderline' : '';
    columns.push({
      columns: [
        {
          text: `${this.i18n?.get('attachment')}`,
          style: ['font9'],
          width: 'auto',
        },
        {
          text: `${attachment.attachment?.fileName + ''}`,
          style: ['font9', 'marginLeft3', `${fontColorBlue}`, `${linkUnderline}`],
          width: '*',
          alignment: 'left',
          link: attachment.publicLink ? `${attachment.publicLink}` : '',
          id: _.uniqueId('imageRow'),
        },
      ],
      width: '*',
    });
  }

  protected addToNonPhotosColumnComment(attachment: AttachmentWithContent<AttachmentProtocolEntry>, columns: Column[]) {
    const fontColorBlue = this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'fontColorBlue' : '';
    const linkUnderline = this.config.pdfProtocolSetting?.showAttachmentDlLink ? 'linkUnderline' : '';
    columns.push({
      columns: [
        {
          text: `${this.i18n?.get('attachment')}`,
          style: ['font9'],
          width: 'auto',
        },
        {
          text: attachment.attachment.fileName ? `${attachment.attachment.fileName}` : `${this.i18n?.get('no_filename')}`,
          style: ['font9', 'marginLeft3', `${fontColorBlue}`, `${linkUnderline}`],
          width: 'auto',
          link: attachment.publicLink ? `${attachment.publicLink}` : '',
        },
      ],
    });
  }

  protected getRotatedText(text: string): string {
    const canvas = createCanvas(30, 270);
    canvas.width = 30;
    canvas.height = 270;
    const ctx = canvas.getContext('2d');
    if (ctx !== null) {
      ctx.font = '30pt Arial';
      ctx.save();
      ctx.translate(30, 270);
      ctx.rotate(-0.5 * Math.PI);
      ctx.fillStyle = '#000';
      ctx.fillText(text, 0, 0);
      ctx.restore();
    }
    return canvas.toDataURL();
  }

  protected getProtocolEntrySubEntries(protocolEntry: ProtocolEntry): ProtocolEntry[] {
    let sortedProtocolEntries: ProtocolEntry[];
    if (this.isGroupBy) {
      const protocolEntryWithSubEntries = protocolEntry as ProtocolEntryWithSubEntries;
      sortedProtocolEntries = this.sortProtocolEntries(protocolEntryWithSubEntries.subEntries);
    } else {
      const subProtocolEntries = _.filter(this.data.protocolEntries, (entry) => entry.parentId === protocolEntry.id);
      sortedProtocolEntries = this.sortProtocolEntries(subProtocolEntries);
    }
    return this.filteredSubEntries(sortedProtocolEntries);
  }

  protected filteredSubEntries(sortedProtocolEntries: ProtocolEntry[]): ProtocolEntry[] {
    if (this.data.filteredProtocolEntries !== undefined && this.data.filteredProtocolEntries?.length > 0) {
      const filteredSubProtocolEntries: ProtocolEntry[] = [];
      for (const sortedProtocolEntry of sortedProtocolEntries) {
        const subEntry = this.data.filteredProtocolEntries.find((filteredEntry) => filteredEntry.id === sortedProtocolEntry.id);
        if (subEntry !== undefined && !_.isEmpty(subEntry) && !filteredSubProtocolEntries.some((filteredSubEntry) => filteredSubEntry.id === subEntry?.id)) {
          filteredSubProtocolEntries.push(subEntry);
        }
      }
      return filteredSubProtocolEntries;
    }
    return sortedProtocolEntries;
  }

  protected getSubEntries(protocolEntry: ProtocolEntry, isTask: boolean): Column[] {
    const columns: Column[] = [];
    const subEntriesColumn: Column[] = [];
    const sortedSubProtocolEntries = this.getProtocolEntrySubEntries(protocolEntry);
    let index = 1;
    for (const subProtocolEntry of sortedSubProtocolEntries) {
      this.writeProtocolEntry(subEntriesColumn, subProtocolEntry, isTask, index === 1);
      index++;
    }

    if (subEntriesColumn.length > 0) {
      columns.push({
        columns: [
          {
            columns: [
              {
                text: `${this.i18n?.get('subentriesFor')} ${isTask ? this.getTaskShortId(protocolEntry) : this.getProtocolEntryShortId(protocolEntry)}`,
                style: ['greyText', 'italic', 'font9'],
                id: _.uniqueId(PDF_NODE_ID_SUBENTRY_HEADER),
              },
            ],
            width: '*',
            margin: [6, 8, 0, 4],
          },
        ],
      });
      columns.push({
        columns: [
          {
            columns: [subEntriesColumn],
            width: '*',
            margin: [6, 0, 0, 0],
          },
        ],
      });
      columns.push({
        columns: [
          {
            text: `${this.i18n?.get('subentriesForEnd')} ${isTask ? this.getTaskShortId(protocolEntry) : this.getProtocolEntryShortId(protocolEntry)}`,
            style: ['greyText', 'italic', 'font9'],
          },
        ],
        width: '*',
        margin: [6, 4, 0, 0],
      });
    }
    return [columns];
  }

  protected getLineWidth(protocolEntry: ProtocolEntry): number {
    return _.isEmpty(protocolEntry.parentId) ? TABLE_WIDTH - 5 : TABLE_WIDTH - 18;
  }

  protected getProtocolEntryPriorityLevel(priorityLevel: ProtocolEntryPriorityType | undefined): string | null {
    switch (priorityLevel) {
      case ProtocolEntryPriorityLevel.LOW:
        return this.sanitizeValue(this.i18n?.get('low'));
      case ProtocolEntryPriorityLevel.HIGH:
        return this.sanitizeValue(this.i18n?.get('high'));
      case ProtocolEntryPriorityLevel.MEDIUM:
        return this.sanitizeValue(this.i18n?.get('medium'));
      default:
        return null;
    }
  }

  protected getSvgPriorityLevel(priorityLevel: ProtocolEntryPriorityType | undefined): string | null {
    switch (priorityLevel) {
      case ProtocolEntryPriorityLevel.LOW:
        return SvgIcons.blackFlag;
      case ProtocolEntryPriorityLevel.HIGH:
        return SvgIcons.redFlag;
      case ProtocolEntryPriorityLevel.MEDIUM:
        return SvgIcons.yellowFlag;
      default:
        return null;
    }
  }
}
