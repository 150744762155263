import {Component, OnDestroy, OnInit} from '@angular/core';
import {Address, Profile, Project} from 'submodules/baumaster-v2-common';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {NavParams} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AddressDataService} from '../../../services/data/address-data.service';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {takeUntil} from 'rxjs/operators';
import {SUPPORTED_LANGUAGES, ToastDurationInMs} from '../../../shared/constants';
import {UserConnectionInviteService} from '../../../services/auth/userConnectionInvite.service';
import {ProjectDataService} from '../../../services/data/project-data.service';
import {ToastService} from 'src/app/services/common/toast.service';

@Component({
  selector: 'app-user-connection-invite',
  templateUrl: './user-connection-invite.component.html',
  styleUrls: ['./user-connection-invite.component.scss'],
})
export class UserConnectionInviteComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  public profile: Profile;
  public address: Address | undefined;
  public userInviteForm: UntypedFormGroup;
  public inProgress = false;
  private destroy$ = new Subject<void>();
  public errorMessage: string | undefined;
  public networkStatusConnected$: Observable<boolean>;
  public languages = SUPPORTED_LANGUAGES;
  private currentProject: Project | undefined;

  constructor(
    public navParams: NavParams,
    private fb: UntypedFormBuilder,
    private userConnectionInviteService: UserConnectionInviteService,
    private translateService: TranslateService,
    private addressDataService: AddressDataService,
    private projectDataService: ProjectDataService,
    private toastService: ToastService,
    private networkStatusService: NetworkStatusService
  ) {}

  ngOnInit() {
    this.profile = this.navParams.data.profile;
    this.networkStatusConnected$ = this.networkStatusService.onlineOrUnknown$;
    this.addressDataService
      .getById(this.profile.addressId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((address) => (this.address = address));
    const defaultLanguage = this.translateService.currentLang;
    this.userInviteForm = this.fb.group({
      language: [defaultLanguage, [Validators.required]],
    });
    this.projectDataService.currentProjectObservable.pipe(takeUntil(this.destroy$)).subscribe((currentProject) => {
      this.currentProject = currentProject;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async cancel() {
    await this.modal.dismiss();
  }

  private handleError(error: any) {
    if (error?.error?.errorCode) {
      const errorCode = error?.error?.errorCode;
      this.errorMessage = this.translateService.instant('errorCodeMessages.' + errorCode);
      return;
    }
    this.errorMessage = this.translateService.instant('companyForm.userConnectionInvite.inviteFailed');
  }

  private async toastMessage(messageKey: string, duration = ToastDurationInMs.INFO, interpolateParams?: any) {
    await this.toastService.toastWithTranslateParams(messageKey, interpolateParams, duration);
  }

  async sendInvite() {
    const language = this.userInviteForm.get('language').value;
    await this.userConnectionInviteService.sendUserConnectionInvite(this.profile.id, language, this.profile.clientId, this.currentProject?.id);
    const email = this.address?.email;
    this.toastMessage('companyForm.employees.invitationSendSuccessfully', ToastDurationInMs.INFO, {email});
    this.modal.dismiss();
  }

  async submit() {
    if (this.inProgress) {
      throw new Error('Server operation already in progress.');
    }
    try {
      this.inProgress = true;
      this.errorMessage = undefined;
      await this.sendInvite();
    } catch (error) {
      this.handleError(error);
    } finally {
      this.inProgress = false;
    }
  }
}
