import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {SizeType} from 'src/app/utils/canvas-utils';
import {Attachment} from 'submodules/baumaster-v2-common';
import {AttachmentBlob} from '../../../model/attachments';
import {ImageUriPipe} from '../../../pipes/image-uri.pipe';
import {BaseAttachmentViewerDirective} from '../base-attachment-viewer/base-attachment-viewer.directive';

const LOG_SOURCE = 'AttachmentViewerComponent';

@Component({
  selector: 'app-attachment-viewer',
  templateUrl: './attachment-viewer.component.html',
  styleUrls: ['./attachment-viewer.component.scss'],
  providers: [ImageUriPipe],
})
export class AttachmentViewerComponent extends BaseAttachmentViewerDirective implements OnInit, OnDestroy, OnChanges {
  private static nextInstanceNumber = 1;
  private static numberOfInstances = 0;

  @Input() attachment: Attachment | AttachmentBlob;
  @Input() size: SizeType = 'large';
  @Input() showChatImageIcon = true;
  @ViewChild('imageCanvas') canvas: ElementRef<HTMLCanvasElement> | undefined;
  private readonly instanceNumber: number;

  constructor() {
    super();
    this.instanceNumber = AttachmentViewerComponent.nextInstanceNumber++;
    AttachmentViewerComponent.numberOfInstances++;
    this.loggingService.debug(LOG_SOURCE, `constructor - instanceNumber=${this.instanceNumber}, numberOfInstances=${AttachmentViewerComponent.numberOfInstances}`);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  async ngOnChanges(changes: SimpleChanges) {
    await super.ngOnChanges(changes);
  }

  ngOnDestroy(): void {
    AttachmentViewerComponent.numberOfInstances--;
    this.loggingService.debug(LOG_SOURCE, `ngOnDestroy - instanceNumber=${this.instanceNumber}, numberOfInstances=${AttachmentViewerComponent.numberOfInstances}`);

    super.ngOnDestroy();
  }
}
