<div class="meetings-outer-container" *ngIf="individualNextMeetings?.length">
  <ion-grid class="ion-no-padding">
    <ion-row class="meetings-header pl-2 pr-2 ion-align-items-center ion-hide-lg-down">
      <ion-col size="2">
        <span class="omg-t-medium-body-bold">{{ 'sendProtocol.nextMeetingsSection.time' | translate }}</span>
      </ion-col>
      <ion-col size="3.3">
        <span class="omg-t-medium-body-bold">{{ 'Company' | translate}}</span>
      </ion-col>
      <ion-col size="3.3">
        <span class="omg-t-medium-body-bold">{{ 'sendProtocol.protocolConfig.signature.signerNameLabel' | translate }}</span>
      </ion-col>
      <ion-col size="3.3">
        <span class="omg-t-medium-body-bold">{{ 'e-mail' | translate }}</span>
      </ion-col>
    </ion-row>
    <app-individual-next-meeting-form
      *ngFor="let meeting of individualNextMeetings; trackBy: trackByProfile; let i = index;"
      [nextMeeting]="nextMeeting"
      [individualNextMeeting]="meeting"
      [even]="i % 2 === 0"
      (individualNextMeetingChange)="handleMeetingChange($event)"
      (deleteClick)="handleDeleteMeeting(meeting)"
    ></app-individual-next-meeting-form>
  </ion-grid>
</div>

