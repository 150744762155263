import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageKeyEnum} from '../../shared/constants';
import {BehaviorSubject, Observable} from 'rxjs';
import {StorageService} from '../storage.service';
import {LoggingService} from '../common/logging.service';
import {FALLBACK_LANGUAGE} from 'submodules/baumaster-v2-common/dist';

const LOG_SOURCE = 'LanguageService';
const STORAGE_KEY = StorageKeyEnum.SELECTED_LANGUAGE;

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  protected languageSubject = new BehaviorSubject<string | undefined>(undefined);
  public readonly selectedLanguage: Observable<string> = this.languageSubject.asObservable();

  get language(): string | undefined {
    return this.languageSubject.value;
  }

  public readonly supportedLanguages = [
    {text: 'English', translationKey: 'language.english', value: 'en'},
    {text: 'German', translationKey: 'language.german', value: 'de'},
    {text: 'French', translationKey: 'language.french', value: 'fr'},
  ];

  public constructor(
    private translate: TranslateService,
    private storage: StorageService,
    private loggingService: LoggingService
  ) {}

  public async setInitialAppLanguage() {
    this.loggingService.debug(LOG_SOURCE, 'setInitialAppLanguage called');
    let language = await this.storage.get(STORAGE_KEY);
    if (this.language) {
      this.loggingService.info(LOG_SOURCE, 'setInitialAppLanguage language set in the meantime; skipping initialization');
      return;
    }
    let writeToStorage = false;
    if (language === null) {
      language = this.translate.getBrowserLang();
      writeToStorage = true;
    }
    await this.setLanguage(language, writeToStorage);
    this.loggingService.debug(LOG_SOURCE, `setInitialAppLanguage - after setLanguage (writeToStorage=${writeToStorage}`);
  }

  public isSupportedLanguage(lng: string): boolean {
    return !!this.supportedLanguages.find((language) => language.value === lng);
  }

  public async getSelectedLanguage(): Promise<string | null> {
    return await this.storage.get(STORAGE_KEY);
  }

  public setTranslateLanguageOrDefault(lng: string): string {
    if (!this.isSupportedLanguage(lng)) {
      lng = FALLBACK_LANGUAGE;
    }
    this.translate.use(lng);
    return lng;
  }

  public async setLanguage(lng: string, writeToStorage = true) {
    lng = this.setTranslateLanguageOrDefault(lng);
    this.languageSubject.next(lng);
    if (writeToStorage) {
      await this.storage.set(STORAGE_KEY, lng);
    }
  }
}
