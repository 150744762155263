import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DeviceService, QUERY} from 'src/app/services/ui/device.service';

const LOG_SOURCE = 'InviteNewContactModalComponent';

@Component({
  selector: 'app-invite-new-contact-modal',
  templateUrl: './invite-new-contact-modal.component.html',
  styleUrls: ['./invite-new-contact-modal.component.scss'],
})
export class InviteNewContactModalComponent implements OnInit, OnDestroy {
  modal: HTMLIonModalElement;

  @Input()
  isInternal: boolean;

  private isAboveMediaQuerySubscription: Subscription | undefined;
  public isSmallScreen = false;
  public dontShowAgain = false;

  constructor(private deviceService: DeviceService) {}

  ngOnInit() {
    this.isAboveMediaQuerySubscription = this.deviceService.isAboveMediaQuery(QUERY.sm).subscribe((isAbove) => {
      if (isAbove) {
        this.isSmallScreen = false;
      } else {
        this.isSmallScreen = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.isAboveMediaQuerySubscription?.unsubscribe();
    this.isAboveMediaQuerySubscription = undefined;
  }

  confirm() {
    this.modal.dismiss({dontShowAgain: this.dontShowAgain}, 'confirm');
  }

  closeModal() {
    this.modal.dismiss({dontShowAgain: this.dontShowAgain}, 'cancel');
  }
}
