import {Injectable, OnDestroy} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {Subject, switchMap, takeUntil, tap} from 'rxjs';
import {StorageKeyEnum} from 'src/app/shared/constants';
import {LoggingService} from '../common/logging.service';
import {StorageService} from '../storage.service';
import {ProjectDataService} from '../data/project-data.service';
import {IdType} from 'submodules/baumaster-v2-common';
import {ProjectService} from './project.service';
import {ProjectWithOffline} from 'src/app/model/project-with-offline';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {SyncService} from '../sync/sync.service';
import {SyncStrategy} from '../sync/sync-utils';
import {NetworkStatusService} from '../common/network-status.service';
import {PosthogService} from '../posthog/posthog.service';

interface NewObjectInProject {
  projectId: IdType;
  timestamp: Date | string;
}

const STORAGE_KEY_OBJECTS = StorageKeyEnum.OBJECTS_CREATED_IN_PROJECT;
const STORAGE_KEY_DATES = StorageKeyEnum.DOWNLOAD_PROJECT_REMIND_DATES;

const LOG_SOURCE = 'ObjectsCreationTrackingService';

@Injectable({
  providedIn: 'root',
})
export class ObjectsCreationTrackingService implements OnDestroy {
  private readonly defaultStorageValueObjects: NewObjectInProject[] = [];
  private readonly defaultStorageValueDates: {[key in IdType]: number} = {};
  private readonly isDeviceEnabled = this.platform.is('android') || this.platform.is('ios') || this.platform.is('capacitor') || this.platform.is('mobileweb');
  private readonly currentProject$ = this.projectDataService.currentProjectObservable;
  public currentProjectWithOffline: ProjectWithOffline | undefined;
  private alertOpen = false;
  private destroy$ = new Subject<void>();

  constructor(
    private storage: StorageService,
    private platform: Platform,
    private loggingService: LoggingService,
    private alertController: AlertController,
    private projectDataService: ProjectDataService,
    private projectService: ProjectService,
    private translateService: TranslateService,
    private syncService: SyncService,
    private networkStatusService: NetworkStatusService,
    private posthogService: PosthogService
  ) {
    this.currentProject$
      .pipe(
        takeUntil(this.destroy$),
        switchMap((currentProject) => this.projectService.getById(currentProject.id).pipe(tap((project) => (this.currentProjectWithOffline = project))))
      )
      .subscribe();
  }

  async initFromStorage() {
    try {
      this.loggingService.debug(LOG_SOURCE, 'initFromStorage');
      const storageValueObjects: NewObjectInProject[] = await this.storage.get(STORAGE_KEY_OBJECTS);
      if (!storageValueObjects) {
        this.loggingService.debug(LOG_SOURCE, 'initFromStorage - No value in storage. Init with defaultStorageValueObjects');
        await this.storage.set(STORAGE_KEY_OBJECTS, this.defaultStorageValueObjects, {
          ensureStored: false,
        });
      }
      const storageValueDates: {[key in IdType]: number} = await this.storage.get(STORAGE_KEY_DATES);
      if (!storageValueDates) {
        this.loggingService.debug(LOG_SOURCE, 'initFromStorage - No value in storage. Init with defaultStorageValueDates');
        await this.storage.set(STORAGE_KEY_DATES, this.defaultStorageValueDates, {
          ensureStored: false,
        });
      }
    } catch (err) {
      this.loggingService.error(LOG_SOURCE, `initFromStorage failed with error ${err.message}`);
      throw err;
    }
  }

  async getStorageValueObjects(): Promise<NewObjectInProject[]> {
    return (await this.storage.get(STORAGE_KEY_OBJECTS)) || this.defaultStorageValueObjects;
  }

  async getStorageValueDates(): Promise<{[key in IdType]: number}> {
    return (await this.storage.get(STORAGE_KEY_DATES)) || this.defaultStorageValueDates;
  }

  async checkForDownloadProjectAlert(projectId: string) {
    this.loggingService.debug(LOG_SOURCE, 'checkForDownloadProjectAlert called');
    if (!this.isDeviceEnabled) {
      this.loggingService.debug(LOG_SOURCE, 'checkForDownloadProjectAlert - alert not supported on platform');
      return;
    }
    if (projectId !== this.currentProjectWithOffline.id) {
      this.loggingService.debug(LOG_SOURCE, 'checkForDownloadProjectAlert - no check needed, because projectId of inserted object is not the current projectId');
      return;
    }
    if (this.currentProjectWithOffline.isOfflineAvailable) {
      this.loggingService.debug(LOG_SOURCE, 'checkForDownloadProjectAlert - no check needed, because project is already offline available');
      return;
    }
    await this.initFromStorage();
    if ((await this.shouldShowAlert()) && !this.alertOpen) {
      await this.showDownloadProjectAlert();
      this.alertOpen = false;
    }
  }

  async addObjectToProject(projectId: string) {
    const storageValues = await this.getStorageValueObjects();
    this.loggingService.debug(LOG_SOURCE, `addObjectToProject - current storageValues ${JSON.stringify(storageValues)}`);
    const timestamp = new Date();
    storageValues.push({projectId, timestamp});
    await this.storage.set(STORAGE_KEY_OBJECTS, storageValues, {
      ensureStored: false,
    });
    this.loggingService.debug(LOG_SOURCE, `addObjectToProject - new storageValues ${JSON.stringify(storageValues)}`);
  }

  async resetObjectsOfCurrentProject() {
    const storageValues = await this.getStorageValueObjects();
    this.loggingService.debug(LOG_SOURCE, `resetObjectsOfCurrentProject - current storageValues ${JSON.stringify(storageValues)}`);
    const updatedStorageValues = storageValues.filter((object) => object.projectId !== this.currentProjectWithOffline.id);
    await this.storage.set(STORAGE_KEY_OBJECTS, updatedStorageValues, {
      ensureStored: false,
    });
    this.loggingService.debug(LOG_SOURCE, `resetObjectsOfCurrentProject - new storageValues ${JSON.stringify(updatedStorageValues)}`);
  }

  async updateRemindDateForProject(projectId: string, month: number) {
    const storageValues = (await this.getStorageValueDates()) || {};
    this.loggingService.debug(LOG_SOURCE, `updateRemindDateForProject - current storageValues: ${JSON.stringify(storageValues)}`);
    const updatedStorageValues = {...storageValues, [projectId]: month};
    await this.storage.set(STORAGE_KEY_DATES, updatedStorageValues);
    this.loggingService.debug(LOG_SOURCE, `updateRemindDateForProject - new storageValues ${JSON.stringify(updatedStorageValues)}`);
  }

  async showDownloadProjectAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('downloadActiveProjectAlert.header'),
      message: this.translateService.instant('downloadActiveProjectAlert.message'),
      buttons: [
        {
          text: this.translateService.instant('downloadActiveProjectAlert.buttons.cancel'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('downloadActiveProjectAlert.buttons.download'),
          role: 'download',
          cssClass: 'primary',
        },
      ],
    });
    await alert.present();
    this.posthogService.captureEvent(`[DownloadProjectReminder] Alert shown`, {});
    this.alertOpen = true;
    const result = await alert.onDidDismiss();
    if (result.role !== 'download') {
      const currentMonth = new Date().getMonth();
      const nextMonth = (currentMonth % 12) + 1;
      await this.updateRemindDateForProject(this.currentProjectWithOffline.id, nextMonth);
      this.posthogService.captureEvent(`[DownloadProjectReminder] later clicked`, {});
      await alert.dismiss();
      return;
    }
    try {
      this.currentProjectWithOffline.isOfflineAvailable = !this.currentProjectWithOffline.isOfflineAvailable;
      await this.projectService.changeProjectOfflineAvailable(this.currentProjectWithOffline, true);
      this.posthogService.captureEvent(`[DownloadProjectReminder] download clicked`, {});
      if (!this.networkStatusService.offline) {
        await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
      }
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `showDownloadProjectAlert failed with "${error.message}".`);
      await alert.dismiss();
      this.alertOpen = false;
    }
  }

  private async shouldShowAlert(): Promise<boolean> {
    const projectId = this.currentProjectWithOffline.id;
    const currentMonth = new Date().getMonth();
    const nextMonth = (currentMonth % 12) + 1;
    const remindDates = await this.getStorageValueDates();
    if (!remindDates[projectId]) {
      remindDates[projectId] = currentMonth;
    }
    const remindDateInProject = remindDates[projectId];
    if (remindDateInProject !== currentMonth) {
      this.loggingService.debug(LOG_SOURCE, 'shouldShowAlert - alert not enabled for the current month');
      return false;
    }

    await this.addObjectToProject(projectId);
    const objects = await this.getStorageValueObjects();
    const objectsCreatedInProject = objects.filter((object) => object.projectId === projectId);
    const objectsSorted = _.orderBy(objectsCreatedInProject, 'timestamp', 'asc');

    if (objectsCreatedInProject.length >= 5) {
      const firstDate = new Date(objectsSorted[0].timestamp);
      const lastDate = new Date(objectsSorted[objectsSorted.length - 1].timestamp);
      const createdOnSameDayMonth = firstDate.getDate() === lastDate.getDate() && firstDate.getMonth() === lastDate.getMonth();
      await this.updateRemindDateForProject(projectId, nextMonth);
      await this.resetObjectsOfCurrentProject();
      return createdOnSameDayMonth;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
