<form class="omg-form" [formGroup]="form">
  <mbsc-datepicker
    [select]="'range'"
    [controls]="['time']"
    #range
    hidden
    [options]="rangePickerSettings"
    display="center"
    [dateFormat]="MBSC_DATE_FORMAT"
    [setText]="'button.apply' | translate"
    [cancelText]="'cancel' | translate"
    [buttons]="[ 'cancel', 'set' ]"
    [locale]="mbscLocale$ | async"
    [themeVariant]="mbscThemeVariant$ | async"
    theme="windows"
    [rangeStartLabel]="'sendProtocol.startDate' | translate"
    [rangeEndLabel]="'sendProtocol.endDate' | translate"
    formControlName="timeStartEnd"
  >
  </mbsc-datepicker>
  <ion-grid class="no-side-padding">
    <!-- TODO: Uncomment once separate mail sending is supported -->
    <!-- <ion-row>
      <ion-col>
        <ion-item>
          <ion-label color="text-primary" position="floating">{{ 'sendProtocol.protocolConfig.nextMeeting.subject' | translate }}</ion-label>
          <ion-textarea auto-grow="true" color="text-primary" formControlName="subject" rows="1" type="text"></ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label color="text-primary" position="floating">{{ 'sendProtocol.protocolConfig.nextMeeting.body' | translate }}</ion-label>
          <ion-textarea auto-grow="true" color="text-primary" formControlName="body" rows="1" type="text"></ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row> -->

    <ion-row class="d-flex gap-3">
      <ion-col size="12" size-sm="8">
        <ion-item>
          <ion-input
            label-placement="floating"
            formControlName="date"
            mbsc-datepicker [controls]="['calendar']"
            [mbscOptions]="datePickerSettings"
            [showWeekNumbers]="true"
            [firstSelectDay]="1"
            [firstDay]="1"
            [setText]="'button.apply' | translate"
            [cancelText]="'cancel' | translate"
            [buttons]="[ 'cancel', 'set' ]"
            [locale]="mbscLocale$ | async"
            [themeVariant]="mbscThemeVariant$ | async"
            theme="windows"
          >
          <div slot="label">
            <fa-icon [fixed-width]="true" [icon]="['fal', 'calendar-alt']" slot="start"></fa-icon>
            <span class="omg-required">
              {{'sendProtocol.date' | translate}}</span>
          </div>
        </ion-input>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-item [disabled]="!nextMeeting.date">
          <ion-input
            label-placement="floating"
            [value]="nextMeetingDateValue"
            readonly
            (click)="range.open()"
          >
            <div slot="label">
              <fa-icon [fixedWidth]="true" [icon]="['fal', 'clock']" slot="start"></fa-icon> {{ 'sendProtocol.nextMeetingsSection.time' | translate }}
            </div>
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item class="omg-form-checkbox" lines="none">
          <ion-checkbox class="auto-width pr-2" label-placement="end" justify="start" formControlName="sendCalendarMail">
            {{ "sendProtocol.protocolConfig.nextMeeting.sendCalendarMail" | translate }}
          </ion-checkbox>
          <fa-icon
            class="grey-text"
            [icon]="['fal', 'info-circle']"
            appInfoPopoverClick
            [infoTitle]="'sendProtocol.nextMeetingsSection.infoTitle'"
            [infoText]="'sendProtocol.nextMeetingsSection.infoText'"
            [infoHintText]="'sendProtocol.nextMeetingsSection.infoHintText'"
          ></fa-icon>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
