<app-modal-header [dismissModalOnClose]="!changeInProgress">
  <ion-title>{{ 'changeEntryNumber.title' | translate }}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="entryNumberForm">
    <ion-grid class="grid-gap-4">
      <ion-row>
        <ion-col size="12">
          <span class="text" [innerHtml]="'changeEntryNumber.messages.numberOfEntries' | translate"></span>
          <span class="badge"><strong>{{ allProtocolEntriesData?.length || 0 }}</strong></span>
        </ion-col>
        <ion-col size="12">
          <span class="text" [innerHTML]="'changeEntryNumber.messages.entryTitle' | translate:{entryTitle: (selectedProtocolEntry?.title)}"></span>
        </ion-col>
        <ion-col size="12">
          <div class="d-flex gap-1 text">{{ 'changeEntryNumber.messages.currentNumber' | translate}}
            <span class="badge">
              {{(protocolType$ | async)?.code + ((protocol$ | async)?.number | number:'2.0')}}.<strong>{{(selectedProtocolEntry.number | number:'3.0')}}</strong>
            </span>
          </div>
        </ion-col>
        <ion-col size="12">
          <ion-item [disabled]="isProtocolClosed || isCarriedOver || changeInProgress">
            <ion-input label="{{ 'changeEntryNumber.entryNumber' | translate }}" label-placement="floating" 
              #inputNumber type="number"
              formControlName="number" maxLength="10"
            >
            </ion-input>
          </ion-item>
          <span *ngIf="entryNumberForm.controls.number.errors?.required && entryNumberForm.controls.number.dirty" class="error-message"> {{ 'formValidation.emptyField' | translate:{field: 'changeEntryNumber.entryNumber' | translate} }}</span>
          <span *ngIf="entryNumberForm.controls.number.errors?.numberTooHigh && entryNumberForm.controls.number.dirty" class="error-message"> {{ 'formValidation.numberTooHigh' | translate:{max: maxNumber} }}</span>
        </ion-col>
        <ion-col>
          <span *ngIf="entryNumberForm.controls.number.errors?.numbersWillChange && entryNumberForm.controls.number.dirty" class="omg-attention omg-attention-warning ion-text-center flex-column" [innerHtml]="'changeEntryNumber.alerts.numberAlreadyUsed.message' | translate"></span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" [disabled]="changeInProgress" (click)="dismissModal()">{{'cancel' | translate}}</ion-button>
    <ion-button class="omg-btn-primary" (click)="changeEntryNumber()" 
      [disabled]="isProtocolClosed || isCarriedOver || changeInProgress || entryNumberForm.controls.number.errors?.required || entryNumberForm.controls.number.errors?.pattern || entryNumberForm.controls.number.errors?.numberTooHigh"
    >
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="changeInProgress"></ion-spinner>
      {{'button.apply' | translate}}
    </ion-button>
  </ion-buttons>
</app-modal-footer>