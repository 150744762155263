<app-modal-header>
  <app-modal-step-indicator
    [stepsCount]="2"
    [current]="currentStepIndex"
    workflowName="{{ 'project_room.pdf_plan_holders.shareWorkflow.title' | translate }}"
    workflowStep="{{ ('project_room.pdf_plan_holders.shareWorkflow.steps.' + currentStep.key) | translate }}">
  </app-modal-step-indicator>
  <div class="d-flex gap-2" *ngIf="currentStepIndex === 0">
    <ion-searchbar class="slim-searchbar" #autofocus [(ngModel)]="searchTextInput" (ngModelChange)="onSearchTextChanged()" debounce="100"
    placeholder="{{'sendProtocol.mailingList.searchName' | translate}}"></ion-searchbar>
    <ion-button class="omg-btn-secondary" *ngIf="currentStepIndex === 0" (click)="openCompanyOrderModal()">
      {{ 'pdfWorkflow.companyOrder.changeOrder' | translate }}
    </ion-button>
  </div>
  <div *ngIf="currentStepIndex === 0" class="d-flex ion-justify-content-between omg-modal-list-header omg-modal-section-header-small">
    <div>{{'sendProtocol.mailingList.companiesAndContacts' | translate}}</div>
    <div>
      <ion-label>{{'sendProtocol.mailingList.recipient' | translate}}</ion-label>
    </div>
  </div>
</app-modal-header>

<ion-content class="with-padding">
  <app-pdf-mailing-list
    #appPdfMailingList
    *ngIf="currentStepIndex === 0"
    [hideHeader]="true"
    [workflowType]="0"
    [showCompanyPin]="true"
    [searchTextInput]="''"
    [skipInactiveContacts]="true"
    [skipInactiveCompanies]="true"
    [mailingListCompanies$]="mailingListCompanies$"
    [hideParticipantCheckbox]="true"
    (recipientChanged)="handleRecipientChanged($event.employee)"
  ></app-pdf-mailing-list>

  <app-pdf-email-settings
    *ngIf="currentStepIndex === 1"
    [language]="currentProjectLanguage$ | async"
    [workflowType]="0"
    [(form)]="emailSettingsForm"
    [showSaveTemplate]="true"
    [recipientEmails]="checkedEmployeeEmails"
    (attachmentUserEmailSignatureCreate)="handleAttachmentUserEmailSignatureCreate($event)"
    (saveAsDefaultSettings)="saveAsDefaultSettings()"
    (resetForm)="restoreToDefaultSettings()"
  >
    <ng-template appPdfEmailSettingsBetweenTemplate>
      <ul class="ion-no-margin">
        <ng-container *ngFor="let plan of plans">
          <li *ngIf="plan.latestPdfPlanVersion as version">
            {{ version.name }} | {{ 'project_room.pdf_plan_holders.shareWorkflow.index' | translate }}: {{ version.index || '-' }} | {{ 'project_room.pdf_plan_holders.shareWorkflow.date' | translate }}: {{ version.date | date:DATE_FORMAT }}<br />
            {{ 'project_room.pdf_plan_holders.shareWorkflow.comment' | translate }}: {{ version.description || '-' }}
          </li>
        </ng-container>
      </ul>
      <ion-button id="send-pdf-workflow-download-button" class="ion-align-self-start omg-btn-primary">{{ 'project_room.pdf_plan_holders.shareWorkflow.downloadButton' | translateInLanguage:(currentProjectLanguage$ | async) | async }}</ion-button>
      <app-tooltip target="send-pdf-workflow-download-button" placement="right">
        {{ 'project_room.pdf_plan_holders.shareWorkflow.downloadButton' | translate }}
      </app-tooltip>
    </ng-template>
  </app-pdf-email-settings>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" [style.visibility]="currentStepIndex === 0 ? 'hidden' : undefined" (click)="back()">{{ 'back' | translate }}</ion-button>
    <ion-button *ngIf="currentStepIndex < workflowSteps.length - 1" (click)="next()" class="omg-btn-primary" [disabled]="!isCurrentStepValid()">
      {{ 'next' | translate }}
    </ion-button>
    <ion-button *ngIf="currentStepIndex === workflowSteps.length - 1" (click)="finish()" class="omg-btn-primary" [disabled]="!isAllStepsValid()">
      {{ 'send' | translate }}
    </ion-button>
  </ion-buttons>
</app-modal-footer>
