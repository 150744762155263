import {Content, ContentOrderedList, ContentText, ContentUnorderedList} from 'pdfmake/interfaces';
import {hyphenateSync as hyphenateDe} from 'hyphen/de';
import {hyphenateSync as hyphenateEn} from 'hyphen/en';
import {hyphenateSync as hyphenateFr} from 'hyphen/fr';
import {SupportedLanguageStrict} from '../../models';
import {FALLBACK_LANGUAGE} from '../../constants';

const CHAR_CODE_A = 'a'.charCodeAt(0);
const MAX_CHAR_CODE = 26;
const MAX_ITERATIONS = 200;
const HYPHEN_WORD_LENGTH = 30;
const DIVISIBLE_WORD_LENGTH = 30;
const DIVIDE_WORD_EVERY_N_CHARACTERS = 15;
export type SupportedLanguage = SupportedLanguageStrict | string;

export function protocolIndexNumberToString(value: number | undefined | null): string | undefined | null {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }

  let result = '';
  let rest = value as number;
  let iteration = 0;
  while (true) {
    const charCode = rest % MAX_CHAR_CODE;
    result = String.fromCharCode(CHAR_CODE_A + charCode) + result;
    rest -= charCode;
    if (rest <= 0) {
      break;
    }
    rest = Math.floor(rest / MAX_CHAR_CODE);

    if (iteration++ >= MAX_ITERATIONS) {
      throw new Error('max iterations reached'); // just for safety, that we do not end in an endless loop
    }
  }

  return result;
}

export const splitWithZeroWidthSpace = (text: string, language: SupportedLanguage = FALLBACK_LANGUAGE): string => {
  switch (language?.toLowerCase()) {
    case 'de':
      text = hyphenateDe(text, {hyphenChar: '\u200B', minWordLength: HYPHEN_WORD_LENGTH});
      break;
    case 'en':
      text = hyphenateEn(text, {hyphenChar: '\u200B', minWordLength: HYPHEN_WORD_LENGTH});
      break;
    case 'fr':
      text = hyphenateFr(text, {hyphenChar: '\u200B', minWordLength: HYPHEN_WORD_LENGTH});
      break;
    default:
      throw new Error(`splitWithZeroWidthSpace - Unsupported language "${language}"`);
  }
  text = hyphenateDe(text, {hyphenChar: '\u200B', minWordLength: DIVISIBLE_WORD_LENGTH});
  const matched = text.match(/(\s+)?([^\s]+)(\s+)?/g);
  if (!matched) {
    return text;
  }

  return matched
    .map((t) => {
      const wordAlone = t.trim();
      const dividedWord = wordAlone.length > DIVISIBLE_WORD_LENGTH ? (wordAlone.match(new RegExp(`.{1,${DIVIDE_WORD_EVERY_N_CHARACTERS}}`, 'g'))?.join('\u200B') ?? wordAlone) : wordAlone;
      return t.replace(wordAlone, dividedWord);
    })
    .join('');
};

export const addZeroWidthSpaces = (
  content: Content | string | ContentText | ContentOrderedList | ContentUnorderedList,
  language: SupportedLanguage = FALLBACK_LANGUAGE
): Content | string | ContentText | ContentOrderedList | ContentUnorderedList => {
  const splitWithZeroWidthSpaceFn = (text: string): string => {
    return splitWithZeroWidthSpace(text, language);
  };
  const addZeroWidthSpacesFn = (content: Content | string | ContentText | ContentOrderedList | ContentUnorderedList): Content | string | ContentText | ContentOrderedList | ContentUnorderedList => {
    return addZeroWidthSpaces(content, language);
  };

  if (typeof content === 'string') {
    return splitWithZeroWidthSpaceFn(content);
  }

  if (typeof content === 'object' && 'text' in content && content.text !== null && content.text !== undefined && typeof content.text === 'string') {
    return {
      ...content,
      text: splitWithZeroWidthSpaceFn(content.text),
    };
  }

  if (typeof content === 'object' && 'text' in content && content.text !== null && content.text !== undefined && Array.isArray(content.text)) {
    return {
      ...content,
      text: content.text.map(addZeroWidthSpacesFn),
    };
  }

  if (typeof content === 'object' && 'ol' in content && content.ol) {
    return {
      ...content,
      ol: content.ol.map(addZeroWidthSpacesFn),
    };
  }

  if (typeof content === 'object' && 'ul' in content && content.ul) {
    return {
      ...content,
      ul: content.ul.map(addZeroWidthSpacesFn),
    };
  }

  if (Array.isArray(content)) {
    return content.map(addZeroWidthSpacesFn);
  }

  return content;
};
