import {Injectable, OnDestroy} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {IdType} from 'submodules/baumaster-v2-common';
import {ProjectDataService} from '../data/project-data.service';
import {combineLatest, distinctUntilChanged, filter, interval, map, pairwise, Subscription} from 'rxjs';
import {ScopeType} from 'src/app/pages/contacts/contacts.page';
import {PdfPlanVersionDataService} from '../data/pdf-plan-version-data.service';
import {BimVersionDataService} from '../data/bim-version-data.service';
import {ReportDataService} from '../data/report-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {DeviceService, QUERY} from '../ui/device.service';
import {AddressDataService} from '../data/address-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {EntryService} from '../entry/entry.service';

const ROOT_PATHS = ['tasks', 'protocols', 'reports', 'constructionReports', 'project-room', 'project-settings', 'company', 'contact-detail', 'contacts'] as const;
export type RootPath = (typeof ROOT_PATHS)[number];
export type SavedUrlsByRoot = Record<RootPath, string>;
export type SavedUrlRecordByProject = Record<IdType, SavedUrlsByRoot>;

@Injectable({
  providedIn: 'root',
})
export class SavedStateNavigationService implements OnDestroy {
  private savedStates: SavedUrlRecordByProject = {};
  private savedContactScope: ScopeType = 'global';
  public previousRoot: string | undefined;

  private planVersionSubscription: Subscription | undefined;
  private bimVersionSubscription: Subscription | undefined;
  private reportSubscription: Subscription | undefined;
  private constructionReportSubscription: Subscription | undefined;
  private protocolSubscription: Subscription | undefined;
  private entrySubscription: Subscription | undefined;
  private profileSubscription: Subscription | undefined;
  private taskSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private projectDataService: ProjectDataService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private bimVersionDataService: BimVersionDataService,
    private reportDataService: ReportDataService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolDataService: ProtocolDataService,
    private deviceService: DeviceService,
    private addressDataService: AddressDataService,
    private profileDataService: ProfileDataService,
    private entryService: EntryService
  ) {
    combineLatest([this.projectDataService.currentProjectObservable, this.router.events]).subscribe(([currentProject, event]) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      const projectId = currentProject?.id;
      if (!projectId) {
        return;
      }
      const url = event.urlAfterRedirects ?? event.url;
      let rootRoute = this.extractRootPath(url);
      if (rootRoute === 'company' || rootRoute === 'contact-detail') {
        rootRoute = 'contacts';
      }
      this.previousRoot = rootRoute ?? 'anyOtherRoot'; // need to assign a value in case previous page does not use SavedStateGuard
      if (rootRoute) {
        const urlToStore = url.includes('?') ? url.split('?')[0] : url;
        this.storeState(projectId, rootRoute, urlToStore);
      }
    });
    this.scheduleMidnightTask();
  }

  private storeState(projectId: IdType, rootRoute: RootPath, url: string): void {
    if (url.includes('entry') && url.includes('tasks')) {
      this.unsubscribeTask();
      const taskId = url.slice(url.indexOf('/entry') + 7);
      this.taskSubscription = combineLatest([this.protocolEntryDataService.getById(taskId), this.entryService.taskProtocol$]).subscribe(([entry, taskProtocol]) => {
        if (!entry || taskProtocol.id !== entry.protocolId) {
          const newUrl = '/tasks/card';
          this.savedStates[projectId][rootRoute] = newUrl;
        }
      });
    }
    if (url.includes('entry') && !url.includes('tasks')) {
      this.unsubscribeEntry();
      this.unsubscribeProtocol();
      const protocolAndEntryId = url.slice(url.indexOf('/entry') + 7);
      const protocolId = protocolAndEntryId.slice(0, protocolAndEntryId.indexOf('/'));
      const entryId = protocolAndEntryId.slice(protocolAndEntryId.indexOf('/') + 1);
      this.entrySubscription = combineLatest([
        this.protocolEntryDataService.getById(entryId),
        this.deviceService.isAboveMediaQuery(QUERY.lg),
        this.protocolDataService.getByIdAcrossProjects(protocolId).pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id)),
      ]).subscribe(([entry, isNotSingleColumn, protocol]) => {
        if (!protocol) {
          this.savedStates[projectId][rootRoute] = undefined;
        } else if (!entry || entry.protocolId !== protocolId) {
          if (isNotSingleColumn) {
            const newUrl = 'protocols/view/' + protocolId + '/' + 'entry/' + protocolId + '/first';
            this.savedStates[projectId][rootRoute] = newUrl;
          } else {
            const newUrl = 'protocols/view/' + protocolId;
            this.savedStates[projectId][rootRoute] = newUrl;
          }
        }
      });
    } else if (url.includes('protocols/view')) {
      this.unsubscribeEntry();
      this.unsubscribeProtocol();
      const protocolId = url.slice(url.indexOf('/view') + 6);
      this.protocolSubscription = this.protocolDataService
        .getByIdAcrossProjects(protocolId)
        .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id))
        .subscribe((protocol) => {
          if (!protocol) {
            this.savedStates[projectId][rootRoute] = undefined;
          }
        });
    }
    if (url.includes('pdf-plan-marker')) {
      this.unsubscribePlanVersion();
      const id = url.substring(url.lastIndexOf('/') + 1);
      this.planVersionSubscription = this.pdfPlanVersionDataService
        .getByIdAcrossProjects(id)
        .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id))
        .subscribe((planVersion) => {
          if (!planVersion) {
            this.savedStates[projectId][rootRoute] = '/project-room/pdf-plan-folders';
          }
        });
    }
    if (url.includes('bim-version')) {
      this.unsubscribeBimVersion();
      const id = url.substring(url.lastIndexOf('/') + 1);
      this.bimVersionSubscription = this.bimVersionDataService
        .getByIdAcrossProjects(id)
        .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id))
        .subscribe((bimVersion) => {
          if (!bimVersion) {
            this.savedStates[projectId][rootRoute] = '/project-room/bim';
          }
        });
    }
    if (url.includes('reports')) {
      this.unsubscribeReport();
      const id = url.substring(url.lastIndexOf('/') + 1);
      if (id !== 'reports') {
        this.reportSubscription = this.reportDataService
          .getByIdAcrossProjects(id)
          .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id))
          .subscribe((report) => {
            if (!report) {
              this.savedStates[projectId][rootRoute] = undefined;
            }
          });
      }
    }
    if (url.includes('constructionReports')) {
      this.unsubscribeConstructionReport();
      const id = url.substring(url.lastIndexOf('/') + 1);
      if (id !== 'constructionReports') {
        this.constructionReportSubscription = this.reportDataService
          .getByIdAcrossProjects(id)
          .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id))
          .subscribe((report) => {
            if (!report) {
              this.savedStates[projectId][rootRoute] = undefined;
            }
          });
      }
    }
    if (url.includes('contact-detail')) {
      this.unsubscribeProfile();
      const id = url.substring(url.lastIndexOf('/') + 1);
      this.profileSubscription = this.profileDataService.getByAddressId(id).subscribe((profile) => {
        if (!profile || profile.isActive === false) {
          this.savedStates[projectId][rootRoute] = '/contacts';
        }
      });
    }
    if (!this.savedStates[projectId]) {
      this.savedStates[projectId] = {} as SavedUrlsByRoot;
    }
    this.savedStates[projectId][rootRoute] = url;
  }

  public getStoredStateByProjectIdAndRootRoute(projectId: IdType, rootRoute: RootPath): string | undefined {
    return this.savedStates[projectId]?.[rootRoute];
  }

  public storeContactScope(scope: ScopeType) {
    this.savedContactScope = scope;
  }

  public getStoredContactScope(): ScopeType {
    return this.savedContactScope;
  }

  public extractRootPath(url: string): RootPath | undefined {
    const maybeRootPath = url.slice(1);
    return ROOT_PATHS.find((path) => maybeRootPath.startsWith(path));
  }

  private scheduleMidnightTask(): void {
    interval(3_600_000)
      .pipe(
        map(() => new Date().toDateString()),
        pairwise(),
        filter(([a, b]) => a !== b)
      )
      .subscribe(() => this.runTaskAtMidnight());
  }

  private runTaskAtMidnight(): void {
    this.savedStates = {};
  }

  ngOnDestroy(): void {
    this.unsubscribePlanVersion();
    this.unsubscribeBimVersion();
    this.unsubscribeReport();
    this.unsubscribeConstructionReport();
    this.unsubscribeProtocol();
    this.unsubscribeEntry();
    this.unsubscribeProfile();
    this.unsubscribeTask();
  }

  unsubscribePlanVersion() {
    this.planVersionSubscription?.unsubscribe();
    this.planVersionSubscription = undefined;
  }

  unsubscribeBimVersion() {
    this.bimVersionSubscription?.unsubscribe();
    this.bimVersionSubscription = undefined;
  }

  unsubscribeReport() {
    this.reportSubscription?.unsubscribe();
    this.reportSubscription = undefined;
  }

  unsubscribeConstructionReport() {
    this.constructionReportSubscription?.unsubscribe();
    this.constructionReportSubscription = undefined;
  }

  unsubscribeProtocol() {
    this.protocolSubscription?.unsubscribe();
    this.protocolSubscription = undefined;
  }

  unsubscribeEntry() {
    this.entrySubscription?.unsubscribe();
    this.entrySubscription = undefined;
  }

  unsubscribeProfile() {
    this.profileSubscription?.unsubscribe();
    this.profileSubscription = undefined;
  }

  unsubscribeTask() {
    this.taskSubscription?.unsubscribe();
    this.taskSubscription = undefined;
  }
}
