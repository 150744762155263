import {ColumnConfig} from '../ColumnConfig';

export const UserRegistrationColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'first_name', prop: 'firstName'},
  {name: 'last_name', prop: 'lastName'},
  {name: 'email', prop: 'email', cnd: true},
  {name: 'phone', prop: 'phone', def: null},
  {name: 'company_name', prop: 'companyName'},
  {name: 'salutation', prop: 'salutation', def: null},
  {name: 'valid_until', prop: 'validUntil', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'language', prop: 'language', def: 'de'},
  {name: 'source', prop: 'source', def: null},
  {name: 'additional', prop: 'additional', def: null},
  {name: 'customer_number', prop: 'customerNumber'},
  {name: 'billing_contact_id', prop: 'billingContactId'},
];
