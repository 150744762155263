import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {
  Attachment,
  EmailSettings,
  GlobalSearchFilter,
  GlobalSearchPdfProtocolGenerateData,
  IdAware,
  IdType,
  PdfGlobalSearchSendReq,
  PdfGlobalSearchSendRes,
  PdfProtocolGenerateData,
  PdfProtocolSetting,
  Project,
  ProtocolEntryPriorityType,
  ProtocolEntryStatus,
  SendPdfProtocolAdditionalInfo,
} from 'submodules/baumaster-v2-common';
import {environment} from '../../../environments/environment';
import {ProjectProtocolAndEntry} from '../../components/search/search-results/search-results.component';
import {ProtocolEntrySearchFilter, SearchFilterField} from '../../model/protocol-entry-search-filter';
import {MOMENT_DATE_FORMAT} from '../../shared/constants';
import {observableToPromise} from '../../utils/async-utils';
import {convertDateTimeToString, convertISOStringToDate} from '../../utils/date-utils';
import {AbstractClientAwareDataService} from '../data/abstract-client-aware-data.service';
import {AddressDataService} from '../data/address-data.service';
import {ClientDataService} from '../data/client-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {NameableDropdownDataService} from '../data/nameable-dropdown-data.service';
import {ParticipantDataService} from '../data/participant-data.service';
import {ProfileCraftDataService} from '../data/profile-craft-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {ProjectCompanyDataService} from '../data/project-company-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {ProtocolEntryLocationDataService} from '../data/protocol-entry-location-data.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {SystemEventService} from '../event/system-event.service';
import {ProtocolEntryFilterService} from '../protocol/protocol-entry-filter.service';
import {SyncHistoryService} from '../sync/sync-history.service';
import {SyncStrategy} from '../sync/sync-utils';
import {SyncService} from '../sync/sync.service';
import {PdfGlobalSearchCommonService} from './pdf-global-search-common.service';
import {PdfProtocolService} from './pdf-protocol.service';
import {UnitService} from '../unit/unit.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {UnitProfileDataService} from '../data/unit-profile-data.service';

@Injectable({
  providedIn: 'root',
})
export class PdfGlobalSearchService {
  constructor(
    private systemEventService: SystemEventService,
    private syncHistoryService: SyncHistoryService,
    private translateService: TranslateService,
    private http: HttpClient,
    private syncService: SyncService,
    private projectDataService: ProjectDataService,
    private pdfGlobalSearchCommonService: PdfGlobalSearchCommonService,
    private pdfProtocolService: PdfProtocolService,
    private companyDataService: CompanyDataService,
    private clientDataService: ClientDataService,
    private profileDataService: ProfileDataService,
    private addressDataService: AddressDataService,
    private profileCraftDataService: ProfileCraftDataService,
    private projectProfileDataService: ProjectProfileDataService,
    private projectCompanyDataService: ProjectCompanyDataService,
    private participantDataService: ParticipantDataService,
    private craftDataService: CraftDataService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private protocolEntryLocationDataService: ProtocolEntryLocationDataService,
    private protocolEntryFilterService: ProtocolEntryFilterService,
    private nameableDropdownDataService: NameableDropdownDataService,
    private unitService: UnitService,
    private protocolDataService: ProtocolDataService,
    private unitProfileDataService: UnitProfileDataService
  ) {}

  public async sendPdf(
    protocolEntries: Array<ProjectProtocolAndEntry>,
    filters: ProtocolEntrySearchFilter,
    sendPdfProtocolAdditionalInfo: SendPdfProtocolAdditionalInfo,
    {
      pdfProtocolSetting,
      emailSettings,
    }: {
      pdfProtocolSetting?: PdfProtocolSetting;
      emailSettings?: EmailSettings;
    }
  ): Promise<void> {
    const protocolEntryIds = protocolEntries.map((protocolEntry) => protocolEntry.entry.id).concat(protocolEntries.reduce((acc, v) => acc.concat(v.entryChildren.map(({id}) => id) ?? []), []));
    const currentProject = await this.projectDataService.getCurrentProject();
    const projects = protocolEntries.map((protocolEntry) => protocolEntry.project);
    if (currentProject) {
      projects.push(currentProject); // Participants are stored in the current project.
    }
    const distinctProjects = [...new Set(projects)];
    const pdfGlobalSearchSendReq: PdfGlobalSearchSendReq = {
      sendPdfProtocolAdditionalInfo,
      pdfProtocolSetting,
      protocolEntryIds,
      language: this.translateService.currentLang,
      pdfTitle: pdfProtocolSetting.reportName,
      searchFilter: await this.convertFilters(filters),
      emailSettings,
    };
    const pdfBannersFromProjectId = filters.project.eq ?? (filters.project.in?.length === 1 ? filters.project.in[0] : undefined) ?? undefined;
    if (pdfBannersFromProjectId) {
      pdfGlobalSearchSendReq.pdfBannersFromProjectId = pdfBannersFromProjectId;
    }
    this.systemEventService.logEvent('PdfGlobalSearchService.sendPdf', `Calling sendPdf with ${protocolEntryIds.length} protocol entries.`);
    const url = await this.getSendPdfUrl(distinctProjects);
    await observableToPromise(this.http.post<PdfGlobalSearchSendRes>(url, pdfGlobalSearchSendReq));
  }

  public async generatePdfPreview(
    protocolEntries: Array<ProjectProtocolAndEntry>,
    filters: ProtocolEntrySearchFilter,
    sendPdfProtocolAdditionalInfo: SendPdfProtocolAdditionalInfo,
    pdfProtocolSetting?: PdfProtocolSetting,
    abortSignal?: AbortSignal
  ): Promise<{blob: Blob; attachmentsMissingContent: Array<Attachment>} | undefined> {
    const protocolEntryIds = protocolEntries.map((protocolEntry) => protocolEntry.entry.id).concat(protocolEntries.reduce((acc, v) => acc.concat(v.entryChildren.map(({id}) => id) ?? []), []));
    const pdfGlobalSearchSendReq: PdfGlobalSearchSendReq = {
      sendPdfProtocolAdditionalInfo,
      pdfProtocolSetting,
      protocolEntryIds,
      language: this.translateService.currentLang,
      pdfTitle: pdfProtocolSetting.reportName || this.translateService.instant('sendProtocol.protocolConfig.reportNameDefaultValue'),
      searchFilter: await this.convertFilters(filters),
    };
    if (abortSignal?.aborted) {
      return undefined;
    }
    this.systemEventService.logEvent('PdfGlobalSearchService.generatePdfPreview', `Calling sendPdf with ${protocolEntryIds.length} protocol entries.`);
    const pdfBannersFromProjectId = filters.project.eq ?? (filters.project.in?.length === 1 ? filters.project.in[0] : undefined) ?? undefined;
    if (pdfBannersFromProjectId) {
      pdfGlobalSearchSendReq.pdfBannersFromProjectId = pdfBannersFromProjectId;
    }
    const data = await this.getData(protocolEntries, pdfGlobalSearchSendReq, pdfProtocolSetting);
    if (abortSignal?.aborted) {
      return undefined;
    }
    const blob = await this.pdfGlobalSearchCommonService.generatePdf(pdfGlobalSearchSendReq, data, abortSignal);
    return {blob, attachmentsMissingContent: data.attachmentsMissingContent};
  }

  private async getOldestSyncDateAndCheckSyncNecessary(
    clientIds: Array<IdType>,
    projectIds: Array<IdType>
  ): Promise<{oldestStartServerTime: Date | undefined; syncNecessary: boolean; syncAllProjectsNecessary: boolean}> {
    let oldestStartServerTime: Date | undefined;

    const nonClientsSyncNecessary = new Set<undefined>();
    const clientsSyncNecessary = new Set<IdType>();
    const projectsSyncNecessary = new Set<IdType>();

    const checkFunc = async (clientOrProjectId: IdType | undefined, setSyncNecessary: Set<IdType | undefined>) => {
      const syncHistory = await this.syncHistoryService.getSyncHistory(clientOrProjectId);
      if (!syncHistory?.startServerTime) {
        setSyncNecessary.add(clientOrProjectId);
      }
      const syncServerHistory = await this.syncHistoryService.getSyncToServerHistory(clientOrProjectId);
      const startServerTimeTimeToUse = syncServerHistory?.startServerTime
        ? Math.max(convertISOStringToDate(syncServerHistory.startServerTime).getTime(), convertISOStringToDate(syncHistory.startServerTime).getTime())
        : convertISOStringToDate(syncHistory.startServerTime).getTime();
      if (oldestStartServerTime === undefined || convertISOStringToDate(oldestStartServerTime).getTime() > startServerTimeTimeToUse) {
        oldestStartServerTime = new Date(startServerTimeTimeToUse);
      }
      if (
        syncHistory?.startServerTime &&
        syncServerHistory?.startServerTime &&
        convertISOStringToDate(syncServerHistory.startServerTime).getTime() > convertISOStringToDate(syncHistory.startServerTime).getTime()
      ) {
        setSyncNecessary.add(clientOrProjectId);
      }
    };

    await checkFunc(undefined, nonClientsSyncNecessary);
    for (const clientId of clientIds) {
      await checkFunc(clientId, clientsSyncNecessary);
    }
    for (const projectId of projectIds) {
      await checkFunc(projectId, projectsSyncNecessary);
    }

    const currentProject = await this.projectDataService.getCurrentProject();
    const syncAllProjectsNecessary = projectsSyncNecessary.size > 1 || projectsSyncNecessary.has(currentProject?.id);
    const syncNecessary = nonClientsSyncNecessary.size > 0 || clientsSyncNecessary.size > 0 || projectsSyncNecessary.size > 0;
    return {oldestStartServerTime, syncNecessary, syncAllProjectsNecessary};
  }

  private async getSinceDate(projects: Array<Project>): Promise<Date | undefined> {
    if (!projects.length) {
      return undefined;
    }
    const projectIds = projects.map((project) => project.id);
    const distinctClientIds = [...new Set(projects.map((project) => project.clientId))];

    const {oldestStartServerTime, syncNecessary, syncAllProjectsNecessary} = await this.getOldestSyncDateAndCheckSyncNecessary(distinctClientIds, projectIds);

    if (!syncNecessary) {
      return oldestStartServerTime;
    }
    await this.syncService.startSync(syncAllProjectsNecessary ? SyncStrategy.AVAILABLE_PROJECTS_WITH_UNLOAD_UNAVAILABLE : SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
    const {oldestStartServerTime: newOldestStartServerTime, syncNecessary: newSyncNecessary} = await this.getOldestSyncDateAndCheckSyncNecessary(distinctClientIds, projectIds);
    if (syncAllProjectsNecessary && newSyncNecessary) {
      throw new Error('Still new syncNecessary even though we synced all projects.');
    }
    return newOldestStartServerTime;
  }

  private async getSendPdfUrl(projects: Array<Project>): Promise<string> {
    let url = environment.serverUrl + `api/pdf/globalSearch/send`;

    const since = await this.getSinceDate(projects);
    if (since) {
      url += '?since=' + since.toISOString();
    }
    return url;
  }

  private async convertFilters(filters: ProtocolEntrySearchFilter): Promise<Array<GlobalSearchFilter>> {
    const convertedFiltersMap: Record<string, GlobalSearchFilter> = {};

    const addToConvertedFilters = (labelKey: string, values: Array<string>) => {
      if (values.length) {
        const globalSearchFilter: GlobalSearchFilter = {
          key: this.translateService.instant(labelKey),
          values,
        };
        if (!convertedFiltersMap[globalSearchFilter.key]) {
          convertedFiltersMap[globalSearchFilter.key] = globalSearchFilter;
        } else {
          convertedFiltersMap[globalSearchFilter.key].values = convertedFiltersMap[globalSearchFilter.key].values.concat(globalSearchFilter.values);
        }
      }
    };

    const addNonEmptyInFilter = async (labelKey: string, field: SearchFilterField<any>, valueConverter: (values: Array<IdType>) => Promise<Array<string>>) => {
      if (!field) {
        return;
      }
      for (const fieldKey of Object.keys(field)) {
        if (fieldKey === 'comparator') {
          continue;
        }
        let values = new Array<any>();
        const fieldValue = field[fieldKey];
        if (fieldValue === null || fieldValue === undefined) {
          continue;
        }
        if (Array.isArray(fieldValue)) {
          values = values.concat(await valueConverter(fieldValue));
        } else {
          values.push(await valueConverter([fieldValue]));
        }
        addToConvertedFilters(labelKey, values);
      }
    };

    const addProjectTeamFilter = (labelKey: string, field: SearchFilterField<boolean>) => {
      if (field.in?.includes(true)) {
        addToConvertedFilters(labelKey, [this.translateService.instant('project_team')]);
      }
    };

    const addNonEmptyDateFilter = (field: SearchFilterField<Date>) => {
      for (const fieldKey of Object.keys(field)) {
        if (fieldKey === 'comparator') {
          continue;
        }
        const values = new Array<string>();
        const fieldValue = field[fieldKey];
        if (fieldValue === null || fieldValue === undefined) {
          continue;
        }
        if (_.isArray(fieldValue)) {
          for (const fieldSingleValue of fieldValue) {
            values.push(convertDateTimeToString(fieldSingleValue, MOMENT_DATE_FORMAT));
          }
        } else {
          values.push(convertDateTimeToString(fieldValue, MOMENT_DATE_FORMAT));
        }
        if (fieldKey === 'gt' || fieldKey === 'gte') {
          addToConvertedFilters('search_page.filter.start_date', values);
        } else {
          addToConvertedFilters('search_page.filter.end_date', values);
        }
      }
    };

    const addNonEmptyPriorityFilter = (labelKey: string, field: SearchFilterField<ProtocolEntryPriorityType>) => {
      const values = new Array<string>();
      for (const fieldKey of Object.keys(field)) {
        if (fieldKey === 'comparator') {
          continue;
        }
        const fieldValue = field[fieldKey];
        if (fieldValue === null || fieldValue === undefined) {
          continue;
        }
        if (_.isArray(fieldValue)) {
          for (const fieldSingleValue of fieldValue) {
            const priority = this.protocolEntryFilterService.getSelectablePriorityLevel(fieldSingleValue);
            values.push(priority.name);
          }
        } else {
          const priority = this.protocolEntryFilterService.getSelectablePriorityLevel(fieldValue);
          values.push(priority.name);
        }
      }
      addToConvertedFilters(labelKey, values);
    };

    const addNonEmptyStatusFilter = (labelKey: string, field: SearchFilterField<ProtocolEntryStatus>) => {
      const values = new Array<string>();
      for (const fieldKey of Object.keys(field)) {
        if (fieldKey === 'comparator') {
          continue;
        }
        const fieldValue = field[fieldKey];
        if (fieldValue === null || fieldValue === undefined) {
          continue;
        }
        if (_.isArray(fieldValue)) {
          for (const fieldSingleValue of fieldValue) {
            const status = this.protocolEntryFilterService.getSelectableStatus(fieldSingleValue);
            values.push(status.name);
          }
        } else {
          const status = this.protocolEntryFilterService.getSelectableStatus(fieldValue);
          values.push(status.name);
        }
      }
      addToConvertedFilters(labelKey, values);
    };

    const dataConverter = async <T extends IdAware>(values: Array<T>, valueProperty: Extract<keyof T, string>): Promise<Array<string>> => {
      return values.map((obj) => {
        const value = obj[valueProperty];
        if (typeof value !== 'string') {
          throw new Error(`Value "${value}" (property "${valueProperty}") is not of type string.`);
        }
        return value as string;
      });
    };

    const clientAwareConverter = async <T extends IdAware>(ids: Array<IdType>, dataService: AbstractClientAwareDataService<T>, valueProperty: Extract<keyof T, string>): Promise<Array<string>> => {
      const values = await observableToPromise(dataService.getByIdsAcrossClients(ids));
      return dataConverter(values, valueProperty);
    };

    await addNonEmptyInFilter('Project', filters.project, async (ids) => clientAwareConverter(ids, this.projectDataService, 'name'));

    for (const fieldKey of Object.keys(filters.protocol)) {
      const field = filters.protocol[fieldKey];
      switch (fieldKey) {
        case 'typeId':
          await addNonEmptyInFilter('ProtocolType', field, async (ids) => clientAwareConverter(ids, this.protocolTypeDataService, 'name'));
          break;
        case 'id':
          await addNonEmptyInFilter('Protocols', field, async (ids) =>
            dataConverter(
              await observableToPromise(
                this.protocolDataService.dataWithoutHiddenAcrossProjects$.pipe(map((protocolsAcrossProjects) => protocolsAcrossProjects.filter((protocol) => ids.includes(protocol.id))))
              ),
              'name'
            )
          );
          break;
        default:
          throw new Error(`field filters.protocol.${fieldKey} not supported`);
      }
    }

    for (const fieldKey of Object.keys(filters.entry)) {
      const field: SearchFilterField<any> = filters.entry[fieldKey];
      switch (fieldKey) {
        case 'craftId':
          await addNonEmptyInFilter('Category', field, async (ids) => clientAwareConverter(ids, this.craftDataService, 'name'));
          break;
        case 'companyId':
          await addNonEmptyInFilter('Company', field, async (ids) => clientAwareConverter(ids, this.companyDataService, 'name'));
          break;
        case 'allCompanies':
          addProjectTeamFilter('Company', field);
          break;
        case 'observerCompanies':
          await addNonEmptyInFilter('observerCompanies', field, async (ids) => clientAwareConverter(ids, this.companyDataService, 'name'));
          break;
        case 'internalAssignmentId':
          await addNonEmptyInFilter('internalAssignment', field, async (values) =>
            dataConverter(
              await observableToPromise(
                this.profileDataService
                  .getByIdsAcrossClients(values)
                  .pipe(switchMap((profiles) => this.addressDataService.getByIdsAcrossClients(profiles.filter((profile) => profile.addressId).map((profile) => profile.addressId))))
              ),
              'lastName'
            )
          );
          break;
        case 'typeId':
          await addNonEmptyInFilter('EntryType', field, async (ids) => clientAwareConverter(ids, this.protocolEntryTypeDataService, 'name'));
          break;
        case 'locationId':
          await addNonEmptyInFilter('Location', field, async (ids) => clientAwareConverter(ids, this.protocolEntryLocationDataService, 'location'));
          break;
        case 'priority':
          await addNonEmptyPriorityFilter('priority', field);
          break;
        case 'status':
          await addNonEmptyStatusFilter('Status', field);
          break;
        case 'createdAt':
          await addNonEmptyDateFilter(field);
          break;
        case 'nameableDropdownId':
          await addNonEmptyInFilter('additional_fields', field, async (ids) => clientAwareConverter(ids, this.nameableDropdownDataService, 'name'));
          break;
        case 'startDate':
          await addNonEmptyDateFilter(field);
          break;
        case 'todoUntil':
          await addNonEmptyDateFilter(field);
          break;
        case 'unitId': {
          await addNonEmptyInFilter(field.in?.length > 1 ? 'unitWithSubunits' : 'unit', field, async (ids) =>
            dataConverter(await observableToPromise(this.unitService.getUnitForBreadcrumbsByIds$([ids.slice(-1).pop()])), 'breadcrumbsName')
          );
          break;
        }
        default:
          throw new Error(`field filters.entry.${fieldKey} not supported`);
      }
    }

    return Object.values(convertedFiltersMap);
  }

  private async getData(
    projectProtocolAndEntries: Array<ProjectProtocolAndEntry>,
    pdfGlobalSearchSendReq: PdfGlobalSearchSendReq,
    pdfProtocolSetting: PdfProtocolSetting
  ): Promise<GlobalSearchPdfProtocolGenerateData> {
    const projectProtocolAndEntriesByProtocol: {[key: string]: Array<ProjectProtocolAndEntry>} = _.groupBy(
      projectProtocolAndEntries,
      (projectProtocolAndEntry: ProjectProtocolAndEntry) => projectProtocolAndEntry.protocol.id
    );
    const protocolDataList = new Array<PdfProtocolGenerateData>();
    let attachmentsMissingContent = new Array<Attachment>();
    for (const protocolId of Object.keys(projectProtocolAndEntriesByProtocol)) {
      const entries = projectProtocolAndEntriesByProtocol[protocolId];
      const pdfProtocolGenerateData = await this.getDataForProtocol(protocolId, entries, pdfProtocolSetting);
      if (pdfProtocolGenerateData?.attachmentsMissingContent?.length) {
        attachmentsMissingContent = attachmentsMissingContent.concat(pdfProtocolGenerateData.attachmentsMissingContent);
      }
      protocolDataList.push(pdfProtocolGenerateData);
    }
    const client = await observableToPromise(
      this.clientDataService.getOwnClient().pipe(
        map((clients) => clients),
        shareReplay(1)
      )
    );
    const attachmentClients = await this.pdfProtocolService.getAttachmentClientMap(client, attachmentsMissingContent);

    let pdfProjectBanners: GlobalSearchPdfProtocolGenerateData['pdfProjectBanners'];
    if (pdfGlobalSearchSendReq.pdfBannersFromProjectId) {
      pdfProjectBanners = await this.pdfProtocolService.getPdfProjectBannersMap(attachmentsMissingContent, pdfGlobalSearchSendReq.pdfBannersFromProjectId);
    }

    return {
      client,
      attachmentClients,
      pdfTitle: pdfGlobalSearchSendReq.pdfTitle,
      language: pdfGlobalSearchSendReq.language,
      participants: await observableToPromise(this.participantDataService.getForGlobalSearchCurrentProject()),
      searchFilter: pdfGlobalSearchSendReq.searchFilter,
      protocolDataList,
      pdfProjectBanners,
      attachmentsMissingContent: _.uniq(attachmentsMissingContent),
      unitProfiles: await observableToPromise(this.unitProfileDataService.dataAcrossProjects$),
      lookup: {
        companies: await this.pdfProtocolService.toMap(this.companyDataService.dataAcrossClients$),
        profiles: await this.pdfProtocolService.toMap(this.profileDataService.dataAcrossClients$),
        addresses: await this.pdfProtocolService.toMap(this.addressDataService.dataAcrossClients$),
        clients: await this.pdfProtocolService.toMap(this.clientDataService.data),
        profileCrafts: await observableToPromise(this.profileCraftDataService.dataAcrossClients$),
        crafts: await this.pdfProtocolService.toMap(this.craftDataService.dataAcrossClients$),
        projectProfiles: await observableToPromise(this.projectProfileDataService.dataAcrossProjects$),
        projectCompanies: await this.pdfProtocolService.toMap(this.projectCompanyDataService.dataAcrossProjects$),
        units: await this.pdfProtocolService.toMap(this.unitService.unitsForBreadcrumbsAcrossProjects$),
      },
    };
  }

  public async getDataForProtocol(protocolId: IdType, projectProtocolAndEntries: Array<ProjectProtocolAndEntry>, pdfProtocolSetting: PdfProtocolSetting): Promise<PdfProtocolGenerateData> {
    if (projectProtocolAndEntries.length === 0) {
      throw new Error('Empty projectProtocolAndEntries provided.');
    }
    const firstEntry = projectProtocolAndEntries[0];
    const protocol = firstEntry.protocol;
    const protocolEntryIds = projectProtocolAndEntries.map((value) => value.entry.id).concat(projectProtocolAndEntries.reduce((acc, v) => acc.concat(v.entryChildren.map(({id}) => id) ?? []), []));
    return await this.pdfProtocolService.getData(protocol.id, pdfProtocolSetting, true, protocolEntryIds, true);
  }
}
