<ng-container [formGroup]="form">
  <mbsc-datepicker
    [select]="'range'"
    [controls]="['time']"
    #range
    hidden
    display="center"
    [disabled]="disableTimePicker"
    [options]="rangePickerSettings"
    [min]="nextMeeting?.timeStart"
    [max]="nextMeeting?.timeEnd"
    [dateFormat]="MBSC_DATE_FORMAT"
    [showWeekNumbers]="true"
    [firstSelectDay]="1"
    [firstDay]="1"
    [setText]="'button.apply' | translate"
    [cancelText]="'cancel' | translate"
    [buttons]="[ 'cancel', 'set' ]"
    [locale]="mbscLocale$ | async"
    [themeVariant]="mbscThemeVariant$ | async"
    theme="windows"
    [rangeStartLabel]="'sendProtocol.startDate' | translate"
    [rangeEndLabel]="'sendProtocol.endDate' | translate"
    formControlName="timeStartEnd"
  >
  </mbsc-datepicker>

  <ion-row lines="none" class="item-legacy ion-align-items-center" [class.even]="even" [class.odd]="!even">
    <ion-col size-lg="2" size-xs="12" class="d-flex ion-align-items-center ion-justify-content-between date-row" [class.disabled]="disableTimePicker" (click)="!disableTimePicker && range.open()">
      <app-time [class.invalid-time]="form.invalid" [label]="'sendProtocol.nextMeetingsSection.time' | translate">
        {{ meetingDateValue }}
      </app-time>
      <ion-button (click)="deleteClick.emit($event)" class="omg-btn-text ion-hide-lg-up">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-col>
    <ion-col size-lg="3.3" size-xs="12" class="small-height-below-lg">
      <ion-item lines="none" *ngIf="company$ | async as company">
        <span class="text-wrap-one-line">
          <strong>{{ company.name }}</strong>
        </span>
      </ion-item>
    </ion-col>
    <ion-col size-lg="3.3" size-xs="12" class="small-height-below-lg">
      <ion-item lines="none" *ngIf="address$ | async as address">
        <span class="text-wrap-one-line">{{ address.firstName }} {{ address.lastName }}</span>
      </ion-item>
    </ion-col>
    <ion-col size-lg="2.8" size-xs="12" class="small-height-below-lg e-mail-row">
      <ion-item lines="none" *ngIf="address$ | async as address">
        <span class="text-wrap-one-line">{{ address.email }}</span>
      </ion-item>
    </ion-col>
    <ion-col size="0.5" class="ion-hide-lg-down">
      <ion-button (click)="deleteClick.emit($event)" class="omg-btn-text">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ng-container>
