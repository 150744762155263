import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {v4 as uuidv4} from 'uuid';
import {PhotoService} from '../../services/photo/photo.service';
import {ErrorWithUserMessage} from '../../shared/errors';
import {Attachment, AttachmentProject, ClientStatusEnum, Country, IdType, LicenseType, Project} from 'submodules/baumaster-v2-common';
import {ProjectDataService} from '../../services/data/project-data.service';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {Observable, Subscription} from 'rxjs';
import {AttachmentProjectDataService} from '../../services/data/attachment-project-data.service';
import {LoggingService} from '../../services/common/logging.service';
import {AttachmentBlob} from '../../model/attachments';
import {SystemEventService} from '../../services/event/system-event.service';
import {ensureMimeTypeSet, extractCommonAttachmentProperties, isQuotaExceededError} from '../../utils/attachment-utils';
import {AttachmentService} from '../../services/attachment/attachment.service';
import _ from 'lodash';
import {ToastService} from 'src/app/services/common/toast.service';
import {PopoverService} from 'src/app/services/ui/popover.service';
import {MainHeaderSettingsPopoverComponent} from './main-header-settings-popover/main-header-settings-popover.component';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {map} from 'rxjs/operators';
import {getFirstCapitalLetter} from 'src/app/utils/text-utils';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {Nullish} from '../../model/nullish';
import {ClientService} from 'src/app/services/client/client.service';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {DeviceService} from 'src/app/services/ui/device.service';
import {UserService} from 'src/app/services/user/user.service';
import {BuyingWorkflowComponent} from 'src/app/components/common/buying-workflow/buying-workflow.component';
import {ModalController} from '@ionic/angular';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {NotificationsCenterService} from 'src/app/services/notifications/notifications-center.service';
import {BuyingWorkflowCountryComponent} from 'src/app/components/common/buying-workflow-country/buying-workflow-country.component';
import {BuyingWorkflowContactFormComponent} from 'src/app/components/common/buying-workflow-contact-form/buying-workflow-contact-form.component';
import {ClientDataService} from 'src/app/services/data/client-data.service';

const LOG_SOURCE = 'MainHeaderComponent';
const DACH_COUNTRIES = ['Österreich', 'Deutschland', 'Schweiz'];

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() showBackButton = true;
  @Input() defaultPage: string;
  @Input() withoutHeaderShadow = false;
  private currentProject: Project | undefined;
  private authenticatedUserId: IdType | undefined;
  private authSubscription: Subscription | undefined;
  private networkStatusSubscription: Subscription | undefined;
  private currentProjectSubscription: Subscription | undefined;
  private currentClientSubscription: Subscription | undefined;
  private userSubscription: Subscription | undefined;
  isTester: boolean;
  isExpiredTester: boolean;
  isClientAdmin: boolean;
  daysLeft = 0;
  isNetworkConnected: boolean;
  isNativeApp = this.deviceService.isNativeApp();
  public readonly isTakingPictureEnabled$: Observable<boolean>;
  public readonly takingPhotosSupported: boolean;
  initials$ = this.userProfileService.currentUserOwnAddress$.pipe(map((address) => (address ? `${getFirstCapitalLetter(address.firstName)}${getFirstCapitalLetter(address.lastName)}` : null)));
  totalUnseenCount$ = this.notificationsCenterService.unseenCountByMode$.pipe(map(({allProjects}) => allProjects));

  constructor(
    private photoService: PhotoService,
    private projectDataService: ProjectDataService,
    private authenticationService: AuthenticationService,
    private attachmentProjectDataService: AttachmentProjectDataService,
    private loggingService: LoggingService,
    private toastService: ToastService,
    private systemEventService: SystemEventService,
    private attachmentService: AttachmentService,
    private posthogService: PosthogService,
    private popoverService: PopoverService,
    private userProfileService: UserProfileService,
    private featureEnabledService: FeatureEnabledService,
    private clientService: ClientService,
    private networkStatusService: NetworkStatusService,
    private deviceService: DeviceService,
    private userService: UserService,
    private modalController: ModalController,
    private notificationsCenterService: NotificationsCenterService,
    private clientDataService: ClientDataService
  ) {
    this.isTakingPictureEnabled$ = combineLatestAsync([
      this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.VIEWER], null, true),
      this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.BASIC, LicenseType.LIGHT, LicenseType.PROFESSIONAL]),
    ]).pipe(map(([isViewerWithReportRights, isNotViewerOrConnected]) => isViewerWithReportRights || isNotViewerOrConnected));
    this.takingPhotosSupported = this.photoService.isCameraSupported();
  }

  openNotificationsCenter() {
    this.notificationsCenterService.openCenter();
  }

  ngOnInit() {
    this.authSubscription = this.authenticationService.authenticatedUserId$.subscribe((authenticatedUserId) => (this.authenticatedUserId = authenticatedUserId));
    this.currentProjectSubscription = this.projectDataService.currentProjectObservable.subscribe((project) => (this.currentProject = project));
    this.currentClientSubscription = this.clientService.ownClient$.subscribe((client) => {
      if (client) {
        this.isTester = client.status === ClientStatusEnum.TEST_PHASE;
        this.isExpiredTester = client.status === ClientStatusEnum.EXPIRED_TEST_PHASE;
        this.daysLeft = Math.ceil(Math.max(new Date(client.trialEndDate).getTime() - new Date().getTime(), 0) / (1000 * 60 * 60 * 24));
      }
    });
    this.networkStatusSubscription = this.networkStatusService.networkStatusObservable.subscribe((connectionStatus) => (this.isNetworkConnected = connectionStatus?.connected));
    this.userSubscription = this.userService.currentUser$.subscribe((user) => {
      if (user) {
        this.isClientAdmin = user.isClientAdmin;
      }
    });
  }

  async takePicture() {
    await this.photoService.getAttachmentFromCamera(
      'main header',
      0,
      undefined,
      (...args) => this.saveAsAttachmentProject(...args),
      (attachment) => this.onTakenPhotoMarkingsChanged(attachment, attachment.markings)
    );
  }

  async takePictures() {
    await this.photoService.getAttachmentsFromCamera(
      'main header',
      0,
      undefined,
      (...args) => this.saveAsAttachmentProject(...args),
      (attachment) => this.onTakenPhotoMarkingsChanged(attachment, attachment.markings)
    );
  }

  private async saveAsAttachmentProject(attachmentBlob: AttachmentBlob): Promise<AttachmentProject> {
    return this.tryOrLog(
      'saveAsAttachmentProject',
      async () => {
        const attachment: AttachmentProject = {
          ..._.omit(attachmentBlob, 'blob'),
          projectId: this.currentProject.id,
        };

        await this.attachmentProjectDataService.insert(attachment, this.currentProject.id, {}, attachmentBlob.blob);

        return attachment;
      },
      true
    );
  }

  private async tryOrLog<T>(functionSource: string, fn: () => Promise<T>, rethrow = false): Promise<T> {
    if (!this.currentProject) {
      throw Error('No currentProject selected');
    }
    if (!this.authenticatedUserId) {
      throw Error('No auth available');
    }
    try {
      return fn();
    } catch (error) {
      let message: string | undefined;
      if (error instanceof ErrorWithUserMessage) {
        const errorWithUserMessage = error as ErrorWithUserMessage;
        message = errorWithUserMessage.userMessage;
      } else {
        message = error.message;
      }
      this.loggingService.error(LOG_SOURCE, `Error in ${functionSource}. "${error?.userMessage}" - "${error?.message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ` ${functionSource}`, error);
      if (isQuotaExceededError(message)) {
        await this.attachmentService.showToastQuotaExceeded();
      } else {
        await this.toastService.errorWithMessageAndHeader('error_saving_message', message);
      }

      if (rethrow) {
        throw error;
      }
    }
  }

  async addAttachments(values: Array<{blob: Blob; fileName: string}>): Promise<Array<AttachmentProject>> {
    return this.tryOrLog('addAttachments', async () => {
      const blobs = new Array<Blob>();
      const attachments = new Array<AttachmentProject>();
      for (const value of values) {
        const fileName = value.fileName;
        const blob = ensureMimeTypeSet(value.blob, fileName);
        const {fileExt} = this.photoService.fileNameSplitToNameAndExt(fileName);
        const attachment: AttachmentProject = {
          id: uuidv4(),
          hash: uuidv4(),
          projectId: this.currentProject.id,
          mimeType: blob.type,
          fileName,
          fileExt,
          changedAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
          createdById: this.authenticatedUserId,
          ...(await extractCommonAttachmentProperties(blob)),
        };

        blobs.push(blob);
        attachments.push(attachment);
      }

      await this.attachmentProjectDataService.insert(attachments, this.currentProject.id, {}, blobs);

      return attachments;
    });
  }

  private onTakenPhotoMarkingsChanged = async (newAttachment: AttachmentBlob | Attachment, markings: Nullish<string>) => {
    newAttachment.markings = markings;
    await this.attachmentProjectDataService.update(newAttachment as AttachmentProject, this.currentProject.id);
  };

  async showUserMenu(event: Event) {
    this.popoverService.openAndClose(event, {
      component: MainHeaderSettingsPopoverComponent,
      cssClass: 'omg-popover-brand-border',
      componentProps: {
        takingPhotosSupported: this.takingPhotosSupported,
        isTakingPictureEnabled$: this.isTakingPictureEnabled$,
        onTakePicture: () => this.takePicture(),
        onTakePictures: () => this.takePictures(),
      },
    });
  }

  async openBuyingModal() {
    const modalCountry = await this.modalController.create({
      component: BuyingWorkflowCountryComponent,
      backdropDismiss: false,
      cssClass: 'buying-modal',
      componentProps: {},
    });
    await modalCountry.present();
    const {data, role} = await modalCountry.onDidDismiss();
    if (role === 'cancel' || !data) {
      return;
    }
    const country: Country = data;
    const client = this.clientService.getCurrentClientMandatory();
    if (client.countryId !== country.id) {
      await this.clientDataService.update({countryId: country.id, ...client});
    }
    if (DACH_COUNTRIES.some((countryName) => countryName.toLowerCase() === country.name.toLowerCase())) {
      this.posthogService.captureEvent('[Buying] Started Buying Workflow', {});
      const modal = await this.modalController.create({
        component: BuyingWorkflowComponent,
        backdropDismiss: false,
        cssClass: 'buying-modal',
        componentProps: {
          country,
        },
      });
      await modal.present();
    } else {
      this.posthogService.captureEvent('[Buying][Global] Started Buying Workflow', {});
      const modal = await this.modalController.create({
        component: BuyingWorkflowContactFormComponent,
        backdropDismiss: false,
        cssClass: 'buying-modal',
        componentProps: {
          country,
        },
      });
      await modal.present();
    }
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
    this.authSubscription = undefined;
    this.currentProjectSubscription?.unsubscribe();
    this.currentProjectSubscription = undefined;
    this.networkStatusSubscription?.unsubscribe();
    this.currentClientSubscription?.unsubscribe();
    this.currentClientSubscription = undefined;
    this.userSubscription?.unsubscribe();
    this.userSubscription = undefined;
  }
}
