import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {UnitContactWithUnitProfiles} from 'src/app/services/units/unit-contacts.service';
import {SelectableInputModule} from 'src/app/shared/module/selectable-input/selectable-input.module';
import {CommonSelectableModule} from 'src/app/shared/module/selectable/selectable.module';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {Router} from '@angular/router';
import {UnitForBreadcrumbs} from 'submodules/baumaster-v2-common';
import {combineLatest, map, startWith} from 'rxjs';
import {ThemeService} from 'src/app/services/ui/theme.service';
import {AsyncPipe} from '@angular/common';

export interface UnitContactMenuClickEvent {
  event: MouseEvent;
  unitContact: UnitContactWithUnitProfiles;
}

@Component({
  selector: 'app-unit-owner-modal',
  templateUrl: './unit-owner-modal.component.html',
  styleUrls: ['./unit-owner-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, FontAwesomeModule, TranslateModule, SelectableInputModule, CommonSelectableModule, UiModule, AsyncPipe],
})
export class UnitOwnerModalComponent {
  modal: HTMLIonModalElement;
  @Input()
  unitContacts: UnitContactWithUnitProfiles[];

  @Input()
  unit: UnitForBreadcrumbs;

  @Input()
  readonly = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private themeService: ThemeService
  ) {}

  protected imageNameSuffix$ = combineLatest([this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)), this.themeService.isDark$]).pipe(
    map(([lang, isDark]) => `${isDark ? 'dm' : 'lm'}-${lang === 'de' || lang === 'en' ? lang : 'en'}`)
  );

  protected async openContact(event: UnitContactMenuClickEvent) {
    const addressId = event.unitContact.address.id;
    this.modal.dismiss().then((dismissed) => {
      if (dismissed) {
        this.router.navigate([`/contact-detail/unit-contact/${addressId}`]);
      }
    });
  }

  close() {
    this.modal.dismiss();
  }
}
