import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {CountryDataService} from 'src/app/services/data/country-data.service';
import {Address, Country} from 'submodules/baumaster-v2-common/';
import {map, switchMap, take} from 'rxjs/operators';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {IonicSelectableComponent} from 'ionic-selectable';
import {SelectableUtilService} from '../../../services/common/selectable-util.service';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {ThemeService} from 'src/app/services/ui/theme.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';

const LOG_SOURCE = 'BuyingWorkflowCountryComponent';

@Component({
  selector: 'app-buying-workflow-country',
  templateUrl: './buying-workflow-country.component.html',
  styleUrls: ['./buying-workflow-country.component.scss'],
})
export class BuyingWorkflowCountryComponent implements OnInit, OnDestroy {
  modal: HTMLIonModalElement;

  countries$: Observable<Country[]>;
  preSelectedCountry: Country | undefined;

  private countrySubscription: Subscription | undefined;
  private companyAddress: Address | undefined;
  private companySubscription: Subscription | undefined;
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  protected imageNameSuffix$ = this.themeService.isDark$.pipe(map((isDark) => `${isDark ? 'dm' : 'lm'}`));

  countryForm = this.fb.group({
    country: [null as Country | null, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private countryDataService: CountryDataService,
    private selectableUtilService: SelectableUtilService,
    private userProfileService: UserProfileService,
    private addressDataService: AddressDataService,
    private themeService: ThemeService,
    private posthogService: PosthogService
  ) {}

  async ngOnInit() {
    this.companySubscription = this.userProfileService.currentUserOwnCompany$.pipe(switchMap((company) => this.addressDataService.getById(company.addressId))).subscribe((address) => {
      this.companyAddress = address;
    });
    this.countries$ = this.countryDataService.dataSorted$;
    this.countrySubscription = this.countries$.pipe(take(1)).subscribe((countries) => {
      this.preSelectedCountry = countries.find((country) => country.name.toLowerCase() === this.companyAddress.country.toLowerCase());
      if (this.preSelectedCountry && !this.countryForm.controls.country.dirty) {
        this.countryForm.controls.country.setValue(this.preSelectedCountry);
      }
    });
  }

  ngOnDestroy(): void {
    this.countrySubscription?.unsubscribe();
    this.countrySubscription = undefined;
    this.companySubscription?.unsubscribe();
    this.companySubscription = undefined;
  }

  async dismissModal(role: string) {
    await this.modal.dismiss(this.countryForm.controls.country.value, role);
    if (role !== 'confirm') {
      this.posthogService.captureEvent('[Buying][Global] Cancelled country step', {});
    }
  }

  async onOpen($event: {component: IonicSelectableComponent}) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: {component: IonicSelectableComponent}) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }

  getGroupText = (country: Country, index: number, countries: Country[]) => {
    return this.countryDataService.getGroupText(country, index, countries);
  };
}
