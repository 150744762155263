import {Injectable} from '@angular/core';
import {TranslateService, USE_STORE} from '@ngx-translate/core';
import {map, Observable, of} from 'rxjs';
import {Nullish} from 'src/app/model/nullish';
import {Translatable} from 'src/app/model/translatable';

@Injectable({
  providedIn: 'root',
  deps: [
    {
      provide: USE_STORE,
      useValue: true,
    },
    {
      provide: TranslateService,
      useClass: TranslateService,
    },
  ],
})
export class WithParamsTranslateService {
  constructor(private translateService: TranslateService) {}

  translate(str: Nullish<Translatable>): string | undefined {
    if (str === undefined || str === null) {
      return undefined;
    }
    if (typeof str === 'string') {
      return this.translateService.instant(str);
    }
    if ('key' in str) {
      return this.translateService.instant(str.key, str.params);
    }

    return str.doNotTranslate;
  }

  translateInLanguage(str: Nullish<Translatable>, language: Nullish<string>): Observable<string> {
    if (str === undefined || str === null) {
      return undefined;
    }
    if (typeof str === 'object' && 'doNotTranslate' in str) {
      return of(str.doNotTranslate);
    }
    let key: string,
      params: object = {};
    if (typeof str === 'object') {
      key = str.key;
      params = str.params;
    } else {
      key = str;
    }

    const result = this.translateService.getTranslation(language ?? this.translateService.currentLang).pipe(
      map((translations) => {
        const translatedText = this.translateService.getParsedResult(translations, key, params);
        return translatedText || key;
      })
    );

    // Hack: call getTranslation again with current language, so internals of ngx-translate properly keep current language set
    this.translateService.getTranslation(this.translateService.currentLang);

    return result;
  }
}
