<ng-template #toolbar>
  <div class="d-flex gap-2 pt-2">
    <ion-button class="omg-btn-secondary" (click)="searchClick.emit($event)">
      <fa-icon slot="start" [icon]="['fal', 'search']"></fa-icon>
    </ion-button>
    <div class="d-flex gap-2 unit-selector-tree-scrollable-levels flex-grow-1 ion-align-items-center">
      @for (level of selectorUnitLevels; track level.id; let last = $last) {
      <ion-button
        [class.level-selected]="currentLevelId() === level.id"
        [class.level-has-selected]="!!selectedUnitByLevel[level.id]"
        class="omg-btn-secondary"
        (click)="currentLevelId.set(level.id)"
      >
        {{level.name}}
      </ion-button>
      @if (!last) {
      <fa-icon [icon]="['fal', 'chevron-right']"></fa-icon>
      }
      }
    </div>
  </div>
</ng-template>

<ion-list class="omg-boundary project-list">
  @if (currentLevelUnits().length === 0) {
    @if (selectorUnitLevels.length > 0) {
    <div class="p-4">
      {{ 'noItemsFound' | translate }}
    </div>
    } @else {
    <app-unit-selector-empty></app-unit-selector-empty>
    }
  }
  <rx-virtual-scroll-viewport autosize [tombstoneSize]="49">
    <div class="unit-selector-list-item full-width cursor-pointer list-item pl-7 pr-7 d-flex ion-align-items-center" *rxVirtualFor="let unit of currentLevelUnits; trackBy 'id'; strategy 'local'" (click)="selectUnit(unit)">
      <div class="d-inline-flex gap-4 ion-align-items-start">
        <span class="bau-radio" [class.checked]="unit.isSelected"></span>
        <div class="d-flex flex-column gap-1">
          <span>
            {{unit.name}}
          </span>
          @if (unit.path.length && !selectedUnitByLevel[unit.previousLevel?.id]) {
          <div class="omg-t-medium-body text-grey">
            @for (path of unit.path; track path.id; let last = $last) {
            {{path.name}} &gt; 
            }
            {{unit.name}}
          </div>
          }
        </div>
      </div>
    </div>
  </rx-virtual-scroll-viewport>
</ion-list>

<ng-template #footer let-cancel="cancel" let-reset="reset" let-apply="apply" let-applyLabel="applyLabel" let-applyIcon="applyIcon">
  <ion-buttons class="unit-selector-tree-footer-buttons" slot="end">
    <ion-button class="omg-btn-text ion-hide-sm-down" (click)="cancel()">
      {{ 'cancel' | translate }}
    </ion-button>
    <div class="back-next d-flex gap-3">
      @if (canGoBack()) {
      <ion-button class="omg-btn-secondary" [class.flex-grow-1]="!canGoNext()" (click)="back()">
        <fa-icon slot="start" [icon]="['fal', 'arrow-left']"></fa-icon>
        <span [class.ion-hide-sm-down]="canGoNext()">{{ 'back' | translate }}</span>
      </ion-button>
      }
      @if (canGoNext()) {
      <ion-button class="omg-btn-secondary flex-grow-1" (click)="next()">
        <fa-icon slot="start" [icon]="['fal', 'arrow-right']"></fa-icon>
        <span>{{ 'next' | translate }}</span>
      </ion-button>
      }
    </div>
    @if (canReset) {
    <ion-button class="omg-btn-primary" (click)="reset()">
      {{ 'reset' | translate }}
    </ion-button>
    } @else {
    <ion-button class="omg-btn-primary" (click)="apply()" [disabled]="!selectedUnit()">
      <fa-icon slot="start" [icon]="applyIcon"></fa-icon>
      <span>{{ applyLabel | translate }}</span>
    </ion-button>
    }
  </ion-buttons>
</ng-template>
