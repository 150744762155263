import {Injectable} from '@angular/core';
import {CopyProtocolEntryService} from './copy-protocol-entry.service';
import {
  AttachmentProtocolEntry,
  IdType,
  NameableDropdownItem,
  PdfPlanMarkerProtocolEntry,
  Project,
  ProjectCompany,
  ProjectCraft,
  ProjectProfile,
  ProjectProtocolEntryType,
  ProjectProtocolLocation,
  ProjectProtocolType,
  Protocol,
  ProtocolEntry,
  ProtocolEntryCompany,
  ProtocolEntryDefaultValue,
  ProtocolOpenEntry,
  TASK_PROTOCOL_NAME,
} from 'submodules/baumaster-v2-common';
import {CopyProtocolEntrySetting, CopyProtocolSetting, ProtocolCopyResult, ProtocolEntryCopyResult} from '../../model/copy';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {observableToPromise} from '../../utils/async-utils';
import {ProtocolOpenEntryDataService} from '../data/protocol-open-entry-data.service';
import {v4 as uuid4} from 'uuid';
import {ProtocolService} from '../protocol/protocol.service';
import {AuthenticationService} from '../auth/authentication.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import _ from 'lodash';
import {ProjectProtocolEntryTypeDataService} from '../data/project-protocol-entry-type-data.service';
import {ProjectProtocolTypeDataService} from '../data/project-protocol-type-data.service';
import {ProjectProtocolLocationDataService} from '../data/project-protocol-location-data.service';
import {NameableDropdownItemDataService} from '../data/nameable-dropdown-item-data.service';
import {ProjectCompanyDataService} from '../data/project-company-data.service';
import {ProjectCraftDataService} from '../data/project-craft-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {ProtocolEntryOrOpen} from '../../model/protocol';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolEntryService} from '../protocol/protocol-entry.service';
import {ClientService} from '../client/client.service';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {isTaskProtocol} from '../../utils/protocol-utils';
import {PdfPlanMarkerProtocolEntryDataService} from '../data/pdf-plan-marker-protocol-entry-data.service';
import {ProtocolEntryDefaultValueDataService} from '../data/protocol-entry-default-value-data.service';

@Injectable({
  providedIn: 'root',
})
export class CopyProtocolService {
  constructor(
    private projectDataService: ProjectDataService,
    private protocolDataService: ProtocolDataService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolOpenEntryDataService: ProtocolOpenEntryDataService,
    private clientService: ClientService,
    private attachmentEntryDataService: AttachmentEntryDataService,
    private projectProtocolTypeDataService: ProjectProtocolTypeDataService,
    private projectProtocolEntryTypeDataService: ProjectProtocolEntryTypeDataService,
    private projectProtocolLocationDataService: ProjectProtocolLocationDataService,
    private nameableDropdownItemDataService: NameableDropdownItemDataService,
    private projectCompanyDataService: ProjectCompanyDataService,
    private projectCraftDataService: ProjectCraftDataService,
    private projectProfileDataService: ProjectProfileDataService,
    private protocolService: ProtocolService,
    private copyProtocolEntryService: CopyProtocolEntryService,
    private authenticationService: AuthenticationService,
    private protocolEntryService: ProtocolEntryService,
    private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
    private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
    private protocolEntryDefaultValueDataService: ProtocolEntryDefaultValueDataService
  ) {}

  public async getEntriesToCopy(
    protocol: Protocol,
    setting?: CopyProtocolSetting
  ): Promise<{protocolEntriesOrOpen: Array<ProtocolEntryOrOpen>; attachmentProtocolEntries: Array<AttachmentProtocolEntry>}> {
    const protocolEntriesOrOpen = await observableToPromise(this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(protocol.id));
    const sortedProtocolEntriesOrOpen = await this.protocolEntryService.sortProtocolEntriesByProtocolId(protocolEntriesOrOpen, protocol.id);
    const attachmentProtocolEntries =
      setting === undefined || setting.copyAttachments
        ? await observableToPromise(this.attachmentEntryDataService.getByProtocolEntries(protocolEntriesOrOpen.map((protocolEntry) => protocolEntry.id)))
        : [];
    return {
      protocolEntriesOrOpen: sortedProtocolEntriesOrOpen,
      attachmentProtocolEntries,
    };
  }

  public async copy(
    protocol: Protocol,
    destinationProject: Project,
    setting: CopyProtocolSetting,
    abortController: AbortController,
    callback?: (protocol: Protocol, numberEntriesCopied: number, numberEntries: number, numberCopiedAttachments: number, numberOfAttachments: number, text?: string) => void
  ): Promise<ProtocolCopyResult | undefined> {
    const authenticatedUserId = await observableToPromise(this.authenticationService.authenticatedUserId$);
    if (!authenticatedUserId) {
      throw new Error('Unable to copy protocol because user is not authenticated.');
    }
    const ownClient = await this.clientService.getOwnClientMandatory();
    const isContinuous = await this.protocolService.isContinuousProtocol(protocol);
    const isCopingToDifferentProject = protocol.projectId !== destinationProject.id;
    if (isContinuous) {
      if (!isCopingToDifferentProject) {
        throw new Error('Unable to copy a continuous protocol to the same project.');
      }
      await this.assertNoProtocolOfTypeExists(protocol.typeId, destinationProject);
    }

    const protocolNumber = isTaskProtocol(protocol) ? protocol.number : await this.protocolService.getProtocolNextNumberByProject(protocol.typeId, destinationProject.id);
    const protocolName = isTaskProtocol(protocol) ? TASK_PROTOCOL_NAME : setting.protocolName;
    const isDestinationProjectSameAsSource = protocol.projectId === destinationProject.id;

    const newProtocol: Protocol = {
      id: uuid4(),
      projectId: destinationProject.id,
      typeId: protocol.typeId,
      number: protocolNumber,
      name: protocolName,
      sortEntriesBy: protocol.sortEntriesBy,
      location: protocol.location,
      ownerClientId: ownClient.id,
      date: protocol.date,
      timeFrom: protocol.timeFrom,
      timeUntil: protocol.timeUntil,
      changedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      closedAt: null,
      createdById: authenticatedUserId,
      includesVat: protocol.includesVat,
      unitId: isDestinationProjectSameAsSource ? protocol.unitId : null,
      isUnitEntryDefault: isDestinationProjectSameAsSource && protocol.unitId ? protocol.isUnitEntryDefault : null,
    };
    const {protocolEntriesOrOpen, attachmentProtocolEntries} = await this.getEntriesToCopy(protocol, setting);

    const protocolEntryCopyResults = new Array<ProtocolEntryCopyResult>();
    const totalNumberOfAttachments = attachmentProtocolEntries.length;
    let totalNumberAttachmentsCopied = 0;
    let nextNumber = 1;
    for (const protocolEntry of protocolEntriesOrOpen) {
      if (abortController.signal.aborted) {
        return;
      }
      if (!protocolEntry.parentId && !(protocolEntry.isOpenEntry && !isCopingToDifferentProject)) {
        const copyProtocolEntrySetting: CopyProtocolEntrySetting = {
          protocol,
          copySubEntries: true,
          copyParentEntry: false,
          copyAttachments: setting.copyAttachments,
          copyDetails: setting.copyDetails,
          copyCreationDate: setting.copyCreationDate,
          copyContacts: setting.copyContacts,
          copyMarkers: setting.copyMarkers,
          protocolEntryNumber: nextNumber++,
          ignoreMissingAttachments: setting.ignoreMissingAttachments,
        };
        const copyProtocolEntryCallback = (callbackProtocolEntry: ProtocolEntry, callbackNumberCopiedAttachments: number, callbackNumberOfAttachments: number, callbackText?: string) => {
          if (typeof callback === 'function') {
            callback(
              protocol,
              protocolEntryCopyResults.length,
              protocolEntriesOrOpen.length,
              totalNumberAttachmentsCopied + callbackNumberCopiedAttachments,
              Math.max(totalNumberAttachmentsCopied + callbackNumberCopiedAttachments, totalNumberOfAttachments)
            );
          }
        };
        const protocolEntryCopyResult = await this.copyProtocolEntryService.startCopy(protocolEntry, newProtocol, copyProtocolEntrySetting, abortController, copyProtocolEntryCallback);
        protocolEntryCopyResult.copiedAttachments.forEach((copiedAttachment) => (totalNumberAttachmentsCopied += copiedAttachment.attachments.length));
        if (typeof callback === 'function') {
          callback(protocol, protocolEntryCopyResults.length, protocolEntriesOrOpen.length, totalNumberAttachmentsCopied, Math.max(totalNumberAttachmentsCopied, totalNumberOfAttachments));
        }
        protocolEntryCopyResults.push(protocolEntryCopyResult);
      }
    }

    await this.protocolDataService.insert(newProtocol, destinationProject.id);
    let newProtocolEntries = new Array<ProtocolEntry>();
    let newProtocolEntryAttachments = new Array<AttachmentProtocolEntry>();
    let newAttachmentBlobs = new Array<Blob>();
    const newProtocolEntryCompanies: ProtocolEntryCompany[] = [];
    let newPdfPlanMarkerProtocolEntries = new Array<PdfPlanMarkerProtocolEntry>();

    for (const protocolEntryCopyResult of protocolEntryCopyResults) {
      newProtocolEntries = newProtocolEntries.concat(protocolEntryCopyResult.copiedEntries);
      newProtocolEntryCompanies.push(...protocolEntryCopyResult.copiedEntryCompanies);
      newPdfPlanMarkerProtocolEntries = newPdfPlanMarkerProtocolEntries.concat(protocolEntryCopyResult.copiedMarkers);
      for (const protocolEntryAttachmentsCopy of protocolEntryCopyResult.copiedAttachments) {
        if (protocolEntryAttachmentsCopy.attachments.length) {
          newProtocolEntryAttachments = newProtocolEntryAttachments.concat(protocolEntryAttachmentsCopy.attachments);
          newAttachmentBlobs = newAttachmentBlobs.concat(protocolEntryAttachmentsCopy.blobs);
        }
      }
    }
    await this.protocolEntryDataService.insert(newProtocolEntries, destinationProject.id);
    await this.protocolEntryCompanyDataService.insert(newProtocolEntryCompanies, destinationProject.id);
    await this.attachmentEntryDataService.insert(newProtocolEntryAttachments, destinationProject.id, {}, newAttachmentBlobs);
    await this.pdfPlanMarkerProtocolEntryDataService.insert(newPdfPlanMarkerProtocolEntries, destinationProject.id);

    if (isDestinationProjectSameAsSource && setting.copyDetails) {
      const defaultValues = await observableToPromise(this.protocolEntryDefaultValueDataService.getByProtocolId(protocol.id));
      const newDefaultValues: ProtocolEntryDefaultValue = {...defaultValues, id: uuid4(), protocolId: newProtocol.id, changedAt: new Date().toISOString(), createdAt: new Date().toISOString()};
      await this.protocolEntryDefaultValueDataService.insert(newDefaultValues, newProtocol.projectId);
    }

    for (const protocolEntry of protocolEntriesOrOpen) {
      if (protocolEntry.isOpenEntry && !isCopingToDifferentProject) {
        const protocolOpenEntry: ProtocolOpenEntry = {
          id: uuid4(),
          protocolId: newProtocol.id,
          protocolEntryId: protocolEntry.id,
          changedAt: new Date().toISOString(),
        };
        await this.protocolOpenEntryDataService.insert(protocolOpenEntry, destinationProject.id);
      }
    }

    if (isCopingToDifferentProject) {
      await this.ensureEnabledInProject(newProtocol, protocolEntriesOrOpen, destinationProject, setting);
    }
    return {
      copiedProtocol: newProtocol,
      copiedAttachments: _.flatten(
        _.concat(
          [],
          protocolEntryCopyResults.map((protocolEntryCopyResult) => protocolEntryCopyResult.copiedAttachments)
        )
      ),
      copiedEntries: _.flatten(
        _.concat(
          [],
          protocolEntryCopyResults.map((protocolEntryCopyResult) => protocolEntryCopyResult.copiedEntries)
        )
      ),
      copiedMarkers: _.flatten(
        _.concat(
          [],
          protocolEntryCopyResults.map((protocolEntryCopyResult) => protocolEntryCopyResult.copiedMarkers)
        )
      ),
    };
  }

  private getUniqueNotNullPropertyValues(
    protocolEntries: Array<ProtocolEntry>,
    property: 'id' | 'typeId' | 'locationId' | 'nameableDropdownId' | 'craftId' | 'companyId' | 'internalAssignmentId'
  ): Array<IdType> {
    return _.uniq(protocolEntries.filter((protocolEntry) => protocolEntry[property]).map((protocolEntry) => protocolEntry[property]));
  }

  private async ensureEnabledInProject(protocol: Protocol, protocolEntries: Array<ProtocolEntryOrOpen>, project: Project, setting: CopyProtocolSetting) {
    const projectProtocolType = {
      changedAt: new Date().toISOString(),
      id: project.id + protocol.typeId,
      projectId: project.id,
      protocoltypeId: protocol.typeId,
    } as ProjectProtocolType;
    await this.projectProtocolTypeDataService.insert(projectProtocolType, project.id);

    const protocolEntryTypeIds = this.getUniqueNotNullPropertyValues(protocolEntries, 'typeId');
    for (const protocolEntryTypeId of protocolEntryTypeIds) {
      const projectProtocolEntryType = {
        changedAt: new Date().toISOString(),
        id: project.id + protocolEntryTypeId,
        projectId: project.id,
        protocolentrytypeId: protocolEntryTypeId,
      } as ProjectProtocolEntryType;
      await this.projectProtocolEntryTypeDataService.insert(projectProtocolEntryType, project.id);
    }

    const protocolEntryLocationIds = this.getUniqueNotNullPropertyValues(protocolEntries, 'locationId');
    for (const protocolEntryLocationId of protocolEntryLocationIds) {
      const projectProtocolLocation = {
        changedAt: new Date().toISOString(),
        id: project.id + protocolEntryLocationId,
        projectId: project.id,
        protocolentrylocationId: protocolEntryLocationId,
      } as ProjectProtocolLocation;
      await this.projectProtocolLocationDataService.insert(projectProtocolLocation, project.id);
    }

    const nameableDropdownIds = this.getUniqueNotNullPropertyValues(protocolEntries, 'nameableDropdownId');
    for (const nameableDropdownId of nameableDropdownIds) {
      const nameableDropdownItem = {
        changedAt: new Date().toISOString(),
        id: project.id + nameableDropdownId,
        projectId: project.id,
        nameabledropdownId: nameableDropdownId,
      } as NameableDropdownItem;
      await this.nameableDropdownItemDataService.insert(nameableDropdownItem, project.id);
    }

    const craftIds = this.getUniqueNotNullPropertyValues(protocolEntries, 'craftId');
    for (const craftId of craftIds) {
      const projectCraft = {
        changedAt: new Date().toISOString(),
        id: project.id + craftId,
        projectId: project.id,
        craftId,
      } as ProjectCraft;
      await this.projectCraftDataService.insert(projectCraft, project.id);
    }

    if (setting.copyContacts) {
      const companyIds = this.getUniqueNotNullPropertyValues(protocolEntries, 'companyId');
      for (const companyId of companyIds) {
        const projectCompany = {
          changedAt: new Date().toISOString(),
          id: project.id + companyId,
          projectId: project.id,
          companyId,
        } as ProjectCompany;
        await this.projectCompanyDataService.insert(projectCompany, project.id);
      }

      const internalAssignmentIds = this.getUniqueNotNullPropertyValues(protocolEntries, 'internalAssignmentId');
      for (const profileId of internalAssignmentIds) {
        const projectProfile = {
          changedAt: new Date().toISOString(),
          id: project.id + profileId,
          projectId: project.id,
          profileId,
        } as ProjectProfile;
        await this.projectProfileDataService.insert(projectProfile, project.id);
      }
    }
  }

  private async assertNoProtocolOfTypeExists(protocolTypeId: IdType, project: Project) {
    const protocolsOfTypeExists = await observableToPromise(this.protocolService.hasProjectProtocolOfType(protocolTypeId, project.id));
    if (protocolsOfTypeExists) {
      throw new Error(`Protocol of type ${protocolTypeId} already exists in project ${project.id}`);
    }
  }
}
