import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {AbstractFileAccessUtil, CacheStorageFileAccessUtil, FileAccessUtilClassName, FileAccessUtilWithDefault, FilesystemFileAccessUtil} from '../../utils/attachment-utils';
import {environment} from '../../../environments/environment';
import {Observable, ReplaySubject} from 'rxjs';
import {StorageService} from '../storage.service';
import {StorageKeyEnum} from '../../shared/constants';
import {observableToPromise} from '../../utils/async-utils';
import {LoggingService} from '../common/logging.service';
import {map} from 'rxjs/operators';

const STORAGE_KEY = StorageKeyEnum.FILE_ACCESS_UTIL;
const LOG_SOURCE = 'AttachmentSettingService';

@Injectable({
  providedIn: 'root',
})
export class AttachmentSettingService {
  private readonly mediaUrl = environment.serverUrl + 'media';
  private readonly cacheStorageFileAccessUtil = new CacheStorageFileAccessUtil(this.mediaUrl);
  private readonly filesystemFileAccessUtil = new FilesystemFileAccessUtil(this.mediaUrl);
  private readonly fileAccessUtilSubject = new ReplaySubject<FileAccessUtilWithDefault>(1);
  public readonly fileAccessUtilWithDefault$: Observable<FileAccessUtilWithDefault> = this.fileAccessUtilSubject.asObservable();
  public readonly fileAccessUtil$: Observable<AbstractFileAccessUtil> = this.fileAccessUtilSubject.asObservable().pipe(map((fileAccessUtilWithDefault) => fileAccessUtilWithDefault.fileAccessUtil));

  constructor(
    private platform: Platform,
    private storage: StorageService,
    private loggingService: LoggingService
  ) {
    this.initFileAccessUtilSubject();
  }

  public getSupportedFileAccessUtils(): Array<AbstractFileAccessUtil> {
    if (this.platform.is('capacitor') && (this.platform.is('android') || this.platform.is('ios'))) {
      return [this.cacheStorageFileAccessUtil, this.filesystemFileAccessUtil];
    }
    return [this.cacheStorageFileAccessUtil];
  }

  public getDefaultFileAccessUtilForDevice(): AbstractFileAccessUtil {
    if (this.platform.is('capacitor') && this.platform.is('ios')) {
      return this.filesystemFileAccessUtil;
    }
    return this.cacheStorageFileAccessUtil;
  }

  public getSupportedFileAccessUtilClassNames(): Array<FileAccessUtilClassName> {
    return this.getSupportedFileAccessUtils().map((fileAccessUtil) => fileAccessUtil.className);
  }

  public isSwitchingFileAccessUtilSupported(): boolean {
    return this.getSupportedFileAccessUtils().length > 1;
  }

  public isFileAccessUtilSupported(fileAccessUtilClassName: FileAccessUtilClassName): boolean {
    return this.getSupportedFileAccessUtilClassNames().some((value) => value === fileAccessUtilClassName);
  }

  public async getFileAccessUtil(): Promise<AbstractFileAccessUtil> {
    return await observableToPromise(this.fileAccessUtil$);
  }

  public async getFileAccessUtilWithDefault(): Promise<FileAccessUtilWithDefault> {
    return await observableToPromise(this.fileAccessUtilWithDefault$);
  }

  private async getFileAccessUtilFromStorageOrDefault(): Promise<FileAccessUtilWithDefault> {
    this.loggingService.debugWithEvent(LOG_SOURCE, 'getFileAccessUtilFromStorageOrDefault', `method called`, {
      isEventLogHigherPriority: true,
    });
    const fileAccessUtilClassName: FileAccessUtilClassName | undefined | null = await this.storage.get(STORAGE_KEY);
    if (fileAccessUtilClassName) {
      try {
        this.loggingService.infoWithEvent(
          LOG_SOURCE,
          'getFileAccessUtilFromStorageOrDefault',
          `storage.get(${STORAGE_KEY}) returned "${fileAccessUtilClassName} - ${await this.storage.getStatusString()}"`,
          {isEventLogHigherPriority: true}
        );
      } catch (error) {
        // ignore
      }
      return {fileAccessUtil: this.getFileAccessUtilByClassName(fileAccessUtilClassName), usedDefault: false};
    }
    try {
      this.loggingService.warnWithEvent(
        LOG_SOURCE,
        'getFileAccessUtilFromStorageOrDefault',
        `storage value for key "${STORAGE_KEY}" was falsy, using default value cacheStorageFileAccessUtil. ${await this.storage.getStatusString()}`,
        {isEventLogHigherPriority: true}
      );
    } catch (error) {
      // ignore
    }
    const defaultFileAccessUtil = this.cacheStorageFileAccessUtil;
    return {fileAccessUtil: defaultFileAccessUtil, usedDefault: true};
  }

  public async changeFileAccessUtil(newFileAccessUtilClassName: FileAccessUtilClassName): Promise<{oldFileAccessUtil: AbstractFileAccessUtil; newFileAccessUtil?: AbstractFileAccessUtil}> {
    const {fileAccessUtil: oldFileAccessUtil, usedDefault: oldFileAccessUsedDefault} = await observableToPromise(this.fileAccessUtilWithDefault$);
    if (oldFileAccessUtil.className === newFileAccessUtilClassName) {
      const fileAccessUtilClassNameInStorage = await this.storage.get(STORAGE_KEY);
      if (!fileAccessUtilClassNameInStorage) {
        this.loggingService.infoWithEvent(
          LOG_SOURCE,
          'changeFileAccessUtil',
          `called without changing the fileAccessUtilClassName ("${newFileAccessUtilClassName}") but no value for ${STORAGE_KEY} found in local storage (probably logout-logon). Persisting it now.`,
          {isEventLogHigherPriority: true}
        );
        await this.storage.set(STORAGE_KEY, oldFileAccessUtil.className);
      } else if (fileAccessUtilClassNameInStorage !== oldFileAccessUtil.className) {
        try {
          this.loggingService.warnWithEvent(
            LOG_SOURCE,
            'changeFileAccessUtil',
            `called without changing the fileAccessUtilClassName ("${newFileAccessUtilClassName}")` +
              ` but a different value ("${fileAccessUtilClassNameInStorage}") found in local storage ${STORAGE_KEY}. Persisting it now.` +
              ` StorageStatus: ${await this.storage.getStatusString()}`,
            {isEventLogHigherPriority: true}
          );
        } catch (error) {
          // ignore
        }
        await this.storage.set(STORAGE_KEY, oldFileAccessUtil.className);
      } else {
        this.loggingService.infoWithEvent(
          LOG_SOURCE,
          'changeFileAccessUtil',
          `called without changing the fileAccessUtilClassName ("${newFileAccessUtilClassName}"). Current one and the one in the storage match.`,
          {isEventLogHigherPriority: true}
        );
      }
      if (oldFileAccessUsedDefault) {
        this.fileAccessUtilSubject.next({fileAccessUtil: oldFileAccessUtil, usedDefault: false});
      }
      return {oldFileAccessUtil};
    }

    if (!this.isFileAccessUtilSupported(newFileAccessUtilClassName)) {
      const errorMessage = `newFileAccessUtilClassName ${newFileAccessUtilClassName} is not supported on this device.`;
      this.loggingService.errorWithEvent(LOG_SOURCE, 'changeFileAccessUtil', errorMessage);
      throw new Error(errorMessage);
    }
    const newFileAccessUtil = this.getFileAccessUtilByClassName(newFileAccessUtilClassName);
    await this.storage.set(STORAGE_KEY, newFileAccessUtil.className);
    this.loggingService.infoWithEvent(LOG_SOURCE, 'changeFileAccessUtil', `Changing storage from "${oldFileAccessUtil?.className}"  to "${newFileAccessUtil?.className}")`, {
      isEventLogHigherPriority: true,
    });
    this.fileAccessUtilSubject.next({fileAccessUtil: newFileAccessUtil, usedDefault: false});
    return {oldFileAccessUtil, newFileAccessUtil};
  }

  private getFileAccessUtilByClassName(fileAccessUtilClassName: FileAccessUtilClassName): AbstractFileAccessUtil {
    switch (fileAccessUtilClassName) {
      case 'CacheStorageFileAccessUtil':
        return this.cacheStorageFileAccessUtil;
      case 'FilesystemFileAccessUtil':
        return this.filesystemFileAccessUtil;
      default:
        return this.cacheStorageFileAccessUtil;
    }
  }

  private async initFileAccessUtilSubject() {
    const fileAccessUtilWithDefault = await this.getFileAccessUtilFromStorageOrDefault();
    this.fileAccessUtilSubject.next(fileAccessUtilWithDefault);
  }
}
