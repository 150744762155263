import {Injectable} from '@angular/core';
import {AttachmentKey, ClientAwareKey, IdType, NonClientAwareKey, ProjectAwareKey} from 'submodules/baumaster-v2-common';
import {AbstractClientAwareDataService} from './abstract-client-aware-data.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';
import {ProtocolDataService} from './protocol-data.service';
import {ProtocolEntryDataService} from './protocol-entry-data.service';
import {ProtocolOpenEntryDataService} from './protocol-open-entry-data.service';
import {AttachmentProjectDataService} from './attachment-project-data.service';
import {AttachmentEntryDataService} from './attachment-entry-data.service';
import {AttachmentChatDataService} from './attachment-chat-data.service';
import {CompanyCraftDataService} from './company-craft-data.service';
import {CompanyDataService} from './company-data.service';
import {ClientDataService} from './client-data.service';
import {ProtocolTypeDataService} from './protocol-type-data.service';
import {ProtocolEntryLocationDataService} from './protocol-entry-location-data.service';
import {ProtocolEntryTypeDataService} from './protocol-entry-type-data.service';
import {PdfPlanMarkerProtocolEntryDataService} from './pdf-plan-marker-protocol-entry-data.service';
import {ProtocolEntryChatDataService} from './protocol-entry-chat-data.service';
import {ProtocolLayoutDataService} from './protocol-layout-data.service';
import {ProfileDataService} from './profile-data.service';
import {AddressDataService} from './address-data.service';
import {UserDataService} from './user-data.service';
import {UserPublicDataService} from './user-public-data.service';
import {UserOfflineProjectDataService} from './user-offline-project.data.service';
import {CraftDataService} from './craft-data.service';
import {ProjectCompanyDataService} from './project-company-data.service';
import {ProjectCraftDataService} from './project-craft-data.service';
import {ProjectProfileDataService} from './project-profile-data.service';
import {ProjectProtocolEntryTypeDataService} from './project-protocol-entry-type-data.service';
import {ProjectProtocolLocationDataService} from './project-protocol-location-data.service';
import {ProjectProtocolTypeDataService} from './project-protocol-type-data.service';
import {NameableDropdownDataService} from './nameable-dropdown-data.service';
import {NameableDropdownItemDataService} from './nameable-dropdown-item-data.service';
import {PdfPlanPageDataService} from './pdf-plan-page-data.service';
import {PdfPlanFolderDataService} from './pdf-plan-folder-data.service';
import {PdfPlanDataService} from './pdf-plan-data.service';
import {PdfPlanVersionDataService} from './pdf-plan-version-data.service';
import {AbstractProjectAwareAttachmentDataService} from './abstract-project-aware-attachment-data.service';
import {ProfileCraftDataService} from './profile-craft-data.service';
import {ParticipantDataService} from './participant-data.service';
import {CustomPdfConfigurationDataService} from './custom-pdf-configuration-data.service';
import {PdfProtocolSettingDataService} from './pdf-protocol-setting-data.service';
import {PdfPreviewDataService} from './pdf-preview-data.service';
import {PdfPlanAttachmentDataService} from './pdf-plan-attachment-data.service';
import {CountryDataService} from './country-data.service';
import {AttachmentClientDataService} from './attachment-client-data.service';
import {AttachmentProjectImageDataService} from './attachment-project-image-data.service';
import {ConstructionScheduleTaskDataService} from './construction-schedule-task-data.service';
import {ConstructionScheduleLinkDataService} from './construction-schedule-link-data.service';
import {AbstractNonClientAwareDataService} from './abstract-non-client-aware-data.service';
import {ActivityDataService} from './activity-data.service';
import {EquipmentDataService} from './equipment-data.service';
import {MaterialDataService} from './material-data.service';
import {ReportDataService} from './report-data.service';
import {ReportActivityDataService} from './report-activity-data.service';
import {ReportEmployeeDataService} from './report-employee-data.service';
import {ReportMachineDataService} from './report-machine-data.service';
import {ReportMaterialDataService} from './report-material-data.service';
import {ReportSupplierDataService} from './report-supplier-data.service';
import {ReportWeekDataService} from './report-week-data.service';
import {AttachmentReportActivityDataService} from './attachment-report-activity-data.service';
import {AttachmentReportEquipmentDataService} from './attachment-report-equipment-data.service';
import {AttachmentReportMaterialDataService} from './attachment-report-material-data.service';
import {ReportTypeDataService} from './report-type-data.service';
import {CustomReportTypeDataService} from './custom-report-type-data.service';
import {ProjectCustomReportTypeDataService} from './project-custom-report-type-data.service';
import {AttachmentReportCompanyDataService} from './attachment-report-company-data.service';
import {ReportCompanyDataService} from './report-company-data.service';
import {ReportCompanyActivityDataService} from './report-company-activity-data.service';
import {UserInviteDataService} from './user-invite-data.service';
import {UserConnectionInviteDataService} from './user-connection-invite-data.service';
import {AbstractDataService} from './abstract-data.service';
import {StaffingTypeDataService} from './staffing-type-data.service';
import {StaffDataService} from './staff-data.service';
import {AdditionalPayTypeDataService} from './additional-pay-type-data.service';
import {EmployeeDataService} from './employee-data.service';
import {AttachmentReportSignatureDataService} from './attachment-report-signature-data.service';
import {AttachmentProtocolSignatureDataService} from './attachment-protocol-signature-data.service';
import {ProtocolEntryCompanyDataService} from './protocol-entry-company-data.service';
import {UserEmailSignatureDataService} from './user-email-signature-data.service';
import {PdfPlanPageMarkingDataService} from './pdf-plan-page-marking-data.service';
import {AttachmentUserEmailSignatureDataService} from './attachment-user-email-signature-data.service';
import {TagClientDataService} from './tag-client-data.service';
import {TagClientObjectDataService} from './tag-client-object-data.service';
import {ShareVersionsEmailSettingsTemplateDataService} from './share-versions-email-settings-template-data.service';
import {PdfPlanVersionAccessDataService} from './pdf-plan-version-access-data.service';
import {AppTokenDataService} from './app-token-data.service';
import {AppTokenPermissionDataService} from './app-token-permission-data.service';
import {CalendarDataService} from './calendar-data.service';
import {CalendarDayDataService} from './calendar-day-data.service';
import {ClientCalendarDataService} from './client-calendar-data.service';
import {ProjectCalendarDayDataService} from './project-calendar-day-data.service';
import {ProjectCalendarDataService} from './project-calendar-data.service';
import {ProjectClientCalendarDataService} from './project-client-calendar-data.service';
import {ClientCalendarDayDataService} from './client-calendar-day-data.service';
import {TextTemplateDataService} from './text-template-data.service';
import {ClientTextTemplateDataService} from './client-text-template-data.service';
import {BimMarkerDataService} from './bim-marker-data.service';
import {AttachmentBimMarkerScreenshotDataService} from './attachment-bim-marker-screenshot-data.service';
import {BimPlanDataService} from './bim-plan-data.service';
import {BimVersionDataService} from './bim-version-data.service';
import {BimVersionAccessDataService} from './bim-version-access-data.service';
import {AttachmentProjectBannerDataService} from './attachment-project-banner-data.service';
import {PdfPlanVersionDistributionAccessDataService} from './pdf-plan-version-distribution-access-data.service';
import {UserDeviceOfflineProjectDataService} from './user-device-offline-project.data.service';
import {LoggingService} from '../common/logging.service';
import {combineLatest, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, shareReplay} from 'rxjs/operators';
import _ from 'lodash';
import {NotificationEventDataService} from './notification-event-data.service';
import {NotificationConfigRecipientDataService} from './notification-config-recipient-data.service';
import {NotificationConfigDataService} from './notification-config-data.service';
import {NotificationEventRecipientDataService} from './notification-event-recipient-data.service';
import {ProtocolEntryDefaultValueDataService} from './protocol-entry-default-value-data.service';
import {UnitLevelDataService} from './unit-level-data.service';
import {UnitDataService} from './unit-data.service';
import {UnitProfileDataService} from './unit-profile-data.service';
import {PdfReportSettingDataService} from './pdf-report-setting-data.service';

const LOG_SOURCE = 'DataServiceFactoryService';

@Injectable({
  providedIn: 'root',
})
export class DataServiceFactoryService {
  public readonly nonClientAwareServices: {[key in NonClientAwareKey]: AbstractNonClientAwareDataService<any>};
  public readonly clientAwareServices: {[key in ClientAwareKey]: AbstractClientAwareDataService<any>};
  public readonly projectAwareServices: {[key in ProjectAwareKey]: AbstractProjectAwareDataService<any>};
  public readonly attachmentServices: {[key in AttachmentKey]: AbstractProjectAwareAttachmentDataService<any>};
  public readonly allServices: {[key in ProjectAwareKey | ClientAwareKey | NonClientAwareKey]: AbstractDataService<any>};

  public readonly nonClientStorageInitialized$: Observable<boolean>;
  public readonly clientIsStorageInitialized$: Observable<Set<IdType>>;
  public readonly projectIsStorageInitialized$: Observable<Set<IdType>>;

  constructor(
    private loggingService: LoggingService,
    private projectDataService: ProjectDataService,
    private protocolDataService: ProtocolDataService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolOpenEntryDataService: ProtocolOpenEntryDataService,
    private attachmentProjectDataService: AttachmentProjectDataService,
    private attachmentEntryDataService: AttachmentEntryDataService,
    private attachmentChatDataService: AttachmentChatDataService,
    private companyCraftDataService: CompanyCraftDataService,
    private companyDataService: CompanyDataService,
    private clientDataService: ClientDataService,
    private protocolTypeDataService: ProtocolTypeDataService,
    private protocolEntryLocationDataService: ProtocolEntryLocationDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
    private protocolEntryChatDataService: ProtocolEntryChatDataService,
    private protocolLayoutDataService: ProtocolLayoutDataService,
    private profileDataService: ProfileDataService,
    private profileCraftDataService: ProfileCraftDataService,
    private addressDataService: AddressDataService,
    private userDataService: UserDataService,
    private userPublicDataService: UserPublicDataService,
    private userOfflineProjectDataService: UserOfflineProjectDataService,
    private craftDataService: CraftDataService,
    private projectCompaniesDataService: ProjectCompanyDataService,
    private projectCraftDataService: ProjectCraftDataService,
    private projectProfileDataService: ProjectProfileDataService,
    private projectProtocolEntryTypeDataService: ProjectProtocolEntryTypeDataService,
    private projectProtocolLocationDataService: ProjectProtocolLocationDataService,
    private projectProtocolTypeDataService: ProjectProtocolTypeDataService,
    private projectProtocolNameableDropdownDataService: NameableDropdownDataService,
    private projectProtocolNameableDropdownItemDataService: NameableDropdownItemDataService,
    private pdfPlanAttachmentDataService: PdfPlanAttachmentDataService,
    private pdfPlanPageDataService: PdfPlanPageDataService,
    private pdfPlanFolderDataService: PdfPlanFolderDataService,
    private pdfPlanDataService: PdfPlanDataService,
    private pdfPlanVersionDataService: PdfPlanVersionDataService,
    private participantDataService: ParticipantDataService,
    private customPdfConfigurationDataService: CustomPdfConfigurationDataService,
    private pdfProtocolSettingDataService: PdfProtocolSettingDataService,
    private pdfReportSettingDataService: PdfReportSettingDataService,
    private pdfPreviewDataService: PdfPreviewDataService,
    private countryDataService: CountryDataService,
    private attachmentClientDataService: AttachmentClientDataService,
    private attachmentProjectImageDataService: AttachmentProjectImageDataService,
    private constructionScheduleTaskDataService: ConstructionScheduleTaskDataService,
    private constructionScheduleLinkDataService: ConstructionScheduleLinkDataService,
    private activityDataService: ActivityDataService,
    private equipmentDataService: EquipmentDataService,
    private materialDataService: MaterialDataService,
    private reportDataService: ReportDataService,
    private reportActivityDataService: ReportActivityDataService,
    private reportEmployeeDataService: ReportEmployeeDataService,
    private reportMachineDataService: ReportMachineDataService,
    private reportMaterialDataService: ReportMaterialDataService,
    private reportSupplierDataService: ReportSupplierDataService,
    private reportWeekDataService: ReportWeekDataService,
    private attachmentReportActivityDataService: AttachmentReportActivityDataService,
    private attachmentReportEquipmentDataService: AttachmentReportEquipmentDataService,
    private attachmentReportMaterialDataService: AttachmentReportMaterialDataService,
    private reportTypeDataService: ReportTypeDataService,
    private customReportTypeDataService: CustomReportTypeDataService,
    private projectCustomReportTypeDataService: ProjectCustomReportTypeDataService,
    private userInviteDataService: UserInviteDataService,
    private userConnectionInviteDataService: UserConnectionInviteDataService,
    private attachmentReportCompanyDataService: AttachmentReportCompanyDataService,
    private attachmentReportSignatureDataService: AttachmentReportSignatureDataService,
    private attachmentProtocolSignatureDataService: AttachmentProtocolSignatureDataService,
    private reportCompanyDataService: ReportCompanyDataService,
    private reportCompanyActivityDataService: ReportCompanyActivityDataService,
    private staffingTypeDataService: StaffingTypeDataService,
    private staffDataService: StaffDataService,
    private additionalPayTypeDataService: AdditionalPayTypeDataService,
    private employeeDataService: EmployeeDataService,
    private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
    private userEmailSignatureDataService: UserEmailSignatureDataService,
    private pdfPlanPageMarkingDataService: PdfPlanPageMarkingDataService,
    private attachmentUserEmailSignatureDataService: AttachmentUserEmailSignatureDataService,
    private tagClientDataService: TagClientDataService,
    private tagClientObjectDataService: TagClientObjectDataService,
    private shareVersionsEmailSettingsTemplateDataService: ShareVersionsEmailSettingsTemplateDataService,
    private pdfPlanVersionAccessDataService: PdfPlanVersionAccessDataService,
    private pdfPlanVersionDistributionAccessDataService: PdfPlanVersionDistributionAccessDataService,
    private appTokenDataService: AppTokenDataService,
    private appTokenPermissionDataService: AppTokenPermissionDataService,
    private calendarDataService: CalendarDataService,
    private calendarDayDataService: CalendarDayDataService,
    private clientCalendarDataService: ClientCalendarDataService,
    private clientCalendarDayDataService: ClientCalendarDayDataService,
    private projectCalendarDataService: ProjectCalendarDataService,
    private projectCalendarDayDataService: ProjectCalendarDayDataService,
    private projectClientCalendarDataService: ProjectClientCalendarDataService,
    private textTemplateDataService: TextTemplateDataService,
    private clientTextTemplateDataService: ClientTextTemplateDataService,
    private bimMarkerDataService: BimMarkerDataService,
    private attachmentBimMarkerScreenshotDataService: AttachmentBimMarkerScreenshotDataService,
    private bimPlanDataService: BimPlanDataService,
    private bimVersionDataService: BimVersionDataService,
    private bimVersionAccessDataService: BimVersionAccessDataService,
    private attachmentProjectBannerDataService: AttachmentProjectBannerDataService,
    private userDeviceOfflineProjectDataService: UserDeviceOfflineProjectDataService,
    private notificationEventDataService: NotificationEventDataService,
    private notificationEventRecipientDataService: NotificationEventRecipientDataService,
    private notificationConfigDataService: NotificationConfigDataService,
    private notificationConfigRecipientDataService: NotificationConfigRecipientDataService,
    private protocolEntryDefaultValueDataService: ProtocolEntryDefaultValueDataService,
    private unitLevelDataService: UnitLevelDataService,
    private unitDataService: UnitDataService,
    private unitProfileDataService: UnitProfileDataService
  ) {
    this.nonClientAwareServices = {
      clients: this.clientDataService,
      users: this.userDataService,
      attachmentClients: this.attachmentClientDataService,
      reportTypes: this.reportTypeDataService,
      userEmailSignatures: this.userEmailSignatureDataService,
      attachmentUserEmailSignatures: this.attachmentUserEmailSignatureDataService,
      calendars: this.calendarDataService,
      calendarDays: this.calendarDayDataService,
      textTemplates: this.textTemplateDataService,
      notificationEvents: this.notificationEventDataService,
      notificationEventRecipients: this.notificationEventRecipientDataService,
      notificationConfigs: this.notificationConfigDataService,
      notificationConfigRecipients: this.notificationConfigRecipientDataService,
    };
    this.clientAwareServices = {
      projects: this.projectDataService,
      addresses: this.addressDataService,
      companies: this.companyDataService,
      companyCrafts: this.companyCraftDataService,
      crafts: this.craftDataService,
      profiles: this.profileDataService,
      profileCrafts: this.profileCraftDataService,
      protocolEntryLocations: this.protocolEntryLocationDataService,
      protocolEntryTypes: this.protocolEntryTypeDataService,
      protocolLayouts: this.protocolLayoutDataService,
      protocolTypes: this.protocolTypeDataService,
      usersPublic: this.userPublicDataService,
      userOfflineProjects: this.userOfflineProjectDataService,
      protocolNameableDropdowns: this.projectProtocolNameableDropdownDataService,
      customPdfConfigurations: this.customPdfConfigurationDataService,
      countries: this.countryDataService,
      attachmentProjectImages: this.attachmentProjectImageDataService,
      customReportTypes: this.customReportTypeDataService,
      userInvites: this.userInviteDataService,
      userConnectionInvites: this.userConnectionInviteDataService,
      staffingTypes: this.staffingTypeDataService,
      additionalPayTypes: this.additionalPayTypeDataService,
      tagClients: this.tagClientDataService,
      tagClientObjects: this.tagClientObjectDataService,
      appTokens: this.appTokenDataService,
      appTokenPermissions: this.appTokenPermissionDataService,
      clientCalendars: this.clientCalendarDataService,
      clientCalendarDays: this.clientCalendarDayDataService,
      clientTextTemplates: this.clientTextTemplateDataService,
      userDeviceOfflineProjects: this.userDeviceOfflineProjectDataService,
    };
    this.projectAwareServices = {
      attachmentChats: this.attachmentChatDataService,
      attachmentProjects: this.attachmentProjectDataService,
      attachmentProjectBanners: this.attachmentProjectBannerDataService,
      attachmentProtocolEntries: this.attachmentEntryDataService,
      pdfPlanMarkerProtocolEntries: this.pdfPlanMarkerProtocolEntryDataService,
      protocols: this.protocolDataService,
      protocolEntryChats: this.protocolEntryChatDataService,
      protocolEntries: this.protocolEntryDataService,
      protocolOpenEntries: this.protocolOpenEntryDataService,
      projectCompanies: this.projectCompaniesDataService,
      projectCrafts: this.projectCraftDataService,
      projectProfiles: this.projectProfileDataService,
      projectProtocolEntryTypes: this.projectProtocolEntryTypeDataService,
      projectProtocolLocations: this.projectProtocolLocationDataService,
      projectProtocolTypes: this.projectProtocolTypeDataService,
      projectProtocolNameableDropdownItems: this.projectProtocolNameableDropdownItemDataService,
      pdfPlanAttachments: this.pdfPlanAttachmentDataService,
      pdfPlanPages: this.pdfPlanPageDataService,
      pdfPlanFolders: this.pdfPlanFolderDataService,
      pdfPlans: this.pdfPlanDataService,
      pdfPlanVersions: this.pdfPlanVersionDataService,
      pdfProtocolSettings: this.pdfProtocolSettingDataService,
      pdfReportSettings: this.pdfReportSettingDataService,
      pdfPreviews: this.pdfPreviewDataService,
      constructionScheduleTasks: this.constructionScheduleTaskDataService,
      constructionScheduleLinks: this.constructionScheduleLinkDataService,
      activities: this.activityDataService,
      equipments: this.equipmentDataService,
      materials: this.materialDataService,
      reports: this.reportDataService,
      reportActivities: this.reportActivityDataService,
      reportEmployees: this.reportEmployeeDataService,
      reportMachines: this.reportMachineDataService,
      reportMaterials: this.reportMaterialDataService,
      reportSuppliers: this.reportSupplierDataService,
      reportWeeks: this.reportWeekDataService,
      reportCompanies: this.reportCompanyDataService,
      reportCompanyActivities: this.reportCompanyActivityDataService,
      projectCustomReportTypes: this.projectCustomReportTypeDataService,
      attachmentReportActivities: this.attachmentReportActivityDataService,
      attachmentReportEquipments: this.attachmentReportEquipmentDataService,
      attachmentReportMaterials: this.attachmentReportMaterialDataService,
      attachmentReportCompanies: this.attachmentReportCompanyDataService,
      attachmentReportSignatures: this.attachmentReportSignatureDataService,
      attachmentProtocolSignatures: this.attachmentProtocolSignatureDataService,
      participants: this.participantDataService,
      staffs: this.staffDataService,
      employees: this.employeeDataService,
      protocolEntryCompanies: this.protocolEntryCompanyDataService,
      pdfPlanPageMarkings: this.pdfPlanPageMarkingDataService,
      shareVersionsEmailSettingsTemplates: this.shareVersionsEmailSettingsTemplateDataService,
      pdfPlanVersionAccesses: this.pdfPlanVersionAccessDataService,
      pdfPlanVersionDistributionAccesses: this.pdfPlanVersionDistributionAccessDataService,
      projectCalendars: this.projectCalendarDataService,
      projectCalendarDays: this.projectCalendarDayDataService,
      projectClientCalendars: this.projectClientCalendarDataService,
      bimMarkers: this.bimMarkerDataService,
      attachmentBimMarkerScreenshots: this.attachmentBimMarkerScreenshotDataService,
      bimPlans: this.bimPlanDataService,
      bimVersions: this.bimVersionDataService,
      bimVersionAccesses: this.bimVersionAccessDataService,
      protocolEntryDefaultValues: this.protocolEntryDefaultValueDataService,
      unitLevels: this.unitLevelDataService,
      units: this.unitDataService,
      unitProfiles: this.unitProfileDataService,
    };
    this.attachmentServices = {
      attachmentChats: this.attachmentChatDataService,
      attachmentProjects: this.attachmentProjectDataService,
      attachmentProjectBanners: this.attachmentProjectBannerDataService,
      attachmentProtocolEntries: this.attachmentEntryDataService,
      pdfPlanPages: this.pdfPlanPageDataService,
      attachmentReportActivities: this.attachmentReportActivityDataService,
      attachmentReportEquipments: this.attachmentReportEquipmentDataService,
      attachmentReportMaterials: this.attachmentReportMaterialDataService,
      attachmentReportCompanies: this.attachmentReportCompanyDataService,
      attachmentReportSignatures: this.attachmentReportSignatureDataService,
      attachmentProtocolSignatures: this.attachmentProtocolSignatureDataService,
      attachmentBimMarkerScreenshots: this.attachmentBimMarkerScreenshotDataService,
    };
    this.allServices = {
      projects: this.projectDataService,
      addresses: this.addressDataService,
      companies: this.companyDataService,
      companyCrafts: this.companyCraftDataService,
      profileCrafts: this.profileCraftDataService,
      crafts: this.craftDataService,
      clients: this.clientDataService,
      profiles: this.profileDataService,
      protocolEntryLocations: this.protocolEntryLocationDataService,
      protocolEntryTypes: this.protocolEntryTypeDataService,
      protocolLayouts: this.protocolLayoutDataService,
      protocolTypes: this.protocolTypeDataService,
      users: this.userDataService,
      usersPublic: this.userPublicDataService,
      userOfflineProjects: this.userOfflineProjectDataService,
      attachmentChats: this.attachmentChatDataService,
      attachmentProjects: this.attachmentProjectDataService,
      attachmentProjectBanners: this.attachmentProjectBannerDataService,
      attachmentProtocolEntries: this.attachmentEntryDataService,
      pdfPlanMarkerProtocolEntries: this.pdfPlanMarkerProtocolEntryDataService,
      protocols: this.protocolDataService,
      protocolEntryChats: this.protocolEntryChatDataService,
      protocolEntries: this.protocolEntryDataService,
      protocolOpenEntries: this.protocolOpenEntryDataService,
      projectCompanies: this.projectCompaniesDataService,
      projectCrafts: this.projectCraftDataService,
      projectProfiles: this.projectProfileDataService,
      projectProtocolEntryTypes: this.projectProtocolEntryTypeDataService,
      projectProtocolLocations: this.projectProtocolLocationDataService,
      projectProtocolTypes: this.projectProtocolTypeDataService,
      protocolNameableDropdowns: this.projectProtocolNameableDropdownDataService,
      projectProtocolNameableDropdownItems: this.projectProtocolNameableDropdownItemDataService,
      pdfPlanAttachments: this.pdfPlanAttachmentDataService,
      pdfPlanPages: this.pdfPlanPageDataService,
      pdfPlanFolders: this.pdfPlanFolderDataService,
      pdfPlans: this.pdfPlanDataService,
      pdfPlanVersions: this.pdfPlanVersionDataService,
      customPdfConfigurations: this.customPdfConfigurationDataService,
      pdfProtocolSettings: this.pdfProtocolSettingDataService,
      pdfReportSettings: this.pdfReportSettingDataService,
      pdfPreviews: this.pdfPreviewDataService,
      participants: this.participantDataService,
      countries: this.countryDataService,
      attachmentClients: this.attachmentClientDataService,
      attachmentProjectImages: this.attachmentProjectImageDataService,
      constructionScheduleTasks: this.constructionScheduleTaskDataService,
      constructionScheduleLinks: this.constructionScheduleLinkDataService,
      activities: this.activityDataService,
      equipments: this.equipmentDataService,
      materials: this.materialDataService,
      reports: this.reportDataService,
      reportActivities: this.reportActivityDataService,
      reportEmployees: this.reportEmployeeDataService,
      reportMachines: this.reportMachineDataService,
      reportMaterials: this.reportMaterialDataService,
      reportSuppliers: this.reportSupplierDataService,
      reportWeeks: this.reportWeekDataService,
      reportTypes: this.reportTypeDataService,
      reportCompanies: this.reportCompanyDataService,
      reportCompanyActivities: this.reportCompanyActivityDataService,
      customReportTypes: this.customReportTypeDataService,
      projectCustomReportTypes: this.projectCustomReportTypeDataService,
      attachmentReportActivities: this.attachmentReportActivityDataService,
      attachmentReportEquipments: this.attachmentReportEquipmentDataService,
      attachmentReportMaterials: this.attachmentReportMaterialDataService,
      attachmentReportCompanies: this.attachmentReportCompanyDataService,
      attachmentReportSignatures: this.attachmentReportSignatureDataService,
      attachmentProtocolSignatures: this.attachmentProtocolSignatureDataService,
      userInvites: this.userInviteDataService,
      userConnectionInvites: this.userConnectionInviteDataService,
      staffingTypes: this.staffingTypeDataService,
      staffs: this.staffDataService,
      additionalPayTypes: this.additionalPayTypeDataService,
      employees: this.employeeDataService,
      protocolEntryCompanies: this.protocolEntryCompanyDataService,
      userEmailSignatures: this.userEmailSignatureDataService,
      pdfPlanPageMarkings: this.pdfPlanPageMarkingDataService,
      attachmentUserEmailSignatures: this.attachmentUserEmailSignatureDataService,
      tagClients: this.tagClientDataService,
      tagClientObjects: this.tagClientObjectDataService,
      shareVersionsEmailSettingsTemplates: this.shareVersionsEmailSettingsTemplateDataService,
      pdfPlanVersionAccesses: this.pdfPlanVersionAccessDataService,
      pdfPlanVersionDistributionAccesses: this.pdfPlanVersionDistributionAccessDataService,
      appTokens: this.appTokenDataService,
      appTokenPermissions: this.appTokenPermissionDataService,
      calendars: this.calendarDataService,
      calendarDays: this.calendarDayDataService,
      clientCalendars: this.clientCalendarDataService,
      clientCalendarDays: this.clientCalendarDayDataService,
      projectCalendars: this.projectCalendarDataService,
      projectCalendarDays: this.projectCalendarDayDataService,
      projectClientCalendars: this.projectClientCalendarDataService,
      textTemplates: this.textTemplateDataService,
      clientTextTemplates: this.clientTextTemplateDataService,
      bimMarkers: this.bimMarkerDataService,
      attachmentBimMarkerScreenshots: this.attachmentBimMarkerScreenshotDataService,
      bimPlans: this.bimPlanDataService,
      bimVersions: this.bimVersionDataService,
      bimVersionAccesses: this.bimVersionAccessDataService,
      userDeviceOfflineProjects: this.userDeviceOfflineProjectDataService,
      notificationEvents: this.notificationEventDataService,
      notificationEventRecipients: this.notificationEventRecipientDataService,
      notificationConfigs: this.notificationConfigDataService,
      notificationConfigRecipients: this.notificationConfigRecipientDataService,
      protocolEntryDefaultValues: this.protocolEntryDefaultValueDataService,
      unitLevels: this.unitLevelDataService,
      units: this.unitDataService,
      unitProfiles: this.unitProfileDataService,
    };

    this.nonClientStorageInitialized$ = combineLatest(
      Object.values(this.nonClientAwareServices)
        .filter((s) => s.hasCurrentUserPermission || s.hasCurrentUserPermission === undefined)
        .map((service) => service.storageInitializedWithOptional$)
    )
      .pipe(debounceTime(0))
      .pipe(map((nonClientIdsSet) => nonClientIdsSet.every((initialized) => initialized.optional || initialized.initializedMap.has(null))))
      .pipe(distinctUntilChanged(_.isEqual))
      .pipe(shareReplay({refCount: true, bufferSize: 1}));

    this.clientIsStorageInitialized$ = combineLatest(
      Object.values(this.clientAwareServices)
        .filter((s) => s.hasCurrentUserPermission || s.hasCurrentUserPermission === undefined)
        .map((service) => service.storageInitializedWithOptional$)
    )
      .pipe(debounceTime(0))
      .pipe(map((projectIdsSets) => _.uniq(_.intersection(...projectIdsSets.filter((v) => !v.optional).map((initialized) => Array.from(initialized.initializedMap).sort())))))
      .pipe(distinctUntilChanged(_.isEqual))
      .pipe(map((array) => new Set<IdType>(array)))
      .pipe(shareReplay({refCount: true, bufferSize: 1}));

    this.projectIsStorageInitialized$ = combineLatest(
      Object.values(this.projectAwareServices)
        .filter((s) => s.hasCurrentUserPermission || s.hasCurrentUserPermission === undefined)
        .map((service) => service.storageInitializedWithOptional$)
    )
      .pipe(debounceTime(0))
      .pipe(map((projectIdsSets) => _.uniq(_.intersection(...projectIdsSets.filter((v) => !v.optional).map((initialized) => Array.from(initialized.initializedMap).sort())))))
      .pipe(distinctUntilChanged(_.isEqual))
      .pipe(map((array) => new Set<IdType>(array)))
      .pipe(shareReplay({refCount: true, bufferSize: 1}));
  }

  public isNonClientAwareStorageDataInitialized(): boolean {
    for (const key of Object.keys(this.nonClientAwareServices)) {
      const service: AbstractNonClientAwareDataService<any> = this.nonClientAwareServices[key];
      if (!service.isStorageInitializedOptional && !service.isStorageInitialized()) {
        return false;
      }
    }
    return true;
  }

  public isClientAwareStorageDataInitialized(clientId: IdType): boolean {
    for (const key of Object.keys(this.clientAwareServices)) {
      const service: AbstractClientAwareDataService<any> = this.clientAwareServices[key];
      if (!service.isStorageInitializedOptional && !service.isStorageInitialized(clientId)) {
        return false;
      }
    }
    return true;
  }

  public isProjectAwareStorageDataInitialized(projectId: IdType): boolean {
    const storageKeysNotInitialized = new Array<string>();
    for (const key of Object.keys(this.projectAwareServices)) {
      const service: AbstractProjectAwareDataService<any> = this.projectAwareServices[key];
      if ((service.hasCurrentUserPermission === true || service.hasCurrentUserPermission === undefined) && !service.isStorageInitializedOptional && !service.isStorageInitialized(projectId)) {
        storageKeysNotInitialized.push(key);
      }
    }
    if (!storageKeysNotInitialized.length) {
      return true;
    }
    if (this.loggingService.isDebugEnabled()) {
      this.loggingService.debug(LOG_SOURCE, `The following storages are not initialized for project ${projectId}: ${storageKeysNotInitialized}`);
    }
    return false;
  }

  public isClientAwareStorageDataInitialized$(clientId: IdType): Observable<boolean> {
    return this.clientIsStorageInitialized$.pipe(map((clientIds) => clientIds.has(clientId)));
  }

  public isProjectAwareStorageDataInitialized$(projectId: IdType): Observable<boolean> {
    return this.projectIsStorageInitialized$.pipe(map((projectIds) => projectIds.has(projectId)));
  }
}
