import {PdfProtocolLayout} from '../pdf/protocol/pdfProtocolEnums';
import {ColumnConfig} from '../ColumnConfig';

const SingleSettingForProtocolTypeInProjectUniq = ['project_id', 'protocoltype_id'];
const SingleSettingForUserUniq = ['user_id'];

export const PdfProtocolSettingColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'layout_type', prop: 'layoutType', def: PdfProtocolLayout.COMPACT},
  {name: 'pdfstarttext', prop: 'pdfStartText'},
  {name: 'pdfendtext', prop: 'pdfEndText'},
  {name: 'mailtext', prop: 'mailText'},
  {name: 'mailsubject', prop: 'mailSubject'},
  {name: 'project_id', prop: 'projectId', def: null, uniqueWith: [SingleSettingForProtocolTypeInProjectUniq]},
  {name: 'protocoltype_id', prop: 'protocolTypeId', uniqueWith: [SingleSettingForProtocolTypeInProjectUniq]},
  {name: 'additional_text', prop: 'additionalText'},
  {name: 'client_show_address', prop: 'clientShowAddress'},
  {name: 'hide_transferred_entries', prop: 'hideTransferredEntries'},
  {name: 'protocol_color', prop: 'protocolColor'},
  {name: 'show_all_contacts_in_project', prop: 'showAllContactsInProject'},
  {name: 'show_unit_contacts', prop: 'showUnitContacts'},
  {name: 'show_entry_comments', prop: 'showEntryComments'},
  {name: 'show_entry_cost', prop: 'showEntryCost'},
  {name: 'show_pdfplan_marker', prop: 'showPdfPlanMarker'},
  {name: 'show_pictures', prop: 'showPictures'},
  {name: 'show_status_bar', prop: 'showStatusBar'},
  {name: 'start_new_page_for_entry', prop: 'startNewPageForEntry'},
  {name: 'show_attachment_dl_link', prop: 'showAttachmentDlLink'},
  {name: 'show_entry_comment_pictures', prop: 'showEntryCommentPictures'},
  {name: 'show_only_open_entries', prop: 'showOnlyOpenEntries'},
  {name: 'is_search_pdf', prop: 'isSearchPdf'},
  {name: 'print_entry_details', prop: 'printEntryDetails', def: null, cast: 'Jsonb'},
  {name: 'show_status_as_checkbox', prop: 'showStatusAsCheckbox', def: null},
  {name: 'highlight_entry_titles', prop: 'highlightEntryTitles', def: null},
  {name: 'more_space_between_entries', prop: 'moreSpaceBetweenEntries', def: null},
  {name: 'append_carried_over_entries', prop: 'appendCarriedOverEntries', def: null},
  {name: 'hide_main_entry', prop: 'hideMainEntry', def: null},
  {name: 'print_filtered_entries_only', prop: 'printFilteredEntriesOnly', def: null},
  {name: 'individual_protocol', prop: 'individualProtocol', def: null},
  {name: 'compact_contact_list', prop: 'compactContactList', def: null},
  {name: 'user_id', prop: 'userId', def: null, uniqueWith: [SingleSettingForUserUniq]},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'ignore_missing_attachments', prop: 'ignoreMissingAttachments', def: null},
  {name: 'add_signature', prop: 'addSignature', def: null},
  {name: 'mail_text_under_download_link', prop: 'mailTextUnderDownloadLink', def: null},
  {name: 'show_participant_col', prop: 'showParticipantCol', def: null},
  {name: 'hide_description', prop: 'hideDescription', def: null},
  {name: 'every_entry_on_new_page', prop: 'everyEntryOnNewPage', def: null},
  {name: 'no_split_entry_info', prop: 'noSplitEntryInfo', def: null},
  {name: 'show_weather', prop: 'showWeather', def: null},
  {name: 'show_bim_marker', prop: 'showBimMarker', def: null},
  {name: 'use_new_layout', prop: 'useNewLayout', def: null},
  {name: 'show_header_banner_on_every_page', prop: 'showHeaderBannerOnEveryPage', def: null},
  {name: 'show_footer_banner_on_every_page', prop: 'showFooterBannerOnEveryPage', def: null},
];
