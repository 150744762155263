import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {map, Observable, startWith, Subject, Subscription, takeUntil} from 'rxjs';
import {LoggingService} from 'src/app/services/common/logging.service';
import {IonicSelectableComponent} from 'ionic-selectable';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../../services/common/selectable-util.service';
import {ProtocolEntryPriorityPopoverComponent} from '../../protocol-entry-priority-popover/protocol-entry-priority-popover.component';
import {ProjectCompanyDataService} from 'src/app/services/data/project-company-data.service';
import {PopoverController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {MobiscrollService} from 'src/app/services/common/mobiscroll.service';
import {ThemeService} from 'src/app/services/ui/theme.service';
import {MbscDatepicker} from '@mobiscroll/angular-ivy';
import {
  Address,
  Company,
  Craft,
  IdType,
  Profile,
  Project,
  ProjectCompany,
  ProjectProfile,
  Protocol,
  ProtocolEntryDefaultValue,
  ProtocolEntryLocation,
  ProtocolEntryPriorityLevel,
  ProtocolEntryType,
} from 'submodules/baumaster-v2-common';
import {CompanyAddresses, CompanyWithRemoved} from '../../protocol-entry-form/protocol-entry-form.component';
import {CompanyDataService} from 'src/app/services/data/company-data.service';
import {combineLatestAsync, defaultIfNullish, observableToPromise} from 'src/app/utils/async-utils';
import {SelectableService} from 'src/app/services/common/selectable.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {CraftDataService} from 'src/app/services/data/craft-data.service';
import {ProfileDataService} from 'src/app/services/data/profile-data.service';
import {ProjectCraftDataService} from 'src/app/services/data/project-craft-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProjectProfileDataService} from 'src/app/services/data/project-profile-data.service';
import {ProjectProtocolLocationDataService} from 'src/app/services/data/project-protocol-location-data.service';
import {ProtocolEntryLocationDataService} from 'src/app/services/data/protocol-entry-location-data.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import _ from 'lodash';
import {CompanyWithProjectId} from 'src/app/model/project-company';
import {ProtocolEntryDefaultValueDataService} from 'src/app/services/data/protocol-entry-default-value-data.service';
import {ProtocolEntryTypeDataService} from 'src/app/services/data/protocol-entry-type-data.service';
import {ProjectProtocolEntryTypeDataService} from 'src/app/services/data/project-protocol-entry-type-data.service';

const LOG_SOURCE = 'ProtocolFormDefaultValueComponent';

@Component({
  selector: 'app-protocol-form-default-value',
  templateUrl: './protocol-form-default-value.component.html',
  styleUrls: ['./protocol-form-default-value.component.scss'],
})
export class ProtocolFormDefaultValueComponent implements OnInit, OnDestroy {
  @Input()
  readonly: boolean;

  @Input()
  form: UntypedFormGroup;

  @Input()
  protocol: Protocol;

  @Output()
  valuesEmptyChange = new EventEmitter<boolean>();

  private readonly companyProjectTeam: CompanyWithRemoved = {
    id: null,
    name: this.translateService.instant('project_team'),
    clientId: null,
    changedAt: new Date(),
    isActive: true,
    isRemoved: false,
  };

  private project: Project | undefined;
  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;
  private destroy$ = new Subject<void>();

  private combineCompanyProfilesSubscription: Subscription | undefined;

  public companyAddresses: Array<CompanyAddresses> | undefined;
  public companyAddressesAll: Array<CompanyAddresses> | undefined;
  public disabledCompanyAddresses: Array<CompanyAddresses> | undefined;

  public companyData$: Observable<Array<CompanyWithRemoved>>;
  public companyDataAll$: Observable<Array<Company>>;
  public disabledCompanies: Array<Company>;

  public locationData$: Observable<Array<ProtocolEntryLocation>>;
  public disabledLocationData$: Observable<Array<ProtocolEntryLocation>>;
  public locationDataAll$: Observable<Array<ProtocolEntryLocation>>;

  public protocolEntryTypeData$: Observable<Array<ProtocolEntryType>>;
  public disabledProtocolEntryTypeData$: Observable<Array<ProtocolEntryType>>;
  public protocolEntryTypeDataAll$: Observable<Array<ProtocolEntryType>>;

  public craftData$: Observable<Array<Craft>>;
  public craftDataAll$: Observable<Array<Craft>>;
  public disabledCraftData$: Observable<Array<Craft>>;

  public observerCompanyData$: Observable<Array<CompanyWithRemoved>>;
  public observerCompanyDataAll$: Observable<Array<Company>>;
  public isCompanyInProject$: Observable<Record<IdType, ProjectCompany>>;
  public companyById$: Observable<Record<IdType, Company>>;

  public mbscThemeVariant$ = this.mobiscrollService.themeVariant$;
  public mbscLocale$ = this.mobiscrollService.locale$;
  public MBSC_DATE_FORMAT = this.mobiscrollService.DATE_FORMAT;

  public selectedPriorityId: number | null = null;
  public selectedNameableDropdownId: IdType | undefined;
  public allowCreatedProjectSettings$: Observable<boolean>;

  public priorityLevel = ProtocolEntryPriorityLevel;

  @ViewChild('startDateDatepicker', {static: false}) startDateDatepicker: MbscDatepicker;
  @ViewChild('todoUntilDatepicker', {static: false}) todoUntilDatePicker: MbscDatepicker;

  constructor(
    private selectableUtilService: SelectableUtilService,
    private projectCompanyDataService: ProjectCompanyDataService,
    private companyDataService: CompanyDataService,
    private craftDataService: CraftDataService,
    private protocolEntryLocationDataService: ProtocolEntryLocationDataService,
    private projectProtocolLocationDataService: ProjectProtocolLocationDataService,
    private projectProfileDataService: ProjectProfileDataService,
    private projectCraftDataService: ProjectCraftDataService,
    private profileService: ProfileDataService,
    private addressDataService: AddressDataService,
    private popoverCtr: PopoverController,
    public translateService: TranslateService,
    private loggingService: LoggingService,
    private platform: Platform,
    private systemEventService: SystemEventService,
    private projectDataService: ProjectDataService,
    public themeService: ThemeService,
    private selectableService: SelectableService,
    private toastService: ToastService,
    private mobiscrollService: MobiscrollService,
    private protocolEntryDefaultValueDataService: ProtocolEntryDefaultValueDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private projectProtocolEntryTypeDataService: ProjectProtocolEntryTypeDataService
  ) {}

  async ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit');
    this.initCompanyData();
    this.isCompanyInProject$ = this.projectCompanyDataService.dataByCompanyId$;
    this.companyById$ = this.companyDataService.dataGroupedById.pipe(defaultIfNullish({} as Record<IdType, Company>), startWith({} as Record<IdType, Company>));
    this.companyData$.pipe(takeUntil(this.destroy$)).subscribe((companies) => (this.disabledCompanies = companies.filter((company) => company.isActive !== undefined && !company.isActive)));
    this.projectDataService.currentProjectObservable.pipe(takeUntil(this.destroy$)).subscribe((project) => (this.project = project));
    this.locationData$ = this.getProtocolEntryLocationsObservable$();
    this.disabledLocationData$ = this.locationData$.pipe(map((locations) => locations.filter((location) => location.isActive === false)));
    this.locationDataAll$ = this.getProtocolEntryLocationsAllObservable$();
    this.craftData$ = this.getCraftDataObservable$();
    this.craftDataAll$ = this.getCraftDataAllObservable$();
    this.disabledCraftData$ = this.craftData$.pipe(map((crafts) => crafts.filter((craft) => craft.isActive === false)));
    this.protocolEntryTypeData$ = this.projectProtocolEntryTypeDataService.getProjectProtocolEntryTypesWithDeletedSuffix();
    this.disabledProtocolEntryTypeData$ = this.protocolEntryTypeData$.pipe(map((entrytypes) => entrytypes.filter((entryType) => entryType.isActive === false)));
    this.protocolEntryTypeDataAll$ = this.protocolEntryTypeDataService.dataActive$;
    if (this.protocol) {
      this.protocolEntryDefaultValueDataService
        .getByProtocolId(this.protocol.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (protocolDefaultValues) => {
          if (!protocolDefaultValues) {
            return;
          }
          const observerCompanies = (await observableToPromise(this.companyDataService.dataActive$)).filter((company) => protocolDefaultValues.observerCompanyIds?.includes(company.id));
          this.form.get('observerCompanies').setValue(observerCompanies.map((company) => company.id));
          this.form.get('startDate').setValue(protocolDefaultValues.startDate);
          this.form.get('todoUntil').setValue(protocolDefaultValues.todoUntil);
          if (protocolDefaultValues?.priority) {
            this.selectedPriorityId = protocolDefaultValues.priority;
            this.form.get('priority').setValue(protocolDefaultValues.priority);
          }
          const company = (await observableToPromise(this.companyData$)).find((company) => company.id === protocolDefaultValues?.companyId);
          if (company && company?.id !== null) {
            this.form.get('company').setValue(company);
            this.initCompanyPeople(company.id, protocolDefaultValues?.internalAssignmentId);
          } else if (protocolDefaultValues?.allCompanies) {
            this.form.get('company').setValue(this.companyProjectTeam);
          }
          const craft = (await observableToPromise(this.craftData$)).find((craft) => craft.id === protocolDefaultValues?.craftId);
          if (craft) {
            this.form.get('craft').setValue(craft);
          }
          const type = (await observableToPromise(this.protocolEntryTypeData$)).find((type) => type.id === protocolDefaultValues?.typeId);
          if (type) {
            this.form.get('type').setValue(type);
          }
          const location = (await observableToPromise(this.locationData$)).find((location) => location.id === protocolDefaultValues?.locationId);
          if (location) {
            this.form.get('location').setValue(location);
          }

          if (protocolDefaultValues?.nameableDropdownId) {
            this.selectedNameableDropdownId = protocolDefaultValues.nameableDropdownId;
            this.form.get('nameableDropdownId').setValue(protocolDefaultValues.nameableDropdownId);
          }
          if (
            observerCompanies.length ||
            protocolDefaultValues.startDate ||
            protocolDefaultValues.todoUntil ||
            protocolDefaultValues?.priority ||
            (company && company?.id !== null) ||
            protocolDefaultValues?.allCompanies ||
            craft ||
            location ||
            protocolDefaultValues.nameableDropdownId
          ) {
            this.valuesEmptyChange.emit(false);
          } else {
            this.valuesEmptyChange.emit(true);
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initCompanyData() {
    this.companyData$ = this.getProjectCompaniesObservable$().pipe(
      map((companies) => {
        const sortedCompanies = _.chain(companies)
          .filter((company) => company.isActive === undefined || company.isActive)
          .map(
            (company) =>
              ({
                ...company,
                isRemoved: this.project ? !company.projectIds.includes(this.project.id) : false,
              }) as CompanyWithRemoved
          )
          .orderBy((company) => (company.isActive === undefined || company.isActive ? 0 : 1))
          .orderBy((company) => (company.isRemoved ? 1 : 0))
          .value();
        sortedCompanies.unshift(this.companyProjectTeam);
        return sortedCompanies;
      })
    );
    this.companyDataAll$ = this.companyDataService.dataActive$.pipe(map((companies) => [this.companyProjectTeam, ...companies]));
    this.observerCompanyData$ = this.companyData$.pipe(map((values) => values.filter((value) => value.id !== null)));
    this.observerCompanyDataAll$ = this.companyDataAll$.pipe(map((values) => values.filter((value) => value.id !== null)));
  }

  private initCompanyPeople(companyId: IdType | undefined, initInternalAssignmentId?: IdType) {
    const companyProfiles$: Observable<Array<Profile>> = this.getCompanyProfilesObservable$(companyId);
    const companyProfilesAll$: Observable<Array<Profile>> = this.getCompanyProfilesObservable$(companyId, false);
    this.combineCompanyProfilesUnsubscribe();
    this.combineCompanyProfilesSubscription = combineLatestAsync([companyProfiles$, companyProfilesAll$, this.addressDataService.data, this.projectProfileDataService.data])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([profiles, profilesAll, addresses, projectProfiles]) => {
        try {
          const newAddresses = this.convertTopCompanyAddresses(profiles, addresses, projectProfiles);
          const allAddresses = this.convertTopCompanyAddresses(profilesAll, addresses, projectProfiles);
          this.companyAddresses = newAddresses;
          this.companyAddressesAll = allAddresses;
          this.disabledCompanyAddresses = newAddresses.filter((address) => address.isActive === false);
          if (initInternalAssignmentId && !this.form.get('internalAssignmentId').dirty) {
            this.form.get('internalAssignmentId').setValue(this.companyAddresses.find((ca) => ca.id === initInternalAssignmentId));
          }
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `initCompanyPeople - combineCompanyProfilesSubscription. ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + ' initCompanyPeople - combineCompanyProfilesSubscription.', error);
          this.toastService.error(`initCompanyPeople - combineCompanyProfilesSubscription. ${convertErrorToMessage(error)}`);
        }
      });
  }

  private convertTopCompanyAddresses(profiles: Array<Profile>, addresses: Array<Address>, projectProfiles: Array<ProjectProfile>): Array<CompanyAddresses> {
    const result = new Array<CompanyAddresses>();
    profiles.forEach((profile) => {
      _.each(addresses, (address) => {
        if (profile?.addressId === address.id && (profile.isActive === undefined || profile.isActive)) {
          const isActiveInProject = projectProfiles.some(({profileId}) => profileId === profile.id);
          const isActiveProfile = profile.isActive === undefined || profile.isActive;
          let suffix = '';
          if (!isActiveProfile) {
            suffix = ` ${this.translateService.instant('deleted')}`;
          }

          const isActive = isActiveProfile && isActiveInProject;
          result.push({
            id: profile.id,
            name: `${_.compact([address.firstName, address.lastName]).join(' ')}${suffix}`,
            isActive,
          });
          return false;
        }
      });
    });
    return result;
  }

  private getProjectCompaniesObservable$(): Observable<CompanyWithProjectId[]> {
    return this.projectCompanyDataService.getProjectCompaniesWithProjectId();
  }

  private getCompanyProfilesObservable$(companyId: string, assignedToProject = true): Observable<Profile[]> {
    return (assignedToProject ? this.projectProfileDataService.getProjectProfiles() : this.profileService.dataWithDefaultType$).pipe(
      map((profiles) => profiles.filter((profile) => profile.companyId === companyId))
    );
  }

  private getProtocolEntryLocationsObservable$(): Observable<ProtocolEntryLocation[]> {
    return this.projectProtocolLocationDataService.getProjectProtocolLocationsWithDeletedSuffix();
  }

  private getProtocolEntryLocationsAllObservable$(): Observable<ProtocolEntryLocation[]> {
    return this.protocolEntryLocationDataService.dataActive$;
  }

  private getCraftDataObservable$(): Observable<Craft[]> {
    return this.projectCraftDataService.getProjectCraftsWithDeletedSuffix();
  }

  private getCraftDataAllObservable$(): Observable<Craft[]> {
    return this.craftDataService.dataWithDeletedSuffixWithIds([]);
  }

  private combineCompanyProfilesUnsubscribe() {
    if (this.combineCompanyProfilesSubscription) {
      this.combineCompanyProfilesSubscription.unsubscribe();
      this.combineCompanyProfilesSubscription = undefined;
    }
  }

  companyChange(event: {component: IonicSelectableComponent; value: Company & CompanyWithRemoved; values: Array<Company & CompanyWithRemoved>}) {
    const company = event.value;
    this.combineCompanyProfilesUnsubscribe();
    this.initCompanyPeople(company?.id);
    this.form.get('internalAssignmentId').reset();
  }

  onAdditionalFieldsChange(nameableDropdownId: string) {
    this.form.get('nameableDropdownId').setValue(nameableDropdownId);
    this.form.get('nameableDropdownId').markAsDirty();
  }

  changePriority(priorityId) {
    this.selectedPriorityId = priorityId;
    this.form.get('priority').setValue(priorityId);
    this.form.get('priority').markAsDirty();
  }

  async showPriorityPopOver(event) {
    this.loggingService.debug(LOG_SOURCE, 'showPriorityPopOver called');
    const popover = await this.popoverCtr.create({
      component: ProtocolEntryPriorityPopoverComponent,
      event,
      translucent: true,
    });

    popover.onDidDismiss().then((response: any) => {
      if (typeof response.data !== 'undefined') {
        this.changePriority(response.data?.newPriority);
      }
    });
    return await popover.present();
  }

  async fillWithPreviousProtocolValues(defaultValues: ProtocolEntryDefaultValue | undefined | null) {
    const company = (await observableToPromise(this.companyData$)).find((company) => company.id === defaultValues?.companyId);
    if (company) {
      this.form.get('company').setValue(company);
      this.combineCompanyProfilesUnsubscribe();
      this.initCompanyPeople(company.id, defaultValues.internalAssignmentId);
    }
    this.form.get('craft').setValue((await observableToPromise(this.craftData$)).find((craft) => craft.id === defaultValues?.craftId));
    this.form.get('type').setValue((await observableToPromise(this.protocolEntryTypeData$)).find((type) => type.id === defaultValues?.typeId));
    this.form.get('location').setValue((await observableToPromise(this.locationData$)).find((location) => location.id === defaultValues?.locationId));
    this.selectedNameableDropdownId = defaultValues?.nameableDropdownId;
    this.form.get('nameableDropdownId').setValue(defaultValues?.nameableDropdownId);
    this.form.get('observerCompanies').setValue(defaultValues?.observerCompanyIds);
    this.changePriority(defaultValues?.priority);
    this.form.get('startDate').setValue(defaultValues?.startDate);
    this.form.get('todoUntil').setValue(defaultValues?.todoUntil);
    this.form.markAsDirty();
  }

  closeCalender() {
    // workaround for ios, mbsc opens sometimes multiple calender dialogs
    if (this.platform.is('ios')) {
      const calenderModals = document.querySelectorAll('div.mbsc-calendar');
      for (const modal of calenderModals[Symbol.iterator]()) {
        modal.remove();
      }
    }
  }

  async onOpen($event: {component: IonicSelectableComponent}, values: number[]) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
  }

  async onClose($event: {component: IonicSelectableComponent}) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }

  resetStartDate = (event: any) => {
    this.startDateDatepicker.setVal(null);
    this.startDateDatepicker.close();
  };

  resetTodoUntil = (event: any) => {
    this.todoUntilDatePicker.setVal(null);
    this.todoUntilDatePicker.close();
  };

  createNewCompanyFunction = (text?: string): Promise<Company | undefined> => {
    return this.selectableService.createNewCompany(text, this.project.id);
  };

  assignCompanyToProjectFunction = (item: Company, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignCompanyToProject(item, assign, this.project.id);
  };

  createNewProfileFunction = (text?: string): Promise<Profile | undefined> => {
    const company = this.form.controls.company.value;
    if (!company) {
      throw new Error('createNewProfileFunction - no company selected.');
    }
    return this.selectableService.createNewProfile(company, text, this.project.id);
  };

  assignProfileToProjectFunction = (item: CompanyAddresses, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignProfileToProject(item, assign, this.project.id);
  };

  createNewLocationFunction = (text?: string): Promise<ProtocolEntryLocation | undefined> => {
    return this.selectableService.createNewLocation(text, this.project.id);
  };

  assignLocationToProjectFunction = (item: ProtocolEntryLocation, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignLocationToProject(item, assign, this.project.id);
  };

  createNewCraftFunction = (text?: string): Promise<Craft | undefined> => {
    return this.selectableService.createNewCraft(text, this.project.id);
  };

  assignCraftToProjectFunction = (item: Craft, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignCraftToProject(item, assign, this.project.id);
  };

  createNewProtocolEntryTypeFunction = (text?: string): Promise<ProtocolEntryType | undefined> => {
    return this.selectableService.createNewProtocolEntryType(text, this.project.id);
  };

  assignProtocolEntryTypeToProjectFunction = (item: ProtocolEntryType, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignProtocolEntryTypeToProject(item, assign, this.project.id);
  };
}
