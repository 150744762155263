<app-modal-header>
    <ion-title>{{titleKey | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="userLicenseForm" action="#">
    <ion-grid class="grid-gap-2">
      <ion-row>
        <ion-col size="3" class="d-flex ion-align-items-center ion-justify-content-center avatar">
          <app-avatar color="inactive" [initials]="initials || '' " size="medium"></app-avatar>
        </ion-col>
        <ion-col size="9" >
          <p>{{address?.firstName}} {{address?.lastName}}</p>
          <p>{{address?.email}}</p>
        </ion-col>
      </ion-row>

      <ion-row class="ion-align-items-center" *ngIf="createMode">
        <ion-col size="12" class="omg-modal-section-header pt-3">
          <span class="pr-2">{{ 'companyForm.userConnectionInvite.language' | translate }}</span>
        </ion-col>
        <ion-col size="12">
          <ionic-selectable class="text-primary app-protocol-form-type"
                            formControlName="language"
                            [items]="languages"
                            searchFailText="{{'noItemsFound' | translate}}">
            <ng-template ionicSelectableItemTemplate let-languageOption="item">
              {{'languageShort.' + languageOption | translate}}
            </ng-template>
            <ng-template ionicSelectableValueTemplate let-languageOption="value">
              {{'languageShort.' + languageOption | translate}}
            </ng-template>
            <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
              <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
            </ng-template>
            <ng-template ionicSelectableHeaderTemplate>
              <app-selectable-header></app-selectable-header>
            </ng-template>
            <ng-template ionicSelectableFooterTemplate>
              <app-selectable-footer [canClear]="false"></app-selectable-footer>
            </ng-template>
          </ionic-selectable>
        </ion-col>
      </ion-row>
     
      <ion-radio-group formControlName="permission">
        <ion-row>
          <ion-col size="12" class="omg-modal-section-header pt-3">
            <span class="pr-2">{{ 'companyForm.employees.permission' | translate }}</span>
            <fa-icon
              [icon]="['fal', 'info-circle']"
              appInfoPopoverClick
              [infoTitle]="'companyForm.employees.infoPermissionHeader'"
              [infoText]="'companyForm.employees.infoPermissionMessage'"
            ></fa-icon>
          </ion-col>
          <ion-col size="4">
            <ion-item class="omg-form-checkbox d-flex">
              <ion-radio label-placement="end" justify="start" [value]="permissionItems[0]" [disabled]="!isChangeOfPermissionAllowed">
                {{ 'companyForm.employees.user' | translate }}
              </ion-radio>
            </ion-item>
          </ion-col>
          <ion-col size="4" radio-group>
            <ion-item class="omg-form-checkbox d-flex">
              <ion-radio label-placement="end" justify="start" [value]="permissionItems[1]" [disabled]="!isChangeOfPermissionAllowed">
                {{ 'account_settings.admin' | translate }}
              </ion-radio>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-radio-group>

      <ion-radio-group formControlName="role">
        <ion-row>
          <ion-col size="12" class="omg-modal-section-header pt-2">
            <span class="pr-2">{{'companyForm.employees.licenseAvailable' | translate}}</span>
            <fa-icon
              [icon]="['fal', 'info-circle']"
              appInfoPopoverClick
              [infoTitle]="'companyForm.employees.infoLicenseHeader'"
              [infoText]="'companyForm.employees.infoLicenseMessage'"
            ></fa-icon>
          </ion-col>
          <ion-col size="4">
            <ion-item class="omg-form-checkbox">
              <ion-radio label-placement="end" justify="start" [value]="roleItems[0]">
                {{ 'licence_types.' + roleItems[0] | translate }} <span *ngIf="licensesAvailable(roleItems[0]) !== undefined">&nbsp;({{licensesAvailable(roleItems[0])}})</span>
              </ion-radio>
            </ion-item>
          </ion-col>
          <ion-col size="4" *ngIf="licensesAvailable(roleItems[1]) > 0">
            <ion-item class="omg-form-checkbox">
              <ion-radio label-placement="end" justify="start" [value]="roleItems[1]">
                {{ 'licence_types.' + roleItems[1] | translate }} <span *ngIf="licensesAvailable(roleItems[1]) !== undefined">&nbsp;({{licensesAvailable(roleItems[1])}})</span>
              </ion-radio>
            </ion-item>
          </ion-col>
          <ion-col size="4" *ngIf="licensesAvailable(roleItems[2]) > 0">
            <ion-item class="omg-form-checkbox">
              <ion-radio label-placement="end" justify="start" [value]="roleItems[2]">
                {{ 'licence_types.' + roleItems[2] | translate }} <span *ngIf="licensesAvailable(roleItems[2]) !== undefined">&nbsp;({{licensesAvailable(roleItems[2])}})</span>
              </ion-radio>
            </ion-item>
          </ion-col>
          <ion-col size="4" *ngIf="licensesAvailable(roleItems[3]) > 0">
            <ion-item class="omg-form-checkbox">
              <ion-radio label-placement="end" justify="start" [value]="roleItems[3]">
                {{ 'licence_types.' + roleItems[3] | translate }} <span *ngIf="licensesAvailable(roleItems[3]) !== undefined">&nbsp;({{licensesAvailable(roleItems[3])}})</span>
              </ion-radio>
            </ion-item>
          </ion-col>
          <span *ngIf="userLicenseForm.controls.role?.errors?.noLicenseAvailable" class="error-message">
            {{ 'companyForm.employees.errorNoLicenseAvailable' | translate }}
          </span>
        </ion-row>
      </ion-radio-group>

      <ion-radio-group [hidden]="userLicenseForm.get('role').value === 2 || userLicenseForm.get('role').value === 3" formControlName="assignedReportRights">
        <ion-row>
          <ion-col size="12" class="omg-modal-section-header pt-2">
            <span class="pr-2">{{'companyForm.employees.reportLicense' | translate}}</span>
          </ion-col>
          <ion-col size="4">
            <ion-item class="omg-form-checkbox">
              <ion-radio label-placement="end" justify="start" [value]="false">
                {{ 'companyForm.employees.without' | translate}}
              </ion-radio>
            </ion-item>
          </ion-col>
          <ion-col size="4" *ngIf="reportsLicensesAvailable() !== undefined">
            <ion-item class="omg-form-checkbox">
              <ion-radio label-placement="end" justify="start" [value]="true">
                <span>{{ 'companyForm.employees.report' | translate }}</span>
                <span *ngIf="reportsLicensesAvailable() !== undefined">&nbsp;({{reportsLicensesAvailable()}})</span>
              </ion-radio>
            </ion-item>
          </ion-col>
          <div *ngIf="userLicenseForm.controls.assignedReportRights?.errors?.noLicenseAvailable" class="error-message">{{ 'companyForm.employees.errorNoLicenseAvailable' | translate }}</div>
        </ion-row>
      </ion-radio-group>

      <ion-row *ngIf="errorMessage">
        <ion-col size="12">
          <span class="error-message"> {{errorMessage}} </span>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!(networkStatusConnected$ | async)">
        <ion-col size="12">
          <span class="error-message"> {{'companyForm.employees.errorNoNetwork' | translate}} </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons *ngIf="editMode && user && user.isActive" slot="start">
    <ion-button class="omg-btn-secondary omg-btn-color-danger" [disabled]="inProgress || !(networkStatusConnected$ | async)" (click)="deactivateUser()">
      <ion-spinner class="spinner-center-button" *ngIf="inProgress"></ion-spinner>
      <span>{{'button.deactivate' | translate}}</span>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">{{'cancel' | translate}}</ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!userLicenseForm.valid || inProgress || !(networkStatusConnected$ | async)" (click)="submit()">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="inProgress"></ion-spinner>
      <span>{{submitTranslationKey | translate}}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
