import {ColumnConfig} from '../ColumnConfig';

const TextTemplateBaseColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'created_at_db', prop: 'createdAtDb', cnd: true, cast: 'Timestamp'},
  {name: 'created_by_id', prop: 'createdById', def: null},
  {name: 'category'},
  {name: 'name'},
  {name: 'text_template', prop: 'textTemplate'},
  {name: 'language', prop: 'language'},
];

export const TextTemplateColumnSet: Array<ColumnConfig> = [...TextTemplateBaseColumnSet];

export const ClientTextTemplateColumnSet: Array<ColumnConfig> = [...TextTemplateBaseColumnSet, {name: 'client_id', prop: 'clientId', cnd: true}];
