import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ProfileDataService} from 'src/app/services/data/profile-data.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {Participant} from 'submodules/baumaster-v2-common';
import {IndividualNextMeetingsGroupsComponent} from './individual-next-meetings-groups/individual-next-meetings-groups.component';
import {NextMeetingFormComponent} from './next-meeting-form/next-meeting-form.component';
import {IndividualNextMeetingForm, NextMeetingForm} from './next-meeting.interface';

@Component({
  selector: 'app-next-meeting',
  templateUrl: './next-meeting.component.html',
  styleUrls: ['./next-meeting.component.scss'],
})
export class NextMeetingComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input()
  participants: Participant[] = [];

  @Input()
  nextMeeting?: NextMeetingForm;
  @Output()
  nextMeetingChange = new EventEmitter<NextMeetingForm>();

  @Input()
  individualNextMeetings: IndividualNextMeetingForm[] = [];
  @Output()
  individualNextMeetingsChange = new EventEmitter<IndividualNextMeetingForm[]>();

  @ViewChild(NextMeetingFormComponent, {
    static: false,
  })
  nextMeetingForm: NextMeetingFormComponent;
  @ViewChild(IndividualNextMeetingsGroupsComponent, {
    static: false,
  })
  individualNextMeetingsGroups: IndividualNextMeetingsGroupsComponent;

  constructor(private profileDataService: ProfileDataService) {}

  ngOnInit() {
    if (!this.nextMeeting) {
      this.nextMeeting = {
        show: false,
        date: null,
        timeEnd: null,
        timeStart: null,
        groupMeeting: true,
        sendCalendarMail: false,
      };
    }
  }

  async onShowChange(show: boolean) {
    if (show) {
      this.nextMeeting = {
        ...this.nextMeeting,
        show: true,
      };
    } else {
      this.nextMeeting = {
        show: false,
        date: null,
        timeEnd: null,
        timeStart: null,
        groupMeeting: true,
        sendCalendarMail: false,
      };
    }

    this.nextMeetingChange.emit(this.nextMeeting);
  }

  handleMeetingsGroupsInit() {
    this.populateIndividualNextMeetingsByParticipantsIfEmpty();
  }

  private async populateIndividualNextMeetingsByParticipantsIfEmpty() {
    if (this.individualNextMeetings.length !== 0) {
      return;
    }
    if (!this.individualNextMeetingsGroups) {
      return;
    }
    const profiles = await observableToPromise(this.profileDataService.getByIds(this.participants.filter(({mailingList}) => mailingList).map(({profileId}) => profileId)));
    this.individualNextMeetingsGroups.updateMeetingsByProfiles(profiles);
  }

  handleNextMeetingChange(meeting: NextMeetingForm) {
    let newMeeting = {...meeting};
    const newStart = newMeeting.timeStart?.length ? newMeeting.timeStart : undefined;
    const newEnd = newMeeting.timeEnd?.length ? newMeeting.timeEnd : undefined;

    const changeStart = newStart && newStart !== this.nextMeeting.timeStart;
    const changeEnd = newEnd && newEnd !== this.nextMeeting.timeEnd;
    const sendCalendarMailUnchanged = this.nextMeeting.sendCalendarMail === newMeeting.sendCalendarMail;

    if (sendCalendarMailUnchanged && (changeStart || changeEnd)) {
      this.individualNextMeetings = this.individualNextMeetings?.map((individual) => ({
        ...individual,
        timeStart: changeStart ? newStart : individual.timeStart,
        timeEnd: changeEnd ? newEnd : individual.timeEnd,
      }));
      this.individualNextMeetingsChange.emit(this.individualNextMeetings);
      if (!this.individualNextMeetings.some((individual) => individual.timeStart !== newStart || individual.timeEnd !== newEnd)) {
        newMeeting = {...newMeeting, groupMeeting: true};
      }
    }

    this.nextMeeting = newMeeting;
    this.nextMeetingChange.emit(newMeeting);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isValid() {
    return this.nextMeetingForm?.form.valid && (!this.nextMeeting?.show || this.individualNextMeetingsGroups.isValid());
  }
}
