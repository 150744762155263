<ion-grid class="omg-form grid-gap-4" [formGroup]="form">
  <ion-row>
    <ion-col size-xs="12">
      <ion-item detail="false" class="larger" button lines="inset">
        <ion-label [appLayout]="0.5" position="floating">
          <fa-icon [fixedWidth]="true" [icon]="['fal', 'map']"></fa-icon>
          <span>{{ 'Location' | translate }}</span>
        </ion-label>
        <ionic-selectable
          #locationSelectable
          closeButtonSlot="end"
          [isMultiple]="true"
          [items]="locations$ | async"
          [shouldStoreItemValue]="true"
          [itemTextField]="'location'"
          [itemValueField]="'id'"
          [canSearch]="true"
          [canClear]="true"
          (onOpen)="onOpen($event)"
          (onClose)="onClose($event)"
          formControlName="locationIds"
          appSelectableCommon
          #fs="appSelectableCommon"
          [shouldFocusSearchbar]="fs.isDesktop"
        >
          <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
            <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
          </ng-template>
          <ng-template ionicSelectableValueTemplate let-value="value">
            <app-selectable-value [value]="value"></app-selectable-value>
          </ng-template>
          <ng-template ionicSelectableItemTemplate let-item="item">
            <ng-container *ngIf="item.id === EMPTY_FILTER_ID">
              <fa-icon class="pr-4" [icon]="['fal', 'empty-set']"></fa-icon>
                {{'emptyFilter' | translate}}
            </ng-container>  
            <ng-container *ngIf="item.id !== EMPTY_FILTER_ID">
              {{item.location}}
            </ng-container>
          </ng-template>
          <ng-template ionicSelectableHeaderTemplate>
            <app-selectable-header></app-selectable-header>
          </ng-template>
          <ng-template ionicSelectableFooterTemplate>
            <app-selectable-footer></app-selectable-footer>
          </ng-template>
        </ionic-selectable>
      </ion-item>
    </ion-col>

    <ion-col size="12">
      <ion-item class="unit-field-item" [class.item-has-value]="!!us.value()" (click)="us.chooseUnit()">
        <ion-label position="floating">
          {{ 'protocol.unit.unit' | translate }}
        </ion-label>
        <div class="ionic-selectable-label-floating">
          <div
            class="unit-field-label"
            [(ngModel)]="selectedUnitId"
            (ngModelChange)="shouldIncludeSubUnitsChanged()"
            [ngModelOptions]="{standalone: true}"
            appUnitSelector
            #us="appUnitSelector"
            [selectorUnits]="units$ | async"
            [selectorUnitLevels]="unitLevels$ | async"
            [idOnly]="true"
          >
            <bdi>{{us.value()?.breadcrumbsName}}</bdi>
          </div>
          <div class="ionic-selectable-icon">
            <div class="ionic-selectable-icon-inner"></div>
          </div>
        </div>
      </ion-item>
    </ion-col>
    <ion-col size="12" class="ion-no-padding">
      <ion-item lines="none" class="small-item">
        <ion-toggle
          formControlName="includeSubUnits"
          (ionChange)="shouldIncludeSubUnitsChanged()"
          appSlimToggle
        ></ion-toggle>
        <ion-label class="slim-toggle-label">{{ ('unitFilter.includeSub' | translate) }}</ion-label>
      </ion-item>
    </ion-col>
    <ion-col size-xs="12">
      <ion-item detail="false" class="larger with-tags" button lines="inset" [class.item-has-value]="form.get('tags')?.value?.length > 0" (click)="editTagsModal.openModal()">
        <ion-label [appLayout]="0.5" position="floating">
          <fa-icon [fixedWidth]="true" [icon]="['fal', 'tags']"></fa-icon>
          <span>{{ 'Tags' | translate }}</span>
        </ion-label>
        <div class="tags-item-content ionic-selectable-label-floating" appLayout appLayoutJustifyContent="space-between" appLayoutAlignItems="stretch">
          <app-tag-list
            [maxTags]="1"
            [showMoreTagsCount]="true"
            appEditTagsModal
            #editTagsModal="appEditTagsModal"
            formControlName="tags"
            [clearLabel]="'reset'"
            [applyLabel]="'button.apply'"
          ></app-tag-list>
          <div class="ionic-selectable-icon">
            <div class="ionic-selectable-icon-inner"></div>
          </div>
        </div>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="3">
      <ion-item class="omg omg-form-checkbox">
        <ion-label class="ion-nowrap">
          {{'project_room.pdf_plan_holders.filters.plan_active' | translate}}
        </ion-label>
      </ion-item>
    </ion-col>
    <ion-col size="2">
      <ion-item detail="false" class="omg omg-form-checkbox">
        <ion-checkbox formControlName="includeActive" labelPlacement="end" justify="start">
          {{'yes' | translate}}
        </ion-checkbox>
      </ion-item>
    </ion-col>
    <ion-col size="2">
      <ion-item detail="false" class="omg omg-form-checkbox">
        <ion-checkbox formControlName="includeInactive" labelPlacement="end" justify="start">
          {{'no' | translate}}
        </ion-checkbox>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>