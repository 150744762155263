<app-modal-header>
  <ion-title>{{'pdfPlanMarker.selectPlan' | translate}}</ion-title>
</app-modal-header>

<ion-content>
  <div class="ion-padding no-files-found" *ngIf="this.bimVersions && this.bimVersions.length === 0">
      <span class="omg-t-header-3-bold">
        {{ 'bimPlanMarker.empty.header' | translate }}
      </span>
    <div class="omg-t-body">
      {{ 'bimPlanMarker.empty.body' | translate }}
    </div>
  </div>
  <ion-list lines="none" class="omg-boundary project-list" *ngIf="this.bimVersions?.length">
    <ion-item-divider class="with-line">
      {{'all' | translate}}
    </ion-item-divider>
    <div>
      <div class="with-line"></div>
      <ion-radio-group (ngModelChange)="openBimViewerMarker($event)" [ngModel]="selectedBimVersion">
        <ion-item lines="none" *ngFor="let bim of bimVersions">
          <ion-radio label-placement="end" justify="start" [value]="bim">
            {{ bim | bimVersionName }}
          </ion-radio>
        </ion-item>
      </ion-radio-group>
    </div>
  </ion-list>
</ion-content>
