import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {PdfPlanVersionWithAttachment, PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {ProtocolEntryLocationDataService} from 'src/app/services/data/protocol-entry-location-data.service';
import {PlanAnalyticsService} from 'src/app/services/project-room/plan-analytics.service';
import {DATE_FORMAT} from 'src/app/shared/constants';
import {differenceInWeekDays} from 'src/app/utils/date-utils';
import {PdfPlanHolderItemDirective} from './pdf-plan-holder-item.directive';
import {UnitService} from '../../../../services/unit/unit.service';

@Component({
  selector: 'app-pdf-plan-holder-list-item-row',
  templateUrl: './pdf-plan-holder-list-item-row.component.html',
  styleUrls: ['./pdf-plan-holder-list-item-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPlanHolderListItemRowComponent extends PdfPlanHolderItemDirective implements OnChanges {
  readonly DATE_FORMAT = DATE_FORMAT;
  readonly date = new Date();

  @Input()
  pdfPlanHolder: PdfPlanWithDeletable;

  @Input()
  pdfPlanVersion: PdfPlanVersionWithAttachment;

  @Input()
  disabled = false;

  @Input()
  @HostBinding('class.inset')
  inset = true;

  @Input()
  activeToggleDisabled = false;

  @Input()
  hideActive = false;

  @Input()
  hideExpand = false;

  @Input()
  hideCheckbox = true;

  @Input()
  hideActions = false;

  @Input()
  expanded = false;

  @Input()
  selected = false;

  @Output()
  holderClick = new EventEmitter<MouseEvent>();

  @Output()
  actionsClick = new EventEmitter<MouseEvent>();

  @Output()
  expandClick = new EventEmitter<MouseEvent>();

  @Output()
  activeChange = new EventEmitter<boolean>();

  @Output()
  selectedChange = new EventEmitter<boolean>();

  highlightAsNew = false;

  @HostBinding('class')
  get viewModeClass() {
    return `is-${this.viewMode}-mode`;
  }

  get markersCount(): string {
    const trueCount = this.pdfPlanVersion?.pdfPlanMarkers?.length || 0;

    return trueCount >= 100 ? '99+' : `${trueCount}`;
  }

  locationById$ = this.locationDataService.dataGroupedById;
  unitById$ = this.unitService.unitsForBreadcrumbsById$;

  constructor(
    private locationDataService: ProtocolEntryLocationDataService,
    public analytics: PlanAnalyticsService,
    private unitService: UnitService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {pdfPlanVersion} = changes;
    if (pdfPlanVersion && (pdfPlanVersion.firstChange || pdfPlanVersion.previousValue?.createdAt !== this.pdfPlanVersion?.createdAt)) {
      this.highlightAsNew = differenceInWeekDays(new Date(this.pdfPlanVersion?.createdAt), new Date(), []) >= -1;
    }
  }
}
