import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertController, NavParams} from '@ionic/angular';
import {Address, AvailableLicense, determineAvailableLicenses, FALLBACK_LANGUAGE, LicenseType, Profile, SUPPORTED_LANGUAGES, User, UserInvite, UserLicense} from 'submodules/baumaster-v2-common';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {UserInviteService} from '../../../services/auth/userInvite.service';
import {v4 as uuid4} from 'uuid';
import {AddressDataService} from '../../../services/data/address-data.service';
import {combineLatest, Observable, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {UserService} from '../../../services/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {ClientService} from '../../../services/client/client.service';
import {UserDataService} from '../../../services/data/user-data.service';
import {UserInviteDataService} from '../../../services/data/user-invite-data.service';
import {ToastDurationInMs} from '../../../shared/constants';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {getFirstCapitalLetter} from 'src/app/utils/text-utils';

export enum PermissionEnum {
  ADMIN = 'admin',
  USER = 'user',
}

@Component({
  selector: 'app-user-license-edit',
  templateUrl: './user-license-edit.component.html',
  styleUrls: ['./user-license-edit.component.scss'],
})
export class UserLicenseEditComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  public createMode: boolean;
  public editMode: boolean;
  public profile: Profile;
  public address: Address | undefined;
  public userInvite?: UserInvite;
  public user?: User;
  public userLicenseForm: UntypedFormGroup;
  public inProgress = false;
  private destroy$ = new Subject<void>();
  private currentUser: User | undefined;
  public titleKey: string;
  public submitTranslationKey: string;
  public readonly roleItems: Array<LicenseType> = [LicenseType.VIEWER, LicenseType.LIGHT, LicenseType.BASIC, LicenseType.PROFESSIONAL];
  public readonly permissionItems: Array<PermissionEnum> = [PermissionEnum.USER, PermissionEnum.ADMIN];
  public errorMessage: string | undefined;
  public availableLicense: AvailableLicense | undefined;
  public networkStatusConnected$: Observable<boolean>;
  public isChangeOfPermissionAllowed: boolean;
  public initials: string | undefined;
  protected readonly languages = SUPPORTED_LANGUAGES;

  constructor(
    public navParams: NavParams,
    private fb: UntypedFormBuilder,
    private userInviteService: UserInviteService,
    private translateService: TranslateService,
    private addressDataService: AddressDataService,
    private userService: UserService,
    private userDataService: UserDataService,
    private clientService: ClientService,
    private userInviteDataService: UserInviteDataService,
    private toastService: ToastService,
    private networkStatusService: NetworkStatusService,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.createMode = this.navParams.data.createMode;
    this.editMode = !this.createMode;
    this.profile = this.navParams.data.profile;
    this.user = this.navParams.data.user;
    this.userInvite = this.navParams.data.userInvite;
    if (!this.profile) {
      throw new Error('Profile was not provided');
    }
    if (this.editMode && !this.user && !this.userInvite) {
      throw new Error('Component was called in editMode but neither user nor userInvite was provided.');
    }
    if (this.user && this.user.profileId !== this.profile.id) {
      throw new Error(`profile.id (${this.profile.id}) does not match the of User's profileId (${this.user.profileId}).`);
    }
    if (this.userInvite && this.userInvite.profileId !== this.profile.id) {
      throw new Error(`profile.id (${this.profile.id}) does not match the of UserInvite's profileId (${this.userInvite.profileId}).`);
    }

    this.networkStatusConnected$ = this.networkStatusService.onlineOrUnknown$;
    this.addressDataService
      .getById(this.profile.addressId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((address) => {
        this.address = address;
        this.initials = `${getFirstCapitalLetter(address?.firstName)}${getFirstCapitalLetter(address?.lastName)}`;
      });
    this.userService.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((currentUser) => {
      if (currentUser?.language && this.userLicenseForm && !this.userLicenseForm.get('language')?.dirty && this.userLicenseForm.get('language')?.value !== currentUser?.language) {
        this.userLicenseForm.get('language').setValue(currentUser.language);
      }
      this.currentUser = currentUser;
      this.isChangeOfPermissionAllowed = this.user?.profileId !== this.currentUser.profileId;
    });

    let roleDefaultValue: LicenseType;
    let permissionDefaultValue: PermissionEnum;
    let assignedReportRightsDefaultValue: boolean;
    if (this.editMode) {
      roleDefaultValue = this.user ? this.user.role : this.userInvite.role;
      permissionDefaultValue = (this.user ? this.user.isClientAdmin : this.userInvite.isClientAdmin) ? PermissionEnum.ADMIN : PermissionEnum.USER;
      assignedReportRightsDefaultValue = this.user ? this.user.assignedReportRights : this.userInvite.assignedReportRights;
    } else {
      roleDefaultValue = LicenseType.VIEWER;
      permissionDefaultValue = PermissionEnum.USER;
      assignedReportRightsDefaultValue = false;
    }

    this.userLicenseForm = this.fb.group({
      role: [roleDefaultValue, [Validators.required, this.licenseAvailableValidator()]],
      permission: [permissionDefaultValue, Validators.required],
      assignedReportRights: [assignedReportRightsDefaultValue, [Validators.required, this.reportsLicenseAvailableValidator()]],
      language: [this.currentUser?.language ?? FALLBACK_LANGUAGE],
    });

    this.userLicenseForm.controls.role.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((permission: LicenseType) => {
      const assignedReportRightsControl = this.userLicenseForm.get('assignedReportRights');
      if (permission === LicenseType.VIEWER || permission === LicenseType.LIGHT) {
        assignedReportRightsControl.enable();
      } else {
        assignedReportRightsControl.setValue(false);
        assignedReportRightsControl.disable();
      }
    });

    combineLatest([this.clientService.ownClient$, this.userDataService.data, this.userInviteDataService.dataForOwnClient$])
      .pipe(takeUntil(this.destroy$), debounceTime(0))
      .subscribe(([client, users, userInvites]) => {
        if (this.editMode && this.user) {
          users = users.filter((user) => user.id !== this.user.id);
        }
        if (this.editMode && this.userInvite) {
          userInvites = userInvites.filter((userInvite) => userInvite.id !== this.userInvite.id);
        }
        this.availableLicense = determineAvailableLicenses(client, users, userInvites);
        this.validateFormControls();
      });

    if (this.createMode) {
      this.titleKey = 'companyForm.employees.inviteTitle';
      this.submitTranslationKey = 'companyForm.employees.sendInvite';
    } else {
      if (this.user) {
        this.titleKey = this.user.isActive ? 'companyForm.employees.updateUserTitle' : 'companyForm.employees.activateUserTitle';
        this.submitTranslationKey = this.user.isActive ? 'button.save' : 'button.activate';
      } else {
        this.titleKey = 'companyForm.employees.resendTitle';
        this.submitTranslationKey = 'companyForm.employees.resend';
      }
    }
  }

  private validateFormControls() {
    Object.keys(this.userLicenseForm.controls).forEach((field) => {
      const control = this.userLicenseForm.get(field);
      control.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private licenseAvailableValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const role: LicenseType = control.value;
      if (role === undefined || role === null || this.licensesAvailable(role) === undefined || this.licensesAvailable(role) >= 1) {
        return null;
      }

      return {noLicenseAvailable: true};
    };
  }

  private reportsLicenseAvailableValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const assignedReportRights: LicenseType = control.value;
      if (!assignedReportRights || this.reportsLicensesAvailable() === undefined || this.reportsLicensesAvailable() >= 1) {
        return null;
      }

      return {noLicenseAvailable: true};
    };
  }

  async cancel() {
    await this.modal.dismiss();
  }

  private createUserInviteFromForm(): UserInvite {
    if (!this.address?.email || this.address?.email === '') {
      throw new Error('Address not found or address does not have an email');
    }
    if (!this.currentUser) {
      throw new Error('currentUser not set.');
    }
    if (!this.userLicenseForm.valid) {
      throw new Error('userLicenseForm is not valid-');
    }
    return {
      id: uuid4(),
      email: this.address.email,
      profileId: this.profile.id,
      validUntil: new Date(), // overwritten by the server anyway
      createdById: this.currentUser.id,
      language: this.userLicenseForm.get('language').value || this.currentUser.language || FALLBACK_LANGUAGE,
      assignedReportRights: this.userLicenseForm.get('assignedReportRights').value,
      isClientAdmin: this.userLicenseForm.get('permission').value === PermissionEnum.ADMIN,
      isStaff: false, // Ignored by the server anyway
      role: this.userLicenseForm.get('role').value,
      changedAt: new Date().toISOString(),
    } as UserInvite;
  }

  private createUserLicenseFromForm(): UserLicense {
    return {
      assignedReportRights: this.userLicenseForm.get('assignedReportRights').value,
      isClientAdmin: this.userLicenseForm.get('permission').value === PermissionEnum.ADMIN,
      isStaff: false, // Ignored by the server anyway
      role: this.userLicenseForm.get('role').value,
    } as UserLicense;
  }

  public licensesAvailable(licenseType: LicenseType): number | undefined {
    if (!this.availableLicense) {
      return undefined;
    }
    switch (licenseType) {
      case LicenseType.VIEWER:
        return undefined;
      case LicenseType.LIGHT:
        return this.availableLicense.LIGHT.available;
      case LicenseType.BASIC:
        return this.availableLicense.BASIC.available;
      case LicenseType.PROFESSIONAL:
        return this.availableLicense.PROFESSIONAL.available;
      default:
        throw new Error(`LicenseType ${licenseType} is not supported.`);
    }
  }

  public reportsLicensesAvailable(): number | undefined {
    return this.availableLicense?.REPORTS?.available;
  }

  private handleError(error: any) {
    if (error?.error?.errorCode) {
      const errorCode = error?.error?.errorCode;
      this.errorMessage = this.translateService.instant('errorCodeMessages.' + errorCode);
      return;
    }
    this.errorMessage = this.translateService.instant('companyForm.employees.licenseUpdateFailed');
  }

  private async toastMessage(messageKey: string, duration = ToastDurationInMs.INFO, interpolateParams?: any) {
    await this.toastService.toastWithTranslateParams(messageKey, interpolateParams, duration);
  }

  async sendInvite() {
    await this.userInviteService.sendUserInvite(this.createUserInviteFromForm(), this.profile.clientId);
    const email = this.address?.email;
    this.toastMessage('companyForm.employees.invitationSendSuccessfully', ToastDurationInMs.INFO, {email});
    this.modal.dismiss();
  }

  async resendInvite() {
    await this.userInviteService.resendInvite(this.createUserInviteFromForm(), this.profile.clientId);
    const email = this.address?.email;
    this.toastMessage('companyForm.employees.invitationSendSuccessfully', ToastDurationInMs.INFO, {email});
    this.modal.dismiss();
  }

  async updateLicense() {
    await this.userInviteService.updateUserLicense(this.user.id, this.createUserLicenseFromForm(), this.profile.clientId);
    const email = this.address?.email;
    this.toastMessage('companyForm.employees.userUpdatedSuccessfully', ToastDurationInMs.INFO, {email});
    this.modal.dismiss();
  }

  async activateUser() {
    await this.userInviteService.activateUser(this.user.id, this.createUserLicenseFromForm(), this.profile.clientId);
    const email = this.address?.email;
    this.toastMessage('companyForm.employees.userActivatedSuccessfully', ToastDurationInMs.INFO, {email});
    this.modal.dismiss();
  }

  async deactivateUser() {
    if (this.inProgress) {
      throw new Error('Server operation already in progress.');
    }
    const alert = await this.alertController.create({
      message: this.translateService.instant('companyForm.employees.confirmDeactivateUser'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: this.translateService.instant('yes'),
          role: 'ok',
        },
      ],
    });
    await alert.present();

    const result = await alert.onDidDismiss();

    if (result.role === 'ok') {
      await this.callDeactivateUser();
    }
  }

  async callDeactivateUser() {
    if (this.inProgress) {
      throw new Error('Server operation already in progress.');
    }
    try {
      this.inProgress = true;
      this.errorMessage = undefined;
      await this.userInviteService.deactivateUser(this.user, this.profile.clientId);
      const email = this.address?.email;
      this.toastMessage('companyForm.employees.userDeactivatedSuccessfully', ToastDurationInMs.INFO, {email});
      await this.modal.dismiss();
    } catch (error) {
      this.handleError(error);
    } finally {
      this.inProgress = false;
    }
  }

  async submit() {
    if (this.inProgress) {
      throw new Error('Server operation already in progress.');
    }
    try {
      this.inProgress = true;
      this.errorMessage = undefined;
      if (this.createMode) {
        await this.sendInvite();
      } else {
        if (this.user) {
          if (this.user.isActive) {
            await this.updateLicense();
          } else {
            await this.activateUser();
          }
        } else {
          await this.resendInvite();
        }
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.inProgress = false;
    }
  }
}
