import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {IonicModule, Platform} from '@ionic/angular';
import {ThemeService} from '../../../services/ui/theme.service';
import {LoggingService} from '../../../services/common/logging.service';
import {NgIf} from '@angular/common';
import _ from 'lodash';
import {MIME_TYPE_WHITELIST} from 'submodules/baumaster-v2-common';
import {ToastService} from '../../../services/common/toast.service';
import mime from 'mime';
import {AlertService} from '../../../services/ui/alert.service';

const LOG_SOURCE = 'DragDropFileUploadOverlayComponent';

const CSS_CLASS_DEFAULT = '';
const CSS_CLASS_DRAGOVER_OUTSIDE_TARGET = 'drag-drop-file-dragging drag-drop-file-outside-target';
const CSS_CLASS_DRAGOVER_INSIDE_TARGET = 'drag-drop-file-dragging drag-drop-file-inside-target dragover';
const ION_MODAL_TAG_NAME = 'ion-modal';
/** Maximum number of files allowed for dragging. This is just a safety mechanism that not too may files are being uploaded (could end in memory problems) */
const MAX_FILES_DRAG_LIMIT = 50;

interface FileWithMimeType {
  file: File;
  mimeType: string;
  allowed: boolean;
}

@Component({
  selector: 'app-drag-drop-file-upload-overlay',
  templateUrl: './drag-drop-file-upload-overlay.component.html',
  styleUrls: ['./drag-drop-file-upload-overlay.component.scss'],
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule, IonicModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragDropFileUploadOverlayComponent implements OnInit, OnDestroy {
  static NEXT_INSTANCE_NUMBER = 0;
  static ACTIVE_INSTANCES = new Array<number>();
  readonly instanceNumber: number;

  @Input()
  readonly = false;
  @Input()
  title = 'dragDropFileUploadArea.title';
  @Input()
  subtitle = 'dragDropFileUploadArea.subtitle.general';
  @Input()
  isOpenedInModal?: boolean;
  /**
   * Hide the "brain" icon to make it smaller
   */
  @Input()
  hideIcon = false;
  @Input()
  acceptedMimeTypes: string[] | string = MIME_TYPE_WHITELIST;

  @Input()
  currentNumberOfFiles?: number;

  @Input()
  /** Only considered when also currentNumberOfFiles is provided.
   * Does not allow to upload files if currentNumberOfFiles.length + numberOfFilesDragging exceeds maxNumberOfFiles */
  maxNumberOfFiles?: number = 50;

  @Input()
  allowPartialUploadOfAcceptedMimeTypes = true;

  private acceptedMimeTypesSet: Set<string>;

  infoMessage?: string;
  warningMessage?: string;
  errorMessage?: string;
  filesExceededAmount: number;

  /**
   * Same as fileDropped but a different format
   * */
  @Output() fileDroppedEvent = new EventEmitter<{target: {files: FileList}}>();

  public readonly isDragDropSupported = this.isDragDropSupportedFn();
  private _draggingOverDocumentCounter = 0;
  private _draggingOverTargetAreaCounter = 0;
  protected numberOfFilesDragging: number | undefined;
  private mimeTypesDragging: Array<string> | undefined;

  get draggingOverDocumentCounter(): number {
    return this._draggingOverDocumentCounter;
  }

  set draggingOverDocumentCounter(value: number) {
    const valueBefore = this._draggingOverDocumentCounter;
    this._draggingOverDocumentCounter = value;
    if (Boolean(valueBefore) !== Boolean(value)) {
      this.applyClassAndMessage();
    }
  }

  get draggingOverTargetAreaCounter(): number {
    return this._draggingOverTargetAreaCounter;
  }

  set draggingOverTargetAreaCounter(value: number) {
    const valueBefore = this._draggingOverTargetAreaCounter;
    this._draggingOverTargetAreaCounter = value;
    if (Boolean(valueBefore) !== Boolean(value)) {
      this.applyClassAndMessage();
    }
  }

  @HostBinding('class') private class = CSS_CLASS_DEFAULT;

  // Dragleave Event
  @HostListener('dragenter', ['$event']) public dragEnter(event: DragEvent) {
    this.loggingService.debug(LOG_SOURCE, `dragenter (instance=${this.instanceNumber}, counter=${this.draggingOverTargetAreaCounter})`);
    if (!this.isDragDropSupportedForThatInstance()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    this.draggingOverTargetAreaCounter++;
    this.updateFilesInfoDragging(event);
  }

  @HostListener('dragleave', ['$event']) public dragLeave(event: DragEvent) {
    this.loggingService.debug(LOG_SOURCE, `dragleave (instance=${this.instanceNumber}, counter=${this.draggingOverTargetAreaCounter})`);
    if (!this.isDragDropSupportedForThatInstance()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.draggingOverTargetAreaCounter--;
    this.updateFilesInfoDragging(event);
  }

  @HostListener('dragover', ['$event']) public dragover(event: DragEvent) {
    if (!!this.isDragDropSupported) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  // Drop Event
  @HostListener('drop', ['$event']) public async drop(event: DragEvent) {
    this.loggingService.debug(LOG_SOURCE, `drop (instance=${this.instanceNumber}, counter=${this.draggingOverTargetAreaCounter})`);
    if (!this.isDragDropSupportedForThatInstance()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    try {
      if (this.errorMessage) {
        this.toastService.info(
          this.translateService.instant('dragDropFileUploadArea.error.notUploaded', {reason: this.translateService.instant(this.errorMessage, {amount: this.filesExceededAmount})})
        );
        this.loggingService.warn(LOG_SOURCE, `drop - do not call fileDropped due to the following error: "${this.errorMessage}"`);
        return;
      }

      const files = event.dataTransfer.files;
      if (files.length > 0) {
        if (!this.allowPartialUploadOfAcceptedMimeTypes) {
          this.fileDroppedEvent.emit({target: {files}});
        } else {
          const filesWithMimeType = this.fileListToFileWithMimeTypes(files);
          if (!filesWithMimeType.some((fileWithMimeType) => !fileWithMimeType.allowed)) {
            this.fileDroppedEvent.emit({target: {files}}); // all allowed
          } else {
            const filenames = filesWithMimeType
              .filter((f) => !f.allowed)
              .map((f) => f.file.name)
              .join(' | ');
            const confirmUpload = await this.alertService.confirm({
              header: 'dragDropFileUploadArea.error.invalidFileTypeModal.title',
              message: this.translateService.instant('dragDropFileUploadArea.error.invalidFileTypeModal.message', {filenames}),
              cancelLabel: 'cancel',
              confirmLabel: 'upload',
              confirmButton: {
                color: 'text-primary',
                fill: 'solid',
              },
            });
            if (confirmUpload) {
              const filteredFiles = new MyFileList(filesWithMimeType.filter((f) => f.allowed));
              this.fileDroppedEvent.emit({target: {files: filteredFiles}});
            }
          }
        }
      }
    } finally {
      this.draggingOverDocumentCounter = 0;
      this.draggingOverTargetAreaCounter = 0;
      this.updateFilesInfoDragging(event);
    }
  }

  @HostListener('window:dragenter', ['$event']) documentDragEnter(event: DragEvent) {
    this.loggingService.debug(LOG_SOURCE, `window:dragenter (instance=${this.instanceNumber}, counter=${this.draggingOverDocumentCounter})`);
    if (!this.isDragDropSupportedForThatInstance()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    this.draggingOverDocumentCounter++;
    this.updateFilesInfoDragging(event);
  }

  @HostListener('window:dragleave', ['$event']) documentDragLeave(event: DragEvent) {
    this.loggingService.debug(LOG_SOURCE, `window:dragleave (instance=${this.instanceNumber}, counter=${this.draggingOverDocumentCounter})`);
    if (!this.isDragDropSupportedForThatInstance()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    this.draggingOverDocumentCounter--;
    this.updateFilesInfoDragging(event);
  }

  @HostListener('window:dragover', ['$event']) public windowDragover(event: DragEvent) {
    if (!this.isDragDropSupported) {
      return;
    }
    // This is needed so the drop event function windowDrop is called.
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('window:drop', ['$event']) public windowDrop(event: DragEvent) {
    this.loggingService.debug(LOG_SOURCE, `window:drop (instance=${this.instanceNumber}, counter=${this.draggingOverDocumentCounter})`);
    if (!this.isDragDropSupportedForThatInstance()) {
      return;
    }
    // This is called when the file was dropped outside the drop area, the default behavior is prevented, which is opening the file in a new tab.
    event.preventDefault();
    event.stopPropagation();

    this.draggingOverDocumentCounter = 0;
    this.draggingOverTargetAreaCounter = 0;
    this.updateFilesInfoDragging(event);
  }

  constructor(
    private elementRef: ElementRef,
    public themeService: ThemeService,
    private platform: Platform,
    private loggingService: LoggingService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private alertService: AlertService
  ) {
    this.instanceNumber = DragDropFileUploadOverlayComponent.NEXT_INSTANCE_NUMBER++;
    DragDropFileUploadOverlayComponent.ACTIVE_INSTANCES.push(this.instanceNumber);
  }

  ngOnInit(): void {
    const acceptedMimeTypesArray = (typeof this.acceptedMimeTypes === 'string' ? this.acceptedMimeTypes.split(',') : this.acceptedMimeTypes).map((mimeType) => mimeType.toLowerCase());
    this.acceptedMimeTypesSet = new Set<string>(acceptedMimeTypesArray);
    if (this.isOpenedInModal === undefined) {
      this.isOpenedInModal = !!this.elementRef.nativeElement.closest(ION_MODAL_TAG_NAME);
    }
    this.filesExceededAmount = this.maxNumberOfFiles === undefined ? MAX_FILES_DRAG_LIMIT : Math.min(this.maxNumberOfFiles, MAX_FILES_DRAG_LIMIT);
  }

  ngOnDestroy() {
    const indexOfActiveInstances = DragDropFileUploadOverlayComponent.ACTIVE_INSTANCES.indexOf(this.instanceNumber);
    if (indexOfActiveInstances === -1) {
      this.loggingService.warn(LOG_SOURCE, `instanceNumber ${this.instanceNumber} not found in ACTIVE_INSTANCES ${JSON.stringify(DragDropFileUploadOverlayComponent.ACTIVE_INSTANCES)}`);
    } else {
      DragDropFileUploadOverlayComponent.ACTIVE_INSTANCES.splice(indexOfActiveInstances, 1);
    }
  }

  public isLastInstance(): boolean {
    return DragDropFileUploadOverlayComponent.ACTIVE_INSTANCES[DragDropFileUploadOverlayComponent.ACTIVE_INSTANCES.length - 1] === this.instanceNumber;
  }

  /**
   * Modals can also have drop zones in which case it may overlaps underlying drop zones.
   * So we assume that an instance in a modal is opened later (is the latest instance) and therefore only the latest instance can be a drop zone.
   */
  private isDragDropSupportedForThatInstance(): boolean {
    if (!this.isDragDropSupported) {
      return false;
    }
    if (this.isOpenedInModal) {
      return true;
    }
    this.loggingService.debug(LOG_SOURCE, `isDragDropSupportedForThatInstance called`);
    return !this.isModalOpen();
  }

  private isDragDropSupportedFn(): boolean {
    return this.platform.is('desktop') || (this.platform.is('mobileweb') && !this.platform.is('android') && !this.platform.is('ios'));
  }

  private fileListToFileWithMimeTypes(fileList: FileList): Array<FileWithMimeType> {
    const filesWithMimeType = new Array<FileWithMimeType>();
    for (const file of fileList) {
      const mimeType = file.type || mime.getType(file.name);
      const allowed = this.acceptedMimeTypesSet.has(mimeType?.toLowerCase());
      filesWithMimeType.push({
        file,
        mimeType,
        allowed,
      });
    }
    return filesWithMimeType;
  }

  private updateFilesInfoDragging(event: DragEvent) {
    if ((!this.draggingOverDocumentCounter && !this.draggingOverTargetAreaCounter) || !event?.dataTransfer?.items) {
      this.numberOfFilesDragging = undefined;
      this.mimeTypesDragging = undefined;
      return;
    }
    const numberOfFilesDraggingBefore = this.numberOfFilesDragging;
    const mimeTypesDraggingBefore = this.mimeTypesDragging;

    this.numberOfFilesDragging = event.dataTransfer.items.length;
    const types = new Array<string>();
    for (const item of event.dataTransfer.items) {
      types.push(item.type);
    }
    this.mimeTypesDragging = _.compact(types);

    if (numberOfFilesDraggingBefore !== this.numberOfFilesDragging || numberOfFilesDraggingBefore !== this.numberOfFilesDragging || mimeTypesDraggingBefore !== this.mimeTypesDragging) {
      this.applyClassAndMessage();
    }
  }

  private applyClassAndMessage() {
    if (!this.isDragDropSupportedForThatInstance() || (!this.draggingOverTargetAreaCounter && !this.draggingOverDocumentCounter)) {
      this.class = CSS_CLASS_DEFAULT;
      this.infoMessage = undefined;
      this.warningMessage = undefined;
      this.errorMessage = undefined;
      return;
    }
    let maxFilesExceeded: boolean;
    if (this.numberOfFilesDragging && this.numberOfFilesDragging > MAX_FILES_DRAG_LIMIT) {
      maxFilesExceeded = true;
    } else {
      const newNumberOfFiles = this.numberOfFilesDragging !== undefined ? (this.numberOfFilesDragging + this.currentNumberOfFiles ?? 0) : undefined;
      maxFilesExceeded = this.maxNumberOfFiles !== undefined && newNumberOfFiles !== undefined ? newNumberOfFiles > this.maxNumberOfFiles : false;
    }
    const numberOfInvalidMimeTypes = this.mimeTypesDragging ? this.mimeTypesDragging.filter((mimeTypeDragging) => !this.acceptedMimeTypesSet.has(mimeTypeDragging?.toLowerCase()))?.length : 0;
    const allMimeTypesInvalid = this.mimeTypesDragging && numberOfInvalidMimeTypes === this.mimeTypesDragging.length;

    if (this.readonly) {
      this.errorMessage = 'dragDropFileUploadArea.error.readonly';
      this.warningMessage = undefined;
    } else if (maxFilesExceeded) {
      this.errorMessage = 'dragDropFileUploadArea.error.filesExceeded';
      this.warningMessage = undefined;
    } else if (numberOfInvalidMimeTypes > 0) {
      if (allMimeTypesInvalid || !this.allowPartialUploadOfAcceptedMimeTypes) {
        this.errorMessage = 'dragDropFileUploadArea.error.invalidFileType';
        this.warningMessage = undefined;
      } else {
        this.errorMessage = undefined;
        this.warningMessage = 'dragDropFileUploadArea.error.invalidFileType';
      }
    } else {
      this.errorMessage = undefined;
      this.warningMessage = undefined;
    }

    if (this.draggingOverTargetAreaCounter) {
      this.class = CSS_CLASS_DEFAULT + ' ' + CSS_CLASS_DRAGOVER_INSIDE_TARGET;
      this.infoMessage = this.errorMessage ? undefined : 'dragDropFileUploadArea.info.droppable';
    } else if (this.draggingOverDocumentCounter) {
      this.class = CSS_CLASS_DEFAULT + ' ' + CSS_CLASS_DRAGOVER_OUTSIDE_TARGET;
      this.infoMessage = undefined;
    } else {
      throw new Error(LOG_SOURCE + '.applyClassAndMessage - Should not be here. Check implementation.');
    }
    this.loggingService.debug(LOG_SOURCE, `applyClass to "${this.class}" (instance=${this.instanceNumber})`);
  }

  private isModalOpen(): boolean {
    const modalElements = document.getElementsByTagName(ION_MODAL_TAG_NAME);
    if (!modalElements?.length) {
      this.loggingService.debug(LOG_SOURCE, `isModalOpen return false because no ion-modals`);
      return false;
    }
    for (const modalElement of modalElements) {
      if (modalElement.checkVisibility?.() ?? true) {
        this.loggingService.debug(LOG_SOURCE, `isModalOpen return true because at least one of the ${modalElements?.length} modals is visible.`);
        return true;
      }
    }
    this.loggingService.debug(LOG_SOURCE, `isModalOpen return false because none of the ${modalElements?.length} are visible.`);
    return false;
  }
}

class MyFileList implements FileList, Iterable<File> {
  readonly length = this.filesWithMimeTypes.length;

  constructor(private filesWithMimeTypes: Array<FileWithMimeType>) {}

  *[Symbol.iterator](): IterableIterator<File> {
    for (const filesWithMimeType of this.filesWithMimeTypes) {
      yield filesWithMimeType.file;
    }
  }

  item(index: number): File | null {
    return this.filesWithMimeTypes[index]?.file ?? null;
  }

  [index: number]: File;
}
