import {Component, OnDestroy, OnInit, Input} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Observable, Subject, Subscription, take, takeUntil} from 'rxjs';
import {Address, Company, Country} from 'submodules/baumaster-v2-common/';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {EMAIL_REG_EXP_PATTERN} from 'src/app/shared/constants';
import {environment} from 'src/environments/environment';
import {ClientService} from 'src/app/services/client/client.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {observableToPromise} from 'src/app/utils/observable-to-promise';
import {HttpClient} from '@angular/common/http';
import {SalesEmailReq} from 'submodules/baumaster-v2-common';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {AlertService} from 'src/app/services/ui/alert.service';
import {combineLatestAsync} from 'src/app/utils/async-utils';

const LOG_SOURCE = 'BuyingWorkflowContactFormComponent';

@Component({
  selector: 'app-buying-workflow-contact-form',
  templateUrl: './buying-workflow-contact-form.component.html',
  styleUrls: ['./buying-workflow-contact-form.component.scss'],
})
export class BuyingWorkflowContactFormComponent implements OnInit, OnDestroy {
  modal: HTMLIonModalElement;

  @Input()
  country: Country;

  countries$: Observable<Country[]>;
  preSelectedCountry: Country | undefined;

  private company: Company | undefined;
  private userAddress: Address | undefined;
  private userAddressSubscription: Subscription | undefined;
  private companySubscription: Subscription | undefined;
  public readonly emailRegExpPattern = EMAIL_REG_EXP_PATTERN;
  private destroy$ = new Subject<void>();

  contactForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.maxLength(50)]],
    lastName: ['', [Validators.required, Validators.maxLength(50)]],
    email: ['', [Validators.required, Validators.maxLength(254), Validators.pattern(this.emailRegExpPattern)]],
    phone: ['', [Validators.required, Validators.maxLength(25)]],
    companyName: ['', [Validators.required, Validators.maxLength(101)]],
    note: [''],
  });

  constructor(
    private fb: FormBuilder,
    private userProfileService: UserProfileService,
    private clientService: ClientService,
    private systemEventService: SystemEventService,
    private http: HttpClient,
    private posthogService: PosthogService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.userAddressSubscription = this.userProfileService.currentUserOwnAddress$.subscribe((addressUser) => {
      this.userAddress = addressUser;
    });
    this.companySubscription = this.userProfileService.currentUserOwnCompany$.subscribe((company) => {
      this.company = company;
    });
    combineLatestAsync([this.userProfileService.currentUserOwnAddress$, this.userProfileService.currentUserOwnCompany$])
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(([userAddress, company]) => {
        this.contactForm.patchValue({
          firstName: userAddress.firstName ?? '',
          lastName: userAddress.lastName ?? '',
          email: userAddress.email ?? '',
          phone: userAddress.mobile ?? userAddress.phone ?? '',
          companyName: company.name,
        });
      });
    this.sendPosthogEvent('[Buying][Global] Entered Sales Contact Workflow ');
  }

  ngOnDestroy(): void {
    this.companySubscription?.unsubscribe();
    this.companySubscription = undefined;
    this.userAddressSubscription?.unsubscribe();
    this.userAddressSubscription = undefined;
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getPatchValueContact() {
    return {
      firstName: this.userAddress.firstName ?? '',
      lastName: this.userAddress.lastName ?? '',
      email: this.userAddress.email ?? '',
      phone: this.userAddress.mobile ?? this.userAddress.phone ?? '',
      companyName: this.company.name,
    };
  }

  openPricingPage() {
    window.open('https://bau-master.com/gb/pricing', '_blank');
  }

  async sendSalesEmail() {
    try {
      const client = this.clientService.getCurrentClientMandatory();
      const customerNumber = client.customerNumber;
      const salesEmailReq: SalesEmailReq = {customerNumber, clientName: client.name, countryName: this.country.name, ...this.contactForm.getRawValue()};
      const response = await observableToPromise(this.http.post<{success: boolean}>(environment.serverUrl + `purchase/${client.id}/sendSalesEmail/`, salesEmailReq));
      if (response?.success) {
        this.sendPosthogEvent('[Buying][Global] Submitted Sales Contact Form ');
        await this.alertService.ok({
          header: 'buyingWorkflow.submitHeader',
          message: 'buyingWorkflow.submitMessage',
          confirmLabel: 'close',
        });
      } else {
        await this.alertService.ok({
          header: 'buyingWorkflow.submitErrorHeader',
          message: 'buyingWorkflow.submitErrorMessage',
          confirmLabel: 'close',
        });
      }

      await this.modal.dismiss();
    } catch (error) {
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - sendSalesEmail', error?.userMessage + '-' + convertErrorToMessage(error));
      await this.alertService.ok({
        header: 'buyingWorkflow.submitErrorHeader',
        message: 'buyingWorkflow.submitErrorMessage',
        confirmLabel: 'close',
      });
      this.sendPosthogEvent('[Buying][Global] Error Submitting Sales Contact Form ');
    }
  }

  sendPosthogEvent(eventName: string) {
    this.posthogService.captureEvent(eventName, {});
  }

  async dismissModal() {
    if (this.contactForm.dirty) {
      const confirm = await this.alertService.confirm({
        header: 'buyingWorkflow.dataLossHeader',
        message: 'buyingWorkflow.dataLossMessage',
      });
      if (confirm) {
        this.sendPosthogEvent('[Buying][Global] Left Sales Contact Workflow ');
        await this.modal.dismiss();
      }
    } else {
      this.sendPosthogEvent('[Buying][Global] Left Sales Contact Workflow ');
      await this.modal.dismiss();
    }
  }
}
