import {ColumnConfig} from '../ColumnConfig';

const SingleSettingForReportTypeInProjectUniq = ['project_id', 'report_type_id'];

export const PdfReportSettingColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'project_id', prop: 'projectId', uniqueWith: [SingleSettingForReportTypeInProjectUniq]},
  {name: 'report_type_id', prop: 'reportTypeId', uniqueWith: [SingleSettingForReportTypeInProjectUniq]},
  {name: 'mail_text', prop: 'mailText', def: null},
  {name: 'mail_subject', prop: 'mailSubject', def: null},
  {name: 'mail_text_under_download_link', prop: 'mailTextUnderDownloadLink', def: null},
  {name: 'add_signature', prop: 'addSignature'},
  {name: 'show_staff_time_blocks_checked', prop: 'showStaffTimeBlocksChecked'},
  {name: 'append_activity_attachments', prop: 'appendActivityAttachments'},
  {name: 'append_other_attachments', prop: 'appendOtherAttachments'},
  {name: 'append_material_attachments', prop: 'appendMaterialAttachments'},
  {name: 'append_equipment_attachments', prop: 'appendEquipmentAttachments'},
];
