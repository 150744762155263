
<ion-grid [formGroup]="form" class="grid-gap-4 omg-form">
  <ion-row>
    <ion-col size="6">
      <ion-item>
        <ion-label position="floating">
          <fa-icon [fixedWidth]="true" [icon]="['fal','building']"></fa-icon> {{ 'Company' | translate }}
        </ion-label>
        <app-selectable
            formControlName="company"
            [recentlyUsedKey]="'company'"
            (onChange)="companyChange($event)"
            [items]="companyData$ | async"
            [allItems]="companyDataAll$ | async"
            [disabledItems]="disabledCompanies"
            itemValueField="id"
            itemTextField="name"
            [isEnabled]="!readonly"
            [itemTemplate]="companyItemTemplate"
            [valueTemplate]="companyValueTemplate"
            [allowCreateNew]="allowCreatedProjectSettings$ | async"
            [createNewFunction]="createNewCompanyFunction"
            [assignToProjectFunction]="assignCompanyToProjectFunction"
            [messageString]="'selectable.company' | translate"
        >
          <ng-template #companyItemTemplate let-company="item" let-showProjectItems="showProjectItems">
            <fa-icon class="ion-padding-end" [icon]="['fal','bullhorn']" *ngIf="company.id === null"></fa-icon>
            <ion-label [class.bold]="company.id === null">{{company | companyNameString:showProjectItems && company.isRemoved}}</ion-label>
          </ng-template>
          <ng-template #companyValueTemplate let-company="value" let-showProjectItems="showProjectItems">
            {{company | companyNameString:showProjectItems && company.isRemoved}}
          </ng-template>
        </app-selectable>
      </ion-item>
    </ion-col>

    <ion-col size="6">
      <ion-item>
        <ion-label position="floating">
          <fa-icon [fixedWidth]="true" [icon]="['fal','user']"></fa-icon> {{ 'internalAssignment' | translate }}
        </ion-label>
        <app-selectable
            formControlName="internalAssignmentId"
            [recentlyUsedKey]="'user'"
            [items]="companyAddresses | orderBy:['isActive', 'name']:['desc', 'asc']"
            [allItems]="companyAddressesAll"
            [disabledItems]="disabledCompanyAddresses"
            itemTextField="name"
            itemValueField="id"
            [isEnabled]="!readonly && form.get('company').value?.id !== null"
            [allowCreateNew]="allowCreatedProjectSettings$ | async"
            [createNewFunction]="createNewProfileFunction"
            [assignToProjectFunction]="assignProfileToProjectFunction"
            [messageString]="'selectable.internal' | translate"
        >
        </app-selectable>
      </ion-item>
    </ion-col>

    <ion-col size="6">
      <ion-item class="protocol-entry-form-observer-companies-field">
        <ion-label position="floating">
          <fa-icon [fixedWidth]="true" [icon]="['fal','city']"></fa-icon> {{ 'observerCompanies' | translate }}
        </ion-label>
        <app-selectable
            formControlName="observerCompanies"
            [recentlyUsedKey]="'company'"
            [items]="observerCompanyData$ | async"
            [allItems]="observerCompanyDataAll$ | async"
            [disabledItems]="disabledCompanies"
            itemValueField="id"
            itemTextField="name"
            [shouldStoreItemValue]="true"
            [isEnabled]="!readonly"
            [allowCreateNew]="allowCreatedProjectSettings$ | async"
            [isMultiple]="true"
            [createNewFunction]="createNewCompanyFunction"
            [assignToProjectFunction]="assignCompanyToProjectFunction"
            [valueTemplate]="observableCompanyValueTemplate"
            [itemTemplate]="observableCompanyItemTemplate"
            [messageString]="'selectable.observer' | translate"
        >
          <ng-template #observableCompanyItemTemplate let-company="item">
            <ion-label>{{company | companyNameString:company.isRemoved}}</ion-label>
          </ng-template>
          <ng-template #observableCompanyValueTemplate let-companies="value">
            <ng-container *ngFor="let company of companies; let last = last">
              {{(companyById$|async)?.[company] | companyNameString:(!((isCompanyInProject$|async)?.[company]))}}<ng-container *ngIf="!last">, </ng-container>
            </ng-container>
          </ng-template>
        </app-selectable>
      </ion-item>
    </ion-col>

    <ion-col size="6">
      <ion-item>
        <ion-label position="floating"><fa-icon [fixedWidth]="true" [icon]="['fal', 'tag']"></fa-icon> {{ 'Type' | translate }}</ion-label>
        <app-selectable
            formControlName="type"
            [recentlyUsedKey]="'protocolEntryType'"
            [items]="protocolEntryTypeData$ | async"
            [disabledItems]="disabledProtocolEntryTypeData$ |async"
            [allItems]="protocolEntryTypeDataAll$ | async"
            itemValueField="id"
            itemTextField="name"
            [isEnabled]="!readonly"
            [allowCreateNew]="allowCreatedProjectSettings$ | async"
            [createNewFunction]="createNewProtocolEntryTypeFunction"
            [assignToProjectFunction]="assignProtocolEntryTypeToProjectFunction"
            [messageString]="'selectable.entryType' | translate"
        >
        </app-selectable>
      </ion-item>
    </ion-col>

    <ion-col size="6">
      <ion-item>
        <ion-label position="floating"><fa-icon [fixedWidth]="true" [icon]="['fal', 'tools']"></fa-icon> {{ 'Category' | translate }}</ion-label>
        <app-selectable
            formControlName="craft"
            [recentlyUsedKey]="'craft'"
            [items]="craftData$ | async"
            [allItems]="craftDataAll$ | async"
            [disabledItems]="disabledCraftData$ | async"
            itemValueField="id"
            itemTextField="name"
            [isEnabled]="!readonly"
            [allowCreateNew]="allowCreatedProjectSettings$ | async"
            [createNewFunction]="createNewCraftFunction"
            [assignToProjectFunction]="assignCraftToProjectFunction"
            [messageString]="'selectable.craft' | translate"
        >
        </app-selectable>
      </ion-item>
    </ion-col>

    <ion-col size="6">
      <ion-item>
        <ion-label position="floating"><fa-icon [fixedWidth]="true" [icon]="['fal', 'map']"></fa-icon> {{ 'Location' | translate }}</ion-label>
        <app-selectable
            formControlName="location"
            [recentlyUsedKey]="'location'"
            [items]="locationData$ | async"
            [disabledItems]="disabledLocationData$ | async"
            [allItems]="locationDataAll$ | async"
            itemValueField="id"
            itemTextField="location"
            [isEnabled]="!readonly"
            [allowCreateNew]="allowCreatedProjectSettings$ | async"
            [createNewFunction]="createNewLocationFunction"
            [assignToProjectFunction]="assignLocationToProjectFunction"
            [messageString]="'selectable.location' | translate"
        >
        </app-selectable>
      </ion-item>
    </ion-col>

    <ion-col size="6">
      <ion-item [disabled]="readonly">
        <ion-input label-placement="floating"
          #startDateDatepicker="mobiscroll"
          [readonly]="readonly"
          (onClose)="closeCalender()"
          appMbscCalendar
          formControlName="startDate"
          [dateFormat]="MBSC_DATE_FORMAT"
          [showWeekNumbers]="true"
          [firstSelectDay]="1"
          [firstDay]="1"
          [max]="form.get('todoUntil')?.value"
          [setText]="'done' | translate"
          [cancelText]="'cancel' | translate"
          [buttons]="[ 'cancel', {text: 'reset_short' | translate, handler: resetStartDate}, 'set' ]"
          [locale]="mbscLocale$ | async"
          [themeVariant]="mbscThemeVariant$ | async"
          theme="windows"
          mbsc-datepicker
          [controls]="['calendar']"
          [mbscOptions]="{disabled: readonly}">
          <div slot="label"><fa-icon [fixedWidth]="true" [icon]="['fal', 'calendar-alt']"></fa-icon> {{ 'protocolEntry.startDate' | translate }}</div>
        </ion-input>
      </ion-item>
    </ion-col>

    <ion-col size="6">
      <ion-item [disabled]="readonly">
        <ion-input label-placement="floating"
          #todoUntilDatepicker="mobiscroll"
          [readonly]="readonly"
          appMbscCalendar
          formControlName="todoUntil"
          [dateFormat]="MBSC_DATE_FORMAT"
          [showWeekNumbers]="true"
          (onClose)="closeCalender()"
          [firstSelectDay]="1"
          [firstDay]="1"
          [min]="form.get('startDate')?.value"
          [setText]="'done' | translate"
          [cancelText]="'cancel' | translate"
          [buttons]="[ 'cancel', {text: 'reset_short' | translate, handler: resetTodoUntil}, 'set' ]"
          mbsc-datepicker
          [controls]="['calendar']"
          [locale]="mbscLocale$ | async"
          [themeVariant]="mbscThemeVariant$ | async"
          theme="windows"
          [mbscOptions]="{disabled: readonly}">
          <div slot="label">
            <fa-icon [fixedWidth]="true" [icon]="['fal', 'calendar-alt']"></fa-icon> {{ 'protocolEntry.todoUntil' | translate }}
          </div>
        </ion-input>
      </ion-item>
    </ion-col>

    <ion-col size="6">
      <app-protocol-additional-fields [initNameableDropdownId]="selectedNameableDropdownId" [protocolEntry]="null" [acrossProjects]="false" [disabled]="readonly" (additionalFieldsChange)="onAdditionalFieldsChange($event)"></app-protocol-additional-fields>
    </ion-col>

    <ion-col size="6">
      <ion-item lines="none">
        <ion-buttons>
          <ion-button [disabled]="readonly" [class]="'app-default-priority app-priority-' + selectedPriorityId" (click)="showPriorityPopOver($event)">
            <div class="pr-2">
              <span *ngIf="selectedPriorityId | isEmpty">{{ 'no_priority' | translate }}</span>
              <span *ngIf="selectedPriorityId === priorityLevel.HIGH">{{ 'highPriority' | translate }}</span>
              <span *ngIf="selectedPriorityId === priorityLevel.MEDIUM">{{ 'mediumPriority' | translate }}</span>
              <span *ngIf="selectedPriorityId === priorityLevel.LOW">{{ 'lowPriority' | translate }}</span>
            </div>
            <fa-icon [icon]="['bau', 'flag']"></fa-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>
