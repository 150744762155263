import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {IdAware} from 'submodules/baumaster-v2-common';
import {AbstractSelectionService} from './abstract-selection.service';
import {PdfPlanHolderService} from './pdf-plan-holder.service';
import {PlanAnalyticsService} from './plan-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class PdfPlanHolderSelectionService extends AbstractSelectionService<PdfPlanWithDeletable> {
  selected$: Observable<PdfPlanWithDeletable[]> = this.pdfPlanHolderService.getAllPdfPlans$().pipe(
    switchMap((allPlans) =>
      this.selectedSet$.pipe(
        filter((selectedSet) => selectedSet.size === 0 || allPlans.length > 0),
        map((selectedSet) => allPlans.filter(({id}) => selectedSet.has(id)))
      )
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private pdfPlanHolderService: PdfPlanHolderService,
    private planAnalyticsService: PlanAnalyticsService
  ) {
    super();
  }

  toggleSelected(obj: IdAware) {
    super.toggleSelected(obj);
    this.planAnalyticsService.planSelected(this.selected.size);
  }
}
