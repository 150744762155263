import {
  Activity,
  AdditionalPayType,
  Address,
  Attachment,
  AttachmentClient,
  AttachmentProjectBanner,
  AttachmentProjectBannerType,
  AttachmentReportActivity,
  AttachmentReportCompany,
  AttachmentReportEquipment,
  AttachmentReportMaterial,
  AttachmentReportSignature,
  AttachmentUserEmailSignature,
  Client,
  Company,
  Craft,
  CustomReportType,
  Employee,
  Equipment,
  IdType,
  Material,
  Participant,
  Profile,
  ProfileCraft,
  Project,
  ProjectCompany,
  ProjectProfile,
  ProtocolEntryLocation,
  Report,
  ReportCompany,
  ReportCompanyActivity,
  ReportType,
  ReportWeek,
  Staff,
  StaffingType,
  UnitForBreadcrumbs,
  UserPublic,
} from '../../models';
import {AttachmentWithContent, ClientAttachmentType} from '../protocol/pdfProtocol.model';

export interface PdfReportGenerateData {
  userId: IdType;
  client: Client;
  project: Project;
  projectAddress: Address | undefined;
  report: Report;
  reportWeek: ReportWeek;
  customReportType?: CustomReportType;
  reportCompanies: Array<ReportCompany>;
  activities: Array<Activity>;
  employees?: Array<Employee>;
  reportCompanyActivities: Array<ReportCompanyActivity>;
  equipments?: Array<Equipment>;
  materials?: Array<Material>;
  staffs?: Array<Staff>;
  participants: Array<Participant>;
  pdfProjectBanners?: Map<AttachmentProjectBannerType, AttachmentWithContent<AttachmentProjectBanner>>;
  attachmentClients?: Map<ClientAttachmentType, AttachmentWithContent<AttachmentClient>>;
  attachmentReportCompanies: Array<AttachmentWithContent<AttachmentReportCompany>>;
  attachmentReportActivities: Array<AttachmentWithContent<AttachmentReportActivity>>;
  attachmentReportEquipments?: Array<AttachmentWithContent<AttachmentReportEquipment>>;
  attachmentReportMaterials?: Array<AttachmentWithContent<AttachmentReportMaterial>>;
  attachmentReportSignatures?: Array<AttachmentWithContent<AttachmentReportSignature>>;
  attachmentUserEmailSignature?: AttachmentWithContent<AttachmentUserEmailSignature>;
  attachmentsMissingContent: Array<Attachment>;
  lookup: PdfReportLookupData;
}

export interface PdfReportLookupData {
  companies: Map<IdType, Company>;
  crafts: Map<IdType, Craft>;
  profiles: Map<IdType, Profile>;
  projectCompanies: Map<IdType, ProjectCompany>;
  userPublicData: Map<IdType, UserPublic>;
  addresses: Map<IdType, Address>;
  projectProfiles: Array<ProjectProfile>;
  profileCrafts: Array<ProfileCraft>;
  reportTypes: Map<IdType, ReportType>;
  customReportTypes: Map<IdType, CustomReportType>;
  protocolEntryLocations: Map<IdType, ProtocolEntryLocation>;
  staffingTypes: Map<IdType, StaffingType>;
  additionalPayTypes: Map<IdType, AdditionalPayType>;
  units: Map<IdType, UnitForBreadcrumbs>;
}

export enum Color {
  BLACK = '#000000',
  BLUE = '#2691C8',
  YELLOW = '#FFDB00',
  RED = '#AD003E',
  GREEN = '#78C832',
  DARK_GRAY = '#5D5D5D',
  LIGHT_GRAY = '#B3B3B3',
  VERY_LIGHT_GRAY = '#EEEEEE',
}

export interface ActivityWithLocation extends Activity {
  locationName?: string | null;
}
