import {bauBim} from './bau-bim';
import {bauCardExpanded} from './bau-card-expanded';
import {bauChat} from './bau-chat';
import {bauEntryExpand} from './bau-entry-expand';
import {bauFlag} from './bau-flag';
import {bauFotoSeries} from './bau-foto-series';
import {bauPaperClip} from './bau-paper-clip';
import {bauPointUp} from './bau-point-up';
import {fal6Shelter} from './fal6-shelter';
import {bauStatusContinuousInfo} from './bau-status-continuous-info';
import {bauStatusDone} from './bau-status-done';
import {bauStatusInfo} from './bau-status-info';
import {bauStatusOnHold} from './bau-status-on-hold';
import {bauStatusOpen} from './bau-status-open';
import {fal6ChartGantt} from './fal6-chart-gantt';
import {fal6DiagramSubtask} from './fal6-diagram-subtask';

export const bau = {
  faFotoSeries: bauFotoSeries,
  faStatusOnHold: bauStatusOnHold,
  faStatusInfo: bauStatusInfo,
  faStatusContinuousInfo: bauStatusContinuousInfo,
  faStatusDone: bauStatusDone,
  faStatusOpen: bauStatusOpen,
  faChat: bauChat,
  faPaperClip: bauPaperClip,
  faFlag: bauFlag,
  faEntryExpand: bauEntryExpand,
  faPointUp: bauPointUp,
  faCardExpanded: bauCardExpanded,
  faBim: bauBim,
};

export const fal6 = {
  faChartGantt: fal6ChartGantt,
  faDiagramSubtask: fal6DiagramSubtask,
  faShelter: fal6Shelter,
};
