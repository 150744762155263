import {Injectable} from '@angular/core';
import {StorageService} from '../storage.service';
import {BehaviorSubject} from 'rxjs';
import {StorageKeyEnum} from '../../shared/constants';
import {DEFAULT_ENTRY_CREATION_MODE, EntryCreationMode} from 'src/app/model/entry-creation-mode';
import {map, shareReplay} from 'rxjs/operators';
import {PopoverService, isPopoverDismissed} from '../ui/popover.service';

@Injectable({
  providedIn: 'root',
})
export class EntryCreationModeService {
  public entryCreationModeSubject = new BehaviorSubject<EntryCreationMode>('text');
  public entryCreationModeRememberedSubject = new BehaviorSubject<boolean>(false);
  entryCreationModeRemembered$ = this.entryCreationModeRememberedSubject.asObservable();
  entryCreationMode$ = this.entryCreationModeSubject.asObservable();
  entryCreationModeIcon$ = this.entryCreationMode$.pipe(
    map((mode) => {
      switch (mode) {
        case 'image':
          return ['fal', 'camera'];
        case 'plan':
          return ['fal', 'map-marker-alt'];
        case 'text':
        default:
          return ['fal', 'text'];
      }
    }),
    shareReplay(1)
  );

  private set entryCreationMode(entryCreationMode: EntryCreationMode) {
    this.entryCreationModeSubject.next(entryCreationMode);
  }

  public get entryCreationMode() {
    return this.entryCreationModeSubject.value;
  }

  private set entryCreationModeRemembered(entryCreationModeRemembered: boolean) {
    this.entryCreationModeRememberedSubject.next(entryCreationModeRemembered);
  }

  public get entryCreationModeRemembered() {
    return this.entryCreationModeRememberedSubject.value;
  }

  constructor(
    private storage: StorageService,
    private popoverService: PopoverService
  ) {
    this.initModeFromStorage();
    this.initRememberedFromStorage();
  }

  private async initModeFromStorage() {
    const entryCreationMode: EntryCreationMode | undefined = await this.storage.get(StorageKeyEnum.ENTRY_CREATION_MODE);
    this.entryCreationMode = entryCreationMode ?? DEFAULT_ENTRY_CREATION_MODE;
  }

  private async initRememberedFromStorage() {
    const entryCreationModeRemembered: boolean | undefined = await this.storage.get(StorageKeyEnum.ENTRY_CREATION_MODE_REMEMBERED);
    this.entryCreationModeRemembered = entryCreationModeRemembered ?? false;
  }

  async updateEntryCreationMode(entryCreationMode: EntryCreationMode) {
    this.entryCreationMode = entryCreationMode;
    await this.storage.set(StorageKeyEnum.ENTRY_CREATION_MODE, entryCreationMode);
  }

  async updateEntryCreationModeRemembered(entryCreationModeRemembered: boolean) {
    this.entryCreationModeRemembered = entryCreationModeRemembered;
    await this.storage.set(StorageKeyEnum.ENTRY_CREATION_MODE_REMEMBERED, entryCreationModeRemembered);
  }

  async openEntryCreationModeOptions(event: Event, isTask?: boolean): Promise<boolean> {
    const {data, theRole} = await this.popoverService.openActionsWithCheckbox(
      event,
      [
        {
          label: isTask ? 'button.textBasedTask' : 'button.textBased',
          role: 'text',
          icon: ['fal', 'text'],
        },
        {
          label: isTask ? 'button.planBasedTask' : 'button.planBased',
          role: 'plan',
          icon: ['fal', 'map-marker-alt'],
        },
      ],
      {
        toastOnFeatureDisabled: true,
        popoverOptions: {},
        toastMessages: {
          connectedString: 'connectedDisabled',
          licenseString: 'licenseDisabled',
        },
        showCheckbox: this.entryCreationModeRemembered ? false : true,
        checkboxTranslationToken: 'saveSelection',
      }
    );

    if (isPopoverDismissed(theRole)) {
      return false;
    }

    if (theRole === 'text') {
      await this.updateEntryCreationMode('text');
      if (data?.checkboxEnabled && !this.entryCreationModeRemembered) {
        await this.updateEntryCreationModeRemembered(true);
      }
      return true;
    }

    if (theRole === 'plan') {
      await this.updateEntryCreationMode('plan');
      if (data?.checkboxEnabled && !this.entryCreationModeRemembered) {
        await this.updateEntryCreationModeRemembered(true);
      }
      return true;
    }

    return false;
  }
}
