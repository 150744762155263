<app-modal-header>
  <ion-title>{{'companyForm.userConnectionInvite.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="userInviteForm" action="#">
    <ion-grid>
      <ion-row *ngIf="address">
        <ion-col size="3" >
          <fa-icon [icon]="['fal', 'user']"></fa-icon>
        </ion-col>
        <ion-col size="9" >
          <div>{{address?.firstName}} {{address?.lastName}}</div>
          <div>{{address?.email}}</div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col size="3">{{'companyForm.userConnectionInvite.language' | translate}}</ion-col>
        <ion-col size="9" >
          <ionic-selectable #roleIonicSelectable
                            item-content
                            class="text-primary app-protocol-form-type"
                            formControlName="language"
                            [items]="languages"
                            searchFailText="{{'noItemsFound' | translate}}">
            <ng-template ionicSelectableItemTemplate let-languageOption="item">
              {{'languageShort.' + languageOption | translate}}
            </ng-template>
            <ng-template ionicSelectableValueTemplate let-languageOption="value">
              {{'languageShort.' + languageOption | translate}}
            </ng-template>
            <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
              <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
            </ng-template>
            <ng-template ionicSelectableHeaderTemplate>
              <app-selectable-header></app-selectable-header>
            </ng-template>
            <ng-template ionicSelectableFooterTemplate>
              <app-selectable-footer [canClear]="false"></app-selectable-footer>
            </ng-template>
          </ionic-selectable>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="errorMessage">
        <ion-col size="12">
          <span class="error-message">{{errorMessage}} </span>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!(networkStatusConnected$ | async)">
        <ion-col size="12">
          <span class="error-message">{{'companyForm.employees.errorNoNetwork' | translate}} </span>
        </ion-col>
      </ion-row>
      <ion-row class="information">
        <ion-col size="12">
          <span>{{'companyForm.userConnectionInvite.information' | translate}} </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">{{'cancel' | translate}}</ion-button>
    <ion-button class="omg-btn-primary" [disabled]="!userInviteForm.valid || inProgress || !(networkStatusConnected$ | async)" (click)="submit()">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="inProgress"></ion-spinner>
      <span>{{'companyForm.employees.invite' | translate}}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
