import {NgModule} from '@angular/core';
import {BytesPipe} from './bytes.pipe';
import {CanvasPipe} from './canvas.pipe';
import {CurrencyCodePipe} from './currency-code.pipe';
import {ImageUriPipe} from './image-uri.pipe';
import {IsEmptyPipe} from './is-empty.pipe';
import {OrderByPipe} from './order-by.pipe';
import {ProjectNumberPipe} from './project-number.pipe';
import {SafeUrlPipe} from './safe-url.pipe';
import {StopwatchPipe} from './stopwatch.pipe';
import {UserNameString} from '../utils/user-name.pipe';
import {ProtocolIndexNumberPipe} from './protocol-index-number.pipe';
import {CompanyNameString} from '../utils/company-name.pipe';
import {ReportGroupNamePipe} from './report-group-name.pipe';
import {DaysAgoPipe} from './days-ago.pipe';
import {NumberFormatPipe} from './number-format.pipe';
import {ToObservablePipe} from './to-observable.pipe';
import {PdfStepTranslationKeyPipe} from './pdf/pdf-step-translation-key.pipe';
import {IconToArrayPipe} from './pdf/icon-to-array.pipe';
import {MonthNamePipe} from './month-name.pipe';
import {MapToIdPipe} from './map-to-id.pipe';
import {EntryMailStepTranslationKeyPipe} from './entryMail/entry-mail-step-translation-key.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import {ProtocolLayoutNamePipe} from './protocol-layout.pipe';
import {BimVersionNamePipe} from './bim-version-name.pipe';
import {TranslateInLanguagePipe} from './translate-in-language.pipe';

const pipes = [
  BytesPipe,
  CanvasPipe,
  CurrencyCodePipe,
  ImageUriPipe,
  IsEmptyPipe,
  OrderByPipe,
  ProjectNumberPipe,
  SafeUrlPipe,
  StopwatchPipe,
  UserNameString,
  ProtocolIndexNumberPipe,
  CompanyNameString,
  ReportGroupNamePipe,
  DaysAgoPipe,
  NumberFormatPipe,
  ToObservablePipe,
  PdfStepTranslationKeyPipe,
  EntryMailStepTranslationKeyPipe,
  IconToArrayPipe,
  MonthNamePipe,
  MapToIdPipe,
  SafeHtmlPipe,
  ProtocolLayoutNamePipe,
  BimVersionNamePipe,
];

const standalonePipes = [TranslateInLanguagePipe];

@NgModule({
  declarations: [...pipes],
  imports: [...standalonePipes],
  exports: [...pipes, ...standalonePipes],
  providers: [...pipes],
})
export class PipesModule {}
