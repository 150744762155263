import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-unit-selector-empty',
  templateUrl: './unit-selector-empty.component.html',
  styleUrls: ['./unit-selector-empty.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class UnitSelectorEmptyComponent {}
