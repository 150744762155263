import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import * as _ from 'lodash';
import {combineLatest} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {ProjectCostsDetails, ProjectCostsGroup, ProjectCostsRow} from 'src/app/model/project-costs';
import {entryShortId, taskShortId} from 'src/app/utils/protocol-entry-utils';
import {isTaskProtocol} from 'src/app/utils/protocol-utils';
import {Company, Craft, IdType, Project, Protocol, ProtocolEntry, ProtocolType} from 'submodules/baumaster-v2-common';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';

type ProjectCostsRowWithCompanyAndTax = ProjectCostsRow & {company: string; tax: number};

@Injectable({
  providedIn: 'root',
})
export class ProjectCostsService {
  projectCosts$ = combineLatest([
    this.projectDataService.currentProjectObservable,
    this.protocolEntryDataService.data,
    this.protocolDataService.dataGroupedById,
    this.protocolEntryDataService.dataGroupedById,
    this.companyDataService.dataGroupedById,
    this.craftDataService.dataGroupedById,
    this.protocolTypeDataService.dataGroupedById,
  ]).pipe(
    debounceTime(0),
    map<[Project, ProtocolEntry[], Record<IdType, Protocol>, Record<IdType, ProtocolEntry>, Record<IdType, Company>, Record<IdType, Craft>, Record<IdType, ProtocolType>], ProjectCostsDetails>(
      (args) => this.getCostsDetailsForProject(...args)
    )
  );

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private projectDataService: ProjectDataService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolDataService: ProtocolDataService,
    private companyDataService: CompanyDataService,
    private craftDataService: CraftDataService,
    private protocolTypeDataService: ProtocolTypeDataService
  ) {}

  getCostsDetailsForProject(
    {taxRate}: Project,
    protocolEntries: ProtocolEntry[],
    protocolById: Record<IdType, Protocol>,
    entriesById: Record<IdType, ProtocolEntry>,
    companyById: Record<IdType, Company>,
    craftById: Record<IdType, Craft>,
    protocolTypesById: Record<IdType, ProtocolType>
  ) {
    const entryRows = protocolEntries
      .filter(({cost}) => cost !== undefined && cost !== null && !isNaN(parseFloat(`${cost}`)))
      .map((entry) => {
        const protocol: Protocol = protocolById[entry.protocolId];
        const netCosts = protocol?.includesVat ? Math.round((parseFloat(`${entry.cost}`) / (taxRate / 100 + 1)) * 100) / 100 : parseFloat(`${entry.cost}`);
        const isTask = protocol && isTaskProtocol(protocol);
        let entryString: string;
        if (isTask) {
          entryString = taskShortId(entry.number, entriesById[entry.parentId]?.number);
        } else {
          entryString = entryShortId(this.locale, protocolTypesById, protocol, entry, entriesById[entry.parentId]);
        }
        const row: ProjectCostsRowWithCompanyAndTax = {
          protocolEntry: entry,
          protocolId: entry.protocolId,
          projectId: protocol?.projectId,
          entry: entryString,
          isTask,
          netCosts,
          tax: protocol?.includesVat ? parseFloat(`${entry.cost}`) - netCosts : Math.ceil(netCosts * taxRate) / 100,
          craft: craftById[entry.craftId]?.name ?? null,
          title: entry.title,
          company: entry.companyId ?? null,
        };
        return row;
      });
    const groups = _.sortBy(
      (Object.entries(_.groupBy(entryRows, 'company')) as [string, ProjectCostsRowWithCompanyAndTax[]][]).map<ProjectCostsGroup>(([company, rows]) => ({
        company: companyById[company]?.name ?? null,
        totalNetCosts: rows.reduce((acc, {netCosts}) => acc + netCosts, 0),
        totalTaxCosts: rows.reduce((acc, {tax}) => acc + tax, 0),
        rows: _.sortBy(
          rows.map(({company: _company, ...row}) => row),
          ['entry']
        ),
      })),
      [(g: ProjectCostsGroup) => g.company?.toLocaleLowerCase()]
    ) as ProjectCostsGroup[];

    const totalNetCosts = groups.reduce((acc, v) => acc + v.totalNetCosts, 0);
    const totalTaxCosts = groups.reduce((acc, v) => acc + v.totalTaxCosts, 0);

    const details: ProjectCostsDetails = {
      groups,
      taxRate,
      totalNetCosts,
      totalTaxCosts,
      totalCosts: totalNetCosts + totalTaxCosts,
    };

    return details;
  }
}
