<app-modal-header>
  <ion-title>
    @if (isInternal) {
      {{'contactCreate.internal.header' | translate}}
    } @else {
      {{'contactCreate.external.header' | translate}}
    }
  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  @if (isInternal) {
    <span [innerHTML]="'contactCreate.internal.message' | translate"></span>
  } @else {
    <span class="text-primary" [innerHTML]="'contactCreate.external.message' | translate"></span>
  }
</ion-content>

<app-modal-footer>
  <div class="footer-content">
    <div class="d-flex ion-justify-content-end ion-align-items-end pr-6">
      <ion-item class="omg omg-form-checkbox">
        <ion-checkbox label-placement="end" justify="start" [(ngModel)]="dontShowAgain">
          {{ 'contactCreate.notAgain' | translate }}
        </ion-checkbox>
      </ion-item>
    </div>

    <ion-buttons slot="end">
      <ion-button class="omg-btn-text" (click)="closeModal()">
        {{ 'contactCreate.internal.cancel' | translate }}
      </ion-button>

      @if (isInternal) {
        <ion-button class="omg-btn-primary" (click)="confirm()">
          {{ (isSmallScreen ? 'companyForm.employees.invite' : 'contactCreate.internal.invite') | translate }}
        </ion-button>
      } @else {
        <ion-button class="omg-btn-primary" (click)="confirm()">
          {{ (isSmallScreen ? 'companyForm.employees.invite' : 'contactCreate.external.invite') | translate }}
        </ion-button>
      }
    </ion-buttons>
  </div>
</app-modal-footer>
